import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  VStack,
  Stack,
  Heading,
  Text,
  Button,
  useDisclosure,
  Image,
  Grid,
  GridItem,
  TagLabel,
  Tag,
  Avatar,
  Link,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

import { Location } from "@styled-icons/entypo/Location";
import { Warning } from "@styled-icons/entypo/Warning";
import { HourglassSplit } from "@styled-icons/bootstrap/HourglassSplit";
import { CalendarDate } from "@styled-icons/bootstrap/CalendarDate";
import { Stars } from "@styled-icons/bootstrap/Stars";
import { Lightning } from "@styled-icons/bootstrap/Lightning";
import { Computer } from "@styled-icons/material-rounded/Computer";
import ModalApply from "../Modals/ModalApply";
import ModalCooptation from "../Modals/ModalCooptation";
import YellowCircle from "../../images/yellow-circle.png";
import QuartCircle from "../../images/quart-circle.png";
import { UserProfiles } from "../../libs/authHeaderLib";
import { getUserProfile } from "../../libs/authHeaderLib";
import { useHistory } from "react-router-dom";

function FreelanceMissionDetailedAvailable({ mission }) {
  //navigation react router dom
  let history = useHistory();

  const userProfile = getUserProfile();
  const {
    isOpen: isApplicationOpen,
    onOpen: onApplicationOpen,
    onClose: onApplicationClose,
  } = useDisclosure();
  const {
    isOpen: isCooptationOpen,
    onOpen: onCooptationOpen,
    onClose: onCooptationClose,
  } = useDisclosure();
  const [isMobile, setIsMobile] = useState(false);
  const [hasApplied, setHasApplied] = useState(false);
  const [hasReferred, setHasReferred] = useState(false);
  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    applyResponsive();
  }, []);

  window.addEventListener("resize", applyResponsive);

  return (
    <>
      <Flex>
        <Box
          w="100%"
          overflow="hidden"
          mt={isMobile ? "100px" : "70px"}
          ml="20px"
          mr="20px"
          mb="20px"
          padding="5"
        >
          {!mission.published && (
            <>
              <Flex justify="center">
                <Box
                  padding="5"
                  borderRadius={5}
                  boxShadow="md"
                  bgColor="#fff"
                  w="50%"
                >
                  <Flex direction="column" gap="2vh">
                    <Box>
                      <Flex align="center">
                        <Box>
                          <Warning size="25px" color="red" />
                        </Box>
                        <Box ml="1vw">
                          <Text fontSize="20px">
                            Cette mission n'est plus disponible.
                          </Text>
                        </Box>
                      </Flex>
                    </Box>
                    <Box ml="2vw">
                      <Button
                        bgColor="gray.200"
                        _hover={{ bgColor: "gray.300" }}
                        onClick={() => {
                          history.push("/freelance/missions");
                        }}
                        borderRadius={0}
                      >
                        Retour aux missions
                      </Button>
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            </>
          )}
          <Flex
            mt={isMobile ? null : "40px"}
            p={4}
            color="black"
            borderRadius="7px"
            justify="center"
          >
            <Flex h="30px" align="left" mb="20px">
              <Heading
                as="h1"
                size="lg"
                mb="40px"
                align={isMobile ? "left" : "center"}
              >
                <Stars size="40px" color="#F8C708" /> {mission.name}
              </Heading>
            </Flex>
          </Flex>
          <Box
            bg="#003B36"
            mr={isMobile ? null : "90px"}
            ml={isMobile ? null : "90px"}
            p={2}
            borderTopRadius="7px"
            h="40px"
          ></Box>
          <Box
            bg="white"
            mr={isMobile ? null : "90px"}
            ml={isMobile ? null : "90px"}
            h="flex"
            color="black"
            borderBottomRadius={7}
            padding="5"
          >
            <Grid
              templateColumns={isMobile ? "null" : "repeat(3, 1fr)"}
              gap={3}
              padding={isMobile ? "null" : "5"}
            >
              <GridItem w="100%" h="300px">
                <VStack
                  direction={["column", "row"]}
                  spacing="24px"
                  mt="1"
                  mr="40px"
                  ml={isMobile ? "null" : "120px"}
                  fontWeight="semibold"
                  as="h3"
                  lineHeight="tight"
                  isTruncated
                  align="left"
                >
                  <Box
                    color="gray.600"
                    fontSize="20px"
                    mt={isMobile ? "20px" : "null"}
                    mb={isMobile ? "20px" : "null"}
                  >
                    Boarding pass de la mission
                  </Box>
                  <Box>
                    <CalendarDate size="20px" /> Date de début :{" "}
                    {mission.beginDate}
                  </Box>

                  <Box>
                    <HourglassSplit size="20px" /> Taille de mission :{" "}
                    {mission.sizing}
                  </Box>
                  <Box>
                    <Location size="20px" /> Localisation : {mission.location}
                  </Box>
                  <Box>
                    <Lightning size="20px" /> Cadence : {mission.timing}
                  </Box>
                  <Box>
                    <Computer size="20px" /> Lieu de travail :{" "}
                    {mission.homeoffice}
                  </Box>
                  {userProfile !== UserProfiles.FREELANCE && (
                    <Box mt="5%">
                      <Link
                        as={RouterLink}
                        to={"/freelance/missions/" + mission.id + "/onboarding"}
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          color="white"
                          bg="yalink.green"
                          _hover={{ bg: "yalink.yellow" }}
                        >
                          Onboarder un freelance
                        </Button>
                      </Link>
                    </Box>
                  )}
                </VStack>
              </GridItem>
              <GridItem w="100%" h={isMobile ? "null" : "300px"}>
                <Box>
                  <VStack
                    padding="5"
                    direction={["column", "row"]}
                    spacing="24px"
                    fontWeight="semibold"
                    as="h3"
                    align="left"
                    ml={isMobile ? null : "100px"}
                  >
                    <Box>
                      <Text>Format :</Text>
                      <Tag
                        size="lg"
                        color="#1a202c"
                        bgColor="#edf2f7"
                        borderRadius="md"
                        mr={3}
                        mt={3}
                        mb={3}
                        key={mission.serviceType}
                        boxShadow="base"
                      >
                        <Avatar
                          size="xs"
                          name={mission.serviceType}
                          ml={-1}
                          mr={2}
                        />
                        <TagLabel>{mission.serviceType}</TagLabel>
                      </Tag>
                    </Box>
                    <Box>
                      <Text>Secteur :</Text>
                      <Tag
                        size="lg"
                        color="#1a202c"
                        bgColor="#edf2f7"
                        borderRadius="md"
                        mr={3}
                        mt={3}
                        mb={3}
                        key={mission.sector}
                        boxShadow="base"
                      >
                        <Avatar
                          size="xs"
                          name={mission.sector}
                          ml={-1}
                          mr={2}
                        />
                        <TagLabel>{mission.sector}</TagLabel>
                      </Tag>
                    </Box>
                    <Box>
                      <Text>Métier :</Text>
                      <Tag
                        size="lg"
                        color="#1a202c"
                        bgColor="#edf2f7"
                        borderRadius="md"
                        mr={3}
                        mt={3}
                        mb={3}
                        key={mission.job}
                        boxShadow="base"
                      >
                        <Avatar size="xs" name={mission.job} ml={-1} mr={2} />
                        <TagLabel>{mission.job}</TagLabel>
                      </Tag>
                    </Box>
                    {mission.skill && (
                      <Box>
                        <Text>Compétences :</Text>
                        {mission.skill}
                      </Box>
                    )}
                  </VStack>
                </Box>
              </GridItem>
              <GridItem w="100%" align="right" display={isMobile && "none"}>
                <Box mt="180px">
                  <Image w="135px" src={QuartCircle} />
                </Box>
              </GridItem>
            </Grid>
          </Box>
          <Box
            bg="white"
            mb="30px"
            p={25}
            color="black"
            borderRadius="7px"
            padding="5"
            mr={isMobile ? "0px" : "90px"}
            ml={isMobile ? "0px" : "90px"}
            mt="30px"
          >
            <Grid
              templateColumns={isMobile ? "null" : "repeat(4, 1fr)"}
              gap={2}
            >
              <GridItem colSpan={3}>
                <Box
                  ml={isMobile ? "null" : "140px"}
                  fontWeight="semibold"
                  as="h3"
                  color="gray.600"
                  fontSize="20px"
                  mt={isMobile ? "20px" : "null"}
                  mb={isMobile ? "20px" : "null"}
                >
                  Description de la mission
                </Box>
                <Box
                  ml={isMobile ? null : "40px"}
                  mt={isMobile ? "15px" : "30px"}
                  mb="15px"
                  overflow="auto"
                  h="300px"
                >
                  {mission.description.split("\n").map((line) => (
                    <Text mb="10px">{line}</Text>
                  ))}
                </Box>
              </GridItem>
              <GridItem align="right" display={isMobile && "none"} colSpan={1}>
                <Box mr="20px" overflow="auto" h="300px" mt="20px">
                  <Image src={YellowCircle}></Image>
                </Box>
              </GridItem>
            </Grid>
          </Box>
          <Stack
            direction={["column", "row"]}
            fontWeight="semibold"
            lineHeight="tight"
            mt={isMobile ? "60px" : "20px"}
            mb="15px"
            mr="20px"
            ml="20px"
            justify="center"
          >
            <Button
              color="white"
              bg="yalink.green"
              _hover={{ bg: "yalink.yellow" }}
              _checked={{
                bg: "yalink.yellow",
                color: "white",
                borderColor: "yalink.yellow",
              }}
              type="submit"
              onClick={onApplicationOpen}
              isDisabled={!mission.published || hasApplied ? true : false}
            >
              Je postule
            </Button>

            <ModalApply
              isOpen={isApplicationOpen}
              onClose={onApplicationClose}
              mission={mission}
              setHasApplied={setHasApplied}
            />

            <Button
              color="white"
              bg="yalink.green"
              _hover={{ bg: "yalink.yellow" }}
              _checked={{
                bg: "yalink.yellow",
                color: "white",
                borderColor: "yalink.yellow",
              }}
              type="submit"
              onClick={onCooptationOpen}
              isDisabled={!mission.published || hasReferred ? true : false}
            >
              Je coopte
            </Button>

            <ModalCooptation
              isOpen={isCooptationOpen}
              onClose={onCooptationClose}
              mission={mission}
              setHasReferred={setHasReferred}
            />
          </Stack>
        </Box>
      </Flex>
    </>
  );
}

export default FreelanceMissionDetailedAvailable;
