import React from "react";
import { LoginSection } from '../../components';
import { homeObjOne } from './Data';


export default function Signup() {
  return (
    <>
      <LoginSection {...homeObjOne} />
    </>
  )
}