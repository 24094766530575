import { useState, useEffect } from "react";

import { Box, Text, SimpleGrid, Button, Heading, Link } from "@chakra-ui/react";

function Mandats() {
  const [isMobile, setIsMobile] = useState(false);
  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    applyResponsive();
  }, []);

  window.addEventListener("resize", applyResponsive);

  return (
    <Box
      mt="7%"
      mb={isMobile && "120px"}
      padding="7"
      borderWidth={1}
      borderRadius={8}
      boxShadow="md"
      bgColor="#fff"
    >
      <Heading mb="20px" size="md" color="gray.600">
        Mandats
      </Heading>
      <Text fontSize="xs" mb={5}>
        Lorsque vos missions sont terminées, Yalink s'occupe d'émettre les
        factures afin de vous simplifier les tâches administratives. Elles
        reprennent les informations que vous avez saisies et sont disponibles à
        tout moment dans votre espace personnel.
      </Text>
      <Text fontSize="xs" mb={5} color="red">
        Ces mandats sont obligatoires pour pouvoir utiliser la plateforme. Vous
        les avez accepté lors de votre inscription sur notre plateforme. Vous
        pouvez à tout moment vous désinscrire.
      </Text>
      <SimpleGrid columns={2}>
        <Box>
          <Text fontWeight="bold">Mandat de facturation</Text>
        </Box>
        <Box ml={isMobile ? "20px" : "-20px"}>
          <Button bgColor="yalink.yellow" color="white" h="18px">
            <Link
              href="https://docs.google.com/document/d/e/2PACX-1vSu_EkXHlQq-7UffwmttyE2GwLBd4bvquEsDf6QCefxW_dc9fvCl4PjMiavn5SzgRfuOm9rq5YQDW8N/pub"
              isExternal
            >
              Consulter
            </Link>
          </Button>
        </Box>
      </SimpleGrid>
      <SimpleGrid columns={2} mt="5%">
        <Box>
          <Text fontWeight="bold">Mandat de gestion d'affacturage</Text>
        </Box>
        <Box ml={isMobile ? "20px" : "-20px"}>
          <Button bgColor="yalink.yellow" color="white" h="18px">
            <Link
              href="https://docs.google.com/document/d/e/2PACX-1vSatfyLrKhMLW5FOREjNlIVyGmO2UkpWNhtxuxMMnCHP0eTLNrjsk-RJDTN5q-jbNOzhYfk53AwMzqQ/pub"
              isExternal
            >
              Consulter
            </Link>
          </Button>
        </Box>
      </SimpleGrid>
    </Box>
  );
}

export default Mandats;
