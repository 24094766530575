import React from 'react';
import { homeObjOne } from './Data';
import { InfoSection, Pricing } from '../../components';

function Company() {
  return (
    <>
      <InfoSection {...homeObjOne} />
      <Pricing />
    </>
  );
}

export default Company  ;