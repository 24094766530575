import React from "react";

import {
  Box,
  Text,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
} from "@chakra-ui/react";
import { useHistory, useParams } from "react-router-dom";

export default function ValidationModalClient({ isOpen, onClose }) {
  const history = useHistory();
  const { missionId} = useParams();

  return (
    <Box>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        closeOnOverlayClick={false}
        bgColor="yalink.green"
      >
        <ModalOverlay backdropFilter="blur(10px)" />
        <ModalContent bgColor="yalink.green">
          <ModalBody>
            <Flex justify="center" align="center" direction="column">
              <Box mb="3%">
                <Text fontSize="18px" color="white">
                  Merci d’avoir validé le CRA de votre freelance !<br></br>
                  Une notification de confirmation lui sera envoyé dès
                  maintenant
                </Text>
              </Box>
            </Flex>
          </ModalBody>

          <ModalFooter alignSelf="flex-start" pt="0">
            <Box>
              <Button
                bgColor="gray.100"
                mr={3}
                onClick={() => {
                  history.push("/freelance/missions/ongoing/" + missionId);
                }}
                color="gray.700"
                borderRadius="0"
                size="xs"
                _hover={{ bgColor: "gray.300" }}
              >
                Retour à la vue détaillée
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
