import Missions from "../../../services/mission.service";

export async function handleSubmit(
  mission,
  year,
  month,
  values,
  actions,
  history,
  toast
) {
  await Missions.updateActivityReport(mission.id, year, month, values)
    .then(() => {
      history.push([history.location.pathname, "review"].join("/"));
    })
    .catch(() => {
      toast({
        position: "top",
        title:
          "Veuillez vérifier les données de votre compte rendu d'avancement",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    });
  actions.setSubmitting(false);
}

export async function handleAlert(mission, year, month, toast) {
  await Missions.alertActivityReport(mission.id, year, month).then(() => {
    toast({
      position: "top",
      title:
        "L'équipe a été informée de ton soucis et nous allons revenir vers toi",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  });
}
