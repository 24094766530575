import axios from "axios";
import authHeader from "../libs/authHeaderLib";
import config from "../config";

class JobTitleService {
  async addJobTitleToFreelance(jobTitle) {
    let path = "/jobtitle/add";
    return axios.put(config.API_URL + path, jobTitle, {
      headers: authHeader(),
    });
  }

  async cleanUpJobTitles() {
    let path = "/jobtitle/cleanup";
    return axios.put(
      config.API_URL + path,
      {},
      {
        headers: authHeader(),
      }
    );
  }

  async getAllJobTitles() {
    let path = "/jobtitle/all";
    return axios.get(config.API_URL + path, {
      headers: authHeader(),
    });
  }
}

export default new JobTitleService();
