import React from "react";
import { Select } from "@chakra-ui/react";

export default function PerPageSelector(props) {
  return (
    <Select
      ml="20px"
      variant="outline"
      bg="white"
      width="180px"
      value={props.perPage}
      onChange={(e) => {
        props.setPerPage(e.target.value);
        props.setPage(1);
        props.setIsLoading(true);
      }}
    >
      <option value={10}>10 par page</option>
      <option value={50}>50 par page</option>
      <option value={100}>100 par page</option>
    </Select>
  );
}
