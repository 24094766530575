import { useEffect, useState } from "react";
import { FormControl } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import ActivitySectorsService from "../../services/activitysectors.service";

function MultiselectSectors() {
  const [availableSectors, setAvailableSectors] = useState("");
  const [changedGroupedOptions, setChangedGroupedOptions] = useState([]);

  useEffect(() => {
    async function getActivitySectors() {
      await ActivitySectorsService.getAllActivitySectors().then((response) => {
        setAvailableSectors(response.data.activity_sectors);

        let computedData = [];

        for (const activity_area in response.data.activity_sectors) {
          for (const sector in response.data.activity_sectors[activity_area]) {
            let newGroupedOption = {};
            newGroupedOption["label"] = activity_area;

            let options = [
              response.data.activity_sectors[activity_area][sector],
            ];

            if (
              response.data.activity_sectors[activity_area][sector].sub_sector
            ) {
              options.push({
                value:
                  response.data.activity_sectors[activity_area][sector].sector +
                  " - " +
                  response.data.activity_sectors[activity_area][sector]
                    .sub_sector,
                label:
                  response.data.activity_sectors[activity_area][sector].sector +
                  " - " +
                  response.data.activity_sectors[activity_area][sector]
                    .sub_sector,
              });

              newGroupedOption["options"] = options;
            } else {
              options.push({
                value:
                  response.data.activity_sectors[activity_area][sector].sector,
                label:
                  response.data.activity_sectors[activity_area][sector].sector,
              });
              newGroupedOption["options"] = options;
            }
            computedData.push(newGroupedOption);
          }
        }
        setChangedGroupedOptions(computedData);
      }).catch(() => {});
    }
    if (!availableSectors) {
      getActivitySectors();
    }
  }, [setChangedGroupedOptions, changedGroupedOptions, availableSectors]);

  return (
    changedGroupedOptions && (
      <>
        <FormControl>
          <Select
            isMulti
            name="sector"
            options={changedGroupedOptions}
            placeholder="Selectionne un secteur..."
            closeMenuOnSelect={false}
            hasStickyGroupHeaders
          />
        </FormControl>
      </>
    )
  );
}

export default MultiselectSectors;
