import axios from "axios";
import config from "../config";
import { querystring } from "../libs/queryStringLib";
import { hasAuthHeader } from "../libs/authHeaderLib";

class Auth {
  async login({ email, password }) {
    return axios
      .post(config.API_URL + "/auth/login", {
        email,
        password,
      })
      .then((response) => {
        if (response.data.auth_token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
      });
  }

  loginFromQueryStringToken() {
    let token = querystring("token", window.location.href);
    if (token) {
      // Set auth header from token if logged in as external OR not logged in
      if (!hasAuthHeader() || hasAuthHeader().external) {
        localStorage.setItem(
          "user",
          JSON.stringify({ auth_token: token, external: true })
        );
        return true;
      }
    }
    return false;
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("linkedin_oauth2_state");
  }

  async linkedinLogin({ code }) {
    return axios
      .get(config.API_URL + "/auth/linkedin/login?code=" + code)
      .then((response) => {
        if (response.data.auth_token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
      });
  }

  async signup({ email, password }) {
    return axios.post(config.API_URL + "/auth/signup", {
      email: email,
      password: password,
    });
  }

  getCurrentUser() {
    if (localStorage.getItem("user")) {
      return JSON.parse(localStorage.getItem("user"));
    } else {
      return null;
    }
  }

  isFirstTimeLogin() {
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      if ("first_time_login" in user && user.first_time_login === "true") {
        return true;
      }
    }
    return false;
  }

  isAdmin() {
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      if ("admin" in user && user.admin) {
        return true;
      }
    }
    return false;
  }
}

export default new Auth();
