import React from "react";

import { Flex, Button, useDisclosure } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import NoActivityToReportModal from "./NoActivityToReport";

export interface MissionProps {
  id: Number
}

export interface InputChoicesProps {
  mission: MissionProps
  today: Date
}

export default function InputChoices({ mission, today }: InputChoicesProps) {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex flexDirection="column">
      <Button
        color="yalink.green"
        borderRadius={0}
        mb="2"
        onClick={() =>
          history.push(
            [
              "/freelance/missions/ongoing",
              mission.id,
              "activity-report",
              today.getFullYear(),
              today.getMonth() + 1,
            ].join("/")
          )
        }
      >
        Saisir CRA
      </Button>
      <Button color="white" bg="yalink.teal" borderRadius={0} onClick={onOpen}>
        Ne rien déclarer
      </Button>
      <NoActivityToReportModal
        isOpen={isOpen}
        onClose={onClose}
        missionId={mission.id}
        year={today.getFullYear()}
        month={today.getMonth() + 1}
      />
    </Flex>
  );
}
