export function setProgressValue(
    tempFreelanceDetails,
    tempNewSummary
  ) {
    let tempFinalValue = 0;
    if (tempFreelanceDetails.skills.length !== 0) {
      tempFinalValue += 20;
    }
    if (tempNewSummary !== "") {
      tempFinalValue += 10;
    }
    if (
      tempFreelanceDetails.experiences &&
      tempFreelanceDetails.experiences.length !== 0
    ) {
      tempFinalValue += 20;
    }
    if (
      tempFreelanceDetails.education &&
      tempFreelanceDetails.education.length !== 0
    ) {
      tempFinalValue += 15;
    }
    if (tempFreelanceDetails.languages.length !== 0) {
      tempFinalValue += 5;
    }
    if (tempFreelanceDetails.sectors.length !== 0) {
      tempFinalValue += 15;
    }
    if (tempFreelanceDetails.jobTitles.length !== 0) {
      tempFinalValue += 15;
    }
    return(tempFinalValue);
  }