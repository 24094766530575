import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useAppContext } from "../../libs/contextLib";
import { LoadingComponent } from "../../components";
import Auth from "../../services/auth.service";
import FreelanceMissionDetailed from "../../components/FreelanceMissions/FreelanceMissionDetailed";

import Missions from "../../services/mission.service";

function FreelanceOngoingMissionsDetailed() {
  const { userHasAuthenticated } = useAppContext();
  const toast = useToast();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [mission, setMission] = useState(null);
  const { missionId } = useParams();

  async function onLoad() {
    if (Auth.loginFromQueryStringToken()) {
      userHasAuthenticated(true);
    }

    await Missions.getMission(missionId)
      .then((response) => {
        setMission({ ...response.data.mission });
      })
      .catch((error) => {
        toast({
          position: "top",
          title: "Vous n'êtes pas autorisé à voir cette mission",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        history.push("/freelance/missions");
      });
  }
  if (isLoading) {
    onLoad();
    setIsLoading(false);
  }

  return (
    [isLoading, missionId],
    mission ? (
      <FreelanceMissionDetailed mission={mission} />
    ) : (
      <LoadingComponent />
    )
  );
}

export default FreelanceOngoingMissionsDetailed;
