import cardImg from '../../images/cards.svg'

export const homeObjOne = {
    primary: true,
    lightBg: true,
    lightTopLine: true,
    lightText: false,
    lightTextDesc: true,
    headline: 'Trouver un indépendant est devenu simple et efficace',
    description:
      "Recrutez en toute transparence l'ingénieur dont vous avez besoin.",
    buttonLabel: 'En savoir plus',
    imgStart: '',
    img: cardImg,
    alt: 'Rocket launch',
    start: ''
  };
