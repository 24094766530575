export function isEmailValid(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function matchingFreelanceList(freelanceList, freelanceFilter) {
  return freelanceList.filter((freelance) => {
    return (
      (freelance.first_name
        ? freelance.first_name
            .toLowerCase()
            .includes(freelanceFilter.toLowerCase())
        : false) ||
      (freelance.last_name
        ? freelance.last_name
            .toLowerCase()
            .includes(freelanceFilter.toLowerCase())
        : false) ||
      (freelance.headline
        ? freelance.headline
            .toLowerCase()
            .includes(freelanceFilter.toLowerCase())
        : false) ||
      (freelance.email
        ? freelance.email.toLowerCase().includes(freelanceFilter.toLowerCase())
        : false)
    );
  });
}

export function convertDate(date) {
  var dateArray = date.split("-");
  var yyyy = dateArray[0];
  var mm = dateArray[1] - 1;
  var dd = dateArray[2];

  return new Date(yyyy, mm, dd);
}
