import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Source Sans Pro', sans-serif;
  }
  *:before,
  *:after {
    box-sizing: border-box;
  }
  html,
  body {
    height: 100%;
    position: relative;
    background: #f8fcfc;
}

.main-container {
    background: #f8fcfc;
    min-height: 100vh; /* will cover the 100% of viewport */
    position: relative;
  }
   

.content-wrapper {
  /* Footer Height */
  padding-bottom: 30vh
}
#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30vh;            /* Footer height */
}
`;

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;

  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const Button = styled.button`
  background: ${({ primary }) => (primary ? "#F8C708" : "#242424")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
  color: #fff;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    transition: all 0.3s ease-out;
    transform: scale(1.1);
  }

  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const ButtonSecondary = styled.button`
  border-radius: 4px;
  background: ${({ primary }) => (primary ? "#fff" : "#F8C708")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
  color: #242424;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    background-color: ${({ primary }) => (primary ? "#4B4A4A" : "#d4ad11")};
    color: #fff;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const NavLogo = styled.a`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
`;

export const NavIcon = styled.div`
  margin-right: 0.5rem;
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 130px;
  vertical-align: middle;
  display: inline-block;
  max-height: 300px;
`;

export default GlobalStyle;
