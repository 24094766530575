import React from "react";
import Sidebar from "../../components/AdminDashboard/Molecules/Sidebar";
import ActivityReportList from "../../components/AdminDashboard/Molecules/Tools/ActivityReportList";
import { Flex, Box } from "@chakra-ui/react";

function AdminDashboard() {
  return (
    <>
      <Flex gap="0px" rowGap="0px">
        <Box>
          <Sidebar />
        </Box>
        <Box w="100%">
          {" "}
          <ActivityReportList />
        </Box>
      </Flex>
    </>
  );
}

export default AdminDashboard;
