import React, { useEffect, useState } from "react";
import { Box, Grid, GridItem, Center, Text } from "@chakra-ui/react";
import Job from "../../components/Job/Job";
import { PersonSquare } from "@styled-icons/bootstrap/PersonSquare";
import { ChevronDownSquare } from "@styled-icons/boxicons-regular/ChevronDownSquare";

function MobileJob({ isMobile }) {
  return (
    <Box bg="white">
      <Box ml="10%" mr="10%" mt="50px">
        <Text fontSize="30px" fontWeight="bold" mb="2%">
          Renseigne les métiers sur lesquels tu veux recevoir des missions !
        </Text>
        <Job isMobile={isMobile} />
      </Box>
    </Box>
  );
}

function FreelanceJob() {
  const [isMobile, setIsMobile] = useState(false);

  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    applyResponsive();
  }, []);

  window.addEventListener("resize", applyResponsive);

  return isMobile ? (
    <MobileJob isMobile={isMobile} />
  ) : (
    <Grid templateColumns="1fr 1fr" h="flex">
      <GridItem h="100%" bg="white">
        <Box ml="10%" mr="10%" mt="50px">
          <Text fontSize="30px" fontWeight="bold" mb="4%">
            Renseigne les métiers sur lesquels tu veux recevoir des missions !
          </Text>
          <Job isMobile={isMobile} freelance />
        </Box>
      </GridItem>
      <GridItem h="100%" bg="#f8fcfc">
        <Center>
          <Box
            mb="50px"
            mt="70px"
            bg="white"
            h="flex"
            width="70%"
            borderRadius={7}
            boxShadow="md"
          >
            <Grid templateColumns="1fr 2fr" ml="10%" mr="10%" mt="50px">
              <GridItem>
                <PersonSquare size="40px" />
              </GridItem>
              <GridItem>
                <Text fontWeight="bold">
                  A quoi cela sert de bien définir mes métiers?
                </Text>
                <Text fontSize="13px">
                  Tu recevras des notifications à chaque fois qu'une nouvelle
                  mission match un des métiers sélectionnés.
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns="1fr 2fr" ml="10%" mr="10%" mt="50px">
              <GridItem>
                <ChevronDownSquare size="50px" />
              </GridItem>
              <GridItem>
                <Text fontWeight="bold">
                  Combien de métiers puis-je ajouter?
                </Text>
                <Text fontSize="13px" mb="20px">
                  Tu peux choisir uniquement 3 métiers par catégories.
                  <br />
                  Nous te conseillons d'avoir un profil spécialisé. Un client
                  préfère choisir un freelance spécialisé dans le/les domaines
                  de sa mission.
                  <br />
                  Si tu indiques trop de métiers, tu risqueras d'être perçu
                  comme "touche-à-tout et expert en rien."
                </Text>
              </GridItem>
            </Grid>
          </Box>
        </Center>
      </GridItem>
    </Grid>
  );
}

export default FreelanceJob;
