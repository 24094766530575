import { useEffect, useState } from "react";
import Table from "./Molecules/table";
import { Center, Box, Badge } from "@chakra-ui/react";

import Pagination from "../Utils/Pagination";
import PerPageSelector from "../Utils/PerPageSelector";
import { querystring } from "../../libs/queryStringLib";
import Deals from "../../services/deal.service";

export default function FreelanceMissionAvailable({
  totalItems,
  setTotalItems,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(
    querystring("page", window.location.href)
      ? querystring("page", window.location.href)
      : 1
  );
  const [perPage, setPerPage] = useState(
    querystring("per_page", window.location.href)
      ? querystring("per_page", window.location.href)
      : 10
  );
  const requestUnpublishedOffers = querystring(
    "unpublished",
    window.location.href
  );
  const [deals, setDeals] = useState(null);

  useEffect(() => {
    async function onLoad() {
      await Deals.getDeals(page, perPage, requestUnpublishedOffers)
        .then((response) => {
          setDeals(response.data.deals);
          setTotalItems(response.data.total);
        })
        .catch((error) => {});
    }
    if (isLoading) {
      onLoad();
      setIsLoading(false);
    }
  }, [
    page,
    perPage,
    isLoading,
    setDeals,
    requestUnpublishedOffers,
    setTotalItems,
  ]);

  function handleSelectedPage(selectedPage) {
    setPage(selectedPage);
    setIsLoading(true);
  }
  const [isMobile, setIsMobile] = useState(false);
  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    applyResponsive();
  }, []);

  window.addEventListener("resize", applyResponsive);

  return (
    !isLoading && (
      <>
        <Center>
          <Table.Container p={4} mr="20px" ml="20px" bg="white" width="90%">
            <Table.Table>
              <Table.Thead bg="gray.200">
                <Table.Tr>
                  <Table.Th display={isMobile && "none"}>Secteur</Table.Th>
                  <Table.Th>Mission</Table.Th>
                  <Table.Th>Métier</Table.Th>
                  <Table.Th display={isMobile && "none"}>Cadence</Table.Th>
                  <Table.Th display={isMobile && "none"}>
                    Lieu de travail
                  </Table.Th>
                  <Table.Th display={isMobile && "none"}>
                    Date de début
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {deals &&
                  deals.map((deal) => (
                    <Table.Tr
                      onClick={() => {
                        const win = window.open(
                          "/freelance/missions/" + deal.id,
                          "_blank"
                        );
                        win.focus();
                        // history.push("/freelance/missions/" + deal.id);
                      }}
                      key={deal.id}
                      _hover={{ bg: "yalink.yellow", cursor: "pointer" }}
                    >
                      <Table.Td display={isMobile && "none"}>
                        <Badge variant="solid" color="black" bg="#FFCD00">
                          {deal.sector}
                        </Badge>
                      </Table.Td>
                      <Table.Td>{deal.name}</Table.Td>
                      <Table.Td>
                        <Badge variant="solid" bg="#003B36">
                          {deal.jobTitle}
                        </Badge>
                      </Table.Td>
                      <Table.Td display={isMobile && "none"}>
                        {deal.workRythm}
                      </Table.Td>
                      <Table.Td display={isMobile && "none"}>
                        {deal.postalCode}
                      </Table.Td>
                      <Table.Td display={isMobile && "none"}>
                        {deal.startDate}
                      </Table.Td>
                    </Table.Tr>
                  ))}
              </Table.Tbody>
            </Table.Table>
          </Table.Container>
        </Center>
        <Center>
          <Box mb="10px" padding="5">
            <PerPageSelector
              perPage={perPage}
              setPerPage={setPerPage}
              setPage={setPage}
              setIsLoading={setIsLoading}
            />
          </Box>
        </Center>
        <Pagination
          totalItems={totalItems}
          perPage={perPage}
          handleSelectedPage={handleSelectedPage}
          page={page}
        />
      </>
    )
  );
}
