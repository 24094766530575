import React, { useState } from "react";

import {
  Box,
  Avatar,
  HStack,
  Button,
  Spacer,
  Badge,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Stack,
} from "@chakra-ui/react";
import Offers from "../../services/offers.service";
import ContactForm from "./ContactForm";
import { useAppContext } from "../../libs/contextLib";
import { hasAuthHeader } from "../../libs/authHeaderLib";
import { ClientReferenceCard, NewClientReference } from "../../components";
import { Dollar } from "@styled-icons/boxicons-regular/Dollar";
import { House } from "@styled-icons/material/House";
import { PersonFill } from "@styled-icons/bootstrap/PersonFill";
import { Stars } from "@styled-icons/bootstrap/Stars";
import { Telephone } from "@styled-icons/bootstrap/Telephone";
function FreelanceSummary(props) {
  const { userHasAuthenticated } = useAppContext();
  const [externalInteraction] = useState(hasAuthHeader().external);
  const [isInterested, setisInterested] = useState(false);
  const [references, setreferences] = useState(props.freelance.references);
  const freelanceProfilePath =
    "/freelance/profile/view?id=" + props.freelance.id;
  const freelanceEnterprisePath =
    "/freelance/enterprise?id=" + props.freelance.id;
  async function handleAddFreelanceToOffer() {
    await Offers.addFreelanceToOffer(props.selectedOfferId, props.freelance.id)
      .then((response) => {
        props.setReloadOfferList(true);
      })
      .catch(() => {
        userHasAuthenticated(false);
      });
  }

  function handleViewProfile() {
    const win = window.open(freelanceProfilePath, "_blank");
    win.focus();
  }
  function handleViewEnterprise() {
    const win = window.open(freelanceEnterprisePath, "_blank");
    win.focus();
  }

  async function handleRemoveFreelanceFromOffer() {
    await Offers.removeFreelanceFromOffer(
      props.attachedOffer,
      props.freelance.id
    )
      .then((response) => {
        props.setReloadOfferList(true);
      })
      .catch(() => {
        userHasAuthenticated(false);
      });
  }

  function ReferenceList() {
    return references
      ? references.map((reference) => (
          <ClientReferenceCard
            freelance={props.freelance}
            reference={reference}
            setReferences={setreferences}
          />
        ))
      : null;
  }

  function FirstContactTag() {
    let has_bad_grade = false;
    references.forEach((item, index) => {
      // ToDo Average is a legacy case that we can deco soon
      if (item.grade === "bad" || item.grade === "average") {
        has_bad_grade = true;
      }
    });
    return !has_bad_grade ? (
      <Badge colorScheme="green">Feedback positif</Badge>
    ) : (
      <Badge colorScheme="red">Feedback négatif</Badge>
    );
  }

  return (
    <Box
      mb="20px"
      width="100%"
      borderWidth={1}
      padding={5}
      borderRadius={5}
      bg="white"
      boxShadow="md"
    >
      <HStack>
        <Avatar
          name={
            props.freelance.first_name +
            " " +
            (props.freelance.last_name ? props.freelance.last_name : "")
          }
          src={props.freelance.profile_picture}
          size="xl"
          mr="5"
          mb="10px"
          showBorder="true"
        />
        <Spacer />
        <Stack>
          <Button colorScheme="teal" onClick={handleViewProfile}>
            Voir le profil
          </Button>
          {!externalInteraction ? (
            <Button colorScheme="orange" onClick={handleViewEnterprise}>
              Voir l'entreprise
            </Button>
          ) : null}
          {
            // Read only means that we are viewing the profile from a company offer view
            props.readOnly ? (
              // External interaction means that we are not admin but a company
              externalInteraction ? (
                <ContactForm
                  freelance={props.freelance}
                  selectedOfferId={props.selectedOfferId}
                  setIsInterested={setisInterested}
                  isInterested={isInterested}
                />
              ) : null
            ) : props.attachedOffer ? (
              <Button
                colorScheme="red"
                onClick={handleRemoveFreelanceFromOffer}
              >
                Enlever
              </Button>
            ) : (
              <Button colorScheme="green" onClick={handleAddFreelanceToOffer}>
                Ajouter
              </Button>
            )
          }
        </Stack>
      </HStack>

      <Box width="100">
        <Box
          fontWeight="semibold"
          letterSpacing="wide"
          fontSize="xl"
          textTransform="uppercase"
        >
          {props.freelance.first_name +
            " " +
            (props.freelance.last_name ? props.freelance.last_name : "")}
        </Box>
        {props.freelance.headline ? (
          <Box
            mt="1"
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            isTruncated
            maxWidth="350px"
          >
            <Stars size="25px" color="#F8C708" /> {props.freelance.headline}
          </Box>
        ) : null}
        {!externalInteraction &&
        props.freelance.tarification &&
        props.freelance.city ? (
          <HStack width="100%">
            <Box>
              <Dollar size="25px" />
              {" " + props.freelance.tarification}
              <Box as="span" color="gray.600" fontSize="sm">
                €/jour (tarif indicatif)
              </Box>
            </Box>
            <Box>
              <House size="25px" /> Disponible sur{" " + props.freelance.city}
            </Box>
          </HStack>
        ) : null}
        {
          // Email and phone can be obfuscated when shown to companies
          props.freelance.email && props.freelance.phone ? (
            <>
              <Box>
                <PersonFill size="25px" fill="#003b35" /> Contact:{" "}
                {props.freelance.email}
              </Box>
              <Box>
                <Telephone size="20px" /> Tel: {props.freelance.phone}
              </Box>
            </>
          ) : null
        }
        {props.freelance.availability ? (
          <HStack>
            <Box>
              {props.freelance.availability === "avail"
                ? "🟢 Disponible"
                : "🔴 Non disponible"}
            </Box>
            {!externalInteraction &&
            props.freelance.availability === "not_avail" ? (
              <Badge colorScheme="orange">
                Dispo: {props.freelance.availability_date}
              </Badge>
            ) : null}
            {props.freelance.last_preferences_update_time ? (
              <Badge colorScheme="blue">
                MàJ: {props.freelance.last_preferences_update_time}
              </Badge>
            ) : null}
          </HStack>
        ) : (
          // Here we check presence of email to see if summary is obfuscated or not
          !externalInteraction && (
            <Badge colorScheme="red">Boarding pass incomplet</Badge>
          )
        )}
        {!externalInteraction && (
          <Badge
            colorScheme={props.freelance.has_legal_data ? "green" : "orange"}
          >
            {props.freelance.has_legal_data
              ? "A des données Entreprise"
              : "Pas de données Entreprise"}
          </Badge>
        )}
        {!externalInteraction ? (
          <Accordion allowToggle mt="20px" mb="20px">
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <HStack>
                    {" "}
                    <Box flex="1" textAlign="left" fontWeight="semibold">
                      Références clients
                    </Box>
                    {props.freelance.references.length === 0 ? (
                      <Badge colorScheme="purple">Pas de refs</Badge>
                    ) : (
                      <FirstContactTag />
                    )}
                  </HStack>

                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <ReferenceList />
                <NewClientReference
                  freelance={props.freelance}
                  setReferences={setreferences}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        ) : null}
      </Box>
    </Box>
  );
}

export default FreelanceSummary;
