import React, { useState, useEffect } from "react";
import { Box, Grid, GridItem, Center, Text } from "@chakra-ui/react";
import { Euro } from "@styled-icons/boxicons-regular/Euro";
import { Hourglass } from "@styled-icons/boxicons-regular/Hourglass";
import { ArrowRepeat } from "@styled-icons/bootstrap/ArrowRepeat";
import FreelancePreferencesFormOnboarding from "../../components/FreelancePreferencesFormOnboarding/FreelancePreferencesFormOnboarding";

function FreelancePreferences() {
  const [hasFreelancePreferences, setHasFreelancePreferences] = useState(false);

  const [freelancePreferences, setFreelancePreferences] = useState({
    availability: "",
    tjm: "",
    city: "",
    workplace: "",
    area: "",
    length: "",
    daysperweek: "",
  });
  const [isMobile, setIsMobile] = useState(false);
  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    applyResponsive();
  }, []);

  window.addEventListener("resize", applyResponsive);

  return (
    <Grid
      h="flex"
      templateColumns={isMobile ? "1fr" : "1fr 1fr"}
      mb={isMobile ? "70px" : null}
    >
      <GridItem bg="white">
        <Box ml="10%" mr="10%" mt="50px">
          <Text fontSize="20px" fontWeight="bold">
            Tes préférences de missions
          </Text>
          <FreelancePreferencesFormOnboarding
            freelancePreferences={freelancePreferences}
            setFreelancePref={setFreelancePreferences}
            hasFreelancePref={hasFreelancePreferences}
            setHasFreelancePref={setHasFreelancePreferences}
            setFreelancePreferences={setFreelancePreferences}
          />
        </Box>
      </GridItem>
      <GridItem h="100%" bg="#f8fcfc" display={isMobile && "none"}>
        <Center>
          <Box
            mb="50px"
            mt="70px"
            bg="white"
            h="flex"
            width="70%"
            borderRadius={7}
            boxShadow="md"
          >
            <Grid templateColumns="1fr 2fr" ml="8%" mr="8%" mt="50px">
              <GridItem>
                <Euro size="40px" />
              </GridItem>
              <GridItem>
                <Text fontWeight="bold">Concernant ton TJM</Text>
                <Text fontSize="13px">
                  Ton TJM est uniquement
                  <Text fontWeight="bold" noOfLines={1}>
                    indicatif.
                  </Text>
                  <br />
                  Il nous sert à filtrer ton profil en focntion des missions que
                  nous avons. Par exemple, si tu as un TJM de 500€ nous n'allons
                  pas te proposer uune mission avec un TJM de 300€.
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns="1fr 2fr" ml="8%" mr="8%" mt="50px">
              <GridItem>
                <Hourglass size="40px" />
              </GridItem>
              <GridItem>
                <Text fontWeight="bold">Concernant les idées de missions</Text>
                <Text fontSize="13px">
                  Exemple pour une mission en régie nous privilègierons des
                  profils disponibles en temps plein (5jrs par semaine) sur une
                  longue durée.<Text></Text>
                  <br />
                  Sur une mision au forfait, nous privilègierons des profils
                  disponibles à temps partiel sur un temps court.
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns="1fr 2fr" ml="8%" mr="8%" mt="50px">
              <GridItem>
                <ArrowRepeat size="40px" />
              </GridItem>
              <GridItem>
                <Text fontWeight="bold">Concernant ta disponibilité</Text>
                <Text fontSize="13px" mb="20px">
                  Nous utilisons des filtres sur ta disponibilité afin de te
                  notifier sur des nouvelles missions !
                </Text>
              </GridItem>
            </Grid>
          </Box>
        </Center>
      </GridItem>
    </Grid>
  );
}

export default FreelancePreferences;
