import React, { useState } from "react";
import {
  Button,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  RadioGroup,
  Stack,
  Radio,
  Input,
  InputGroup,
  useToast,
} from "@chakra-ui/react";

import User from "../../services/user.service";

function ModalAvailability(props) {
  const toast = useToast();
  const [available, setAvailable] = useState(
    props.freelancePreferences.availability
  );
  const [availableDate, setAvailableDate] = useState(null);
  const today = new Date(Date.now());
  const dateInOneMonth = new Date(today.setMonth(today.getMonth() + 1));
  const dateInThreeMonth = new Date(today.setMonth(today.getMonth() + 2));
  const dateInSixMonth = new Date(today.setMonth(today.getMonth() + 3));

  function isAvailable() {
    if (available === "not_avail") {
      return false;
    } else {
      return true;
    }
  }

  function updateAvailability() {
    const preferences = {
      availability: available,
      availabilityDate: availableDate,
    };
    User.updateFreelancePreferences(preferences)
      .then(() => {
        toast({
          position: "top",
          title: "Disponibilité mise à jour",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch((error) => {});
    let tempFreelancePreferences = props.freelancePreferences;
    tempFreelancePreferences.availability = available;
    props.setFreelancePreferences({ ...tempFreelancePreferences });
    props.onClose();
  }

  return (
    <>
      <ModalHeader>Disponibilité</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        Etes-vous disponible pour recevoir des propositions ?
        <RadioGroup onChange={setAvailable} value={available}>
          <Stack spacing={5} direction="row">
            <Radio value="avail" colorScheme="green">
              Oui
            </Radio>
            <Radio value="not_avail" colorScheme="red">
              Non
            </Radio>
          </Stack>
        </RadioGroup>
      </ModalBody>

      <ModalBody>
        Merci d'indiquer quand vous serez à nouveau disponible (choisis une
        durée ou une date)
        <RadioGroup onChange={setAvailableDate} value={availableDate}>
          <Stack direction="row">
            <Radio
              value={dateInOneMonth.toISOString().slice(0, 10)}
              isDisabled={isAvailable()}
            >
              Dans 1 mois
            </Radio>
            <Radio
              value={dateInThreeMonth.toISOString().slice(0, 10)}
              isDisabled={isAvailable()}
            >
              Dans 3 mois
            </Radio>
            <Radio
              value={dateInSixMonth.toISOString().slice(0, 10)}
              isDisabled={isAvailable()}
            >
              Dans 6 mois
            </Radio>
          </Stack>
          <Stack spacing={4}>
            <InputGroup>
              <Input
                type="date"
                isDisabled={isAvailable()}
                value={availableDate}
                onChange={(e) => {
                  setAvailableDate(e.target.value);
                }}
              />
            </InputGroup>
          </Stack>
        </RadioGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          color="white"
          bg="yalink.green"
          _hover={{ bg: "yalink.yellow" }}
          onClick={() => updateAvailability()}
        >
          Valider
        </Button>
      </ModalFooter>
    </>
  );
}

export default ModalAvailability;
