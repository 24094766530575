import axios from "axios";
import authHeader from "../libs/authHeaderLib";
import config from "../config";

class NewsletterService {
  async getNewsletters() {
    let path = "/newsletter/";
    return axios.get(config.API_URL + path, {
      headers: authHeader(),
    });
  }
}

export default new NewsletterService();
