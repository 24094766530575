import React from 'react'

import {
  Box,
  Text,
  Flex,
} from "@chakra-ui/react";
import { Briefcase } from "@styled-icons/boxicons-solid/Briefcase";

export interface ActivityReportProps {
  amount_billed: Number
}

export default function BreakdownForfait({ activityReport }: { activityReport: ActivityReportProps }) {
  return (
    <>
      <Box mb="2%">
        <Text color="grey">Avancement</Text>
      </Box>
      <Box mb="2%">
        <Flex>
          <Box mr="4%">
            <Briefcase size="20px" />
          </Box>
          <Box>
            <Text>{activityReport.amount_billed + " € HT"}</Text>
          </Box>
        </Flex>
      </Box>
    </>
  )
}
