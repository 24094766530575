import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { Button, ButtonSecondary } from "../../globalStyles";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavIcon,
  NavMenu,
  NavMenuFlex,
  MobileIconFlex,
  NavItem,
  NavItemBtn,
  NavLinks,
} from "./Navbar.elements";
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Box,
  Grid,
  Image,
  GridItem,
  Flex,
} from "@chakra-ui/react";

import Auth from "../../services/auth.service";
import Notification from "../Notifications/Notifications";
import { useAppContext } from "../../libs/contextLib.js";
import img from "../../images/Fond-Blanc-x1.png";
import { NotificationsActive } from "@styled-icons/material-rounded/NotificationsActive";
import { Notifications } from "@styled-icons/material/Notifications";
import { Help } from "@styled-icons/material-rounded/Help";
import { getUserProfile, UserProfiles } from "../../libs/authHeaderLib";

function Navbar() {
  const [hasNotifications, setHasNotifications] = useState(false);
  const userProfile = getUserProfile();
  const history = useHistory();
  const [click, setClick] = useState(false);
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  // isMobile responsive useState and useEffect

  const [isMobile, setIsMobile] = useState(false);

  const location = useLocation();

  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    applyResponsive();
  }, []);

  window.addEventListener("resize", applyResponsive);

  function handleLogout() {
    Auth.logout();

    userHasAuthenticated(false);
    history.push("/login");
  }

  function sendToYalinkDocs() {
    window.open("https://www.yalink.fr/faq", "_blank").focus();
    return null;
  }
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  function UnAuthenticatedNavMenu() {
    return (
      <NavMenuFlex onClick={handleClick} click={click}>
        {/* <NavItem>
          <NavLinks to="/freelance" onClick={closeMobileMenu}>
            Freelances
          </NavLinks>
        </NavItem>
        <NavItem>
          <NavLinks to="/company" onClick={closeMobileMenu}>
            Entreprises
          </NavLinks>
        </NavItem> */}
        <NavItem>
          {!isMobile ? (
            <NavLinks to="/login">Me connecter</NavLinks>
          ) : (
            <NavLinks to="/login">
              <ButtonSecondary onClick={closeMobileMenu} fontBig primary>
                Me connecter
              </ButtonSecondary>
            </NavLinks>
          )}
        </NavItem>
        <NavItem>
          {!isMobile ? (
            <NavLinks to="/freelance/signup">Créer mon compte</NavLinks>
          ) : (
            <NavLinks to="/freelance/signup">
              <Button onClick={closeMobileMenu} fontBig primary>
                Créer mon compte
              </Button>
            </NavLinks>
          )}
        </NavItem>
      </NavMenuFlex>
    );
  }

  function FreelanceNavMenu() {
    return (
      <>
        <Box>
          <NavMenu>
            {!isMobile ? (
              <>
                <NavItem>
                  <NavLinks to="/freelance/home" onClick={closeMobileMenu}>
                    Dashboard
                  </NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks to="/freelance/profile" onClick={closeMobileMenu}>
                    Profil
                  </NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks
                    to="/freelance/enterprise"
                    onClick={closeMobileMenu}
                  >
                    Mon entreprise
                  </NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks to="/freelance/missions" onClick={closeMobileMenu}>
                    Missions
                  </NavLinks>
                </NavItem>
                <NavItemBtn>
                  <Box>
                    <Menu>
                      <Flex alignItems="center">
                        <MenuButton
                          as={Box}
                          pt="25px"
                          h="80px"
                          borderBottom="2px solid transparent"
                          _hover={{
                            transform: "scale(1.1)",
                            borderBottom: "4px solid #f8c708",
                          }}
                        >
                          <Box bgColor="yalink.green">
                            {hasNotifications ? (
                              <NotificationsActive
                                size="25px"
                                color="#ff6666"
                              />
                            ) : (
                              <Notifications size="25px" color="#fff" />
                            )}
                          </Box>
                        </MenuButton>
                      </Flex>
                      <Box>
                        <MenuList
                          p={0}
                          w="500px"
                          maxHeight="500px"
                          overflow="auto"
                        >
                          <Notification
                            setHasNotifications={setHasNotifications}
                          />
                        </MenuList>
                      </Box>
                    </Menu>
                  </Box>
                </NavItemBtn>
                <NavItem>
                  <NavLinks to="/support" onClick={closeMobileMenu}>
                    <Help size="25px" color="#fff" />
                  </NavLinks>
                </NavItem>
                <NavItemBtn>
                  <Menu>
                    <MenuButton as={Button} primary>
                      Mon compte
                    </MenuButton>
                    <MenuList>
                      <MenuGroup title="Freelance">
                        <Link to="/freelance/home">
                          <MenuItem fontSize="md">Dashboard</MenuItem>
                        </Link>
                        <Link to="/freelance/profile">
                          <MenuItem fontSize="md">Profil</MenuItem>
                        </Link>
                        <Link to="/freelance/enterprise">
                          <MenuItem fontSize="md">Mon entreprise</MenuItem>
                        </Link>
                        <Link to="/freelance/missions">
                          <MenuItem fontSize="md">Missions</MenuItem>
                        </Link>
                        <Link to="/freelance/our-partners">
                          <MenuItem fontSize="md">Nos partenaires</MenuItem>
                        </Link>
                      </MenuGroup>
                      <MenuDivider />
                      <MenuGroup title="Aide">
                        <MenuItem fontSize="md" onClick={sendToYalinkDocs}>
                          FAQ
                        </MenuItem>
                        <Link to="/support">
                          <MenuItem fontSize="md">À l'aide</MenuItem>
                        </Link>
                      </MenuGroup>
                      <MenuDivider />
                      <MenuGroup title="Paramètres">
                        {!localStorage.getItem("linkedin_oauth2_state") ? (
                          <Link to="/settings/password">
                            <MenuItem fontSize="md">
                              Changer mon mot de passe
                            </MenuItem>
                          </Link>
                        ) : null}
                        <Link to="/settings/forgetme">
                          <MenuItem fontSize="md">
                            Supprimer mon compte
                          </MenuItem>
                        </Link>
                        <Link to="/settings/notifications">
                          <MenuItem fontSize="md">Notifications</MenuItem>
                        </Link>
                      </MenuGroup>
                      <MenuDivider />
                      <MenuItem fontSize="md" onClick={handleLogout}>
                        Me déconnecter
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </NavItemBtn>
              </>
            ) : (
              <>
                <NavItemBtn>
                  <Menu>
                    <Flex alignItems="center">
                      <MenuButton as={Box}>
                        <Box bgColor="yalink.green">
                          {hasNotifications ? (
                            <NotificationsActive size="25px" color="#ff6666" />
                          ) : (
                            <Notifications size="25px" color="#fff" />
                          )}
                        </Box>
                      </MenuButton>
                    </Flex>
                    <MenuList
                      mt="10%"
                      w="300px"
                      h="400px"
                      overflow="auto"
                      p={0}
                      justify="left"
                    >
                      <Notification setHasNotifications={setHasNotifications} />
                    </MenuList>
                  </Menu>
                </NavItemBtn>
                <NavItemBtn>
                  <Menu>
                    <MenuButton as={Button} primary>
                      Mon compte
                    </MenuButton>
                    <MenuList>
                      <MenuGroup title="Freelance">
                        <Link to="/freelance/home">
                          <MenuItem fontSize="md">Dashboard</MenuItem>
                        </Link>
                        <Link to="/freelance/profile">
                          <MenuItem fontSize="md">Profil</MenuItem>
                        </Link>
                        <Link to="/freelance/missions">
                          <MenuItem fontSize="md">Missions</MenuItem>
                        </Link>
                        <Link to="/freelance/our-partners">
                          <MenuItem fontSize="md">Nos partenaires</MenuItem>
                        </Link>
                      </MenuGroup>
                      <MenuDivider />
                      <MenuGroup title="Aide">
                        <MenuItem fontSize="md" onClick={sendToYalinkDocs}>
                          FAQ
                        </MenuItem>
                        <Link to="/support">
                          <MenuItem fontSize="md">À l'aide</MenuItem>
                        </Link>
                      </MenuGroup>
                      <MenuDivider />
                      <MenuGroup title="Paramètres">
                        {!localStorage.getItem("linkedin_oauth2_state") ? (
                          <Link to="/settings/password">
                            <MenuItem fontSize="md">
                              Changer mon mot de passe
                            </MenuItem>
                          </Link>
                        ) : null}
                        <Link to="/settings/forgetme">
                          <MenuItem fontSize="md">
                            Supprimer mon compte
                          </MenuItem>
                        </Link>
                        <Link to="/settings/notifications">
                          <MenuItem fontSize="md">Notifications</MenuItem>
                        </Link>
                      </MenuGroup>
                      <MenuDivider />
                      <MenuItem fontSize="md" onClick={handleLogout}>
                        Me déconnecter
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </NavItemBtn>
              </>
            )}
          </NavMenu>
        </Box>
      </>
    );
  }

  function AdminNavMenu() {
    return (
      <NavMenu>
        {!isMobile ? (
          <>
            <NavItem>
              <NavLinks to="/admin/dashboard" onClick={closeMobileMenu}>
                Admin
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="/admin" onClick={closeMobileMenu}>
                Appels d'offre
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="/admin/freelance" onClick={closeMobileMenu}>
                Freelances
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="/freelance/missions" onClick={closeMobileMenu}>
                Missions
              </NavLinks>
            </NavItem>
          </>
        ) : null}
        <NavItemBtn>
          <Menu>
            <MenuButton as={Button} primary>
              Mon compte
            </MenuButton>
            <MenuList>
              <MenuGroup title="Admin">
                <Link to="/admin">
                  <MenuItem fontSize="md">Appels d'offre</MenuItem>
                </Link>
                <Link to="/admin/freelance">
                  <MenuItem fontSize="md">Freelances</MenuItem>
                </Link>
                <Link to="/freelance/missions">
                  <MenuItem fontSize="md">Missions</MenuItem>
                </Link>
              </MenuGroup>

              {!localStorage.getItem("linkedin_oauth2_state") ? (
                <>
                  <MenuDivider />
                  <MenuGroup title="Paramètres">
                    <Link to="/settings/password">
                      <MenuItem fontSize="md">
                        Changer mon mot de passe
                      </MenuItem>
                    </Link>
                  </MenuGroup>
                  <MenuDivider />
                </>
              ) : null}

              <MenuItem fontSize="md" onClick={handleLogout}>
                Me déconnecter
              </MenuItem>
            </MenuList>
          </Menu>
        </NavItemBtn>
      </NavMenu>
    );
  }

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav lightBg={isAuthenticated && Auth.isAdmin() ? true : false}>
          <NavbarContainer>
            <NavLogo to="/" onClick={closeMobileMenu}>
              <NavIcon>
                <Image
                  src={img}
                  alt="Yalink logo"
                  pr="0"
                  borderWidth="0"
                  maxW="150px"
                  maxH="300px"
                  w={isMobile && "100px"}
                />
              </NavIcon>
            </NavLogo>
            {!isAuthenticated ? (
              <MobileIconFlex onClick={handleClick}>
                {click ? <FaTimes /> : <FaBars />}
              </MobileIconFlex>
            ) : (
              <></>
            )}
            {!isAuthenticated || userProfile === UserProfiles.COMPANY ? (
              <UnAuthenticatedNavMenu />
            ) : Auth.isAdmin() ? (
              <AdminNavMenu />
            ) : // In the onboarding process (/freelance/about/*), we don't want to show any navigation buttons in the navbar.
            location.pathname.includes("about") ? null : (
              <>
                <Grid
                  ml={isMobile && "10%"}
                  gap={isMobile && "10%"}
                  templateRows="1fr"
                  templateColumns={isMobile ? "1fr 1fr" : null}
                >
                  <GridItem colStart={isMobile && 2}>
                    <FreelanceNavMenu />
                  </GridItem>
                </Grid>
              </>
            )}
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
