import React from "react";

import {
  Box,
  Text,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/react";
import { approveReportWithNoActivity } from "../Libs/activityReportLib";

export default function NoActivityToReportModal({
  isOpen,
  onClose,
  missionId,
  year,
  month,
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent>
        <ModalBody>
          <Flex justify="center" align="center">
            <Box mb="10%" mt="10%">
              <Text fontSize="18px">
                Etes-vous sûr de ne rien déclarer ce mois-ci ?
              </Text>
            </Box>
          </Flex>
          <Flex flexWrap="wrap" justifyContent="space-around">
            <Button
              mr={3}
              mb={3}
              borderRadius="0"
              colorScheme="red"
              onClick={onClose}
            >
              Annuler
            </Button>
            <Button
              bgColor="yalink.green"
              mr={3}
              mb={3}
              onClick={async () => {
                await approveReportWithNoActivity(missionId, year, month);
                onClose();
                window.location.reload();
              }}
              borderRadius="0"
              color="white"
            >
              Je ne déclare rien ce mois ci
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
