import React from "react";
import { NotFoundContainerStyle, Img } from "./NotFound.elements";
import img from "../../images/404.svg";

export default function NotFound() {
  return (
    <NotFoundContainerStyle>
      <Img src={img} alt="Not found" />
    </NotFoundContainerStyle>
  );
}
