import React from "react";
import { Box, Text, Badge } from "@chakra-ui/react";

export default function ContactInfo({ mission, isClient }) {
  return (
    <Box bg="white" color="black" borderRadius={7} padding={10} boxShadow="md">
      <Box mb="4%">
        <Text align="left" fontWeight="bold">
          Mes contacts
        </Text>
      </Box>
      <Box mb="2%">
        <Badge>{isClient ? "Freelance" : "Clients"}</Badge>
      </Box>
      {!isClient && (
        <Box mb="2%">
          <Text fontWeight="semibold">{mission.client_organization_name}</Text>
        </Box>
      )}
      {isClient && (
        <Box mb="2%">
          <Text fontWeight="semibold">
            {mission.freelance.first_name + " " + mission.freelance.last_name}
          </Text>
        </Box>
      )}
      {!isClient ? (
        <Box mb="5%">
          <Text>{mission.client_operational_email}</Text>
        </Box>
      ) : (
        <>
          <Box mb="5%">
            <Text>{mission.freelance.email}</Text>
          </Box>
        </>
      )}
      <Box mb="2%">
        <Badge>Yalink</Badge>
      </Box>
      <Box mb="2%">
        <Text fontWeight="semibold">Vincent Pépin</Text>
      </Box>
      <Box mb="5%">
        <Text>vincent@yalink.fr</Text>
      </Box>
    </Box>
  );
}
