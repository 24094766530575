import axios from "axios";
import authHeader from "../libs/authHeaderLib";
import config from "../config";

class OffersService {
  async createOffer(details) {
    return axios.post(config.API_URL + "/offers", details, {
      headers: authHeader(),
    });
  }

  async updateOffer(details) {
    return axios.put(config.API_URL + "/offers", details, {
      headers: authHeader(),
    });
  }

  async deleteOffer(id) {
    return axios.delete(config.API_URL + "/offers/" + id, {
      headers: authHeader(),
    });
  }

  async getAllOffers(page, perPage) {
    let path = "/offers";
    if (page) {
      path += "?page=" + page;
      if (perPage) {
        path += "&per_page=" + perPage;
      }
    }

    return axios.get(config.API_URL + path, {
      headers: authHeader(),
    });
  }

  async getOfferId(offerId) {
    let path = "/offers/" + offerId;
    return axios.get(config.API_URL + path, {
      headers: authHeader(),
    });
  }

  async getFreelancesSummaryByOfferId(offerId) {
    let path = "/offers/" + offerId + "/freelances";
    return axios.get(config.API_URL + path, {
      headers: authHeader(),
    });
  }

  async getOfferToken(offerId) {
    let path = "/offers/" + offerId + "/token";
    return axios.get(config.API_URL + path, {
      headers: authHeader(),
    });
  }

  async addFreelanceToOffer(offerId, freelanceId) {
    let path = "/offers/" + offerId + "/add";
    let details = { freelanceId: freelanceId };
    return axios.post(config.API_URL + path, details, {
      headers: authHeader(),
    });
  }

  async offerInterestedByProfile(
    offerId,
    freelanceId,
    companyEmail,
    companyPhone
  ) {
    let path = "/offers/" + offerId + "/notify/interested";
    let details = {
      freelanceId: freelanceId,
      companyEmail: companyEmail,
      companyPhone: companyPhone,
    };
    return axios.post(config.API_URL + path, details, {
      headers: authHeader(),
    });
  }

  async removeFreelanceFromOffer(offerId, freelanceId) {
    let path = "/offers/" + offerId + "/remove";
    let details = { freelanceId: freelanceId };
    return axios.post(config.API_URL + path, details, {
      headers: authHeader(),
    });
  }
}

export default new OffersService();
