import React, { useEffect, useState } from "react";
import { Box, Grid, GridItem, useToast } from "@chakra-ui/react";
import FreelanceBillingInfo from "../../components/FreelanceBillingInfo/FreelanceBillingInfo";
import YourEnterprise from "../../components/FreelanceEnterprise/YourEnterprise";
import LegalRepresentation from "../../components/LegalRepresentation/LegalRepresentation";
import BankAccount from "../../components/BankAccount/BankAccount";
import Mandats from "../../components/Mandats/Mandats";
import LegalDocuments from "../../components/LegalDocuments/LegalDocuments";
import LegalDocumentsChecklist from "../../components/LegalDocumentsChecklist/LegalDocumentsChecklist";
import FreelanceService from "../../services/freelance.service";
import BackBanner from "../../components/Utils/BackBanner";
import { querystring } from "../../libs/queryStringLib";
import { LoadingComponent } from "../../components";

function FreelanceEnterprise() {
  const [freelanceId] = useState(
    querystring("id", window.location.href)
      ? querystring("id", window.location.href)
      : null
  );

  const toast = useToast();

  const [gmapsLoaded, setGmapsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [legalData, setLegalData] = useState(null);
  const [isUpdatingLegalData, setIsUpdatingLegalData] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // Some documents are not mandatory based on
  // the legal structure of the company used
  const showDocuments = {
    vigilance:
      legalData &&
      legalData.legalStructure &&
      legalData.legalStructure !== "AE",
    sirene:
      legalData &&
      legalData.legalStructure &&
      (legalData.legalStructure === "AE" ||
        legalData.legalStructure === "EI" ||
        legalData.legalStructure === "EIRL"),
    kbis:
      legalData &&
      legalData.legalStructure &&
      !(
        legalData.legalStructure === "AE" ||
        legalData.legalStructure === "EI" ||
        legalData.legalStructure === "EIRL"
      ),
    legalForms:
      legalData &&
      legalData.legalStructure &&
      !(
        legalData.legalStructure === "AE" ||
        legalData.legalStructure === "EI" ||
        legalData.legalStructure === "EIRL"
      ),
  };

  useEffect(() => {
    async function onLoad() {
      await FreelanceService.getGoogleMapsApiKey().then((response) => {
        if (response.data.api_key) {
          window.initMap = () => setGmapsLoaded(true);
          const gmapScriptEl = document.createElement(`script`);
          gmapScriptEl.src =
            `https://maps.googleapis.com/maps/api/js?key=` +
            response.data.api_key +
            `&libraries=places&callback=initMap`;
          document
            .querySelector(`body`)
            .insertAdjacentElement(`beforeend`, gmapScriptEl);
        }
      });
      await FreelanceService.getLegalData(freelanceId)
        .then((response) => {
          if (response.data.legalData) {
            setLegalData({ ...response.data.legalData });
          }
          setIsLoading(false);
        })
        .catch(() => {
          setLegalData({
            wagePortage: false,
            legalStructure: "",
            businessName: "",
            siretNumber: "",
            legalRepresentativeAddress: "",
            legalRepresentativeAdressDetails: null,
            headquartersAdress: "",
            headquartersAdressDetails: null,
            firstName: "",
            lastName: "",
            birthDate: "",
            citizenship: "",
            birthCity: "",
            postalCode: "",
            birthCountry: "",
            vatPercentage: "",
            socialCapital: "",
            vatIntracommunityNumber: "",
            rcsRegistrationCity: "",
            iban: "",
            bic: "",
            // Attachments
            identityDocument: null,
            rcProDocument: null,
            vigilanceCertificateDocument: null,
            sireneStatusCertificateDocument: null,
            kbisDocument: null,
            legalFormsDocument: null,
            mangopayDeclarationDocument: null,
          });
          setIsLoading(false);
        });
    }
    applyResponsive();

    if (isLoading) {
      onLoad();
    }
  }, [isLoading, freelanceId]);

  async function handleLegalDataUpdate(setIsUpdatingLegalDataState) {
    setIsUpdatingLegalDataState(true);
    await FreelanceService.updateLegalData(legalData, freelanceId)
      .then((response) => {
        if (response.data.payment_provider_user_exists) {
          let tempLegalData = legalData;
          tempLegalData.paymentProviderUserExists = true;
          setLegalData({ ...tempLegalData });
        }
        setIsUpdatingLegalDataState(false);
        toast({
          position: "top",
          title: "Votre entreprise a été mise à jour !",
          status: "success",
          duration: 1000,
          isClosable: true,
        });
      })
      .catch(() => {});
  }

  window.addEventListener("resize", applyResponsive);
  return !isLoading && gmapsLoaded ? (
    <>
      <BackBanner title="Mon entreprise" isMobile={isMobile} />
      <Box mt="2%" pl="10%" pr="10%">
        <Box>
          <Grid
            templateColumns={isMobile ? null : "repeat(6, 1fr)"}
            gap="2.5%"
            mb="2%"
          >
            <GridItem colSpan={isMobile ? null : 4}>
              <YourEnterprise
                legalData={legalData}
                setLegalData={setLegalData}
                handleLegalDataUpdate={handleLegalDataUpdate}
                setIsUpdatingLegalData={setIsUpdatingLegalData}
                isUpdatingLegalData={isUpdatingLegalData}
              />
            </GridItem>
            <GridItem colSpan={isMobile ? null : 2}>
              <LegalDocumentsChecklist
                legalData={legalData}
                showDocuments={showDocuments}
              />
            </GridItem>
          </Grid>
        </Box>
        <Box>
          <Grid
            gap={isMobile ? "0.8%" : "2.5%"}
            templateColumns={isMobile ? null : "repeat(6, 1fr)"}
          >
            <GridItem
              colSpan={isMobile ? null : 4}
              colStart={isMobile ? null : 1}
              colEnd={isMobile ? null : 5}
            >
              <LegalRepresentation
                legalData={legalData}
                setLegalData={setLegalData}
                handleLegalDataUpdate={handleLegalDataUpdate}
                setIsUpdatingLegalData={setIsUpdatingLegalData}
                isUpdatingLegalData={isUpdatingLegalData}
              />
              <FreelanceBillingInfo
                legalData={legalData}
                setLegalData={setLegalData}
                handleLegalDataUpdate={handleLegalDataUpdate}
                setIsUpdatingLegalData={setIsUpdatingLegalData}
                isUpdatingLegalData={isUpdatingLegalData}
              />
              <BankAccount
                legalData={legalData}
                setLegalData={setLegalData}
                handleLegalDataUpdate={handleLegalDataUpdate}
                setIsUpdatingLegalData={setIsUpdatingLegalData}
                isUpdatingLegalData={isUpdatingLegalData}
              />
            </GridItem>

            <GridItem
              colSpan={isMobile ? null : 2}
              colStart={isMobile ? null : 5}
              colEnd={isMobile ? null : 7}
            >
              <LegalDocuments
                showDocuments={showDocuments}
                legalData={legalData}
                setLegalData={setLegalData}
              />
              <Mandats />
            </GridItem>
          </Grid>
        </Box>
      </Box>
    </>
  ) : (
    <LoadingComponent />
  );
}

export default FreelanceEnterprise;
