import ActivityReportReviewComponent from "../../components/ActivityReportReview/ActivityReportReviewComponent";
import { useState } from "react";
import Missions from "../../services/mission.service";
import FreelanceService from "../../services/freelance.service";
import { useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

export default function ActivityReportReview() {
  const toast = useToast();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [mission, setMission] = useState(null);
  const [activityReport, setActivityReport] = useState(null);
  const [legalData, setLegalData] = useState(null);
  const { missionId, year, month } = useParams();

  async function onLoad() {
    await Missions.getMission(missionId)
      .then((response) => {
        setMission({
          ...response.data.mission,
        });
        Missions.getActivityReport(missionId, year, month).then((response) => {
          setActivityReport({ ...response.data.activity_report });
        });
        FreelanceService.getLegalData(response.data.mission.freelance_id)
          .then((response) => {
            if (response.data.legalData) {
              setLegalData({ ...response.data.legalData });
            }
          })
          .catch(() => {
            toast({
              position: "top",
              title:
                "Renseigne tes données entreprise avant de finaliser ton Compte Rendu D'Activité",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
            history.push("/freelance/enterprise");
          });
      })
      .catch(() => {});
  }
  if (isLoading) {
    onLoad();
    setIsLoading(false);
  }

  return (
    [isLoading, missionId],
    mission && legalData && activityReport && (
      <>
        <ActivityReportReviewComponent
          mission={mission}
          activityReport={activityReport}
          setActivityReport={setActivityReport}
          legalData={legalData}
        />
      </>
    )
  );
}
