import React from 'react'
import { useState } from "react";

import {
  Box,
  Text,
  Flex,
} from "@chakra-ui/react";
import { Briefcase } from "@styled-icons/boxicons-solid/Briefcase";
import { Euro } from "@styled-icons/boxicons-regular/Euro";

import { getDateList } from "../../../libs/dateListLib";

export interface MissionProps {
  freelance_fees_per_day: Number
}

export interface ActivityReportProps {
  full_days_worked: Array<string>
  half_days_worked: Array<string>
}

export default function BreakdownRegie({ activityReport, mission }: { activityReport: ActivityReportProps, mission: MissionProps }) {
  const [totalDays] = useState(
    getDateList(activityReport.full_days_worked).length +
    0.5 * getDateList(activityReport.half_days_worked).length
  );
  const [tjm] = useState(mission.freelance_fees_per_day);

  return (
    <>
      <Box id="jours travaillés">
        <Box mb="2%">
          <Text color="grey">Jours travaillés</Text>
        </Box>
        <Box mb="2%">
          <Flex>
            <Box mr="4%">
              <Briefcase size="20px" />
            </Box>
            <Box>
              <Text>{totalDays + " jours"}</Text>
            </Box>
          </Flex>
        </Box>
      </Box>

      <Box id="TJM">
        <Box mb="2%">
          <Text color="grey">TJM</Text>
        </Box>
        <Box mb="6%">
          <Flex>
            <Box mr="4%">
              <Euro size="20px" />
            </Box>
            <Box>
              <Text>{tjm + " € HT"}</Text>
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  )
}
