import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { useAppContext } from "../../libs/contextLib";
import LinkedInPage from "../LinkedInPage/LinkedInPage";
import Auth from "../../services/auth.service";
import User from "../../services/user.service";
import { isEmailValid } from "../../libs/regexLib";
import SignupVideo from "../../videos/AnimApp_Mission.gif";

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Box,
  Divider,
  Grid,
  Center,
  Heading,
  useToast,
  Checkbox,
  Text,
  VStack,
  Link,
  Tooltip,
  GridItem,
  SimpleGrid,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";

function SignupSection() {
  const history = useHistory();
  const { userHasAuthenticated } = useAppContext();
  const toast = useToast();
  const [agreementChecked, setAgreementChecked] = useState(false);

  async function handleSubmit(values, actions) {
    await Auth.signup({
      email: values.email,
      password: values.password,
    })
      .then((response) => {
        if (response.status === 201) {
          if (response.data.auth_token) {
            localStorage.setItem("user", JSON.stringify(response.data));
          }
          User.createVerificationCode({
            email: values.email,
          }).catch(() => {});
          userHasAuthenticated(true);
        } else {
          toast({
            position: "top",
            title: "Tu possèdes déjà un compte Yalink, connecte-toi !",
            status: "warning",
            duration: 9000,
            isClosable: true,
          });
          userHasAuthenticated(false);
          history.push("/login");
        }
      })
      .catch(() => {
        toast({
          position: "top",
          title: "Nous n'avons pas pu t'inscrire",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        userHasAuthenticated(false);
      });
    actions.setSubmitting(false);
  }

  function validateEmail(value) {
    let error;
    if (!isEmailValid(value)) {
      error = "*Cet email n'est pas valide";
    }
    return error;
  }

  function validateAgreement(value) {
    let error;
    if (!value || value !== true) {
      error = "*Vous devez accepter les conditions générales d'utilisation";
      setAgreementChecked(false);
    } else {
      setAgreementChecked(true);
    }
    return error;
  }

  function validatePassword(value) {
    let error = "";
    const passwordRegex = /(?=.*[0-9])/;
    if (!value) {
      error = "*Il va falloir remplir ce champ 😱";
    } else if (value.length < 8) {
      error = "*Doit comprendre au moins 8 charactères";
    } else if (!passwordRegex.test(value)) {
      error = "*Doit comprendre au moins un chiffre";
    }
    return error;
  }

  function validateConfirmPassword(password, value) {
    let error;
    if (!value) {
      error = "*Il va falloir remplir ce champ 😱";
    } else {
      if (value !== password) {
        error = "*Les mots de passe ne correspondent pas";
      }
    }
    return error;
  }

  // isMobile responsive useState and useEffect

  const [isMobile, setIsMobile] = useState(false);

  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    applyResponsive();
  }, []);

  window.addEventListener("resize", applyResponsive);

  return (
    <>
      <Grid templateColumns={isMobile ? "1fr" : "1fr 1fr"}>
        <GridItem h="100%" bg="white">
          <Box ml="10%" mr="10%" mt="50px" mb="10%">
            <Center>
              <Box mb="40px">
                <Heading as="h1" size="lg" mb="25px">
                  Rejoins Yalink
                </Heading>
                <Text mb="20px">
                  Ou{" "}
                  <Link color="teal.500" as={RouterLink} to="/login">
                    connecte toi
                  </Link>
                </Text>
                <Formik
                  initialValues={{
                    name: "",
                    lastname: "",
                    email: "",
                    password: "",
                    confirmpassword: "",
                  }}
                  onSubmit={(values, actions) => {
                    handleSubmit(values, actions);
                  }}
                >
                  {(props) => (
                    <Form>
                      <Field name="email" validate={validateEmail}>
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={form.errors.email && form.touched.email}
                            isRequired
                          >
                            <FormLabel htmlFor="email">E-mail</FormLabel>
                            <Input
                              {...field}
                              id="email"
                              placeholder="hello@yalink.fr"
                            />
                            <FormErrorMessage>
                              {form.errors.email}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Divider padding="3" mb="20px" maxW="90%" />
                      <Field name="password" validate={validatePassword}>
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.password && form.touched.password
                            }
                            isRequired
                          >
                            <FormLabel htmlFor="password">
                              Mot de passe
                            </FormLabel>
                            <Input
                              {...field}
                              id="password"
                              placeholder=""
                              type="password"
                            />
                            <FormErrorMessage>
                              {form.errors.password}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field
                        name="confirmpassword"
                        validate={(value) =>
                          validateConfirmPassword(props.values.password, value)
                        }
                      >
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.confirmpassword &&
                              form.touched.confirmpassword
                            }
                            isRequired
                          >
                            <FormLabel htmlFor="confirmpassword">
                              Confirmation du mot de passe
                            </FormLabel>
                            <Input
                              {...field}
                              id="confirmpassword"
                              placeholder=""
                              type="password"
                            />
                            <FormErrorMessage>
                              {form.errors.confirmpassword}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Divider padding="3" mb="20px" maxW="90%" />
                      <Field name="agree" validate={validateAgreement}>
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={form.errors.agree && form.touched.agree}
                            isRequired
                          >
                            <Checkbox {...field} id="agree">
                              J'ai lu et accepte les
                              <Link
                                href="https://www.yalink.fr/cguv/"
                                isExternal
                                color="teal.500"
                              >
                                {" "}
                                Conditions Générales d'Utilisation du site
                                Yalink{" "}
                              </Link>
                              , la
                              <Link
                                href="https://www.yalink.fr/politique-de-confidentialite/"
                                isExternal
                                color="teal.500"
                              >
                                {" "}
                                Politique de Confidentialité{" "}
                              </Link>
                              ainsi que le mandat de gestion de
                              <Link
                                href="https://docs.google.com/document/d/e/2PACX-1vSu_EkXHlQq-7UffwmttyE2GwLBd4bvquEsDf6QCefxW_dc9fvCl4PjMiavn5SzgRfuOm9rq5YQDW8N/pub"
                                isExternal
                                color="teal.500"
                              >
                                {" "}
                                facturation{" "}
                              </Link>
                              <Tooltip
                                label="Nous nous chargeons d'émettre ta facture en ton nom et pour ton compte"
                                fontSize="md"
                              >
                                <InfoIcon />
                              </Tooltip>
                            </Checkbox>
                            <FormErrorMessage>
                              {form.errors.agree}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <VStack>
                        <Button
                          mt={4}
                          width="100%"
                          color="white"
                          bg="yalink.green"
                          _hover={{ bg: "yalink.yellow" }}
                          isLoading={props.isSubmitting}
                          type="submit"
                          disabled={
                            !props.isValid ||
                            (Object.keys(props.touched).length === 0 &&
                              props.touched.constructor === Object)
                          }
                        >
                          Créer mon compte
                        </Button>
                        <SimpleGrid
                          columns={3}
                          spacing={1}
                          mt="20px"
                          mb="20px"
                          w="100%"
                        >
                          <Box w="100%" h="10">
                            <Divider padding="2" />
                          </Box>
                          <Box w="100%" h="10">
                            <Center>
                              <Text fontWeight="bold">Ou</Text>
                            </Center>
                          </Box>
                          <Box w="100%" h="10">
                            <Divider padding="2" />
                          </Box>
                        </SimpleGrid>
                        <Box w="100%">
                          <LinkedInPage
                            signup={true}
                            agreementChecked={agreementChecked}
                          />
                        </Box>
                      </VStack>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Center>
          </Box>
        </GridItem>
        <GridItem h="100%" bg="#f8fcfc" display={isMobile && "none"}>
          <Box align="center" mt="10%">
            <Text fontSize="35px" fontWeight="bold" color="yalink.green">
              Yalink décroche
            </Text>
            <Text color="yalink.green" fontSize="25px">
              tes futures missions dans la construction et l'industrie
            </Text>
          </Box>
          <Center mt="4%">
            <img src={SignupVideo} alt="Animation Missions" />
          </Center>
        </GridItem>
      </Grid>
    </>
  );
}

export default SignupSection;
