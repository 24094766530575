import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Flex,
  Grid,
  Text,
  Input,
  Switch,
  GridItem,
  Button,
  Select,
  useToast,
} from "@chakra-ui/react";
import { querystring } from "../../libs/queryStringLib";
import MissionService from "../../services/mission.service";
import FreelanceService from "../../services/freelance.service";
export default function OnboardingFreelanceDetails() {
  const toast = useToast();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  const missionId = querystring("missionId", window.location.href);

  const [mission, setMission] = useState({});
  const [freelanceSearch, setFreelanceSearch] = useState({
    term: null,
    freelance_list: [],
    isLoading: false,
  });
  const [isForfait, setIsForfait] = useState(false);
  function changeToForfait() {
    let tempMission = mission;
    setIsForfait(!isForfait);
    if (isForfait) {
      tempMission.service_type = "regie";
    } else {
      tempMission.service_type = "forfait";
    }
    setMission({ ...tempMission });
  }
  const [isMobile, setIsMobile] = useState(false);
  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  async function getFreelanceList() {
    setFreelanceSearch({
      term: freelanceSearch.term,
      freelance_list: freelanceSearch.freelance_list,
      isLoading: true,
    });
    await FreelanceService.getFreelanceList(freelanceSearch.term)
      .then((response) => {
        let tempSearch = freelanceSearch;
        tempSearch.freelance_list = response.data.freelance_list;
        tempSearch.isLoading = false;
        if (tempSearch.freelance_list.length === 1) {
          let tempMission = mission;
          tempMission.freelance_id = tempSearch.freelance_list[0].id;
          setMission(tempMission);
        }
        setFreelanceSearch({ ...tempSearch });
      })
      .catch(() => {});
  }

  async function handleSubmit() {
    await MissionService.updateMission(mission.id, mission)
      .then((response) => {
        history.push("/freelance/missions/ongoing/" + mission.id);
      })
      .catch(() => {
        toast({
          position: "top",
          title: "Il manque des informations !",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  }

  useEffect(() => {
    async function onLoad() {
      await MissionService.getMission(missionId)
        .then((response) => {
          if (response.data.mission.service_type === "forfait") {
            setIsForfait(true);
          } else {
            response.data.mission.service_type = "regie";
          }
          if (response.data.mission.freelance) {
            response.data.mission.freelance_id =
              response.data.mission.freelance.id;
            setFreelanceSearch({
              term: null,
              isLoading: false,
              freelance_list: [response.data.mission.freelance],
            });
          }
          setMission({ ...response.data.mission });
        })
        .catch((error) => {});
    }
    if (isLoading) {
      onLoad();
      setIsLoading(false);
    }
  }, [isLoading, missionId]);

  window.addEventListener("resize", applyResponsive);
  return (
    <Box mt="5%">
      <Flex justify="center">
        <Box
          w={isMobile ? "80%" : "50%"}
          padding="10"
          borderWidth={1}
          borderRadius={5}
          boxShadow="md"
          mb="80px"
          mt="80px"
          bg="white"
        >
          <Box mb="2%">
            <Text fontSize="20" fontWeight="bold">
              Onboarding du freelance
            </Text>
          </Box>
          <Box mb="2%">
            <Box mb="2%">
              <Text>Titre de la mission</Text>
            </Box>
            <Box>
              <Input
                w={isMobile ? "100%" : "60%"}
                value={mission.name}
                onChange={(e) => {
                  let tempMission = mission;
                  tempMission.name = e.target.value;
                  setMission({ ...tempMission });
                }}
              ></Input>
            </Box>
          </Box>
          <Flex mb="2%" justify="space-between" w={isMobile ? "100%" : "60%"}>
            <Box>
              <Box mb="2%">
                <Text>Date de début</Text>
              </Box>
              <Box>
                <Input
                  type="date"
                  value={mission.start_date}
                  onChange={(e) => {
                    let tempMission = mission;
                    tempMission.start_date = e.target.value;
                    setMission({ ...tempMission });
                  }}
                />
              </Box>
            </Box>
            <Box>
              <Box mb="2%">
                <Text>Date de fin</Text>
              </Box>
              <Box>
                <Input
                  type="date"
                  value={mission.end_date}
                  onChange={(e) => {
                    let tempMission = mission;
                    tempMission.end_date = e.target.value;
                    setMission({ ...tempMission });
                  }}
                />
              </Box>
            </Box>
          </Flex>
          <Box mt="4%">
            <Grid
              templateColumns={
                isMobile ? "0.3fr 0.3fr 0.3fr" : "0.1fr 0.1fr 0.1fr"
              }
            >
              <GridItem>
                <Box>Régie</Box>
              </GridItem>
              <GridItem mt="3%">
                <Box>
                  <Switch
                    colorScheme="green"
                    onChange={changeToForfait}
                    data-testid="switch"
                    isChecked={isForfait}
                  ></Switch>
                </Box>
              </GridItem>
              <GridItem>
                <Box>Forfait</Box>
              </GridItem>
            </Grid>
          </Box>
          <Box>
            {isForfait ? (
              <Box mt="2%">
                <Box mb="2%">
                  <Text>Enveloppe en € (Hors commission Yalink)</Text>
                </Box>
                <Box>
                  <Input
                    type="number"
                    w={isMobile ? "100%" : "60%"}
                    value={mission.total_amount_billed}
                    onChange={(e) => {
                      let tempMission = mission;
                      tempMission.total_amount_billed = e.target.value;
                      setMission({ ...tempMission });
                    }}
                  ></Input>
                </Box>
                <Box mb="2%" mt="2%">
                  <Text>Enveloppe de commission Yalink (en €)</Text>
                </Box>
                <Box>
                  <Input
                    type="number"
                    w={isMobile ? "100%" : "60%"}
                    value={mission.yalink_fees_total_amount}
                    onChange={(e) => {
                      let tempMission = mission;
                      tempMission.yalink_fees_total_amount = e.target.value;
                      setMission({ ...tempMission });
                    }}
                  ></Input>
                </Box>
                <Box mb="2%" mt="2%">
                  <Text fontWeight="bold">
                    Enveloppe totale:{" "}
                    {Number(
                      Number(mission.total_amount_billed) +
                        Number(mission.yalink_fees_total_amount)
                    )}
                    {" €"}
                  </Text>
                </Box>
              </Box>
            ) : (
              <>
                <Box mb="2%" mt="2%">
                  <Text>TJM Freelance en € (hors commission Yalink)</Text>
                </Box>
                <Box>
                  <Input
                    w={isMobile ? "100%" : "60%"}
                    type="number"
                    value={mission.freelance_fees_per_day}
                    onChange={(e) => {
                      let tempMission = mission;
                      tempMission.freelance_fees_per_day = e.target.value;
                      setMission({ ...tempMission });
                    }}
                  ></Input>
                </Box>
                <Box mb="2%" mt="2%">
                  <Text>Marge Yalink par jour en €</Text>
                </Box>
                <Box>
                  <Input
                    w={isMobile ? "100%" : "60%"}
                    type="number"
                    value={mission.yalink_fees_per_day}
                    onChange={(e) => {
                      let tempMission = mission;
                      tempMission.yalink_fees_per_day = e.target.value;
                      setMission({ ...tempMission });
                    }}
                  ></Input>
                </Box>
                <Box mb="2%" mt="2%">
                  <Text fontWeight="bold">
                    TJM total:{" "}
                    {Number(
                      Number(mission.freelance_fees_per_day) +
                        Number(mission.yalink_fees_per_day)
                    )}
                    {" €"}
                  </Text>
                </Box>
                <Box mb="2%" mt="2%">
                  <Text>Nombres de jours travaillés</Text>
                </Box>
                <Box>
                  <Input
                    w={isMobile ? "100%" : "60%"}
                    type="number"
                    value={mission.total_working_days}
                    onChange={(e) => {
                      let tempMission = mission;
                      tempMission.total_working_days = e.target.value;
                      setMission({ ...tempMission });
                    }}
                  ></Input>
                </Box>
              </>
            )}
          </Box>
          <Box>
            <Box mt="3%">
              <Box>
                <Box mb="2%">
                  <Text>Sélection du freelance </Text>
                </Box>
                <Flex justify="space-between" w={isMobile ? "100%" : "60%"}>
                  <Box>
                    <Input
                      value={freelanceSearch.term}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          getFreelanceList();
                        }
                      }}
                      onChange={(e) => {
                        let tempSearch = freelanceSearch;
                        tempSearch.term = e.target.value;
                        setFreelanceSearch({ ...tempSearch });
                      }}
                    ></Input>
                  </Box>
                  <Button
                    bgColor="yalink.green"
                    borderRadius={0}
                    color="white"
                    onClick={getFreelanceList}
                    disabled={freelanceSearch.isLoading}
                    isLoading={freelanceSearch.isLoading}
                  >
                    Rechercher
                  </Button>
                </Flex>
                <Box w={isMobile ? "100%" : "60%"} mt="2%">
                  <Select
                    placeholder={
                      freelanceSearch.freelance_list.length +
                      " freelances trouvé(s)"
                    }
                    onChange={(e) => {
                      let tempMission = mission;
                      tempMission.freelance_id = e.target.value;
                      setMission({ ...tempMission });
                    }}
                  >
                    {freelanceSearch.freelance_list.map((freelance) => {
                      return (
                        <option
                          value={freelance.id}
                          selected={
                            freelanceSearch.freelance_list.length === 1
                              ? true
                              : false
                          }
                        >
                          {freelance.first_name} {freelance.last_name}
                        </option>
                      );
                    })}
                  </Select>
                </Box>
              </Box>
              <Box mt="2%">
                <Box mb="2%">
                  <Text>Délais de paiement</Text>
                </Box>
                <Box>
                  <Select
                    placeholder="Selectionnez le délai"
                    w={isMobile ? "100%" : "60%"}
                    value={mission.payment_delay}
                    onChange={(e) => {
                      let tempMission = mission;
                      tempMission.payment_delay = e.target.value;
                      setMission({ ...tempMission });
                    }}
                  >
                    <option value="at_billing_time">À facture</option>
                    <option value="at_30_days_eom">30 jours fin de mois</option>
                    <option value="at_45_days_eom">45 jours fin de mois</option>
                    <option value="at_60_days_eom">60 jours fin de mois</option>
                  </Select>
                </Box>
              </Box>
              <Box mt="2%">
                <Box mb="2%">
                  <Text>Nom de l'entreprise</Text>
                </Box>
                <Box>
                  <Input
                    w={isMobile ? "100%" : "60%"}
                    value={mission.client_organization_name}
                    onChange={(e) => {
                      let tempMission = mission;
                      tempMission.client_organization_name = e.target.value;
                      setMission({ ...tempMission });
                    }}
                  ></Input>
                </Box>
              </Box>
              <Box mt="2%">
                <Box mb="2%">
                  <Text>SIRET</Text>
                </Box>
                <Box>
                  <Input
                    w={isMobile ? "100%" : "60%"}
                    value={mission.client_siret_number}
                    onChange={(e) => {
                      let tempMission = mission;
                      tempMission.client_siret_number = e.target.value;
                      setMission({ ...tempMission });
                    }}
                  ></Input>
                </Box>
              </Box>
              <Box mt="2%">
                <Box mb="2%">
                  <Text>Adresse du client</Text>
                </Box>
                <Box>
                  <Input
                    w={isMobile ? "100%" : "60%"}
                    value={mission.client_address}
                    onChange={(e) => {
                      let tempMission = mission;
                      tempMission.client_address = e.target.value;
                      setMission({ ...tempMission });
                    }}
                  ></Input>
                </Box>
              </Box>
              <Box mt="2%">
                <Box mb="2%">
                  <Text>E-mail opérationnel</Text>
                </Box>
                <Box>
                  <Input
                    w={isMobile ? "100%" : "60%"}
                    value={mission.client_operational_email}
                    onChange={(e) => {
                      let tempMission = mission;
                      tempMission.client_operational_email = e.target.value;
                      setMission({ ...tempMission });
                    }}
                  ></Input>
                </Box>
              </Box>
              <Box mt="2%">
                <Box mb="2%">
                  <Text>E-mail comptabilité fournisseur</Text>
                </Box>
                <Box>
                  <Input
                    w={isMobile ? "100%" : "60%"}
                    value={mission.client_cc_email}
                    onChange={(e) => {
                      let tempMission = mission;
                      tempMission.client_cc_email = e.target.value;
                      setMission({ ...tempMission });
                    }}
                  ></Input>
                </Box>
              </Box>
              <Box mt="2%">
                <Box mb="2%">
                  <Text>ID Mangopay</Text>
                </Box>
                <Box>
                  <Input
                    w={isMobile ? "100%" : "60%"}
                    value={mission.client_payment_provider_id}
                    onChange={(e) => {
                      let tempMission = mission;
                      tempMission.client_payment_provider_id = e.target.value;
                      setMission({ ...tempMission });
                    }}
                  ></Input>
                </Box>
              </Box>
              <Box mt="2%">
                <Box mb="2%">
                  <Text>IBAN Mangopay</Text>
                </Box>
                <Box>
                  <Input
                    w={isMobile ? "100%" : "60%"}
                    value={mission.client_payment_provider_iban}
                    onChange={(e) => {
                      let tempMission = mission;
                      tempMission.client_payment_provider_iban = e.target.value;
                      setMission({ ...tempMission });
                    }}
                  ></Input>
                </Box>
              </Box>
              <Box mt="2%">
                <Box mb="2%">
                  <Text>BIC Mangopay</Text>
                </Box>
                <Box>
                  <Input
                    w={isMobile ? "100%" : "60%"}
                    value={mission.client_payment_provider_bic}
                    onChange={(e) => {
                      let tempMission = mission;
                      tempMission.client_payment_provider_bic = e.target.value;
                      setMission({ ...tempMission });
                    }}
                  ></Input>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mt={isMobile ? "15%" : "5%"}>
            <Flex justify="center" gap="50px">
              <Box>
                <Button borderRadius={0}>Annuler</Button>
              </Box>
              <Box>
                <Button
                  bgColor="yalink.green"
                  borderRadius={0}
                  color="white"
                  onClick={handleSubmit}
                >
                  Confirmer
                </Button>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}
