import React, { useState, useEffect } from "react";
import {
  Button,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Spacer,
  HStack,
  Text,
  FormControl,
  FormLabel,
  Textarea,
  Input,
  InputGroup,
  InputRightElement,
  ModalContent,
  Modal,
  ModalOverlay,
  Image,
  useToast,
} from "@chakra-ui/react";
import ApplyPicture from "../../images/ApplyPicture.png";
import Applications from "../../services/application.service";
import { Stars } from "@styled-icons/bootstrap/Stars";

function ModalApply({
  isOpen: isApplicationOpen,
  onClose: onApplicationClose,
  mission,
  setHasApplied,
}) {
  const [apply, setApply] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [tarification, setTarification] = useState(null);
  const [motivation, setMotivation] = useState(null);

  const toast = useToast();

  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  window.addEventListener("resize", applyResponsive);

  useEffect(() => {
    async function sendApplication() {
      setApply(false);
      const application = {
        tarification: tarification,
        startDate: startDate,
        motivation: motivation,
      };
      setStartDate(null);
      setTarification(null);
      setMotivation(null);
      setHasApplied(true);
      await Applications.apply(mission.id, application)
        .then(() => {
          onApplicationClose();
          toast({
            position: "top",
            title:
              "🎉 Vous avez postulé ! Notre équipe va revenir vers vous dans les plus brefs delais.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        })
        .catch(() => {});
    }

    applyResponsive();
    if (apply) {
      sendApplication();
    }
  }, [
    apply,
    mission,
    onApplicationClose,
    setHasApplied,
    motivation,
    startDate,
    tarification,
    toast,
    setApply,
  ]);

  return (
    <div>
      <Modal isOpen={isApplicationOpen} onClose={onApplicationClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <Box ml="50%" align="right">
            <Image src={ApplyPicture} h="250px" w="150px"></Image>
          </Box>
          <ModalHeader
            w={isMobile ? "100%" : null}
            pl={0}
            fontSize={isMobile ? "17px" : null}
            align="center"
            mt="-180px"
          >
            <Stars size="40px" color="#F8C708" /> Je postule !
          </ModalHeader>
          <ModalCloseButton />
          <Text
            color="yalink.yellow"
            align="center"
            fontWeight="bold"
            fontSize={isMobile ? "17px" : "20px"}
            mt="-10px"
          >
            {mission.name}
          </Text>
          <ModalBody mb="auto">
            <Box
              bg="gray.100"
              w="100%"
              p={4}
              color="black"
              borderRadius="7px"
              mr="5px"
              mb="20px"
              mt="100px"
            >
              <FormControl isRequired mb="20px">
                <FormLabel>Quand peux-tu démarrer ?</FormLabel>
                <Input
                  type="date"
                  background="white"
                  data-testid="Start Date"
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                />
              </FormControl>

              <FormControl isRequired mb="20px">
                <FormLabel>Pour quel taux journalier ? (TJM)</FormLabel>
                <InputGroup>
                  <InputRightElement
                    pointerEvents="none"
                    color="black"
                    fontSize="1.2em"
                    children="€"
                    mr="8px"
                  />
                  <Input
                    type="number"
                    placeholder="450"
                    background="white"
                    data-testid="TJM"
                    onChange={(e) => {
                      setTarification(e.target.value);
                    }}
                  />
                </InputGroup>
              </FormControl>

              <FormControl isRequired mb="10px">
                <FormLabel>Quelles sont tes motivations ?</FormLabel>
                <Textarea
                  overflow="auto"
                  background="white"
                  placeholder="Compétences en liens avec la mission, expériences passées..."
                  data-testid="Motivation"
                  onChange={(e) => {
                    setMotivation(e.target.value);
                  }}
                />
              </FormControl>
            </Box>

            <HStack>
              <Spacer />
              <Button
                color="white"
                bg="yalink.green"
                _hover={{ bg: "yalink.yellow" }}
                _checked={{
                  bg: "yalink.yellow",
                  color: "white",
                  borderColor: "yalink.yellow",
                }}
                type="submit"
                ms="285"
                mb="5"
                onClick={() => setApply(true)}
                disabled={!(motivation && tarification && startDate)}
              >
                Je postule
              </Button>
              <Spacer />
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default ModalApply;
