import React from "react";

import { Box, Badge, Text, Button, Flex, Divider } from "@chakra-ui/react";
import { monthNamesFr } from "../../../libs/monthsLib";
import { Briefcase } from "@styled-icons/boxicons-solid/Briefcase";

export default function ProgressSummaryForfait({
  getSecondAmount,
  values,
  getValues,
  month,
  year,
  isValid,
  activityReport,
}) {
  return (
    <Box
      h="100%"
      bg="white"
      color="black"
      borderRadius={7}
      padding={10}
      pt={5}
      boxShadow="md"
    >
      <Box mb="4%">
        <Text align="left" fontWeight="bold">
          {monthNamesFr[month - 1]} {year}
        </Text>
      </Box>
      <Box mb="2%">
        <Text color="grey">Avancement</Text>
      </Box>
      <Box mb="2%">
        <Flex>
          <Box mr="4%">
            <Briefcase size="20px" />
          </Box>
          <Box>
            <Text>{getSecondAmount(values.avancement) + " € HT"}</Text>
          </Box>
        </Flex>
      </Box>
      <Box mb="2%">
        <Text color="grey">Frais</Text>
      </Box>
      <Box mb="6%">
        <Flex>
          <Box mr="4%">
            <Briefcase size="20px" />
          </Box>
          <Box>
            <Text>{getValues(values.frais) + " € HT"}</Text>
          </Box>
        </Flex>
      </Box>
      <Box mb="5%">
        <Divider bgColor="black" colorScheme="blackAlpha" h="0.5%" />
      </Box>
      <Box mb="3%">
        <Badge colorScheme="green" fontSize="1.5rem">
          {getValues(values.frais) + getSecondAmount(values.avancement) + " €"}
        </Badge>
      </Box>
      <Box mb="5%">
        <Text color="grey">Montant HT de votre facture</Text>
      </Box>
      <Box>
        <Button
          p={4}
          bgColor="yalink.green"
          color="white"
          w="100%"
          type="submit"
          borderRadius={0}
          disabled={
            !isValid ||
            activityReport.status === "approved" ||
            activityReport.status === "pending_approval" ||
            activityReport.status === "nothing_declared"
          }
        >
          Valider l'état d'avancement
        </Button>
      </Box>
    </Box>
  );
}
