import React from "react";
import { Route, Switch } from "react-router-dom";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";

import {
  NotFoundPage,
  Freelance,
  Company,
  FreelanceSignup,
  SuccessfulSignup,
  FreelanceHome,
  Login,
  FreelanceRegister,
  FreelanceProfile,
  AdminHome,
  OfferView,
  FreelanceMissions,
  FreelanceAvailableMissionsDetailed,
  FreelanceList,
  FreelanceEnterprise,
  FreelanceOnboarding,
  FreelanceOnboardingDetails,
  FreelanceOngoingMissionsDetailed,
  ExternalReco,
  FreelanceJob,
  FreelancePreferences,
  FreelanceLinkedIn,
  ActivityReport,
  ActivityReportReview,
} from "./pages";

import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import NotAuthManagedRoute from "./components/NotAuthManagedRoute";
import { ChangePassword, DeleteAccount, ResetPassword } from "./components";
import ChangeNotifications from "./components/ChangeNotifications/ChangeNotifications.tsx";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
export default function Routes() {
  return (
    <Switch>
      <UnauthenticatedRoute exact path="/">
        <FreelanceSignup />
      </UnauthenticatedRoute>
      <Route path="/linkedin" component={LinkedInPopUp} />
      <UnauthenticatedRoute exact path="/freelance/signup">
        <FreelanceSignup />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/login">
        <Login />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/reset/password">
        <ResetPassword />
      </UnauthenticatedRoute>
      <NotAuthManagedRoute exact path="/freelance">
        <Freelance />
      </NotAuthManagedRoute>
      <NotAuthManagedRoute exact path="/company">
        <Company />
      </NotAuthManagedRoute>
      <AuthenticatedRoute
        exact
        path="/freelance/our-partners"
        component={() => {
          window.open("https://www.yalink.fr/nos-partenaires/", "_blank");
          return <FreelanceHome />;
        }}
      />
      <AuthenticatedRoute
        exact
        path="/support"
        component={() => {
          window.open(
            "https://yalink.notion.site/Centre-d-aide-Yalink-32e860df4f0a4d598bb03e773be7d5e4",
            "_blank"
          );
          return <FreelanceHome />;
        }}
      />
      <AuthenticatedRoute exact path="/freelance/home">
        <FreelanceHome />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/freelance/profile">
        <FreelanceProfile />
      </AuthenticatedRoute>
      <UnauthenticatedRoute exact path="/external-reco">
        <ExternalReco />
      </UnauthenticatedRoute>
      <AuthenticatedRoute exact path="/freelance/enterprise">
        <FreelanceEnterprise />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/freelance/missions">
        <FreelanceMissions />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/freelance/missions/:offerId">
        <FreelanceAvailableMissionsDetailed />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/freelance/missions/:offerId/onboarding">
        <FreelanceOnboarding />
      </AuthenticatedRoute>
      <AuthenticatedRoute
        exact
        path="/freelance/missions/:offerId/onboarding/details"
      >
        <FreelanceOnboardingDetails />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/freelance/missions/:status/:missionId">
        <FreelanceOngoingMissionsDetailed />
      </AuthenticatedRoute>
      <AuthenticatedRoute
        exact
        path="/freelance/missions/:status/:missionId/update"
      >
        <FreelanceOnboardingDetails />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/freelance/welcome">
        <SuccessfulSignup />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/freelance/about/you">
        <FreelanceRegister />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/freelance/about/job">
        <FreelanceJob />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/freelance/about/preferences">
        <FreelancePreferences />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/freelance/about/linkedin">
        <FreelanceLinkedIn />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/settings/password">
        <ChangePassword />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/settings/forgetme">
        <DeleteAccount />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/settings/notifications">
        <ChangeNotifications />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/admin">
        <AdminHome />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/admin/dashboard">
        <AdminDashboard />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/admin/freelance">
        <FreelanceList />
      </AuthenticatedRoute>
      <AuthenticatedRoute
        exact
        path="/freelance/missions/:status/:missionId/activity-report/:year/:month"
      >
        <ActivityReport />
      </AuthenticatedRoute>
      <AuthenticatedRoute
        exact
        path="/freelance/missions/:status/:missionId/activity-report/:year/:month/review"
      >
        <ActivityReportReview />
      </AuthenticatedRoute>

      {/* Routes accessed by the client via an external link 
      (with a JWT for further authentication) */}
      <NotAuthManagedRoute exact path="/offer/view">
        <OfferView />
      </NotAuthManagedRoute>
      <NotAuthManagedRoute exact path="/freelance/profile/view">
        <FreelanceProfile />
      </NotAuthManagedRoute>

      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  );
}
