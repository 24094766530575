import React from "react";
import {
  InputGroup,
  InputLeftAddon,
  Input,
  Button,
  InputRightElement,
} from "@chakra-ui/react";

export default function FilterInput(props) {
  return (
    <InputGroup mb="20px" bg="white">
      <InputLeftAddon children="Filtrer" />
      <Input
        placeholder={props.placeholder ? props.placeholder : "Tesla"}
        value={props.filter}
        onChange={(e) => {
          props.setFilter(e.target.value);
        }}
      />
      <InputRightElement width="7rem" mr="0.5rem">
        <Button
          h="1.75rem"
          size="md"
          disabled={props.searching}
          onClick={() => {
            props.setSearching(true);
          }}
        >
          {"Rechercher"}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
}
