import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Text, Link } from "@chakra-ui/react";
import { ArrowLeft } from "@styled-icons/bootstrap/ArrowLeft";

export default function BackBanner({ path, title, isMobile }) {
  const history = useHistory();
  return (
    <Box bg="yalink.green" h={isMobile ? "170px" : "120px"} w="100%">
      <Text color="white" padding="5" ml="3%">
        <ArrowLeft size="20px" />
        <Link
          ml="10px"
          onClick={() => {
            history.goBack();
          }}
          _hover={{ color: "yalink.yellow" }}
        >
          Retour
        </Link>
        <Text mt="10px" fontSize="30px" fontWeight="bold">
          {title}
        </Text>
      </Text>
    </Box>
  );
}
