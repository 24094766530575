import React, { useState } from "react";
import { useToast } from "@chakra-ui/react";
import { LinkedIn } from "react-linkedin-login-oauth2";
import { Button } from "@chakra-ui/react";

import { useAppContext } from "../../libs/contextLib";
import Auth from "../../services/auth.service";
import User from "../../services/user.service";
import config from "../../config";
import { FaLinkedin } from "react-icons/fa";

function LinkedInPage(props) {
  const { userHasAuthenticated } = useAppContext();
  const [code, setCode] = useState("");
  const toast = useToast();

  function authenticate() {
    Auth.linkedinLogin({ code: code })
      .then(() => {
        if (props.signup) {
          User.createVerificationCode({
            email: Auth.getCurrentUser().email,
          }).catch(() => {});
        }
        userHasAuthenticated(true);
      })
      .catch(() => {
        toast({
          position: "top",
          title: "Nous n'avons pas pu t'authentifier, verifie tes informations",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  }

  const handleSuccess = (data) => {
    setCode(data.code);
  };

  const handleFailure = (error) => {};

  return (
    <div>
      <LinkedIn
        clientId="78g7uq9c4bclud"
        onFailure={handleFailure}
        onSuccess={handleSuccess}
        scope="r_liteprofile r_emailaddress"
        redirectUri={config.CLIENT_URL + "/linkedin"}
        renderElement={({ onClick, disabled }) => (
          <Button
            width="100%"
            colorScheme="linkedin"
            fontSize="0.75rem"
            leftIcon={<FaLinkedin />}
            onClick={onClick}
            disabled={props.signup && !props.agreementChecked}
          >
            {props.signup ? "S'inscrire " : "Se connecter "}avec Linkedin
          </Button>
        )}
      />
      {code && authenticate()}
    </div>
  );
}

export default LinkedInPage;
