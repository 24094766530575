import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { Link as RouterLink } from "react-router-dom";

import LinkedInPage from "../LinkedInPage/LinkedInPage";
import { useAppContext } from "../../libs/contextLib";
import Auth from "../../services/auth.service";
import User from "../../services/user.service";

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Box,
  Grid,
  Center,
  Divider,
  SimpleGrid,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Heading,
  useToast,
  Link,
  GridItem,
} from "@chakra-ui/react";
import SignupVideo from "../../videos/AnimApp_Mission.gif";

function SignupSection() {
  const { userHasAuthenticated } = useAppContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  // isMobile responsive useState and useEffect

  const [isMobile, setIsMobile] = useState(false);

  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    applyResponsive();
  }, []);

  window.addEventListener("resize", applyResponsive);

  async function handleSubmit(values, actions) {
    await Auth.login({
      email: values.email,
      password: values.password,
    })
      .then(() => {
        userHasAuthenticated(true);
      })
      .catch(() => {
        toast({
          position: "top",
          title: "Nous n'avons pas pu t'authentifier, vérifie tes informations",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
    actions.setSubmitting(false);
  }

  async function handleResetPassword(values, actions) {
    await User.forgetPassword({ email: values.forgetEmail })
      .then(() => {
        toast({
          position: "top",
          title:
            "Un e-mail te sera envoyé sous peu de temps afin de réinitialiser ton mot de passe. Tu auras alors 10h pour créer ton nouveau mot de passe",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          position: "top",
          title: "Nous avons des difficultés à soumettre cette demande...",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
    actions.setSubmitting(false);
    onClose();
  }

  function validateName(value) {
    let error;
    if (!value) {
      error = "*Il va falloir remplir ce champ 😱";
    }
    return error;
  }

  function validatePassword(value) {
    let error = "";
    if (!value) {
      error = "*Il va falloir remplir ce champ 😱";
    }
    return error;
  }

  return (
    <>
      <Grid
        templateColumns={isMobile ? "1fr" : "1fr 1fr"}
        mb={isMobile ? "70px" : null}
      >
        <GridItem h="100%" bg="white">
          <Box mt="50px" w="100%" pl="10%" pr="10%" mb="10%">
            <Box mb="40px">
              <Heading as="h1" size="lg" mb="25px">
                Connecte toi
              </Heading>
              <Text mb="20px">
                Ou{" "}
                <Link color="teal.500" as={RouterLink} to="/freelance/signup">
                  inscris toi
                </Link>
              </Text>
              <Formik
                initialValues={{
                  name: "",
                  lastname: "",
                  email: "",
                  password: "",
                  confirmpassword: "",
                }}
                onSubmit={(values, actions) => {
                  handleSubmit(values, actions);
                }}
              >
                {(props) => (
                  <Form>
                    <Field name="email" validate={validateName}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.email && form.touched.email}
                          isRequired
                        >
                          <FormLabel htmlFor="email">E-mail</FormLabel>
                          <Input
                            {...field}
                            id="email"
                            placeholder="hello@yalink.fr"
                          />
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="password" validate={validatePassword}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.password && form.touched.password
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="password">Mot de passe</FormLabel>
                          <Input
                            {...field}
                            id="password"
                            placeholder=""
                            type="password"
                          />
                          <FormErrorMessage>
                            {form.errors.password}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Button
                      mt={4}
                      width="100%"
                      color="white"
                      bg="yalink.green"
                      _hover={{ bg: "yalink.yellow" }}
                      isLoading={props.isSubmitting}
                      type="submit"
                      disabled={!(props.isValid && props.dirty)}
                    >
                      Me connecter
                    </Button>
                    <SimpleGrid columns={2} spacing={2}>
                      <Box mt="25px" align="right">
                        <Link color="teal.500" onClick={onOpen}>
                          Mot de passe oublié?
                        </Link>
                      </Box>
                    </SimpleGrid>
                  </Form>
                )}
              </Formik>
              <SimpleGrid columns={3} spacing={1} mt="20px" mb="20px" w="100%">
                <Box w="100%" h="10">
                  <Divider padding="2" />
                </Box>
                <Box w="100%" h="10">
                  <Center>
                    <Text fontWeight="bold">Ou</Text>
                  </Center>
                </Box>
                <Box w="100%" h="10">
                  <Divider padding="2" />
                </Box>
              </SimpleGrid>
              <Box w="100%">
                <LinkedInPage />
              </Box>
            </Box>
          </Box>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Mot de passe oublié?</ModalHeader>
              <ModalCloseButton />
              <Formik
                initialValues={{ forgetEmail: "" }}
                onSubmit={(values, actions) => {
                  handleResetPassword(values, actions);
                }}
                validator={() => ({})}
              >
                {(props) => (
                  <>
                    <ModalBody pb={6}>
                      <Text mb="20px">
                        Pour réinitialiser ton mot de passe, saisis l'addresse
                        email avec laquelle tu as créé ton compte Yalink.
                      </Text>

                      <Form>
                        <Field name="forgetEmail" validate={validateName}>
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.forgetEmail &&
                                form.touched.forgetEmail
                              }
                              isRequired
                            >
                              <FormLabel htmlFor="forgetEmail">
                                E-mail
                              </FormLabel>
                              <Input
                                {...field}
                                id="forgetEmail"
                                placeholder="hello@yalink.fr"
                              />
                              <FormErrorMessage>
                                {form.errors.forgetEmail}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Form>
                    </ModalBody>

                    <ModalFooter>
                      <Button
                        color="white"
                        bg="yalink.green"
                        _hover={{ bg: "yalink.yellow" }}
                        mr={3}
                        isLoading={props.isSubmitting}
                        type="submit"
                        onClick={props.handleSubmit}
                        disabled={!(props.isValid && props.dirty)}
                      >
                        Envoyer
                      </Button>
                    </ModalFooter>
                  </>
                )}
              </Formik>
            </ModalContent>
          </Modal>
        </GridItem>

        <GridItem h="100%" bg="#f8fcfc" display={isMobile && "none"}>
          <Box align="center" mt="10%">
            <Text fontSize="35px" fontWeight="bold" color="yalink.green">
              Connecte toi
            </Text>
            <Text color="yalink.green" fontSize="25px">
              aux meilleures missions
            </Text>
          </Box>
          <Center mt="4%">
            <img src={SignupVideo} alt="Animation Missions" />
          </Center>
        </GridItem>
      </Grid>
    </>
  );
}

export default SignupSection;
