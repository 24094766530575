import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Deals from "../../services/deal.service";
import OnboardingFreelance from "../../components/OnboardingFreelance/OnboardingFreelance";

function FreelanceOnboarding() {
  const [isLoading, setIsLoading] = useState(true);
  const [deal, setDeal] = useState(null);
  const { offerId } = useParams();

  useEffect(() => {
    async function onLoad() {
      await Deals.getDeal(offerId)
        .then((response) => {
          setDeal({
            id: offerId,
            sector: response.data.deal.sector,
            job: response.data.deal.jobTitle,
            name: response.data.deal.name,
            location: response.data.deal.postalCode,
            beginDate: response.data.deal.startDate,
            sizing: response.data.deal.sizing,
            skill: null,
            timing: response.data.deal.workRythm,
            homeoffice: response.data.deal.workplace,
            description: response.data.deal.description,
          });
        })
        .catch((error) => {});
    }
    if (isLoading) {
      onLoad();
      setIsLoading(false);
    }
  }, [isLoading, offerId]);

  return deal && !isLoading && <OnboardingFreelance deal={deal} />;
}

export default FreelanceOnboarding;
