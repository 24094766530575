import axios from "axios";
import authHeader from "../libs/authHeaderLib";
import config from "../config";

class ClientReferenceService {
  // Reference requested by a freelance
  async requestRef(referenceRequest, freelanceId) {
    let path = "/references/request";
    if (freelanceId) {
      path += "?id=" + freelanceId;
    }
    return axios.post(config.API_URL + path, referenceRequest, {
      headers: authHeader(),
    });
  }
  // Reference submitted by a client
  async submitRef(reference) {
    return axios.post(
      config.API_URL + "/references/request/submit",
      reference,
      {
        headers: authHeader(),
      }
    );
  }
  // Actions for administrators to create internal references
  async createRef(details) {
    return axios.post(config.API_URL + "/references", details, {
      headers: authHeader(),
    });
  }

  async updateRef(details) {
    return axios.put(config.API_URL + "/references", details, {
      headers: authHeader(),
    });
  }

  async deleteRef(id) {
    return axios.delete(config.API_URL + "/references/" + id, {
      headers: authHeader(),
    });
  }
}

export default new ClientReferenceService();
