import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Stack,
  Box,
  Center,
  Grid,
  GridItem,
  Avatar,
  HStack,
  ButtonGroup,
  IconButton,
  Flex,
  Text,
  Divider,
  Heading,
  Textarea,
  Tag,
  TagLabel,
  TagCloseButton,
  Spacer,
  useToast,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import User from "../../services/user.service";
import { CheckIcon, EditIcon, AddIcon } from "@chakra-ui/icons";
import { FaLinkedin } from "react-icons/fa";
import { LoadingComponent, NewFreelanceExperience } from "../../components";
import Portfolio from "../../components/Portfolio/Portfolio";
import Recommendations from "../../components/Recommendations/Recommendations";
import { preferencesDict } from "../../components/FreelancePreferencesForm/FreelancePreferencesForm";
import { useAppContext } from "../../libs/contextLib";
import { querystring } from "../../libs/queryStringLib";
import { hasAuthHeader } from "../../libs/authHeaderLib";
import { getUserProfile } from "../../libs/authHeaderLib";
import { UserProfiles } from "../../libs/authHeaderLib";
import TagInput from "../../components/Modals/TagInput";
import ModalJobs from "../../components/Modals/ModalJobs";
import ModalSectors from "../../components/Modals/ModalSectors";
import CheckListFreelance from "../../components/ChecklistFreelance/CheckListFreelance";
import { Dollar } from "@styled-icons/boxicons-regular/Dollar";
import { House } from "@styled-icons/material/House";
import { PersonFill } from "@styled-icons/bootstrap/PersonFill";
import { Location } from "@styled-icons/entypo/Location";
import { Earth } from "@styled-icons/icomoon/Earth";
import { StopwatchFill } from "@styled-icons/bootstrap/StopwatchFill";
import { HourglassSplit } from "@styled-icons/bootstrap/HourglassSplit";
import { CalendarDate } from "@styled-icons/bootstrap/CalendarDate";
import { Business } from "@styled-icons/ionicons-outline/Business";
import { School } from "@styled-icons/ionicons-sharp/School";
import { Stars } from "@styled-icons/bootstrap/Stars";
import { Upload } from "@styled-icons/boxicons-regular/Upload";

function FreelanceProfile() {
  // Controls modal for activity sectors and skills
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { userHasAuthenticated } = useAppContext();
  const toast = useToast();
  const [newSummary, setNewSummary] = React.useState("");
  const [newHeadline, setNewHeadline] = React.useState("");
  const [newLinkedinUrl, setNewLinkedinUrl] = React.useState("");
  const [isRefreshingProfile, setIsRefreshingProfile] = React.useState(false);
  const [newLanguage, setNewLanguage] = React.useState("");
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [freelance, setFreelance] = useState(null);
  // const [isRecommendationChecked] = useState(true);
  const [freelanceDetails, setFreelanceDetails] = useState({
    picture: "",
    headline: "",
    summary: "",
    linkedinUrl: "",
    experiences: [],
    education: [],
    sectors: [],
    jobTitles: [],
    skills: [],
    languages: [],
  });

  const [freelancePreferences, setFreelancePreferences] = useState({
    availability: "",
    tjm: "",
    city: "",
    workplace: "",
    area: "",
    length: "",
    daysperweek: "",
  });
  const [hasfreelancePreferences, sethasfreelancePreferences] = useState(false);
  const [skillsOpen, setSkillsOpen] = useState(true);
  const [jobsOpen, setJobsOpen] = useState(true);
  const [sectorsOpen, setSectorsOpen] = useState(true);
  const [freelanceId] = useState(
    querystring("id", window.location.href)
      ? querystring("id", window.location.href)
      : null
  );
  const [overProfilePicture, setOverProfilePicture] = useState(false);
  const regexBreakLine = /\n|- |•/;

  const userProfile = getUserProfile();

  const [recommendations, setRecommendations] = useState([]);

  // isMobile responsive useState and useEffect

  const [isMobile, setIsMobile] = useState(false);

  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    applyResponsive();
  }, []);

  window.addEventListener("resize", applyResponsive);

  // add with enter the language
  const handleKeyPress = (event) => {
    if (event.charCode === 13) {
      addKeyToArray(newLanguage, setNewLanguage, "languages");
    }
  };

  useEffect(() => {
    async function onLoad() {
      await User.getFreelance(freelanceId, hasAuthHeader().external)
        .then((response) => {
          setFreelance({
            fname: response.data.first_name,
            lname: response.data.last_name ? response.data.last_name : "",
            profile_complete: response.data.profile_complete,
          });
          if (
            // If preferences are not filled with both availability and all the other fields,
            // redirect to home page
            response.data.preferences &&
            response.data.preferences.availability &&
            response.data.preferences.tarification
          ) {
            setFreelancePreferences({
              availability: response.data.preferences.availability,
              tjm: response.data.preferences.tarification,
              city: response.data.preferences.city,
              workplace: response.data.preferences.remote_friendly,
              area: response.data.preferences.mobility,
              length: response.data.preferences.duration,
              daysperweek: response.data.preferences.fulltime,
            });
            sethasfreelancePreferences(true);
          } else {
            if (userProfile === UserProfiles.FREELANCE) {
              toast({
                position: "top",
                title: "Finalisons ton profil",
                description:
                  "Remplis ton boarding pass afin d'acceder à ton profil",
                status: "warning",
                duration: 9000,
                isClosable: true,
              });
              history.push("/freelance/home");
            }
          }
          if (response.data.profile_complete) {
            let tempDetails = freelanceDetails;
            if (response.data.details) {
              if (response.data.details.headline) {
                tempDetails.headline = response.data.details.headline;
                setNewHeadline(response.data.details.headline);
              }
              if (response.data.details.summary) {
                tempDetails.summary = response.data.details.summary;
                setNewSummary(response.data.details.summary);
              }
              if (response.data.details.linkedin_url) {
                tempDetails.linkedinUrl = response.data.details.linkedin_url;
                setNewLinkedinUrl(response.data.details.linkedin_url);
              }
              tempDetails.picture = response.data.details.profile_picture
                ? response.data.details.profile_picture
                : tempDetails.picture;
              tempDetails.portfolioUrlList = response.data.details
                .portfolio_url_list
                ? response.data.details.portfolio_url_list
                : tempDetails.portfolioUrlList;
              tempDetails.experiences = response.data.details.experiences
                ? response.data.details.experiences
                : tempDetails.experiences;
              tempDetails.education = response.data.details.education
                ? response.data.details.education
                : tempDetails.education;
              tempDetails.languages = response.data.details.languages
                ? response.data.details.languages
                : tempDetails.languages;
              tempDetails.sectors = response.data.details.activity_sectors
                ? response.data.details.activity_sectors
                : tempDetails.sectors;
              tempDetails.jobTitles = response.data.details.job_titles
                ? response.data.details.job_titles
                : tempDetails.jobTitles;
              tempDetails.skills = response.data.details.skills
                ? response.data.details.skills
                : tempDetails.skills;
              setFreelanceDetails({
                ...tempDetails,
              });
            }
          }
          let tempReferences = [];
          for (let i = 0; i < response.data.references.length; i++) {
            tempReferences.push({
              title:
                response.data.references[i].firstName +
                " " +
                response.data.references[i].lastName +
                ".",
              description: response.data.references[i].description,
              company: response.data.references[i].company,
            });
          }
          setRecommendations([...tempReferences]);
          setIsLoading(false);
          let atLeastOneDetailEmpty = false;
          if (freelanceDetails.sectors.length !== 0) {
            setSectorsOpen(false);
          } else {
            atLeastOneDetailEmpty = true;
          }
          if (freelanceDetails.jobTitles.length !== 0) {
            setJobsOpen(false);
          } else {
            atLeastOneDetailEmpty = true;
          }
          if (freelanceDetails.skills.length !== 0) {
            setSkillsOpen(false);
          } else {
            atLeastOneDetailEmpty = true;
          }
          if (atLeastOneDetailEmpty) {
            onOpen();
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            toast({
              position: "top",
              title: "Valide ton email afin de commencer à utiliser Yalink",
              status: "warning",
              duration: 9000,
              isClosable: true,
            });
            history.push("/freelance/welcome");
          } else {
            userHasAuthenticated(false);
          }
        });
    }
    if (isLoading) {
      onLoad();
    }
  }, [
    isLoading,
    history,
    toast,
    freelanceDetails,
    userHasAuthenticated,
    freelanceId,
    onOpen,
    userProfile,
  ]);

  let handleSummaryChange = (e) => {
    let inputValue = e.target.value;
    setNewSummary(inputValue);
  };
  let handleHeadlineChange = (e) => {
    let inputValue = e.target.value;
    setNewHeadline(inputValue);
  };
  let handleLinkedinUrlChange = (e) => {
    let inputValue = e.target.value;
    setNewLinkedinUrl(inputValue);
  };
  let handleLanguageChange = (e) => {
    let inputValue = e.target.value;
    setNewLanguage(inputValue);
  };
  async function handleSummaryUpdate() {
    const details = { summary: newSummary };
    await User.updateFreelanceDetails(details)
      .then(() => {
        toast({
          position: "top",
          title: "Description mise à jour !",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch(() => {});
  }
  async function handleHeadlineUpdate() {
    const details = { headline: newHeadline };
    await User.updateFreelanceDetails(details)
      .then(() => {
        toast({
          position: "top",
          title: "Titre mis à jour !",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch(() => {});
  }

  async function refreshLinkedinProfile() {
    const details = { linkedinUrl: newLinkedinUrl, freelanceId: freelanceId };
    await User.updateFreelanceDetails(details)
      .then(() => {
        toast({
          position: "top",
          title: "Lien Linkedin mis à jour !",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch(() => {});

    setIsRefreshingProfile(true);
    await User.populateProfileFromLinkedin(freelanceId)
      .then(() => {
        setIsRefreshingProfile(false);
        window.location.reload();
      })
      .catch((error) => {
        setIsRefreshingProfile(false);
        toast({
          position: "top",
          title: "Ton profil n'a pas pu être rafraichi",
          status: "warning",
          duration: 2000,
          isClosable: true,
        });
      });
  }

  async function deleteKeyFromArray(key, arrayName) {
    let tempDetails = freelanceDetails;
    const index = tempDetails[arrayName].indexOf(key);
    if (index > -1) {
      tempDetails[arrayName].splice(index, 1);
      const details = { [arrayName]: { remove: key } };
      await User.updateFreelanceDetails(details).catch(() => {});
      setFreelanceDetails({ ...tempDetails });
    }
  }

  async function addKeyToArray(key, setKey, arrayName) {
    if (key !== "") {
      let tempDetails = freelanceDetails;
      tempDetails[arrayName].push(key);
      const details = { [arrayName]: { add: key } };
      await User.updateFreelanceDetails(details).catch(() => {});
      setFreelanceDetails({ ...tempDetails });
      setKey("");
    }
  }

  const goToDashboard = () => {
    let path = `/freelance/home`;
    history.push(path);
  };

  let ReadOnlyNoRender = ({ component, noRenderBoolExpression }) => {
    return <>{noRenderBoolExpression ? null : component}</>;
  };

  function Preferences() {
    if (hasfreelancePreferences) {
      let preferences = [];
      for (let [key, value] of Object.entries(freelancePreferences)) {
        let icon = "";
        let desc = "";
        let text = "";

        if (key === "availability") {
          icon = <PersonFill size="25px" />;
          desc = "Je suis";
          text = preferencesDict.availability[value].text;
        } else if (key === "tjm") {
          icon = <Dollar size="25px" />;
          desc = "Tarif";
          text = value + " €/jour (tarif indicatif)";
        } else if (key === "city") {
          icon = <Location size="25px" />;
          desc = "Localisation";
          text = value;
        } else if (key === "workplace") {
          icon = <House size="25px" />;
          desc = "Télé-travail";
          text = preferencesDict.workplace[value].text;
        } else if (key === "area") {
          icon = <Earth size="20px" />;
          desc = "Mobilité";
          text = preferencesDict.area[value].text;
        } else if (key === "length") {
          icon = <StopwatchFill size="20px" />;
          desc = "Durée";
          text = preferencesDict.length[value].text;
        } else if (key === "daysperweek") {
          icon = <HourglassSplit size="20px" />;
          desc = "Temps partiel/plein";
          text = preferencesDict.daysperweek[value].text;
        }
        preferences.push({ icon: icon, desc: desc, value: text });
      }
      return preferences.map((pref) => (
        <Box key={pref.value} mb="20px">
          <HStack>
            <Text>{pref.icon}</Text>
            <Text fontWeight="semibold" as="h4" lineHeight="tight">
              {pref.desc}
            </Text>
            <Spacer />
            <Text>{pref.value}</Text>
          </HStack>
        </Box>
      ));
    } else {
      return null;
    }
  }

  function CustomList(props) {
    return freelanceDetails[props.arrayName]
      ? freelanceDetails[props.arrayName].map((key) => (
          <Tag
            size="lg"
            color="#1a202c"
            bgColor="#edf2f7"
            borderRadius="md"
            mr={3}
            mt={3}
            mb={3}
            key={key.id}
            boxShadow="base"
          >
            <Avatar size="xs" name={key.id} ml={-1} mr={2} />
            <TagLabel>{key.id}</TagLabel>
          </Tag>
        ))
      : null;
  }

  function LegacyCustomList(props) {
    return freelanceDetails[props.arrayName]
      ? freelanceDetails[props.arrayName].map((key) => (
          <Tag
            size="lg"
            colorScheme={props.color ? props.color : "orange"}
            borderRadius="md"
            mr={2}
            mt={2}
            mb={2}
            boxShadow="base"
            key={key}
          >
            <Avatar size="xs" name={key} ml={-1} mr={2} />
            <TagLabel>{key}</TagLabel>
            <ReadOnlyNoRender
              noRenderBoolExpression={userProfile !== UserProfiles.FREELANCE}
              component={
                <TagCloseButton
                  onClick={() => deleteKeyFromArray(key, props.arrayName)}
                />
              }
            />
          </Tag>
        ))
      : null;
  }

  function Education({ educationList }) {
    if (!educationList || educationList.length === 0) {
      return null;
    }
    var sortedEducation = educationList.sort((a, b) => {
      if (a.starts_at && b.ends_at) {
        return parseInt(a.starts_at.year) < parseInt(b.ends_at.year) ? 1 : -1;
      }
      return 1;
    });
    return sortedEducation.map((education) => (
      <Box key={education.school}>
        <HStack alignItems="flex-start">
          <Box mr="10px">
            <School size="30px" />
          </Box>
          <Box>
            <Flex>
              <Text
                fontWeight="semibold"
                letterSpacing="wide"
                fontSize="md"
                textTransform="uppercase"
              >
                {education.school}
              </Text>
            </Flex>
            <Text fontWeight="semibold" as="h4" lineHeight="tight" mb="20px">
              {education.degree_name ? education.degree_name : null}
            </Text>
            <HStack mb="10px">
              {education.location ? (
                <Text mr="20px">
                  <Location size="20px" /> {education.location}
                </Text>
              ) : null}
              <Text>
                <CalendarDate size="20px" />{" "}
                {education.starts_at
                  ? education.starts_at.year
                  : "Dates non disponibles"}{" "}
                {education.ends_at ? "à " + education.ends_at.year : ""}
              </Text>
            </HStack>

            {education.description
              ? education.description
                  .split(regexBreakLine)
                  .map((str) => <Text>{str}</Text>)
              : null}
          </Box>
          <Spacer />
          <ReadOnlyNoRender
            noRenderBoolExpression={userProfile === UserProfiles.COMPANY}
            component={
              <NewFreelanceExperience
                freelanceId={freelanceId}
                freelanceDetails={freelanceDetails}
                setFreelanceDetails={setFreelanceDetails}
                type="formation"
                keyName={
                  education.degree_name
                    ? education.degree_name
                    : education.school
                }
                keyDescription={education.description}
              />
            }
          />
        </HStack>
        <Divider mb="10px" mt="10px" />
      </Box>
    ));
  }

  function Experiences({ experiences }) {
    if (!experiences || experiences.length === 0) {
      return null;
    }
    var sortedExperiences = experiences.sort((a, b) => {
      if (a.starts_at && b.ends_at) {
        return parseInt(a.starts_at.year) < parseInt(b.ends_at.year) ? 1 : -1;
      }
      return 1;
    });
    return sortedExperiences.map((exp) => (
      <Box key={exp.title}>
        <HStack alignItems="flex-start">
          <Flex>
            <Business size="40px" />
          </Flex>
          <Box>
            <Flex>
              <Text
                fontWeight="semibold"
                letterSpacing="wide"
                fontSize="xl"
                textTransform="uppercase"
                mb="5px"
              >
                {exp.company}
              </Text>
            </Flex>
            <Text fontWeight="semibold" as="h4" lineHeight="tight" mb="15px">
              {exp.title}
            </Text>
            <HStack mb="10px">
              <Text mr="20px">
                <Location size="20px" /> {exp.location}
              </Text>
              <Text>
                <CalendarDate size="20px" />{" "}
                {exp.starts_at ? exp.starts_at.year : "Dates non disponibles"}{" "}
                {exp.ends_at ? "à " + exp.ends_at.year : "jusqu'à présent"}
              </Text>
            </HStack>

            {exp.description
              ? exp.description
                  .split(regexBreakLine)
                  .map((str) => <Text>{str}</Text>)
              : null}
          </Box>
          <Spacer />
          <ReadOnlyNoRender
            noRenderBoolExpression={userProfile === UserProfiles.COMPANY}
            component={
              <NewFreelanceExperience
                freelanceId={freelanceId}
                freelanceDetails={freelanceDetails}
                setFreelanceDetails={setFreelanceDetails}
                type="experiences"
                keyName={exp.title}
                keyDescription={exp.description}
              />
            }
          />
        </HStack>
        <Divider mb="20px" mt="20px" />
      </Box>
    ));
  }

  let hiddenInput = null;
  async function handlePhotoSelect(file) {
    await User.uploadProfilePicture(file)
      .then((response) => {
        let tempDetails = freelanceDetails;
        tempDetails.picture = response.data.presigned_url;
        setOverProfilePicture(false);
        setFreelanceDetails({ ...tempDetails });
        toast({
          position: "top",
          title: "Photo de profil mise à jour !",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {
        if (error.response.status === 413) {
          toast({
            position: "top",
            title: "Le fichier ne doit pas depasser 10 MB",
            status: "warning",
            duration: 9000,
            isClosable: true,
          });
        } else {
          userHasAuthenticated(false);
        }
      });
  }

  function SimpleAvatar() {
    return (
      <Avatar
        name={freelance.fname + " " + freelance.lname}
        src={freelanceDetails.picture}
        size="2xl"
        mr="5"
        ml={isMobile ? "60px" : null}
        mb="10px"
        showBorder="true"
      />
    );
  }

  function AvatarWithUpload() {
    return (
      <Avatar
        icon={<Upload width="80%" />}
        bg="white"
        src={overProfilePicture ? null : freelanceDetails.picture}
        size="2xl"
        mr="5"
        ml={isMobile ? "60px" : null}
        mb="10px"
        showBorder="true"
        onClick={() => hiddenInput.click()}
        _hover={{ cursor: "pointer" }}
        onMouseOver={() => setOverProfilePicture(true)}
        onMouseOut={() => setOverProfilePicture(false)}
      />
    );
  }

  return !isLoading && freelance ? (
    <>
      <div>
        {sectorsOpen && userProfile === UserProfiles.FREELANCE ? (
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={isMobile ? "xs" : "3xl"}
          >
            <ModalOverlay />
            <ModalContent>
              <Center>
                <ModalSectors
                  onClose={onClose}
                  onOpen={onOpen}
                  setSectorsOpen={setSectorsOpen}
                  setFreelanceDetails={setFreelanceDetails}
                  freelanceDetails={freelanceDetails}
                  isMobile={isMobile}
                />
              </Center>
            </ModalContent>
          </Modal>
        ) : jobsOpen && userProfile === UserProfiles.FREELANCE ? (
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={isMobile ? "xs" : "5xl"}
          >
            <ModalOverlay />
            <ModalContent jobsOpen={jobsOpen}>
              <Center>
                <ModalJobs
                  onClose={onClose}
                  onOpen={onOpen}
                  setJobsOpen={setJobsOpen}
                  setFreelanceDetails={setFreelanceDetails}
                  freelanceDetails={freelanceDetails}
                />
              </Center>
            </ModalContent>
          </Modal>
        ) : skillsOpen && userProfile === UserProfiles.FREELANCE ? (
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={isMobile ? "xs" : "md"}
          >
            <ModalOverlay />
            <Center>
              <ModalContent skillsOpen={skillsOpen}>
                <ModalHeader>
                  <Stars size="40px" color="#F8C708" /> Complète tes compétences
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody mb="auto">
                  <Box
                    bg="gray.100"
                    w="100%"
                    p={4}
                    color="black"
                    borderRadius="7px"
                    mr="5px"
                    align={isMobile ? "justify" : null}
                  >
                    Renseigne tes compétences et outils maitrisés. Plus tes tags
                    seront nombreux et précis plus tu auras de chance d'être
                    contacté pour des missions qui te correspondent.
                  </Box>
                  <br />
                  <Text fontWeight="bold">Ajoute une compétence</Text>
                  <Text fontSize="sm" color="gray.500" as="i">
                    Appuie sur ENTREE pour valider la saisie d'un mot-clé.
                  </Text>

                  <TagInput
                    existingSkills={freelanceDetails.skills}
                    setFreelanceDetails={setFreelanceDetails}
                    freelanceDetails={freelanceDetails}
                  />
                  <Button
                    ms="285"
                    ml={isMobile ? "200" : "300"}
                    mb="5"
                    mt="10"
                    onClick={() => setSkillsOpen(false)}
                  >
                    Fermer
                  </Button>
                </ModalBody>
              </ModalContent>
            </Center>
          </Modal>
        ) : null}
      </div>
      <Center>
        <Box w={isMobile ? null : "80%"} mt="100px" mb="20px" padding="5">
          <Grid templateColumns={isMobile ? null : "repeat(6, 1fr)"}>
            <GridItem colSpan={4}>
              <Stack>
                <Box
                  w={isMobile ? "80" : "95%"}
                  padding="5"
                  borderWidth={1}
                  borderRadius={5}
                  boxShadow="md"
                  mb="20px"
                  bg="white"
                >
                  <Box>
                    {userProfile === UserProfiles.FREELANCE ? (
                      <>
                        <input
                          hidden
                          type="file"
                          ref={(element) => (hiddenInput = element)}
                          onChange={(e) => handlePhotoSelect(e.target.files[0])}
                        />
                        <AvatarWithUpload />
                      </>
                    ) : (
                      <SimpleAvatar />
                    )}
                    <Box>
                      <Box
                        fontWeight="semibold"
                        letterSpacing="wide"
                        fontSize="xl"
                        textTransform="uppercase"
                        textAlign={isMobile ? "center" : null}
                      >
                        {freelance.fname + " " + freelance.lname}
                      </Box>
                      <HStack
                        mb="20px"
                        ml={isMobile ? "20px" : null}
                        w={isMobile ? "90%" : null}
                      >
                        {userProfile === UserProfiles.COMPANY ||
                        userProfile === UserProfiles.ADMIN ? (
                          <Text mt="20px">
                            <Stars size="40px" color="#F8C708" /> {newHeadline}
                          </Text>
                        ) : (
                          <>
                            <Box mt="1" width="100%">
                              <Input
                                variant="flushed"
                                placeholder="Titre de ton profil freelance"
                                value={newHeadline}
                                onChange={handleHeadlineChange}
                              />
                            </Box>
                            <ButtonGroup size="sm">
                              <IconButton
                                icon={<CheckIcon />}
                                onClick={handleHeadlineUpdate}
                              />
                            </ButtonGroup>
                          </>
                        )}
                      </HStack>
                      {hasfreelancePreferences ? (
                        <HStack mb="20px" ml={isMobile ? "20px" : null}>
                          <Box mr="20px">
                            <Dollar size="25px" /> {freelancePreferences.tjm}{" "}
                            <Box
                              as="span"
                              color="gray.600"
                              fontSize="sm"
                              ml={isMobile ? "20px" : null}
                            >
                              €/jour (tarif indicatif)
                            </Box>
                          </Box>
                          <Box>
                            <House size="25px" /> Disponible sur{" "}
                            {freelancePreferences.city}
                          </Box>
                        </HStack>
                      ) : null}
                      {userProfile === UserProfiles.FREELANCE ||
                      userProfile === UserProfiles.ADMIN ? (
                        <>
                          <Accordion allowMultiple>
                            <AccordionItem>
                              <h2>
                                <AccordionButton>
                                  <Box flex="1" textAlign="left">
                                    Rafraichir ton profil depuis Linkedin
                                  </Box>
                                  <AccordionIcon />
                                </AccordionButton>
                              </h2>
                              <AccordionPanel pb={4} width="100%">
                                <Text>
                                  Si tu souhaites rafraichir les informations de
                                  ton profil Yalink depuis Linkedin, vérifie que
                                  ton adresse Linkedin est à jour et clique sur
                                  "Rafraichir mon profil".
                                </Text>
                                <Text mt="20px">
                                  ⚠️ Attention: En rafraichissant ton profil,
                                  toutes les données seront écrasées
                                </Text>
                                <HStack mt="20px">
                                  <Box mt="1" width="100%">
                                    <Input
                                      variant="flushed"
                                      placeholder="Lien linkedin"
                                      value={newLinkedinUrl}
                                      onChange={handleLinkedinUrlChange}
                                    />
                                  </Box>
                                </HStack>
                                <Button
                                  mt={isMobile ? "30px" : "10px"}
                                  width="100%"
                                  borderRadius="40px"
                                  colorScheme="linkedin"
                                  leftIcon={<FaLinkedin />}
                                  onClick={refreshLinkedinProfile}
                                  isLoading={isRefreshingProfile}
                                >
                                  Rafraichir mon profil
                                </Button>
                              </AccordionPanel>
                            </AccordionItem>
                          </Accordion>
                        </>
                      ) : null}
                    </Box>
                  </Box>
                </Box>
                {userProfile !== UserProfiles.FREELANCE &&
                !newSummary ? null : (
                  <Box
                    maxWidth={isMobile ? "80" : "95%"}
                    mb="20px"
                    padding="5"
                    borderWidth={1}
                    borderRadius={5}
                    boxShadow="md"
                    bg="white"
                  >
                    <Heading mb="20px" size="md" color="gray.600">
                      Ma description
                    </Heading>
                    {userProfile !== UserProfiles.FREELANCE ? (
                      <Text>
                        {newSummary.split(regexBreakLine).map((str) => (
                          <Text>{str}</Text>
                        ))}
                      </Text>
                    ) : (
                      <>
                        <Textarea
                          value={newSummary}
                          onChange={handleSummaryChange}
                          placeholder="Présente toi de manière brève afin de décrocher ta prochaine mission!"
                          height={isMobile ? "150px" : null}
                          mb={isMobile && "20px"}
                        />
                        <ButtonGroup size="sm">
                          <IconButton
                            icon={<CheckIcon />}
                            onClick={handleSummaryUpdate}
                          />
                        </ButtonGroup>
                      </>
                    )}
                  </Box>
                )}
                {userProfile !== UserProfiles.FREELANCE &&
                freelanceDetails.skills.length === 0 ? null : (
                  <Box
                    w={isMobile ? "80" : "95%"}
                    padding="5"
                    mt="20px"
                    mb="20px"
                    borderWidth={1}
                    borderRadius={5}
                    boxShadow="md"
                    bg="white"
                  >
                    <Heading mb="20px" size="md" color="gray.600">
                      Compétences
                    </Heading>
                    <Box>
                      <CustomList arrayName={"skills"} />
                      {userProfile !== UserProfiles.FREELANCE ? null : (
                        <HStack>
                          <Spacer />
                          <Button
                            onClick={() => {
                              setSkillsOpen(true);
                              setJobsOpen(false);
                              setSectorsOpen(false);
                              onOpen();
                            }}
                          >
                            Modifier
                          </Button>
                        </HStack>
                      )}
                    </Box>
                  </Box>
                )}
                {recommendations.length === 0 &&
                userProfile === UserProfiles.COMPANY ? null : (
                  <Recommendations recommendations={recommendations} />
                )}

                <Portfolio
                  portfolioUrlList={freelanceDetails.portfolioUrlList}
                />

                {userProfile === UserProfiles.COMPANY &&
                freelanceDetails.experiences.length === 0 ? null : (
                  <Box
                    w={isMobile ? "80" : "95%"}
                    mb="20px"
                    padding="5"
                    borderWidth={1}
                    borderRadius={5}
                    boxShadow="md"
                    bg="white"
                  >
                    <Flex mb="25px">
                      <Heading
                        mb={isMobile ? "40px" : "20px"}
                        size="md"
                        color="gray.600"
                      >
                        Expériences
                      </Heading>
                      <ReadOnlyNoRender
                        noRenderBoolExpression={
                          userProfile === UserProfiles.COMPANY
                        }
                        component={
                          <>
                            <Spacer />
                            <NewFreelanceExperience
                              freelanceDetails={freelanceDetails}
                              setFreelanceDetails={setFreelanceDetails}
                              type="expérience"
                              freelanceId={freelanceId}
                            />
                          </>
                        }
                      />
                    </Flex>
                    <Experiences experiences={freelanceDetails.experiences} />
                  </Box>
                )}
              </Stack>
            </GridItem>
            <GridItem colSpan={2}>
              {userProfile === UserProfiles.FREELANCE ? (
                <CheckListFreelance
                  freelanceDetails={freelanceDetails}
                  newSummary={newSummary}
                />
              ) : null}

              {hasfreelancePreferences &&
              userProfile !== UserProfiles.COMPANY ? (
                <Box
                  w={isMobile ? "80" : "100%"}
                  mb="20px"
                  padding="5"
                  borderWidth={1}
                  borderRadius={5}
                  boxShadow="md"
                  bg="white"
                >
                  <Flex>
                    <Heading mb="20px" size="md" color="gray.600">
                      Boarding pass
                    </Heading>
                    <ReadOnlyNoRender
                      noRenderBoolExpression={
                        userProfile !== UserProfiles.FREELANCE
                      }
                      component={
                        <>
                          <Spacer />
                          <IconButton
                            size="sm"
                            icon={<EditIcon />}
                            onClick={goToDashboard}
                          />
                        </>
                      }
                    />
                  </Flex>
                  <Box padding="5">
                    <Preferences />
                  </Box>
                </Box>
              ) : null}

              {freelanceId && freelanceDetails.sectors.length === 0 ? null : (
                <Box
                  w={isMobile ? "80" : "100%"}
                  padding="5"
                  mb="20px"
                  borderWidth={1}
                  borderRadius={5}
                  boxShadow="md"
                  bg="white"
                >
                  <Heading mb="20px" size="md" color="gray.600">
                    Secteurs
                  </Heading>
                  <CustomList arrayName={"sectors"} />
                  {freelanceId ? null : (
                    <HStack>
                      <Spacer />
                      <Button
                        onClick={() => {
                          setSkillsOpen(false);
                          setJobsOpen(false);
                          setSectorsOpen(true);
                          onOpen();
                        }}
                      >
                        Modifier
                      </Button>
                    </HStack>
                  )}
                </Box>
              )}

              {freelanceId && freelanceDetails.jobTitles.length === 0 ? null : (
                <Box
                  w={isMobile ? "80" : "100%"}
                  padding="5"
                  mb="20px"
                  borderWidth={1}
                  borderRadius={5}
                  boxShadow="md"
                  bg="white"
                >
                  <Heading mb="20px" size="md" color="gray.600">
                    Métiers
                  </Heading>
                  <CustomList arrayName={"jobTitles"} />
                  {freelanceId ? null : (
                    <HStack>
                      <Spacer />
                      <Button
                        onClick={() => {
                          setSkillsOpen(false);
                          setJobsOpen(true);
                          setSectorsOpen(false);
                          onOpen();
                        }}
                      >
                        Modifier
                      </Button>
                    </HStack>
                  )}
                </Box>
              )}

              {userProfile !== UserProfiles.FREELANCE &&
              freelanceDetails.education.length === 0 ? null : (
                <Box
                  w={isMobile ? "80" : "100%"}
                  mb="20px"
                  padding="5"
                  borderWidth={1}
                  borderRadius={5}
                  boxShadow="md"
                  bg="white"
                >
                  <Flex>
                    <Heading
                      mb={isMobile ? "40px" : "45px"}
                      size="md"
                      color="gray.600"
                    >
                      Formation
                    </Heading>
                    <ReadOnlyNoRender
                      noRenderBoolExpression={
                        userProfile === UserProfiles.COMPANY
                      }
                      component={
                        <>
                          <Spacer />
                          <NewFreelanceExperience
                            freelanceId={freelanceId}
                            freelanceDetails={freelanceDetails}
                            setFreelanceDetails={setFreelanceDetails}
                            type="formation"
                          />
                        </>
                      }
                    />
                  </Flex>
                  <Education educationList={freelanceDetails.education} />
                </Box>
              )}

              {userProfile !== UserProfiles.FREELANCE &&
              freelanceDetails.languages.length === 0 ? null : (
                <Box
                  w={isMobile ? "80" : "100%"}
                  padding="5"
                  borderWidth={1}
                  borderRadius={5}
                  boxShadow="md"
                  bg="white"
                  mb={isMobile ? 70 : null}
                >
                  <Heading mb="20px" size="md" color="gray.600">
                    Langues
                  </Heading>
                  <LegacyCustomList arrayName={"languages"} color={"teal"} />
                  {freelanceId ? null : (
                    <HStack>
                      <Input
                        variant="flushed"
                        placeholder="Ajoute une langue en appuyant sur la touche Entrée"
                        onChange={handleLanguageChange}
                        value={newLanguage}
                        onKeyPress={handleKeyPress}
                      />
                      <ButtonGroup>
                        <IconButton
                          icon={<AddIcon />}
                          onClick={() =>
                            addKeyToArray(
                              newLanguage,
                              setNewLanguage,
                              "languages"
                            )
                          }
                        />
                      </ButtonGroup>
                    </HStack>
                  )}
                </Box>
              )}
            </GridItem>
          </Grid>
        </Box>
      </Center>
    </>
  ) : (
    <LoadingComponent />
  );
}

export default FreelanceProfile;
