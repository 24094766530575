import React, { useState } from "react";

import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  IconButton,
  Button,
  Center,
  Stack,
  Box,
  FormLabel,
  Select,
  Textarea,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import References from "../../services/clientref.service";

function NewClientReference(props) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [referenceName, setreferenceName] = useState("");
  const [grade, setgrade] = useState("");
  const [description, setdescription] = useState("");

  const [id, setId] = useState("");

  function resetAllValues() {
    setreferenceName("");
    setgrade("");
    setdescription("");
    setId("");
  }

  function isFormValid() {
    if (referenceName === "") {
      return false;
    }
    if (grade === "") {
      return false;
    }
    return true;
  }

  function setReferenceDetailsFromDict(refDict) {
    setreferenceName(refDict.referenceName);
    setgrade(refDict.grade);
    setdescription(refDict.description);
    setId(refDict.id);
  }

  function handleUpdate() {
    var result = props.freelance.references.filter((reference) => {
      if (reference.id === props.reference.id) {
        return true;
      }
      return null;
    });
    setReferenceDetailsFromDict(result[0]);
    return true;
  }

  function submitForm(verb) {
    const details = {
      referenceName: referenceName,
      grade: grade,
      description: description,
      id: id,
      freelanceId: props.freelance.id,
    };
    if (verb === "add") {
      References.createRef(details).then((response) => {
        toast({
          position: "top",
          title: "Creation de la ref réussie",
          status: "success",
          duration: 4500,
          isClosable: true,
        });
        let tempReferences = props.freelance.references;
        tempReferences.push(response.data.reference);
        props.setReferences([...tempReferences]);
      });
    }
    if (verb === "update") {
      References.updateRef(details).then((response) => {
        toast({
          position: "top",
          title: "Mise à jour réussie",
          status: "success",
          duration: 4500,
          isClosable: true,
        });
        let tempReferences = props.freelance.references;
        let indexToUpdate = null;
        tempReferences.forEach((reference) => {
          if (reference.id === response.data.reference.id) {
            indexToUpdate = tempReferences.indexOf(reference);
          }
        });

        tempReferences.splice(indexToUpdate, 1, response.data.reference);
        props.setReferences([...tempReferences]);
      });
    }
    if (verb === "remove") {
      References.deleteRef(id).then((response) => {
        toast({
          position: "top",
          title: "Ref supprimée",
          status: "success",
          duration: 4500,
          isClosable: true,
        });
        let tempReferences = props.freelance.references;
        let indexToUpdate = null;
        tempReferences.forEach((reference) => {
          if (reference.id === id) {
            indexToUpdate = tempReferences.indexOf(reference);
          }
        });

        tempReferences.splice(indexToUpdate, 1);
        props.setReferences([...tempReferences]);
      });
    }
    onClose();
    handleClose();
  }

  function onUpdate() {
    handleUpdate();
    onOpen();
  }

  function handleClose() {
    onClose();
    resetAllValues();
  }
  return (
    <>
      {props.reference ? (
        <IconButton size="xs" icon={<EditIcon />} onClick={onUpdate} />
      ) : (
        <Center>
          <Button onClick={onOpen} colorScheme="teal">
            Ajouter ref
          </Button>
        </Center>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Nouvelle référence client</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Stack spacing="24px">
              <Box>
                <FormLabel htmlFor="referenceName">Type de référence</FormLabel>
                <Select
                  placeholder="Type de ref"
                  value={referenceName}
                  onChange={(e) => setreferenceName(e.target.value)}
                >
                  <option value="first_contact">Premier contact</option>
                  <option value="client">Client</option>
                </Select>
              </Box>
              <Box>
                <FormLabel htmlFor="grade">Note</FormLabel>
                {referenceName === "client" ? (
                  <Select
                    placeholder="Selectionner la note"
                    value={grade}
                    onChange={(e) => setgrade(e.target.value)}
                  >
                    <option value="good">Positif</option>
                    <option value="bad">Négatif</option>
                  </Select>
                ) : (
                  <Select
                    placeholder="Selectionner la note"
                    value={grade}
                    onChange={(e) => setgrade(e.target.value)}
                  >
                    <option value="good">Positif</option>
                    <option value="bad">Négatif</option>
                  </Select>
                )}
              </Box>
              <Box>
                <FormLabel htmlFor="description">Description</FormLabel>
                <Textarea
                  placeholder="Details de la référence"
                  value={description}
                  onChange={(e) => setdescription(e.target.value)}
                />
              </Box>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" mr={3} onClick={() => handleClose()}>
              Annuler
            </Button>
            {props.reference ? (
              <>
                <Button
                  colorScheme="red"
                  mr={3}
                  onClick={(e) => submitForm("remove")}
                >
                  Supprimer
                </Button>
                <Button
                  colorScheme="teal"
                  isDisabled={!isFormValid()}
                  onClick={(e) => submitForm("update")}
                >
                  Sauver
                </Button>
              </>
            ) : (
              <Button
                colorScheme="teal"
                isDisabled={!isFormValid()}
                onClick={(e) => submitForm("add")}
              >
                Ajouter
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default NewClientReference;
