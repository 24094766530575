import React from "react";
import { Box, Text, chakra } from "@chakra-ui/react";
function CheckboxComponent(props) {
  return (
    <>
      {props.JOBS.map((option) => (
        <div className="form-check" key={option.id}>
          <input
            className="form-check-input"
            type="checkbox"
            name="families"
            id={option.id}
            value={option.id}
            checked={option.isChecked}
            disabled={option.isDisabled}
            onChange={(e) => props.onChange(e, option, props.JOBS)}
          />
          <label className="form-check-label" htmlFor={option.id}>
            {option.name}
          </label>
        </div>
      ))}
      {props.JOBS.filter(
        (chkItem) => chkItem.isChecked && chkItem.isChecked === true
      ).length >= 3 && (
        <chakra.div id="text">
          <Box>
            <br />
            <Text fontSize="20px" color="red">
              Vous ne pouvez sélectionner que 3 métiers 😱
            </Text>
          </Box>
        </chakra.div>
      )}
    </>
  );
}

export default CheckboxComponent;
