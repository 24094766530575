import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Divider,
  Box,
  Text,
  Grid,
  GridItem,
  Heading,
  Flex,
  Link,
} from "@chakra-ui/react";
import { Work } from "@styled-icons/material/Work";
import { Email } from "@styled-icons/material/Email";
import { PersonCheckFill } from "@styled-icons/bootstrap/PersonCheckFill";
import { DocumentSearch } from "@styled-icons/fluentui-system-filled/DocumentSearch";
import NotificationService from "../../services/notification.service";
function Notifications({ initialNotifications = [], setHasNotifications }) {
  const [notifications, setNotifications] = useState(initialNotifications);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const history = useHistory();

  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    async function getNotifications() {
      await NotificationService.getNotifications()
        .then((response) => {
          setNotifications(response.data.notifications);
          if (response.data.notifications.length > 0) {
            setHasNotifications(true);
          }
        })
        .catch((error) => {
          setNotifications([]);
        });
    }
    if (loading && !notifications.length) {
      getNotifications();
      setLoading(false);
    }
    applyResponsive();
  }, [loading, notifications.length, setHasNotifications]);

  let IconNotificationChange = ({ component }) => {
    return (
      <>
        <Box bgColor="yalink.green" borderRadius="20" w="40px" h="40px" pt="1">
          <Box align="center" justify="center">
            {component}
          </Box>
        </Box>
      </>
    );
  };

  let ReturnIconNotification = ({ notification }) => {
    if (
      notification.type === "Reminder" ||
      notification.type === "Reminder-boarding"
    ) {
      return (
        <IconNotificationChange
          component={<PersonCheckFill size="25px" color="#fff" />}
        />
      );
    } else if (notification.type === "Nouvelle mission") {
      return (
        <IconNotificationChange component={<Work size="25px" color="#fff" />} />
      );
    } else if (notification.type === "Documents") {
      return (
        <IconNotificationChange
          component={<DocumentSearch size="25px" color="#fff" />}
        />
      );
    } else if (notification.type === "Nouvel e-mail") {
      return (
        <IconNotificationChange
          component={<Email size="25px" color="#fff" />}
        />
      );
    }
  };

  return (
    <>
      <Grid p={isMobile && 2}>
        {notifications.length === 0 ? (
          <>
            <GridItem>
              <Box>
                <Heading fontSize="15px" p={5}>
                  Aucune notification pour le moment
                </Heading>
              </Box>
            </GridItem>
          </>
        ) : null}
        {notifications.map((notif) => (
          <Box
            _hover={{ bg: "#e8ebed" }}
            key={notif.description ? notif.description : notif.job}
          >
            <GridItem textAlign="left">
              <Grid templateColumns={isMobile ? "0.2fr 0.8fr" : "0.6fr 3fr"}>
                <GridItem
                  justify={isMobile ? "center" : undefined}
                  mt={
                    notif.type === "Nouvel e-mail" ||
                    notif.type === "Documents" ||
                    notif.type === "Reminder"
                      ? "40%"
                      : "80%"
                  }
                  ml={isMobile ? 2 : 5}
                >
                  <ReturnIconNotification notification={notif} />
                </GridItem>
                <GridItem>
                  {notif.type === "Nouvelle mission" && (
                    <Box>
                      <Heading
                        fontSize="18px"
                        mb="3%"
                        mt="3%"
                        data-testid="notif"
                      >
                        {notif.title}
                      </Heading>
                      <Text fontSize="14px" fontWeight="semibold" mb="3%">
                        {notif.content.name}
                      </Text>
                      <Flex align="center" justify="unset" mb="1%">
                        <Box>
                          <Text fontSize="14px" fontWeight="semibold">
                            Taille de mission :
                          </Text>
                        </Box>
                        <Box ml="2%">
                          <Text fontSize="14px">{notif.content.sizing}</Text>
                        </Box>
                      </Flex>
                      <Flex align="center" justify="unset" mb="5%">
                        <Box>
                          <Text fontSize="14px" fontWeight="semibold">
                            Lieu de travail :
                          </Text>
                        </Box>
                        <Box ml="2%">
                          <Text fontSize="14px">{notif.content.workplace}</Text>
                        </Box>
                      </Flex>
                      <Box mb="2%">
                        <Link
                          onClick={() => {
                            history.push(
                              "/freelance/missions/" + notif.content.id
                            );
                          }}
                        >
                          <Text
                            fontSize="14px"
                            color="yalink.green"
                            fontWeight="semibold"
                          >
                            Voir la mission
                          </Text>
                        </Link>
                      </Box>
                    </Box>
                  )}
                  {notif.type === "Documents" && (
                    <>
                      <Heading
                        fontSize="18px"
                        mb="3%"
                        mt="3%"
                        data-testid="notif"
                      >
                        {notif.title} :{" "}
                      </Heading>
                      <Box mb="4%">
                        <Text fontSize="14px">{notif.description}</Text>
                      </Box>
                      <Box mb="2%">
                        <Link
                          onClick={() => {
                            history.push("/freelance/enterprise");
                          }}
                        >
                          <Text
                            fontSize="14px"
                            color="yalink.green"
                            fontWeight="semibold"
                          >
                            Remplir mes documents
                          </Text>
                        </Link>
                      </Box>
                    </>
                  )}
                  {notif.type === "Reminder" && (
                    <>
                      <Heading
                        fontSize="18px"
                        mb="3%"
                        mt="3%"
                        data-testid="notif"
                      >
                        {notif.title} :{" "}
                      </Heading>
                      <Box mb="4%">
                        <Text fontSize="14px">{notif.description}</Text>
                      </Box>
                      <Box mb="2%">
                        <Link
                          onClick={() => {
                            history.push("/freelance/home");
                          }}
                        >
                          <Text
                            fontSize="14px"
                            color="yalink.green"
                            fontWeight="semibold"
                          >
                            Mettre à jour ma disponibilité
                          </Text>
                        </Link>
                      </Box>
                    </>
                  )}
                  {notif.type === "Reminder-boarding" && (
                    <>
                      <Heading
                        fontSize="18px"
                        mb="3%"
                        mt="3%"
                        data-testid="notif"
                      >
                        {notif.title} :{" "}
                      </Heading>
                      <Box mb="4%">
                        <Text fontSize="14px">{notif.description}</Text>
                      </Box>
                      <Box mb="2%">
                        <Link
                          onClick={() => {
                            history.push("/freelance/home");
                          }}
                        >
                          <Text
                            fontSize="14px"
                            color="yalink.green"
                            fontWeight="semibold"
                          >
                            Mettre à jour mon Boarding pass
                          </Text>
                        </Link>
                      </Box>
                    </>
                  )}
                  {notif.type === "Nouvel e-mail" && (
                    <>
                      <Heading
                        fontSize="18px"
                        mb="3%"
                        mt="3%"
                        data-testid="notif"
                      >
                        {notif.title} :{" "}
                      </Heading>
                      <Box mb="4%">
                        <Text fontSize="14px">{notif.description}</Text>
                      </Box>
                    </>
                  )}
                </GridItem>
              </Grid>
              <Divider w="80%" ml="10%"></Divider>
            </GridItem>
          </Box>
        ))}
      </Grid>
    </>
  );
}

export default Notifications;
