import { useState, useEffect } from "react";

import { Box, Flex, Button, Text, Heading, useToast } from "@chakra-ui/react";

import { useDropzone } from "react-dropzone";
import PDF_LOGO from "../../images/PDF_LOGO.png";
import { Cross } from "@styled-icons/entypo/Cross";
import FreelanceService from "../../services/freelance.service";
import { getUserProfile } from "../../libs/authHeaderLib";
import { UserProfiles } from "../../libs/authHeaderLib";
import { CloudArrowUp } from "@styled-icons/bootstrap/CloudArrowUp";
import { PlusCircle } from "@styled-icons/bootstrap/PlusCircle";

export default function Portfolio({ portfolioUrlList }) {
  const [filesLoaded, setFilesLoaded] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const fileLimit = 5;
  const userProfile = getUserProfile();
  const toast = useToast();

  //Dropzone configuration
  const [files, setFiles] = useState([]);
  const [fileKeyList, setFileKeyList] = useState([]);

  function findCommonElements(array1, array2) {
    return array1.some((item) => array2.includes(item));
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
      "application/pdf": [],
    },
    onDrop: (acceptedFiles) => {
      let tempFiles = files;
      let fileSize;
      acceptedFiles.forEach((f) => {
        if (f.size >= 12500000) {
          fileSize = true;
        }
      });

      if (acceptedFiles.length > 1) {
        toast({
          title: "Erreur",
          position: "top",
          description: "Veuillez rajouter un fichier à la fois !",
          status: "error",
          duration: 7000,
          isClosable: true,
        });
      } else if (fileSize) {
        toast({
          title: "Erreur",
          position: "top",
          description: "Le fichier ne doit pas dépasser la taille de 10 Mo !",
          status: "error",
          duration: 15000,
          isClosable: true,
        });
      } else {
        let oldFilesPaths = [];
        let newFilesPaths = [];

        files.forEach((f) => {
          let tempPath = f.name.substring(f.name.lastIndexOf("/") + 1);
          oldFilesPaths.push(tempPath);
        });

        acceptedFiles.forEach((f) => {
          let tempPath = f.name.substring(f.name.lastIndexOf("/") + 1);
          newFilesPaths.push(tempPath);
        });
        if (findCommonElements(oldFilesPaths, newFilesPaths)) {
          toast({
            title: "Erreur",
            position: "top",
            description: "Le fichier est déjà présent dans votre portfolio !",
            status: "error",
            duration: 15000,
            isClosable: true,
          });
        } else {
          let newFiles = acceptedFiles.map((file) => {
            let presigned_url = URL.createObjectURL(file);
            FreelanceService.uploadPortfolio(file).then((response) => {
              presigned_url = response.data.presigned_url;
              Object.assign(file, {
                preview: presigned_url,
              });
            });
            return Object.assign(file, {
              preview: presigned_url,
            });
          });
          tempFiles = tempFiles.concat(newFiles);
          setFiles([...tempFiles]);
        }
      }
    },
  });

  useEffect(() => {
    if (!filesLoaded && portfolioUrlList) {
      portfolioUrlList.forEach((portfolioUrl) => {
        let tempFileKeyList = fileKeyList;
        let file_key_no_aws_url = decodeURI(
          portfolioUrl.split("amazonaws.com/")[1]
        );
        let file_key = file_key_no_aws_url.split("?")[0];
        tempFileKeyList.push(file_key);
        setFileKeyList(tempFileKeyList);

        var file = new File([""], file_key);
        Object.assign(file, {
          preview: portfolioUrl,
        });
        let tempFiles = files;
        tempFiles.push(file);
        setFiles([...tempFiles]);
      });
      setFilesLoaded(true);
    }
    if (files.length >= fileLimit) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [files, filesLoaded, portfolioUrlList, fileKeyList]);

  function arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele !== value;
    });
  }

  return files.length === 0 && userProfile === UserProfiles.COMPANY ? null : (
    <>
      <Box
        w="95%"
        padding="5"
        mt="20px"
        mb="20px"
        borderWidth={1}
        borderRadius={5}
        boxShadow="md"
        bg="white"
      >
        <Box mb="3vh">
          <Heading size="md" color="gray.600">
            Portfolio
          </Heading>
        </Box>

        <Flex w="100%" flexWrap="wrap">
          {files.length === 0 ? (
            <>
              <Box w="10vw" border="dashed #ABABAB" padding="2vh">
                <Flex justify="center" align="center" direction="column">
                  <Box mb="1vh">
                    <CloudArrowUp size="25px" color="black" />
                  </Box>
                  <Box mb="1vh">
                    <Text textAlign="center" fontSize="11px">
                      Ajouter une image ou un fichier JPEG, PNG, GIG, PDF de
                      taille minimum de 600 x 375 pixel{" "}
                    </Text>
                  </Box>
                  <Box>
                    <Text textAlign="center" fontSize="11px">
                      - Poids maximum : 10 Mo
                    </Text>
                  </Box>
                  <Box mt="2vh">
                    <PlusCircle size="20px" color="black" />
                  </Box>
                </Flex>
              </Box>
            </>
          ) : (
            <>
              {files.map((file, i) => {
                return (
                  <>
                    {file.name.includes("pdf") ? (
                      <Box
                        mb="2vh"
                        maxW="8vw"
                        boxShadow="md"
                        borderRadius={5}
                        bgColor="yalink.grey"
                        p="1vw"
                        pt="1vh"
                        mr="1vw"
                      >
                        {userProfile !== UserProfiles.FREELANCE ? null : (
                          <Flex justify="flex-end" w="100%" mb="2vh">
                            <Box
                              _hover={{ cursor: "pointer" }}
                              onClick={() => {
                                FreelanceService.deletePortfolio(
                                  file.name
                                ).then((response) => {
                                  if (response.status === 200) {
                                    setFiles(arrayRemove(files, files[i]));
                                  }
                                });
                              }}
                            >
                              <Cross color="black" size="25px" />
                            </Box>
                          </Flex>
                        )}
                        <Flex
                          direction="column"
                          align="center"
                          _hover={{ cursor: "pointer" }}
                          onClick={() => {
                            window.open(file.preview, "_blank");
                          }}
                        >
                          <Box mb="1vh">
                            <img
                              alt="file"
                              key={file.name}
                              src={PDF_LOGO}
                              style={{ width: "100px", height: "100px" }}
                            />
                          </Box>
                          <Box maxW="5vw">
                            <Text fontSize="15px">
                              {file.name.substring(
                                file.name.lastIndexOf("/") + 1
                              )}
                            </Text>
                          </Box>
                        </Flex>
                      </Box>
                    ) : (
                      <Box
                        mb="2vh"
                        mr="1vw"
                        maxW="8vw"
                        boxShadow="md"
                        bgColor="yalink.grey"
                        p="1vw"
                        pt="1vh"
                        borderRadius={5}
                      >
                        {userProfile !== UserProfiles.FREELANCE ? null : (
                          <Flex justify="flex-end" w="100%" mb="2vh">
                            <Box
                              _hover={{ cursor: "pointer" }}
                              onClick={() => {
                                FreelanceService.deletePortfolio(
                                  file.name
                                ).then((response) => {
                                  if (response.status === 200) {
                                    setFiles(arrayRemove(files, files[i]));
                                  }
                                });
                              }}
                            >
                              <Cross color="black" size="25px" />
                            </Box>
                          </Flex>
                        )}

                        <Flex
                          direction="column"
                          align="center"
                          _hover={{ cursor: "pointer" }}
                          onClick={() => {
                            window.open(file.preview, "_blank");
                          }}
                        >
                          <Box mb="1vh">
                            <img
                              alt="file"
                              key={file.name}
                              src={file.preview}
                              style={{ width: "100px", height: "100px" }}
                            />
                          </Box>
                          <Box maxW="5vw">
                            <Text fontSize="15px">
                              {file.name.substring(
                                file.name.lastIndexOf("/") + 1
                              )}
                            </Text>
                          </Box>
                        </Flex>
                      </Box>
                    )}
                  </>
                );
              })}
            </>
          )}
        </Flex>
        <Box>
          {files.length >= 5 ? (
            <>
              <Text fontSize="18px" color="red">
                Vous ne pouvez pas mettre plus de 5 fichiers
              </Text>
            </>
          ) : null}
        </Box>
        {userProfile !== UserProfiles.FREELANCE ? null : (
          <Flex justify="flex-end" w="100%">
            <input {...getInputProps()} />
            <Button {...getRootProps()} isDisabled={isButtonDisabled}>
              Ajouter une image ou un fichier
            </Button>
          </Flex>
        )}
      </Box>
    </>
  );
}
