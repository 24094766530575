import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import "./styles/bootstrap.min.css";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-WHXSCGG",
};

TagManager.initialize(tagManagerArgs);

const theme = extendTheme({
  fonts: {
    heading: "Source Sans Pro",
  },
  colors: {
    yalink: {
      grey: "#ECECEC",
      bg: "#F8FCFC",
      green: "#003B36",
      yellow: "#FFCD00",
      teal: "#0F665D",
    },
  },
});

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <App />
  </ChakraProvider>,
  document.getElementById("root")
);
