import axios from "axios";
import authHeader from "../libs/authHeaderLib";
import config from "../config";

class ActivitySectorsService {
  async addActivitySectorToFreelance(activitySector) {
    let path = "/activitysector/add";
    return axios.put(config.API_URL + path, activitySector, {
      headers: authHeader(),
    });
  }

  async cleanUpActivitySectors() {
    let path = "/activitysector/cleanup";
    return axios.put(
      config.API_URL + path,
      {},
      {
        headers: authHeader(),
      }
    );
  }

  async getAllActivitySectors() {
    let path = "/activitysector/all";
    return axios.get(config.API_URL + path, {
      headers: authHeader(),
    });
  }
}

export default new ActivitySectorsService();
