import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  SimpleGrid,
  Box,
  Flex,
  HStack,
  Text,
  Spacer,
  Badge,
  Divider,
  Center,
  Link,
  IconButton,
  Stack,
} from "@chakra-ui/react";
import { AddIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { FreelanceSummary, NewJobOffer } from "../../components";
import Offers from "../../services/offers.service";
import { useAppContext } from "../../libs/contextLib";
import { House } from "@styled-icons/material/House";
import { PersonFill } from "@styled-icons/bootstrap/PersonFill";
import { Location } from "@styled-icons/entypo/Location";
import { HourglassSplit } from "@styled-icons/bootstrap/HourglassSplit";
import { CalendarDate } from "@styled-icons/bootstrap/CalendarDate";
function JobOfferCard(props) {
  const { userHasAuthenticated } = useAppContext();
  const [linkGenerated, setlinkGenerated] = useState(null);
  const [shortUrl, setShortUrl] = useState(null);
  const [selectedFreelance, setSelectedFreelance] = useState(null);
  const [columnNumber, setColumnNumber] = useState(2);

  const resizeColumn = () => {
    if (window.innerWidth <= 1150) {
      setColumnNumber(1);
    } else {
      setColumnNumber(2);
    }
  };

  useEffect(() => {
    resizeColumn();
  }, []);

  window.addEventListener("resize", resizeColumn);

  if (
    !selectedFreelance &&
    props.offer.tentativeFreelanceId.jobDuration !== 0
  ) {
    Offers.getFreelancesSummaryByOfferId(props.offer.id).then((response) => {
      setSelectedFreelance(response.data.freelances);
    });
  }

  async function generateOfferExternalLink() {
    await Offers.getOfferToken(props.offer.id)
      .then((response) => {
        setlinkGenerated(
          "/offer/view?id=" + props.offer.id + "&token=" + response.data.token
        );
        if (response.data.short_url) {
          setShortUrl(response.data.short_url);
        }
      })
      .catch(() => {
        userHasAuthenticated(false);
      });
  }
  function GenerateOfferExternalLink() {
    return (
      <IconButton
        size="sm"
        ml="10px"
        icon={<ExternalLinkIcon />}
        onClick={generateOfferExternalLink}
      />
    );
  }
  function StatusBadge() {
    if (props.offer.status) {
      if (props.offer.status === "open") {
        return <Badge colorScheme="purple">Ouvert</Badge>;
      } else if (props.offer.status === "ongoing") {
        return <Badge colorScheme="orange">En cours</Badge>;
      }
      // rest to be implemented
    }
    return <Badge colorScheme="purple">Ouvert</Badge>;
  }

  function SelectedFreelanceList() {
    return selectedFreelance ? (
      <SimpleGrid columns={columnNumber} spacing={10}>
        {selectedFreelance.map((freelance) => (
          <FreelanceSummary
            freelance={freelance}
            selectedOfferId={
              props.selectedAO ? props.selectedAO.id : props.offer.id
            }
            attachedOffer={props.offer.id}
            setReloadOfferList={props.setReloadOfferList}
            readOnly={props.readOnly ? props.readOnly : null}
            key={freelance.id}
          />
        ))}
      </SimpleGrid>
    ) : null;
  }

  return (
    <Box
      padding="5"
      borderWidth={1}
      borderRadius={5}
      boxShadow="md"
      bg="white"
      mb="20px"
      key={props.offer.id ? props.offer.id : "fake"}
    >
      <Box>
        <Flex>
          <HStack>
            <Text
              fontWeight="semibold"
              letterSpacing="wide"
              fontSize="xl"
              textTransform="uppercase"
            >
              {props.offer.offerName}
            </Text>
            {props.readOnly ? null : (
              <StatusBadge status={props.offer.status} />
            )}
          </HStack>

          <Spacer />
          {props.readOnly ? null : (
            <>
              <Text
                fontWeight="semibold"
                letterSpacing="wide"
                fontSize="xl"
                textTransform="uppercase"
                mr="20px"
              >
                OFFER-ID-{props.offer.id}
              </Text>
              <Stack>
                <Box>
                  <NewJobOffer
                    offers={props.offers}
                    setOffers={props.setOffers}
                    offerId={props.offer.id}
                  />
                  <GenerateOfferExternalLink />
                </Box>
                {linkGenerated ? (
                  shortUrl ? (
                    <Link
                      href={shortUrl}
                      isExternal
                      color="teal.500"
                      target="_blank"
                    >
                      Copie ce lien !
                    </Link>
                  ) : (
                    <Link
                      color="teal.500"
                      as={RouterLink}
                      to={linkGenerated}
                      target="_blank"
                    >
                      Copie ce lien !
                    </Link>
                  )
                ) : (
                  <Text>Lien externe ?</Text>
                )}
              </Stack>
            </>
          )}
        </Flex>
        {props.readOnly ? null : (
          <>
            {props.offer.company ? (
              <Text fontWeight="semibold" as="h3" lineHeight="tight">
                <House size="25px" /> {props.offer.company}
              </Text>
            ) : null}
            {props.offer.contactName && props.offer.mail ? (
              <HStack>
                <Text fontWeight="semibold" as="h4" lineHeight="tight">
                  <PersonFill size="25px" /> {props.offer.contactName}
                </Text>
                <Text lineHeight="tight" ml="10px">
                  {props.offer.mail}
                </Text>
              </HStack>
            ) : null}
            {props.offer.beginDate &&
            props.offer.jobDuration &&
            props.offer.postalCode ? (
              <HStack mb="10px">
                <Text>
                  <CalendarDate size="20px" /> Date de début:{" "}
                  {props.offer.beginDate.split("T")[0]}
                </Text>
                <Text mr="20px">
                  <HourglassSplit size="20px" /> Durée:{" "}
                  {(() => {
                    switch (props.offer.jobDuration) {
                      case "lt3m":
                        return "Courte (-3 mois)";
                      case "gt3m":
                        return "Longue (+3 mois)";
                      default:
                        return "Indifférent";
                    }
                  })()}
                </Text>
                <Text>
                  <Location size="50px" /> Localisation:{" "}
                  {props.offer.postalCode}
                </Text>
              </HStack>
            ) : null}

            {props.offer.position ||
            props.offer.sector ||
            props.offer.daysPerWeek ||
            props.offer.workplace ||
            props.offer.description ? (
              <>
                <Divider />
                <Text
                  fontWeight="semibold"
                  as="h3"
                  fontSize="xl"
                  lineHeight="tight"
                >
                  Details du poste
                </Text>
                {props.offer.position && props.offer.sector ? (
                  <>
                    <HStack>
                      <Text fontWeight="semibold" as="h4" lineHeight="tight">
                        Métier:
                      </Text>
                      <Text>{props.offer.position}</Text>
                    </HStack>
                    <HStack>
                      <Text fontWeight="semibold" as="h4" lineHeight="tight">
                        Secteur:
                      </Text>
                      <Text>{props.offer.sector}</Text>
                    </HStack>
                  </>
                ) : null}
                {props.offer.daysPerWeek && props.offer.workplace ? (
                  <>
                    <HStack>
                      <Text fontWeight="semibold" as="h4" lineHeight="tight">
                        Cadence:{" "}
                      </Text>
                      <Text>
                        {(() => {
                          switch (props.offer.daysPerWeek) {
                            case "part":
                              return "Temps partiel";
                            case "daysPerWeek1":
                              return "1 jour";
                            case "daysPerWeek2":
                              return "2 jours";
                            case "daysPerWeek3":
                              return "3 jours";
                            case "daysPerWeek4":
                              return "4 jours";
                            case "full":
                              return "Temps plein";
                            default:
                              return "Indifférent";
                          }
                        })()}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontWeight="semibold" as="h4" lineHeight="tight">
                        Lieu de travail:{" "}
                      </Text>
                      <Text>
                        {(() => {
                          switch (props.offer.workplace) {
                            case "on_site":
                              return "Sur site uniquement";
                            case "remote":
                              return "Télé-travail uniquement";
                            default:
                              return "Indifférent";
                          }
                        })()}
                      </Text>
                    </HStack>
                  </>
                ) : null}
                {props.offer.description ? (
                  <>
                    <Text fontWeight="semibold">Description de l'offre:</Text>
                    <Text mb="10px">{props.offer.description}</Text>
                  </>
                ) : null}
              </>
            ) : null}
          </>
        )}

        <Divider mt="20px" mb="20px" />

        <Text
          fontWeight="semibold"
          as="h3"
          fontSize="xl"
          lineHeight="tight"
          mb="20px"
        >
          Freelances sélectionnés
        </Text>
        {props.readOnly ? null : (
          <Center mb="10px">
            <IconButton
              icon={<AddIcon />}
              onClick={() => {
                props.setSelectedAO({
                  name: props.offer.offerName,
                  id: props.offer.id,
                });
                props.onOpen();
              }}
            />
          </Center>
        )}

        <SelectedFreelanceList offer={props.offer} />
      </Box>
    </Box>
  );
}

export default JobOfferCard;
