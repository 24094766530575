import React, { useState, useEffect } from "react";
import {
  Button,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Spacer,
  HStack,
  Text,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Center,
  Modal,
  ModalContent,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { PhoneIcon, EmailIcon } from "@chakra-ui/icons";
import { Stars } from "@styled-icons/bootstrap/Stars";
import Applications from "../../services/application.service";
import { isEmailValid } from "../../libs/regexLib";

function ModalCooptation({
  isOpen: isCooptationOpen,
  onClose: onCooptationClose,
  mission,
  setHasReferred,
}) {
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [cooptation, setCooptation] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const toast = useToast();

  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  window.addEventListener("resize", applyResponsive);

  useEffect(() => {
    async function sendReferral() {
      setCooptation(false);
      const referral = {
        email: email,
        phone: phone,
      };
      setEmail(null);
      setHasReferred(true);
      await Applications.refer(mission.id, referral)
        .then(() => {
          onCooptationClose();
          toast({
            position: "top",
            title:
              "🎉 Vous avez coopté ! Notre équipe va revenir vers vous dans les plus brefs delais.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        })
        .catch(() => {});
    }
    applyResponsive();
    if (cooptation) {
      sendReferral();
    }
  }, [
    cooptation,
    email,
    mission,
    onCooptationClose,
    setHasReferred,
    toast,
    phone,
  ]);

  function isFormInvalid() {
    if (phone || isEmailValid(email)) {
      return false;
    }
    return true;
  }

  return (
    <div>
      <Modal isOpen={isCooptationOpen} onClose={onCooptationClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            w={isMobile ? "100%" : null}
            pl={0}
            fontSize={isMobile ? "17px" : null}
            align="center"
          >
            <Stars size="40px" color="#F8C708" /> Je coopte !
          </ModalHeader>
          <ModalCloseButton />
          <Text
            color="yalink.yellow"
            align="center"
            fontWeight="bold"
            fontSize={isMobile ? "17px" : "20px"}
            mt="-10px"
          >
            {mission.name}
          </Text>
          <ModalBody mb="auto">
            <Box
              bg="gray.100"
              w="100%"
              p={4}
              color="black"
              borderRadius="7px"
              mr="5px"
              mb="20px"
              mt="10px"
            >
              <FormControl mb="20px">
                <FormLabel>Email de la personne que je coopte :</FormLabel>
                <InputGroup>
                  <InputRightElement
                    pointerEvents="none"
                    children={<EmailIcon color="black" />}
                  />
                  <Input
                    type="email"
                    placeholder="Bonjour@yalink.fr"
                    background="white"
                    data-testid="Email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </InputGroup>
              </FormControl>

              <FormControl mb="20px">
                <FormLabel>Et/ou téléphone portable :</FormLabel>
                <InputGroup>
                  <InputRightElement
                    pointerEvents="none"
                    children={<PhoneIcon color="black" />}
                  />
                  <Input
                    type="tel"
                    placeholder="06 21 74 09 89"
                    background="white"
                    data-testid="Phone"
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />
                </InputGroup>
              </FormControl>
              <Center>
                <Link href="https://www.yalink.fr/cooptation/" data-testid="link">
                  (en savoir plus sur la cooptation)
                </Link>
              </Center>
            </Box>

            <HStack>
              <Spacer />
              <Button
                color="white"
                bg="yalink.green"
                _hover={{ bg: "yalink.yellow" }}
                _checked={{
                  bg: "yalink.yellow",
                  color: "white",
                  borderColor: "yalink.yellow",
                }}
                type="submit"
                ms="285"
                mb="5"
                onClick={() => setCooptation(true)}
                disabled={isFormInvalid()}
              >
                Je coopte
              </Button>
              <Spacer />
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default ModalCooptation;
