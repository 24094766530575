import {React, useState, useEffect} from "react";
import { Formik, Form, Field } from "formik";
import UserService from "../../services/user.service";
import { useAppContext } from "../../libs/contextLib.js";

import {
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Box,
  Center,
  Heading,
  useToast,
} from "@chakra-ui/react";

function DeleteAccount() {
  const toast = useToast();
  const { userHasAuthenticated } = useAppContext();

  async function handleSubmit(values, actions) {
    await UserService.deleteAccount({
      email: values.email,
    })
      .then(() => {
        toast({
          position: "top",
          title: "Ton compte a été supprimé de notre base de donnée",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        userHasAuthenticated(false);
      })
      .catch(() => {
        toast({
          position: "top",
          title:
            "Nous n'avons pas pu supprimer ton compte. Vérifie les informations soumises et réessaie !",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
    actions.setSubmitting(false);
  }

  // isMobile responsive useState and useEffect

  
  const [isMobile, setIsMobile] = useState(false);

  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    applyResponsive();
  }, []);

  window.addEventListener("resize", applyResponsive);

  return (
    <Center>
      <Box
        w="60%"
        mt="100px"
        mb="100px"
        padding="5"
        maxW="3xl"
        borderWidth={1}
        borderRadius={8}
        boxShadow="md"
      >
        <Heading as="h1" size="lg" mb="25px">
          Supprime ton compte Yalink
        </Heading>
        <Text mb="20px">‼️ Attention cette action est irréversible ‼️</Text>
        <Formik
          initialValues={{ email: "" }}
          onSubmit={(values, actions) => {
            handleSubmit(values, actions);
          }}
        >
          {(props) => (
            <Form>
              <Field name="email">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.email && form.touched.email}
                    isRequired
                  >
                    <FormLabel htmlFor="email">E-mail</FormLabel>
                    <Input {...field} id="email" maxW="sm" />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Button
                mt={4}
                w={isMobile ? "100%" : null}
                fontSize={isMobile ? "10.5px" : null}
                
                colorScheme="teal"
                isLoading={props.isSubmitting}
                type="submit"
                disabled={
                  !props.isValid ||
                  (Object.keys(props.touched).length === 0 &&
                    props.touched.constructor === Object)
                }
              >
                Supprimer mon compte definitivement
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Center>
  );
}

export default DeleteAccount;
