import React from "react";

import { Formik, Form, Field } from "formik";
import {
  Modal,
  Button,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  FormErrorMessage,
  Divider,
  Link,
  Checkbox,
  Text,
  useToast,
} from "@chakra-ui/react";
import { isEmailValid } from "../../libs/regexLib";
import Offers from "../../services/offers.service";

function ContactForm(props) {
  const freelance = props.freelance;
  const selectedOfferId = props.selectedOfferId;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  function validateEmail(value) {
    let error;
    if (!isEmailValid(value)) {
      error = "*Cet email n'est pas valide";
    }
    return error;
  }

  function validatePhone(value) {
    let error;
    if (!value) {
      error = "*Numéro de téléphone requis";
    }
    return error;
  }

  async function handleSubmit(values, actions) {
    await Offers.offerInterestedByProfile(
      selectedOfferId,
      freelance.id,
      values.email,
      values.phone
    )
      .then(() => {
        props.setIsInterested(true);
        actions.setSubmitting(false);
        onClose();
        toast({
          position: "top",
          title: "Yalink a été notifié!",
          description:
            "Un e-mail a été envoyé à l'équipe Yalink, nous retournons vers vous dans les plus brefs delais.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch(() => {
        actions.setSubmitting(false);
        onClose();
      });
  }

  function validateAgreement(value) {
    let error;
    if (!value || value !== true) {
      error = "*Vous devez accepter les conditions générales d'utilisation";
    }
    return error;
  }

  return (
    <>
      <Button
        onClick={onOpen}
        colorScheme="green"
        disabled={props.isInterested}
      >
        {props.isInterested ? "Demande prise en compte" : "Prendre rendez-vous"}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <Formik
          initialValues={{
            email: "",
            phone: "",
          }}
          onSubmit={(values, actions) => {
            handleSubmit(values, actions);
          }}
        >
          {(props) => (
            <Form>
              <ModalContent>
                <ModalHeader>Rencontrez {freelance.first_name}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                  <Text>
                    Pour bien prendre en compte votre demande, nous avons besoin
                    de votre email et de votre téléphone pour pouvoir revenir
                    vers vous dans les 24 heures.
                  </Text>
                  <Divider padding="3" mb="20px" maxW="90%" />

                  <Field name="email" validate={validateEmail}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.email && form.touched.email}
                        isRequired
                      >
                        <FormLabel htmlFor="email">E-mail</FormLabel>
                        <Input
                          {...field}
                          id="email"
                          placeholder="hello@yalink.fr"
                        />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="phone" validate={validatePhone}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.phone && form.touched.phone}
                        isRequired
                      >
                        <FormLabel htmlFor="phone" mt="10px">
                          Téléphone
                        </FormLabel>
                        <Input
                          {...field}
                          id="phone"
                          placeholder="01 47 20 00 01"
                        />
                        <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Divider padding="3" mb="20px" maxW="90%" />
                  <Field name="agree" validate={validateAgreement}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.agree && form.touched.agree}
                        isRequired
                      >
                        <FormLabel htmlFor="agree">
                          En cochant cette case, je reconnais avoir pris
                          connaissance des
                          <Link
                            href="https://www.yalink.fr/cguv/"
                            isExternal
                            color="teal.500"
                          >
                            {" "}
                            Conditions Générales d'Utilisation du site{" "}
                          </Link>
                          ainsi que de sa
                          <Link
                            href="https://www.yalink.fr/politique-de-confidentialite/"
                            isExternal
                            color="teal.500"
                          >
                            {" "}
                            Politique de Confidentialité{" "}
                          </Link>
                          et je les accepte.
                        </FormLabel>
                        <Checkbox {...field} id="agree">
                          J'accepte
                        </Checkbox>
                        <FormErrorMessage>{form.errors.agree}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </ModalBody>

                <ModalFooter>
                  <Button
                    mr={3}
                    colorScheme="blue"
                    isLoading={props.isSubmitting}
                    type="submit"
                    disabled={
                      !props.isValid ||
                      (Object.keys(props.touched).length === 0 &&
                        props.touched.constructor === Object)
                    }
                  >
                    Envoyer
                  </Button>
                  <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
              </ModalContent>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default ContactForm;
