import React from "react";
import { Center } from "@chakra-ui/react";
import PaginationComponent from "react-reactstrap-pagination";

export default function Pagination(props) {
  return (
    <Center>
      <PaginationComponent
        totalItems={props.totalItems}
        pageSize={props.perPage}
        onSelect={props.handleSelectedPage}
        maxPaginationNumbers={5}
        defaultActivePage={props.page}
        firstPageText="Premiere page"
        previousPageText="Précédent"
        nextPageText="Suivant"
        lastPageText="Derniere page"
        size="sm"
      />
    </Center>
  );
}
