import React, { useState, useEffect } from "react";
import { Box, Center, Heading, Flex } from "@chakra-ui/react";
import { FreelanceSummary, LoadingComponent } from "../../components";
import Users from "../../services/user.service";
import { useAppContext } from "../../libs/contextLib";
import FilterInput from "../../components/Utils/FilterInput";
import PerPageSelector from "../../components/Utils/PerPageSelector";
import Pagination from "../../components/Utils/Pagination";
import { convertDate } from "../../libs/regexLib";
import { querystring } from "../../libs/queryStringLib";
import { ListStars } from "@styled-icons/bootstrap/ListStars";
function FreelanceList() {
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [refreshFreelance, setRefreshFreelance] = useState(false);
  const [freelanceFilter, setFreelanceFilter] = useState("");
  const [freelanceList, setFreelanceList] = useState(null);
  const [page, setPage] = useState(
    querystring("page", window.location.href)
      ? querystring("page", window.location.href)
      : 1
  );
  const [perPage, setPerPage] = useState(
    querystring("per_page", window.location.href)
      ? querystring("per_page", window.location.href)
      : 10
  );
  const [totalItems, setTotalItems] = useState(0);
  const [searching, setSearching] = useState(false);
  const [isBrowsing, setIsBrowsing] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if (!isBrowsing) {
        setPage(1);
      }
      await Users.getAllFreelances(!isBrowsing ? 1 : page, perPage)
        .then((response) => {
          setFreelanceList(response.data.freelances);
          setTotalItems(response.data.total);
          setIsBrowsing(true);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setFreelanceList([]);
          } else {
            userHasAuthenticated(false);
          }
        });
      setIsLoading(false);
    }

    async function search() {
      if (isBrowsing) {
        setPage(1);
      }
      await Users.searchFreelance(
        freelanceFilter,
        isBrowsing ? 1 : page,
        perPage
      )
        .then((response) => {
          setFreelanceList(response.data.freelances);
          setTotalItems(response.data.total);
          setIsBrowsing(false);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setFreelanceList([]);
          } else {
            userHasAuthenticated(false);
          }
        });
      setSearching(false);
    }

    // Bootstrap the page
    if (isLoading) {
      onLoad();
    }

    // Handle pagination change
    if (refreshFreelance) {
      onLoad();
      setRefreshFreelance(false);
    }

    // Handle search
    if (searching) {
      if (freelanceFilter) {
        search();
      } else {
        onLoad();
      }
      setSearching(false);
    }
  }, [
    isLoading,
    userHasAuthenticated,
    page,
    perPage,
    refreshFreelance,
    searching,
    freelanceFilter,
    isBrowsing,
  ]);

  function handleSelectedPage(selectedPage) {
    setPage(selectedPage);
    if (freelanceFilter) {
      setSearching(true);
    } else {
      setRefreshFreelance(true);
    }
  }

  function FreelanceList() {
    let matchingList = null;

    matchingList = freelanceList;

    matchingList = matchingList.sort((a, b) => {
      if (a.registered_on && b.registered_on) {
        return convertDate(a.registered_on) < convertDate(b.registered_on)
          ? 1
          : -1;
      }
      return 1;
    });

    return matchingList.length ? (
      matchingList.map((freelance) => (
        <Box key={freelance.id}>
          <FreelanceSummary freelance={freelance} readOnly={true} />
        </Box>
      ))
    ) : (
      <Center mt="100px" ml="20px" mr="20px" mb="20px" padding="5">
        <Heading as="h1" size="lg" mb="25px">
          Ouuups, nous n'avons trouvé aucun freelance...
        </Heading>
      </Center>
    );
  }

  function PaginationInstance() {
    return (
      <Pagination
        totalItems={totalItems}
        perPage={perPage}
        handleSelectedPage={handleSelectedPage}
        page={page}
      />
    );
  }

  return !isLoading ? (
    <>
      <Box>
        <Center mt="100px" ml="20px" mr="20px" mb="20px" padding="5">
          <Heading as="h1" size="lg" mb="25px">
            <ListStars size="40px" /> Liste des freelances
          </Heading>
        </Center>
        <Center>
          <Box w="80%" mb="20px" padding="5">
            <Flex>
              <FilterInput
                filter={freelanceFilter}
                setFilter={setFreelanceFilter}
                placeholder="Nom, Prénom, Email, Titre"
                searching={searching}
                setSearching={setSearching}
              />
              <PerPageSelector
                perPage={perPage}
                setPerPage={setPerPage}
                setPage={setPage}
                setIsLoading={setRefreshFreelance}
              />
            </Flex>
            <PaginationInstance />
            <Center>
              <Box w="100%" mb="20px" padding="5">
                <FreelanceList />
              </Box>
            </Center>
            <PaginationInstance />
          </Box>
        </Center>
      </Box>
    </>
  ) : (
    <LoadingComponent />
  );
}

export default FreelanceList;
