import React from "react";
import {
  Box,
  Flex,
  Text,
  Grid,
  GridItem,
  Badge,
  Avatar,
  Link,
  CircularProgress,
  CircularProgressLabel,
  VStack,
  Tooltip,
} from "@chakra-ui/react";
import Table from "./Molecules/table";
import { InfoIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";

import { useState, useEffect } from "react";
import { CurrencyEuro } from "@styled-icons/bootstrap/CurrencyEuro";
import { Wallet2 } from "@styled-icons/bootstrap/Wallet2";
import { Clock } from "@styled-icons/bootstrap/Clock";
import { HourglassSplit } from "@styled-icons/bootstrap/HourglassSplit";
import { Buildings } from "@styled-icons/boxicons-regular/Buildings";
import { ArrowLeft } from "@styled-icons/bootstrap/ArrowLeft";
import { Location } from "@styled-icons/entypo/Location";
import { CalendarDate } from "@styled-icons/bootstrap/CalendarDate";
import { ArrowRight } from "@styled-icons/bootstrap/ArrowRight";
import ContactInfo from "../Utils/ContactInfo";
import { monthNamesFr } from "../../libs/monthsLib";
import InputChoices from "./Molecules/InputChoices";
import { getUserProfile, UserProfiles } from "../../libs/authHeaderLib";

export default function FreelanceMissionDetailed({ mission }) {
  const history = useHistory();
  const isForfait = mission.service_type === "forfait" ? true : false;
  const userProfile = getUserProfile();
  const [isMobile, setIsMobile] = useState(false);

  let tempOngoingInvoicedAmount = 0;
  let tempOngoingInvoicedDays = 0;
  // Dans le back
  mission.activity_reports.forEach((activityReport) => {
    if (activityReport.status !== "approved") {
      if (userProfile === UserProfiles.COMPANY) {
        // When we show this data to the client we want to include the Yalink Margin
        tempOngoingInvoicedAmount += Number(
          Number(activityReport.amount_billed) +
            Number(activityReport.vat_excluded_yalink_activity_margin)
        );
      } else {
        // When we show this data to the freelance we want to exclude the Yalink Margin
        tempOngoingInvoicedAmount += activityReport.amount_billed;
      }
      tempOngoingInvoicedDays += activityReport.activity_days_count;
    }
  });

  // Compute some of the amounts we use in the display of the page
  const freelanceAndYalinkTotal = Number(
    Number(mission.total_amount_billed) +
      Number(mission.yalink_fees_total_amount)
  );
  const freelanceAndYalinkCompletedTotal = Number(
    Number(mission.completed_amount) +
      Number(mission.yalink_fees_completed_amount)
  );
  const a =
    mission.service_type === "forfait"
      ? parseFloat(
          userProfile === UserProfiles.COMPANY
            ? freelanceAndYalinkCompletedTotal
            : mission.completed_amount
        ) + parseFloat(tempOngoingInvoicedAmount)
      : Number(
          Number(mission.completed_days) + Number(tempOngoingInvoicedDays)
        );

  const b =
    mission.service_type === "forfait"
      ? Number(
          userProfile === UserProfiles.COMPANY
            ? freelanceAndYalinkTotal
            : mission.total_amount_billed
        )
      : Number(mission.total_working_days);
  const missingAmount =
    mission.service_type === "forfait"
      ? Number(
          userProfile === UserProfiles.COMPANY
            ? Number(freelanceAndYalinkTotal) -
                (Number(freelanceAndYalinkCompletedTotal) +
                  Number(tempOngoingInvoicedAmount))
            : Number(mission.total_amount_billed) -
                (Number(mission.completed_amount) +
                  Number(tempOngoingInvoicedAmount))
        )
      : Number(
          Number(mission.total_working_days) -
            (Number(mission.completed_days) + Number(tempOngoingInvoicedDays))
        );
  const invoicedAmount =
    mission.service_type === "forfait"
      ? userProfile === UserProfiles.COMPANY
        ? Number(
            Number(freelanceAndYalinkCompletedTotal) +
              Number(tempOngoingInvoicedAmount)
          )
        : Number(
            Number(mission.completed_amount) + Number(tempOngoingInvoicedAmount)
          )
      : Number(
          Number(mission.completed_days) + Number(tempOngoingInvoicedDays)
        );
  const calculPercentage = (a / b) * 100;
  const roundedValue = Math.round(calculPercentage);
  const progressValues = {
    invoicedAmount: invoicedAmount.toFixed(2),
    completedPercentage: roundedValue,
    missingAmount: missingAmount.toFixed(2),
  };

  // Compute variables used to determine the status of the current month's activity report
  const today = new Date();
  const filteredActivityReports = mission.activity_reports.filter(
    (activityReport) => {
      return (
        Number(activityReport.date.split("-")[1]) ===
        Number(today.getMonth() + 1)
      );
    }
  );

  let currentMonthActivityReportStatus = "N/A";
  if (filteredActivityReports.length === 1) {
    currentMonthActivityReportStatus = filteredActivityReports[0].status;
  }

  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    applyResponsive();
  }, []);

  window.addEventListener("resize", applyResponsive);

  // Mocked data for filling the component

  function renderBadge(status) {
    if (status === "modification_requested") {
      return (
        <Badge fontSize="1rem" pr={5} pl={5} colorScheme="red">
          Modifications demandées
        </Badge>
      );
    } else if (status === "not_started") {
      return (
        <Badge fontSize="1rem" colorScheme="blue">
          À saisir
        </Badge>
      );
    } else if (status === "ongoing") {
      return <Badge fontSize="1rem">Édition en cours</Badge>;
    } else if (status === "pending_approval") {
      return (
        <Badge colorScheme="orange" fontSize="1rem">
          En attente du client
        </Badge>
      );
    } else if (status === "approved") {
      return (
        <>
          <GridItem>
            <Badge colorScheme="green" fontSize="1rem">
              Validé
            </Badge>
          </GridItem>
        </>
      );
    } else if (status === "nothing_declared") {
      return (
        <>
          <GridItem>
            <Badge colorScheme="green" fontSize="1rem">
              Pas d'activité
            </Badge>
          </GridItem>
        </>
      );
    }
  }

  function MissionDescriptionForfait() {
    return (
      <>
        <Box bg="yalink.green" p={2} borderTopRadius={7}>
          <Flex justify="center" w="100%">
            <Box>
              <Buildings size="30px" color="white"></Buildings>
            </Box>
            <Box w="20%">
              <Text color="white" ml="2%" numberOfLines={1}>
                {mission.client_organization_name}
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box
          bg="white"
          color="black"
          borderBottomRadius={7}
          padding="5"
          boxShadow="md"
        >
          <VStack
            direction={["column", "row"]}
            spacing="24px"
            mt="1"
            mr="10px"
            ml={isMobile ? "null" : "10px"}
            fontWeight="semibold"
            as="h3"
            lineHeight="tight"
            isTruncated
            align="left"
          >
            <Flex>
              <Box mr="2vw">
                {" "}
                <CalendarDate size="20px" />
              </Box>
              <Box mr="1vw">{mission.start_date}</Box>
              <Box mr="1vw">
                <ArrowRight size="20px" />
              </Box>{" "}
              <Box>
                <Text>{mission.end_date}</Text>
              </Box>
            </Flex>
            <Flex>
              <Box mr="2vw">
                <Location size="20px" />
              </Box>
              <Box>
                <Text>{mission.client_address}</Text>
              </Box>
            </Flex>
            <Flex>
              <Box mr="2vw">
                <CurrencyEuro size="20px" />
              </Box>
              <Box>
                <Text>
                  {userProfile === UserProfiles.COMPANY
                    ? Number(
                        Number(mission.total_amount_billed) +
                          Number(mission.yalink_fees_total_amount)
                      ) + " € HT"
                    : mission.total_amount_billed + " € HT"}
                </Text>
              </Box>
              <Box w="100%">
                <Badge
                  variant="solid"
                  bg="yalink.grey"
                  color="black"
                  fontSize="md"
                  fontWeight="normal"
                  ml="2"
                  p={1}
                >
                  <Flex>
                    <Avatar
                      name="F"
                      size="xs"
                      bg="yalink.green"
                      fontSize="1.5rem"
                    ></Avatar>
                    <Text>{mission.service_type}</Text>
                  </Flex>
                </Badge>
              </Box>
            </Flex>
          </VStack>
        </Box>
      </>
    );
  }

  function MissionDescriptionRegie() {
    return (
      <>
        <Box bg="yalink.green" p={2} borderTopRadius={7}>
          <Flex justify="center" w="100%">
            <Box>
              <Buildings size="30px" color="white"></Buildings>
            </Box>
            <Box w="20%">
              <Text color="white" ml="2%" numberOfLines={1}>
                {mission.client_organization_name}
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box
          bg="white"
          color="black"
          borderBottomRadius={7}
          padding="5"
          boxShadow="md"
        >
          <VStack
            direction={["column", "row"]}
            spacing="24px"
            mt="1"
            mr="10px"
            ml={isMobile ? "null" : "10px"}
            fontWeight="semibold"
            as="h3"
            lineHeight="tight"
            isTruncated
            align="left"
          >
            <Flex>
              <Box mr="2vw">
                {" "}
                <CalendarDate size="20px" />
              </Box>
              <Box mr="1vw">{mission.start_date}</Box>
              <Box mr="1vw">
                <ArrowRight size="20px" />
              </Box>{" "}
              <Box>
                <Text>{mission.end_date}</Text>
              </Box>
            </Flex>
            <Flex>
              <Box mr="2vw">
                <HourglassSplit size="20px" />
              </Box>
              <Box>
                {" "}
                <Text>{mission.total_working_days + " jours"} </Text>
              </Box>
            </Flex>
            <Flex>
              <Box mr="2vw">
                <Wallet2 size="20px" />
              </Box>
              <Box>
                <Text>
                  {userProfile === UserProfiles.COMPANY
                    ? Number(
                        Number(mission.freelance_fees_per_day) +
                          Number(mission.yalink_fees_per_day)
                      )
                    : mission.freelance_fees_per_day}{" "}
                  € HT / jour
                </Text>
              </Box>
            </Flex>
            <Flex>
              <Box mr="2vw">
                <Location size="20px" />
              </Box>
              <Box>
                <Text>{mission.client_address}</Text>
              </Box>
            </Flex>
            <Box>
              <Grid templateColumns="2vw 0.3fr 1fr" gap={5}>
                <Box>
                  <CurrencyEuro size="20px" />
                </Box>
                <Box w="100%">
                  <Badge
                    variant="solid"
                    bg="yalink.grey"
                    color="black"
                    fontSize="md"
                    fontWeight="normal"
                    p={1}
                  >
                    <Grid templateColumns="1.5fr 2fr">
                      <GridItem>
                        <Box>
                          <Avatar
                            name="R"
                            size="xs"
                            bg="yalink.green"
                            fontSize="1.5rem"
                          ></Avatar>
                        </Box>
                      </GridItem>
                      <GridItem>
                        <Box>
                          <Text>{mission.service_type}</Text>
                        </Box>
                      </GridItem>
                    </Grid>
                  </Badge>
                </Box>
              </Grid>
            </Box>
            <Flex>
              <Box mr="2vw">
                <Clock size="20px" />
              </Box>
              <Box>
                <Text>{mission.deal.workRythm}</Text>
              </Box>
            </Flex>
          </VStack>
        </Box>
      </>
    );
  }

  function EtatAvancementSaisie() {
    return (
      <Box
        w={isMobile && "90vw"}
        bg="yalink.green"
        color="black"
        borderRadius={7}
        boxShadow="md"
        p={10}
        mb={isMobile ? null : "2%"}
      >
        <Flex justify="space-between" direction={isMobile && "column"}>
          <Box>
            <Grid templateColumns={isMobile ? null : "0.5fr 2.5fr"}>
              <GridItem
                align={isMobile ? "center" : null}
                mb={isMobile && "10%"}
              >
                <Clock size="70px" color="white"></Clock>
              </GridItem>
              <GridItem mb={isMobile && "10%"}>
                <Grid
                  templateRows={isMobile ? "0.3fr 1fr" : "repeat(2,1fr)"}
                  gap={4}
                >
                  <GridItem>
                    <Text fontWeight="semibold" color="white">
                      Nouvel état d'avancement à saisir
                    </Text>
                  </GridItem>
                  <GridItem>
                    <Text color="white">
                      Ton jalon est terminé ? Tu peux dès maintenant saisir ton
                      état d’avancement.
                    </Text>
                  </GridItem>
                </Grid>
              </GridItem>
            </Grid>
          </Box>
          <InputChoices mission={mission} today={today} />
        </Flex>
      </Box>
    );
  }

  function NewCraInput() {
    return (
      <>
        <Box
          bg="yalink.green"
          p={8}
          borderRadius={7}
          mb={isMobile ? null : "2%"}
        >
          <Grid templateColumns={isMobile ? null : "4fr 1fr"}>
            <GridItem>
              <Text color="white" fontWeight="bold" fontSize="18px">
                Nouveau CRA à saisir
              </Text>
              <br />
              <Text color="white">
                Ton CRA du mois en cours est disponible. Tu peux le saisir dès
                maintenant.
              </Text>
            </GridItem>
            <GridItem alignSelf="center">
              <InputChoices mission={mission} today={today} />
            </GridItem>
          </Grid>
        </Box>
      </>
    );
  }

  const MissionProgressStatus = () => {
    return (
      <Box
        h="100%"
        bg="white"
        color="black"
        borderRadius={7}
        boxShadow="md"
        p={5}
      >
        <Box>
          <Text fontWeight="bold" align="center">
            État d'avancement de la mission
          </Text>
        </Box>
        <Box>
          <Flex justify="space-evenly" mt="10%" w="100%">
            <Box>
              <CircularProgress
                value={progressValues.completedPercentage}
                size="160px"
                thickness="8px"
                color="yalink.green"
              >
                <CircularProgressLabel>
                  {progressValues.completedPercentage + "%"}
                </CircularProgressLabel>
              </CircularProgress>
            </Box>
            <Box>
              <Grid templateRows="1fr 1.2fr 1fr 1.2fr" gap={5}>
                <GridItem>
                  <Text fontSize="18">Restant :</Text>
                </GridItem>
                <GridItem>
                  <Badge fontSize="xl" colorScheme="green">
                    {progressValues.missingAmount}
                    {mission.service_type === "forfait" ? " € HT" : " Jours"}
                  </Badge>
                </GridItem>
                <GridItem>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Text fontSize="18">
                      {mission.service_type === "forfait"
                        ? "Facturé :"
                        : "Facturés :"}
                    </Text>
                    <Tooltip
                      label="Ce montant comprend les montants déjà facturés et les montants en cours d'édition et de validation"
                      fontSize="md"
                    >
                      <InfoIcon />
                    </Tooltip>
                  </Flex>
                </GridItem>
                <GridItem>
                  <Badge fontSize="xl" colorScheme="orange">
                    {progressValues.invoicedAmount}
                    {mission.service_type === "forfait" ? " € HT" : " Jours"}
                  </Badge>
                </GridItem>
              </Grid>
            </Box>
          </Flex>
        </Box>
      </Box>
    );
  };

  const ProgressStatusTable = () => {
    return (
      <Box
        // h="50%"
        w={isMobile && "90vw"}
        bg="white"
        color="black"
        borderRadius={7}
        padding={5}
        boxShadow="md"
        mb={isMobile && "15%"}
      >
        <Box mb="3%">
          <Text fontSize="20" fontWeight="semibold">
            États d'avancement
          </Text>
        </Box>
        <Table.Container>
          {!isMobile ? (
            <Table.Table>
              <Table.Thead bg="gray.200">
                <Table.Tr>
                  <Table.Th>Date</Table.Th>
                  <Table.Th>Statut</Table.Th>
                  <Table.Th>Montant Facturé (HT)</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {mission.activity_reports &&
                  mission.activity_reports.map((activityReport) => (
                    <Table.Tr
                      key={activityReport.date}
                      onClick={() => {
                        history.push(
                          [
                            "/freelance/missions/ongoing",
                            mission.id,
                            "activity-report",
                            activityReport.date.split("-")[0],
                            activityReport.date.split("-")[1],
                          ].join("/")
                        );
                      }}
                      _hover={{ bg: "yalink.yellow", cursor: "pointer" }}
                    >
                      <Table.Td>
                        <Text>
                          {
                            monthNamesFr[
                              Number(activityReport.date.split("-")[1] - 1)
                            ]
                          }{" "}
                          {activityReport.date.split("-")[0]}
                        </Text>
                      </Table.Td>
                      <Table.Td>{renderBadge(activityReport.status)}</Table.Td>
                      <Table.Td>
                        <Text fontWeight="semibold">
                          {userProfile === UserProfiles.COMPANY
                            ? Number(
                                Number(
                                  activityReport.vat_excluded_yalink_activity_margin
                                ) +
                                  Number(
                                    activityReport.vat_and_margin_excluded_total_amount
                                  )
                              )
                            : Number(
                                activityReport.vat_and_margin_excluded_total_amount
                              )}
                          {"  €"}
                        </Text>
                      </Table.Td>
                    </Table.Tr>
                  ))}
              </Table.Tbody>
            </Table.Table>
          ) : (
            <Table.Table>
              <Table.Thead bg="gray.200">
                <Table.Tr>
                  <Table.Th>Date</Table.Th>
                  <Table.Th>Statut</Table.Th>
                  <Table.Th>Montant Facturé (HT)</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {mission.activity_reports &&
                  mission.activity_reports.map((activityReport) => (
                    <Table.Tr
                      key={activityReport.date}
                      onClick={() => {
                        history.push(
                          [
                            "/freelance/missions/ongoing",
                            mission.id,
                            "activity-report",
                            activityReport.date.split("-")[0],
                            activityReport.date.split("-")[1],
                          ].join("/")
                        );
                      }}
                      _hover={{ bg: "yalink.yellow", cursor: "pointer" }}
                    >
                      <Table.Td>
                        <Text>
                          {
                            monthNamesFr[
                              Number(activityReport.date.split("-")[1] - 1)
                            ]
                          }{" "}
                          {activityReport.date.split("-")[0]}
                        </Text>
                      </Table.Td>
                      <Table.Td>{renderBadge(activityReport.status)}</Table.Td>
                      <Table.Td>
                        <Text fontWeight="semibold">
                          {activityReport.amount_billed
                            ? activityReport.amount_billed +
                              activityReport.total_extra_cost_amount
                            : 0}
                          {"  €"}
                        </Text>
                      </Table.Td>
                    </Table.Tr>
                  ))}
              </Table.Tbody>
            </Table.Table>
          )}
        </Table.Container>
      </Box>
    );
  };

  return (
    <Box bgColor="yalink.bg">
      <Box bg="yalink.green" h={isMobile ? "170px" : "10%"} w="100%" mb="5%">
        <Text color="white" padding="5" ml="3%">
          <ArrowLeft size="20px" />
          <Link
            ml="10px"
            onClick={() => {
              history.goBack();
            }}
            _hover={{ color: "yalink.yellow" }}
          >
            Retour
          </Link>
          <Text mt="10px" fontSize="30px" fontWeight="bold">
            Vue détaillée mission : {mission.name}
          </Text>
        </Text>
      </Box>
      <Flex justify="center" mb="20%">
        <Grid
          templateColumns={isMobile ? null : "2fr 5fr"}
          w="90%"
          h={isMobile ? null : "100vh"}
          gap={10}
        >
          <GridItem>
            <Box mb="2vh" w={isMobile && "90vw"}>
              {isForfait ? (
                <MissionDescriptionForfait />
              ) : (
                <MissionDescriptionRegie />
              )}
            </Box>
            <Box mb="2vh" w={isMobile && "90vw"}>
              <MissionProgressStatus />
            </Box>
            <Box mb="2vh" w={isMobile && "90vw"}>
              <ContactInfo mission={mission} />
            </Box>
          </GridItem>
          <GridItem>
            {userProfile !== UserProfiles.FREELANCE ||
            currentMonthActivityReportStatus === "approved" ||
            currentMonthActivityReportStatus === "nothing_declared" ||
            currentMonthActivityReportStatus === "pending_approval" ? null : (
              <>{isForfait ? <EtatAvancementSaisie /> : <NewCraInput />}</>
            )}
            <ProgressStatusTable />
          </GridItem>
        </Grid>
      </Flex>
    </Box>
  );
}
