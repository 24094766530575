import {
  Box,
  GridItem,
  Text,
  Grid,
  Button,
  Flex,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  AspectRatio,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ContactInfo from "../Utils/ContactInfo";
import ExtraCosts from "./Molecules/ExtraCosts";
import InvoiceBreakdown from "../ActivityReportReview/Molecules/InvoiceBreakdown";
import AmountProgress from "./Molecules/AmountProgress";
import ProgressDescription from "./Molecules/ProgressDescription";
import ProgressSummaryForfait from "./Molecules/ProgressSummaryForfait";
import CompletedMissionModal from "./Molecules/CompletedMissionModal";
import { handleSubmit } from "./Libs/submitActivityReportLib";
import { getUserProfile, UserProfiles } from "../../libs/authHeaderLib";
import Header from "../Utils/Header";

export default function ActivityReportForfait({
  mission,
  activityReport,
  legalData,
  freelanceEmail,
}) {
  let isClient = true;
  const userProfile = getUserProfile();
  if (userProfile === UserProfiles.FREELANCE) {
    isClient = false;
  }
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isTutorialOpen,
    onOpen: onTutorialOpen,
    onClose: onTutorialClose,
  } = useDisclosure();
  const toast = useToast();
  const { year, month } = useParams();
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(false);

  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    applyResponsive();
  }, []);

  window.addEventListener("resize", applyResponsive);

  // Second Formik functions and values

  function getTotalAmount(values) {
    let a = mission.completed_amount;
    let total = a + parseInt(values);
    if (total >= mission.total_amount_billed) {
      total = mission.total_amount_billed;
    }
    if (isNaN(total) === true) {
      return a;
    }
    return total;
  }
  function getSecondAmount(values) {
    let total = parseInt(values);
    let b = 0;
    if (isNaN(total) === true) {
      return b;
    }
    return total;
  }

  function getValues(values) {
    let amount = 0;
    if (values && values.length !== 0) {
      values.forEach((f) => {
        amount += f.amount;
      });
    }
    return parseInt(amount);
  }

  // ValidationSchema with Yup library
  const ValidationSchema = Yup.object().shape({
    avancement: Yup.number()
      .max(
        mission.total_amount_billed - mission.completed_amount,
        "Ne peut pas dépasser le montant de l'enveloppe"
      )
      .required("Ce champ est requis"),
    progressDescription: Yup.string().required("Ce champ est requis"),
  });

  const ModalTutorial = () => {
    return (
      <Modal isOpen={isTutorialOpen} onClose={onTutorialClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Tutoriel pour l'état d'avancement</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AspectRatio ratio={4 / 3}>
              <iframe
                title="tutorial"
                src="https://www.loom.com/embed/42af5049bf1140e5ae2bfd7bca111744?hideEmbedTopBar=true&t=1"
                allowFullScreen
                webkitallowfullscreen
                mozallowfullscreen
              />
            </AspectRatio>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onTutorialClose}>
              Fermer le tutoriel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const Fonctionnement = ({ isMobile, isClient, onTutorialOpen }) => {
    return (
      <Box h="100%" bg="white" color="black" borderRadius={7} boxShadow="md">
        <Box bg="yalink.green" borderTopRadius={7} padding={2} pl={5} mb="2%">
          <Text color="white" fontWeight="semi-bold">
            Fonctionnement
          </Text>
        </Box>
        <Box p={2}>
          {!isClient ? (
            <Grid templateRows="repeat(5,1fr)" ml="5%">
              <GridItem>
                <Text>1. Indiquer le montant à facturer en € </Text>
              </GridItem>
              <GridItem>
                <Text>2. Ajouter des frais si nécéssaire</Text>
              </GridItem>
              <GridItem>
                <Text>3. Ajouter le justificatif d'avancement du projet</Text>
              </GridItem>
              <GridItem>
                <Text>
                  4. Cliquer sur le bouton "Valider l'état d'avancement".
                </Text>
              </GridItem>
              <GridItem align="center">
                <Button
                  borderRadius={0}
                  mt={isMobile && "5%"}
                  onClick={onTutorialOpen}
                >
                  Voir le tutoriel
                </Button>
                <ModalTutorial />
              </GridItem>
            </Grid>
          ) : (
            <Grid templateRows="repeat(3,7vh)" ml="5%">
              <GridItem>
                <Text>1. Le freelance indique le montant en € </Text>
              </GridItem>
              <GridItem>
                <Text>2. Il ajoute des frais si nécéssaire</Text>
              </GridItem>
              <GridItem>
                <Text>
                  3. Il rajoute le justificatif d'avancement du projet
                </Text>
              </GridItem>
            </Grid>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box bgColor="yalink.bg">
      <Header isMobile={isMobile} mission={mission} activityReport={activityReport} />
      <Formik
        initialValues={{
          avancement: activityReport.amount_billed,
          progressDescription: activityReport.progress_proof_description,
          frais: activityReport.extra_costs,
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          if (
            values.avancement + mission.completed_amount ===
            mission.total_amount_billed
          ) {
            onOpen();
          } else {
            handleSubmit(mission, year, month, values, actions, history, toast);
          }
        }}
      >
        {({ values, isValid, actions }) => (
          <Form>
            {!isMobile ? (
              <Grid
                templateColumns={isMobile ? null : "2fr 5fr"}
                mr="4%"
                ml="4%"
                mb={isMobile ? "30%" : "10%"}
                gap={isMobile ? "1%" : "4%"}
              >
                <GridItem h="100%">
                  <Box mb="5vh">
                    <Fonctionnement
                      isMobile={isMobile}
                      isClient={isClient}
                      onTutorialOpen={onTutorialOpen}
                    />
                  </Box>
                  <Box mb="5vh">
                    {!isClient ? (
                      <ProgressSummaryForfait
                        getSecondAmount={getSecondAmount}
                        values={values}
                        getValues={getValues}
                        month={month}
                        year={year}
                        isValid={isValid}
                        activityReport={activityReport}
                      />
                    ) : (
                      <InvoiceBreakdown
                        activityReport={activityReport}
                        legalData={legalData}
                        mission={mission}
                        isClient={isClient}
                      />
                    )}
                  </Box>
                  <Box mb="5vh">
                    <ContactInfo
                      mission={mission}
                      isClient={isClient}
                      freelanceEmail={freelanceEmail}
                    />
                  </Box>
                </GridItem>

                <GridItem h="100%">
                  <AmountProgress
                    isClient={isClient}
                    isMobile={isMobile}
                    mission={mission}
                    getTotalAmount={getTotalAmount}
                    values={values}
                  />
                  <ExtraCosts
                    isMobile={isMobile}
                    isClient={isClient}
                    values={values}
                  />
                  <ProgressDescription isClient={isClient} />
                </GridItem>
              </Grid>
            ) : (
              <Flex mr="5%" ml="5%" direction="column" mb="10%">
                <Box mb="10%">
                  <Fonctionnement isClient={isClient} />
                </Box>
                <AmountProgress
                  isClient={isClient}
                  isMobile={isMobile}
                  mission={mission}
                  getTotalAmount={getTotalAmount}
                  values={values}
                />
                <ExtraCosts isMobile={isMobile} values={values} />
                <ProgressDescription isClient={isClient} />
                <ProgressSummaryForfait
                  getSecondAmount={getSecondAmount}
                  values={values}
                  getValues={getValues}
                  month={month}
                  year={year}
                  isValid={isValid}
                  activityReport={activityReport}
                />
              </Flex>
            )}
            <CompletedMissionModal
              isOpen={isOpen}
              onClose={onClose}
              mission={mission}
              year={year}
              month={month}
              values={values}
              actions={actions}
              toast={toast}
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
}
