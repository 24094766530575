import React, { useState } from "react";

import {
  Stack,
  Box,
  Textarea,
  Input,
  DrawerBody,
  FormLabel,
  DrawerFooter,
  Button,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  IconButton,
  useToast,
  Divider,
  Select,
  FormControl,
} from "@chakra-ui/react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { EditIcon } from "@chakra-ui/icons";
import Offers from "../../services/offers.service";
import MultiselectJobs from "./MultiselectJobs";
import MultiselectSectors from "./MultiselectSectors";

export const preferencesDict = {
  workplace: {
    on_site: {
      code: "on_site",
      text: "Sur site uniquement",
    },
    remote: {
      code: "remote",
      text: "Télé-travail uniquement",
    },
    no_pref: {
      code: "no_pref",
      text: "Indifférent",
    },
  },
  jobDuration: {
    lt3m: {
      code: "lt3m",
      text: "Courte (-3 mois)",
    },
    gt3m: {
      code: "gt3m",
      text: "Longue (+3 mois)",
    },
    indifferent: {
      code: "indifferent",
      text: "Indifférent",
    },
  },
  daysPerWeek: {
    part: {
      code: "part",
      text: "Temps partiel",
    },
    daysPerWeek1: {
      code: "daysPerWeek1",
      text: "1 jour",
    },
    daysPerWeek2: {
      code: "daysPerWeek2",
      text: "2 jours",
    },
    daysPerWeek3: {
      code: "daysPerWeek3",
      text: "3 jours",
    },
    daysPerWeek4: {
      code: "daysPerWeek4",
      text: "4 jours",
    },
    full: {
      code: "full",
      text: "Temps plein",
    },
    indifferent: {
      code: "indifferent",
      text: "Indifférent",
    },
  },
};

function NewJobOffer(props) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const firstField = React.useRef();
  const [offerName, setOfferName] = useState("");
  const [company, setCompany] = useState("");
  const [mail, setMail] = useState("");
  const [contactName, setContactName] = useState("");
  const [beginDate, setBeginDate] = useState("");
  const [jobDuration, setJobDuration] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [daysPerWeek, setDaysPerWeek] = useState("");
  const [workplace, setWorkplace] = useState("");
  const [position, setPosition] = useState("");
  const [sector, setSector] = useState("");
  const [description, setDescription] = useState("");
  const [id, setId] = useState("");

  function resetAllValues() {
    setOfferName("");
    setCompany("");
    setMail("");
    setContactName("");
    setBeginDate("");
    setJobDuration("");
    setPostalCode("");
    setDaysPerWeek("");
    setWorkplace("");
    setPosition("");
    setSector("");
    setDescription("");
    setId("");
  }

  function isFormValid() {
    if (offerName === "") {
      return false;
    }
    return true;
  }

  function setOfferDetailsFromDict(offerDict) {
    setOfferName(offerDict.offerName);
    setCompany(offerDict.company);
    setMail(offerDict.mail);
    setContactName(offerDict.contactName);
    setBeginDate(offerDict.beginDate);
    setJobDuration(offerDict.jobDuration);
    setPostalCode(offerDict.postalCode);
    setDaysPerWeek(offerDict.daysPerWeek);
    setWorkplace(offerDict.workplace);
    setPosition(offerDict.position);
    setSector(offerDict.sector);
    setDescription(offerDict.description);
    setId(offerDict.id);
  }

  function handleUpdate() {
    var result = props.offers.filter((offer) => {
      if (offer.id === props.offerId) {
        return true;
      }
      return null;
    });
    setOfferDetailsFromDict(result[0]);
    return true;
  }

  function submitForm(verb) {
    const details = {
      offerName: offerName,
      company: company,
      mail: mail,
      contactName: contactName,
      beginDate: beginDate,
      jobDuration: jobDuration,
      postalCode: postalCode,
      daysPerWeek: daysPerWeek,
      workplace: workplace,
      position: position,
      sector: sector,
      description: description,
      id: id,
    };
    if (verb === "add") {
      Offers.createOffer(details).then((response) => {
        toast({
          position: "top",
          title: "Offre créée",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        let tempOffers = props.offers;
        tempOffers.push(response.data.offer);
        props.setOffers([...tempOffers]);
      });
    }
    if (verb === "update") {
      Offers.updateOffer(details).then((response) => {
        toast({
          position: "top",
          title: "Offre mise à jour",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        let tempOffers = props.offers;
        let indexToUpdate = null;
        tempOffers.forEach((offer) => {
          if (offer.id === response.data.offer.id) {
            indexToUpdate = tempOffers.indexOf(offer);
          }
        });

        tempOffers.splice(indexToUpdate, 1, response.data.offer);
        props.setOffers([...tempOffers]);
      });
    }
    if (verb === "remove") {
      Offers.deleteOffer(id).then(() => {
        toast({
          position: "top",
          title: "Offre supprimée",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        let tempOffers = props.offers;
        let indexToUpdate = null;
        tempOffers.forEach((offer) => {
          if (offer.id === id) {
            indexToUpdate = tempOffers.indexOf(offer);
          }
        });

        tempOffers.splice(indexToUpdate, 1);
        props.setOffers([...tempOffers]);
      });
    }
    onClose();
    handleClose();
  }

  function onUpdate() {
    handleUpdate();
    onOpen();
  }

  function handleClose() {
    onClose();
    resetAllValues();
  }
  return (
    <>
      {props.offerId ? (
        <IconButton size="sm" icon={<EditIcon />} onClick={onUpdate} />
      ) : (
        <Button
          color="white"
          bg="yalink.green"
          _hover={{ bg: "yalink.yellow" }}
          _checked={{
            bg: "yalink.yellow",
            color: "white",
            borderColor: "yalink.yellow",
          }}
          onClick={onOpen}
        >
          Nouvel appel d'offre
        </Button>
      )}

      <Drawer
        isOpen={isOpen}
        placement="right"
        initialFocusRef={firstField}
        onClose={onClose}
        size="lg"
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px">
              Nouvel Appel D'Offre
            </DrawerHeader>

            <DrawerBody>
              <Stack spacing="24px">
                <Box>
                  <FormLabel htmlFor="offerName">Nom de l'offre</FormLabel>
                  <Input
                    ref={firstField}
                    id="offerName"
                    value={offerName}
                    onChange={(e) => setOfferName(e.target.value)}
                    placeholder={"AO Gestion de projet Tesla"}
                  />
                </Box>
                <Box>
                  <FormLabel htmlFor="company">Entreprise</FormLabel>
                  <Input
                    id="company"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    placeholder={"Tesla"}
                  />
                  <FormLabel htmlFor="contactName">Nom représentant</FormLabel>
                  <Input
                    id="contactName"
                    value={contactName}
                    onChange={(e) => setContactName(e.target.value)}
                    placeholder="George Duroy"
                  />
                  <FormLabel htmlFor="mail">E-mail</FormLabel>
                  <Input
                    id="mail"
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                    placeholder="yo@maggle.fr"
                  />
                </Box>

                <Divider />

                <Box>
                  <FormLabel htmlFor="beginDate" isRequired>
                    Date de début
                  </FormLabel>
                  <Box borderWidth={1} borderRadius={5} padding={1}>
                    <DayPicker
                      value={beginDate}
                      onDayChange={(day) => setBeginDate(day)}
                    />
                  </Box>
                  <FormLabel htmlFor="jobDuration">Durée</FormLabel>
                  <Select
                    placeholder="Selectionner"
                    id="jobDuration"
                    value={jobDuration}
                    onChange={(e) => setJobDuration(e.target.value)}
                  >
                    <option value={preferencesDict.jobDuration.lt3m.code}>
                      {preferencesDict.jobDuration.lt3m.text}
                    </option>
                    <option value={preferencesDict.jobDuration.gt3m.code}>
                      {preferencesDict.jobDuration.gt3m.text}
                    </option>
                    <option
                      value={preferencesDict.jobDuration.indifferent.code}
                    >
                      {preferencesDict.jobDuration.indifferent.text}
                    </option>
                  </Select>
                  <FormLabel htmlFor="postalCode">Localisation</FormLabel>
                  <Input
                    id="postalCode"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                    placeholder="33600"
                  />
                  <FormLabel htmlFor="daysPerWeek">Cadence</FormLabel>
                  <Select
                    placeholder="Selectionner"
                    id="daysPerWeek"
                    value={daysPerWeek}
                    onChange={(e) => setDaysPerWeek(e.target.value)}
                  >
                    <option
                      value={preferencesDict.daysPerWeek.daysPerWeek1.code}
                    >
                      {preferencesDict.daysPerWeek.daysPerWeek1.text}
                    </option>
                    <option
                      value={preferencesDict.daysPerWeek.daysPerWeek2.code}
                    >
                      {preferencesDict.daysPerWeek.daysPerWeek2.text}
                    </option>
                    <option
                      value={preferencesDict.daysPerWeek.daysPerWeek3.code}
                    >
                      {preferencesDict.daysPerWeek.daysPerWeek3.text}
                    </option>
                    <option
                      value={preferencesDict.daysPerWeek.daysPerWeek4.code}
                    >
                      {preferencesDict.daysPerWeek.daysPerWeek4.text}
                    </option>
                    <option value={preferencesDict.daysPerWeek.full.code}>
                      {preferencesDict.daysPerWeek.full.text}
                    </option>
                    <option
                      value={preferencesDict.daysPerWeek.indifferent.code}
                    >
                      {preferencesDict.daysPerWeek.indifferent.text}
                    </option>
                  </Select>
                  <FormLabel htmlFor="workplace">Lieu de travail</FormLabel>
                  <Select
                    placeholder="Selectionner"
                    id="workplace"
                    value={workplace}
                    onChange={(e) => setWorkplace(e.target.value)}
                  >
                    <option value={preferencesDict.workplace.on_site.code}>
                      {preferencesDict.workplace.on_site.text}
                    </option>
                    <option value={preferencesDict.workplace.remote.code}>
                      {preferencesDict.workplace.remote.text}
                    </option>
                    <option value={preferencesDict.workplace.no_pref.code}>
                      {preferencesDict.workplace.no_pref.text}
                    </option>
                  </Select>
                </Box>
                <Box>
                  <FormLabel htmlFor="position">Métier</FormLabel>
                  <MultiselectJobs />
                </Box>
                <Box>
                  <FormLabel htmlFor="sector">Secteur</FormLabel>
                  <MultiselectSectors />
                </Box>
                <Box>
                  <FormLabel htmlFor="desc">Description</FormLabel>
                  <Textarea
                    id="desc"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <FormControl />
                </Box>
              </Stack>
            </DrawerBody>

            <DrawerFooter borderTopWidth="1px">
              <Button variant="outline" mr={3} onClick={() => handleClose()}>
                Annuler
              </Button>
              {props.offerId ? (
                <>
                  <Button
                    colorScheme="red"
                    mr={3}
                    onClick={(e) => submitForm("remove")}
                  >
                    Supprimer
                  </Button>
                  <Button
                    color="white"
                    bg="yalink.green"
                    _hover={{ bg: "yalink.yellow" }}
                    _checked={{
                      bg: "yalink.yellow",
                      color: "white",
                      borderColor: "yalink.yellow",
                    }}
                    isDisabled={!isFormValid()}
                    onClick={(e) => submitForm("update")}
                  >
                    Sauver
                  </Button>
                </>
              ) : (
                <Button
                  color="white"
                  bg="yalink.green"
                  _hover={{ bg: "yalink.yellow" }}
                  _checked={{
                    bg: "yalink.yellow",
                    color: "white",
                    borderColor: "yalink.yellow",
                  }}
                  isDisabled={!isFormValid()}
                  onClick={(e) => submitForm("add")}
                >
                  Ajouter
                </Button>
              )}
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
}

export default NewJobOffer;
