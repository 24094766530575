import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import {
  Box,
  Text,
  Grid,
  GridItem,
  Input,
  InputRightElement,
  FormErrorMessage,
  FormControl,
  FormLabel,
  InputGroup,
  Heading,
  Button,
} from "@chakra-ui/react";

function FreelanceBillingInfoForm({
  legalData,
  setLegalData,
  handleLegalDataUpdate,
  setIsUpdatingLegalData,
  isUpdatingLegalData,
}) {
  // Mobile Responsive Grid
  const [gridRows, setGridRows] = useState("repeat(2, 1fr)");
  const [gridColumns, setGridColumns] = useState("repeat(2, 1fr)");
  const [isMobile, setIsMobile] = useState(false);

  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
      setGridRows("0.8fr 1fr");
      setGridColumns("1fr");
    } else {
      setIsMobile(false);
      setGridRows("repeat(2, 1fr)");
      setGridColumns("repeat(2, 1fr)");
    }
  };

  useEffect(() => {
    applyResponsive();
  }, []);

  window.addEventListener("resize", applyResponsive);

  function validateVAT(value) {
    let error;
    if (!/^((100)|([0-9]{1,2}))$/i.test(value)) {
      error = "*Il va falloir remplir ce champ par un nombre entre 0 et 100 😱";
    } else if (value !== "0" && value !== "20") {
      error = "*La TVA doit être de 0% où 20%";
    }
    return error;
  }

  function validateCapital(value) {
    let error;
    if (!/^((10000000)|([0-9]{1,7}))$/i.test(value)) {
      error = "*Il va falloir remplir ce champ par un nombre 😱";
    }
    return error;
  }

  function validateVatNumber(value) {
    let error;
    if (value && !/^[A-Z]{2}[0-9]*$/i.test(value)) {
      error = "*Exemple valide: FR74917475717";
    }
    return error;
  }

  function validateText(value) {
    let error;
    if (!value) {
      error = "*Il va falloir remplir ce champ 😱";
    }
    return error;
  }

  return (
    <>
      <Box
        mb="3%"
        padding="5"
        borderWidth={1}
        borderRadius={8}
        boxShadow="md"
        bgColor="#fff"
      >
        <Heading mb="20px" size="md" color="gray.600">
          Données de facturation
        </Heading>
        <Text>
          Afin de générer les factures mensuelles, nous avons besoin de savoir
          si ton entreprise est soumise à la TVA. Le taux de TVA est
          généralement fixé à 20%.
        </Text>
        <Text mb="7">
          Si tu es auto-entrepreneur tu en es exempté et tu peut indiquer 0%.
        </Text>
        <Formik
          initialValues={{
            vatPercentage: legalData.vatPercentage,
            vatIntracommunityNumber: legalData.vatIntracommunityNumber,
            socialCapital: legalData.socialCapital,
            rcsRegistrationCity: legalData.rcsRegistrationCity,
          }}
          onSubmit={(values) => {
            let tempLegalData = legalData;
            tempLegalData.vatPercentage = values.vatPercentage;
            tempLegalData.vatIntracommunityNumber =
              values.vatIntracommunityNumber;
            tempLegalData.socialCapital = values.socialCapital;
            tempLegalData.rcsRegistrationCity = values.rcsRegistrationCity;
            setLegalData({ ...tempLegalData });

            handleLegalDataUpdate(setIsUpdatingLegalData);
          }}
        >
          {(props) => (
            <Form>
              <Grid
                templateRows={gridRows}
                templateColumns={gridColumns}
                gap={10}
                mr={5}
                mb="20px"
              >
                <GridItem rowSpan={isMobile ? 1 : 2} colSpan={1}>
                  <Field name="vatPercentage" validate={validateVAT}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.vatPercentage &&
                          form.touched.vatPercentage
                        }
                        isRequired
                      >
                        <FormLabel htmlFor="vatPercentage">TVA</FormLabel>
                        <InputGroup>
                          <Input
                            {...field}
                            id="vatPercentage"
                            placeholder="20"
                          />
                          <InputRightElement
                            pointerEvents="none"
                            color="gray.300"
                            fontSize="1.2em"
                            children="%"
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.vatPercentage}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="socialCapital" validate={validateCapital}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.socialCapital &&
                          form.touched.socialCapital
                        }
                        isRequired
                      >
                        <FormLabel htmlFor="socialCapital" mt="5">
                          CAPITAL SOCIAL
                        </FormLabel>
                        <InputGroup>
                          <Input
                            {...field}
                            id="socialCapital"
                            placeholder="Capital social"
                          />
                          <InputRightElement
                            pointerEvents="none"
                            color="gray.300"
                            fontSize="1.2em"
                            children="€"
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.socialCapital}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </GridItem>
                <GridItem rowSpan={isMobile ? 1 : 2} colSpan={1}>
                  <Field
                    name="vatIntracommunityNumber"
                    validate={validateVatNumber}
                  >
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.vatIntracommunityNumber}
                      >
                        <FormLabel htmlFor="vatIntracommunityNumber">
                          N° de TVA intracommunautaire
                        </FormLabel>
                        <InputGroup>
                          <Input
                            {...field}
                            id="vatIntracommunityNumber"
                            placeholder="Ex FR78894060565"
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.vatIntracommunityNumber}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="rcsRegistrationCity" validate={validateText}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.rcsRegistrationCity &&
                          form.touched.rcsRegistrationCity
                        }
                        isRequired
                      >
                        <FormLabel htmlFor="rcsRegistrationCity" mt="5">
                          Ville d'immatriculation au RCS
                        </FormLabel>
                        <InputGroup>
                          <Input
                            {...field}
                            id="rcsRegistrationCity"
                            placeholder="Ex Paris"
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.rcsRegistrationCity}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </GridItem>
              </Grid>
              <Button
                mt={4}
                width="100%"
                color="white"
                borderRadius="0"
                bg="yalink.green"
                _hover={{ bg: "yalink.yellow" }}
                isLoading={isUpdatingLegalData}
                type="submit"
                disabled={isUpdatingLegalData || !props.isValid || !props.dirty}
              >
                Enregistrer
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
}

export default FreelanceBillingInfoForm;
