import React from "react";
import { Center, Box, CircularProgress } from "@chakra-ui/react";

function LoadingComponent() {
  return (
    <Center>
      <Box w="80%" mt="20px" mb="20px" padding="5">
        <Center>
          <CircularProgress
            mt="200px"
            mb="400px"
            isIndeterminate
            color="yalink.green"
            size="100px"
            thickness="4px"
          />
        </Center>
      </Box>
    </Center>
  );
}

export default LoadingComponent;
