import {
  Box,
  Switch,
  Stack,
  Text,
  SimpleGrid,
  Select,
  FormControl,
  Input,
  FormErrorMessage,
  Heading,
  Button,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";

function YourEnterprise({
  legalData,
  setLegalData,
  handleLegalDataUpdate,
  setIsUpdatingLegalData,
  isUpdatingLegalData,
}) {
  const [address, setAddress] = useState(legalData.headquartersAdress);

  const isLegalStructureError = legalData.legalStructure === "";
  const isError = legalData.businessName === "";
  const isSiretInvalid =
    legalData.siretNumber === "" || !legalData.siretNumber.match(/\d{14}\b/);
  const isError3 = legalData.headquartersAdress === "";

  const [isMobile, setIsMobile] = useState(false);
  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  window.addEventListener("resize", applyResponsive);

  useEffect(() => {
    applyResponsive();
  }, []);

  return (
    <Box
      padding="5"
      borderWidth={1}
      borderRadius={8}
      boxShadow="md"
      bgColor="#fff"
    >
      <Heading mb="20px" size="md" color="gray.600">
        Votre entreprise
      </Heading>
      <Text fontSize="xs" mb={5}>
        Ces informations sont nécéssaires pour l’édition de tes factures et
        permettre le paiement chaque mois. Ces informations n’apparaissent pas
        sur ton profil public.
      </Text>
      <Text mb={1}>Passes-tu par une société de portage salarial ? </Text>
      <Stack align="center" direction="row">
        <Text mb={2}>Non</Text>
        <Switch
          colorScheme="yellow"
          size="sm"
          data-testid="switch"
          isChecked={legalData.wagePortage}
          onChange={() => {
            let tempLegalData = legalData;
            tempLegalData.wagePortage = !tempLegalData.wagePortage;
            setLegalData({ ...tempLegalData });
          }}
        />
        <Text mb={2}>Oui</Text>
      </Stack>
      <Text fontSize="xs" mb={5}>
        Si oui, renseigne ci-dessous les informations et documents légaux
        relatifs à ton entreprise de portage.
      </Text>
      <SimpleGrid
        columns={3}
        width="80%"
        mb={3}
        display={isMobile ? "none" : "grid"}
      >
        <Text>Forme Juridique</Text>
        <Text>Raison Sociale</Text>
        <Text>SIRET</Text>
      </SimpleGrid>
      <SimpleGrid
        columns={isMobile ? 1 : 3}
        rows={isMobile ? 3 : null}
        spacingY={isMobile ? 7 : null}
        width="70%"
      >
        <Text d={isMobile ? "block" : "none"}>Forme Juridique</Text>
        <FormControl isRequired isInvalid={isLegalStructureError}>
          <Select
            placeholder="Sélectionner"
            width="90%"
            value={legalData.legalStructure}
            onChange={(e) => {
              let tempLegalData = legalData;
              tempLegalData.legalStructure = e.target.value;
              setLegalData({ ...tempLegalData });
            }}
          >
            <option value="AE">AE - Micro Entreprise</option>
            <option value="SA">SA</option>
            <option value="SAS">SAS</option>
            <option value="SARL">SARL</option>
            <option value="EI">EI - Entreprise Individuelle</option>
            <option value="EIRL">EIRL</option>
            <option value="EURL">EURL</option>
            <option value="SASU">SASU</option>
            <option value="SAU">SAU</option>
            <option value="GIE">GIE</option>
            <option value="SDE">Société de droit étranger</option>
            <option value="other">Autre</option>
          </Select>
          <FormErrorMessage>
            Veuillez sélectionner une forme juridique
          </FormErrorMessage>
        </FormControl>

        <Text d={isMobile ? "block" : "none"}>Raison Sociale</Text>
        <FormControl
          isRequired
          ml={isMobile ? null : "14%"}
          mb={5}
          isInvalid={isError}
        >
          <Input
            id="raison-sociale"
            placeholder="Ex : Yalink"
            value={legalData.businessName}
            onChange={(e) => {
              let tempLegalData = legalData;
              tempLegalData.businessName = e.target.value;
              setLegalData({ ...tempLegalData });
            }}
          />
          <FormErrorMessage>
            Veuillez entrer votre Raison Sociale
          </FormErrorMessage>
        </FormControl>
        <Text d={isMobile ? "block" : "none"}>SIRET</Text>
        <FormControl
          isRequired
          ml={isMobile ? null : "28%"}
          isInvalid={isSiretInvalid}
        >
          <Input
            id="numero-siret"
            placeholder="Ex : 894 060 565 00013"
            onChange={(e) => {
              let tempLegalData = legalData;
              tempLegalData.siretNumber = e.target.value;
              setLegalData({ ...tempLegalData });
            }}
            value={legalData.siretNumber}
          />
          <FormErrorMessage>
            Veuillez entrer votre numéro de SIRET (doit comprendre 14 chiffres)
          </FormErrorMessage>
        </FormControl>
      </SimpleGrid>
      <Text mb={3} mt={isMobile ? "10%" : null}>
        Adresse
      </Text>
      <FormControl isRequired w="76.5%" mb="4%" isInvalid={isError3}>
        <PlacesAutocomplete
          value={address}
          onChange={(address) => {
            setAddress(address);
          }}
          onSelect={(address) => {
            setAddress(address);
            geocodeByAddress(address)
              .then((results) => {
                let tempLegalData = legalData;
                tempLegalData.headquartersAdress = address;
                tempLegalData.headquartersAdressDetails = results[0];
                setLegalData({ ...tempLegalData });
              })
              .catch(() => {});
          }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <Input
                id="adresse"
                data-testid="address"
                placeholder={"Entrez votre adresse"}
                {...getInputProps({
                  placeholder: "Entrez votre adresse",
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>

        <FormErrorMessage>Veuillez renseigner votre adresse</FormErrorMessage>
      </FormControl>

      <Button
        color="white"
        bg="yalink.green"
        borderRadius="0"
        width="100%"
        _hover={{ bg: "yalink.yellow" }}
        _checked={{
          bg: "yalink.yellow",
          color: "white",
          borderColor: "yalink.yellow",
        }}
        type="submit"
        mb="5"
        onClick={() => handleLegalDataUpdate(setIsUpdatingLegalData)}
        isLoading={isUpdatingLegalData}
        disabled={
          isLegalStructureError ||
          isError ||
          isSiretInvalid ||
          isError3 ||
          isUpdatingLegalData
        }
      >
        Enregistrer
      </Button>
    </Box>
  );
}

export default YourEnterprise;
