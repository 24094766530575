import React, { useEffect, useState } from "react";
import { FormControl } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import jobtitlesService from "../../services/jobtitles.service";

function MultiselectJobs() {
  const [availableJobTitles, setAvailableJobTitles] = useState("");
  const [computedGroupedOptions, setComputedGroupedOptions] = useState([]);

  useEffect(() => {
    async function getJobTitles() {
      await jobtitlesService.getAllJobTitles().then((response) => {
        setAvailableJobTitles(response.data.job_titles);

        let computedData = [];

        for (const activity_area in response.data.job_titles) {
          for (const category in response.data.job_titles[activity_area]) {
            let newGroupedOption = {};
            newGroupedOption["label"] = activity_area + " - " + category;

            let options = [];
            for (const job in response.data.job_titles[activity_area][
              category
            ]) {
              options.push({
                value:
                  response.data.job_titles[activity_area][category][job].name,
                label:
                  response.data.job_titles[activity_area][category][job].name,
              });
            }
            newGroupedOption["options"] = options;
            computedData.push(newGroupedOption);
          }
        }
        setComputedGroupedOptions(computedData);
      }).catch(() => {});
    }
    if (!availableJobTitles) {
      getJobTitles();
    }
  }, [setComputedGroupedOptions, computedGroupedOptions, availableJobTitles]);

  return (
    computedGroupedOptions && (
      <>
        <FormControl>
          <Select
            isMulti
            name="position"
            options={computedGroupedOptions}
            placeholder="Selectionne des métiers..."
            closeMenuOnSelect={false}
            hasStickyGroupHeaders
          />
        </FormControl>
      </>
    )
  );
}

export default MultiselectJobs;
