import React from "react";

import {
  Box,
  Text,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { handleAlert, handleSubmit } from "../Libs/submitActivityReportLib";

export default function CompletedMissionModal({
  isOpen,
  onClose,
  mission,
  year,
  month,
  values,
  actions,
  toast,
}) {
  const history = useHistory();

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay backdropFilter="blur(10px) hue-rotate(90deg)" />
        <ModalContent>
          <ModalBody>
            <Flex justify="center" align="center" direction="column">
              <Box mb="3%" alignSelf="start">
                <Text fontWeight="semibold" fontSize="22px" align="left">
                  Fin de mission
                </Text>
              </Box>
              <Box mb="3%">
                <Text fontSize="18px">
                  Avec ce nouvel avancement, tu vas completer ta mission à 100%
                  !
                </Text>
              </Box>
            </Flex>
            <Flex flexWrap="wrap" flexDirection="column">
              <Button
                bgColor="yalink.green"
                mr={3}
                mb={3}
                onClick={() => {
                  handleSubmit(
                    mission,
                    year,
                    month,
                    values,
                    actions,
                    history,
                    toast
                  );
                  onClose();
                }}
                borderRadius="0"
                _hover={{ bgColor: "yalink.green" }}
                flexWrap="wrap"
                color="white"
              >
                Je valide ma fin de mission
              </Button>
              <Button
                bgColor="yalink.yellow"
                mr={3}
                mb={3}
                onClick={() => {
                  handleAlert(mission, year, month, toast);
                  onClose();
                }}
                borderRadius="0"
                color="white"
                _hover={{ bgColor: "yalink.yellow" }}
              >
                Mon avancement est correct mais je n'ai pas terminé ma mission
              </Button>
              <Button
                bgColor="yalink.grey"
                mr={3}
                onClick={onClose}
                mb={3}
                borderRadius="0"
                flexWrap="wrap"
                _hover={{ bgColor: "yalink.grey" }}
              >
                Je n'ai pas terminé, je modifie mon compte rendu
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
