import React from "react";
import { useState, useEffect } from "react";
import GlobalStyle from "./globalStyles";
import Routes from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { ScaleFade, Image, Box, Center } from "@chakra-ui/react";

import ScrollToTop from "./components/ScrollToTop";
import { AppContext } from "./libs/contextLib";
import { Navbar, Footer } from "./components";
import UserService from "./services/user.service";
import Auth from "./services/auth.service";
import { hasAuthHeader } from "./libs/authHeaderLib";
import img from "./images/Logo-Yalink+texte-Jaune.png";

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function onLoad() {
      if (hasAuthHeader() && !hasAuthHeader().external) {
        await UserService.getLoginStatus()
          .then((response) => {
            setUser({
              email: response.data.data.email,
              admin: response.data.data.admin,
              registered_on: response.data.data.registered_on,
              user_id: response.data.data.user_id,
              role: response.data.data.role,
            });
          })
          .catch(() => {
            Auth.logout();
          });
        if (user) {
          userHasAuthenticated(true);
        }
      }
      setIsAuthenticating(false);
    }

    if (isAuthenticating) {
      onLoad();
    }
  }, [user, isAuthenticated, isAuthenticating]);

  return isAuthenticating ? (
    <ScaleFade initialScale={0.6} in={true}>
      <Center>
        <Box width="30%" mt="200px">
          <Image src={img} alt="Yalink Loader logo" />
        </Box>
      </Center>
    </ScaleFade>
  ) : (
    <div class="main-container">
      <div class="content-wrapper">
        <Router>
          <GlobalStyle />
          <ScrollToTop />
          <AppContext.Provider
            value={{ isAuthenticated, userHasAuthenticated }}
          >
            <Navbar
              isAuthenticated={isAuthenticated}
              userHasAuthenticated={userHasAuthenticated}
              isAuthenticating={isAuthenticating}
              setIsAuthenticating={setIsAuthenticating}
            />
            <Routes />
          </AppContext.Provider>
          <Footer />
        </Router>
      </div>
    </div>
  );
}

export default App;
