import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Button,
  Text,
  Flex,
  GridItem,
  Textarea,
  Input,
  useToast
} from "@chakra-ui/react";
import References from "../../services/clientref.service";
import { useHistory } from "react-router-dom";

import { Bulb } from "@styled-icons/ionicons-outline/Bulb";
import { isEmailValid } from "../../libs/regexLib";
import { querystring } from "../../libs/queryStringLib";
function ExternalReco() {
  const toast = useToast();
  const history = useHistory();
  const [reference, setReference] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    description: ""
  })
  // isMobile responsive useState and useEffect

  const [isMobile, setIsMobile] = useState(false);

  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    applyResponsive();
  }, []);

  async function submitReference() {
    let token = querystring("token", window.location.href);
    if (token && token !== "") {
      let tempRef = reference;
      reference.token = token;
      setReference({...tempRef});
      await References.submitRef(reference).then(() => {
        toast({
          position: "top",
          title: "Votre recommendation a été envoyée !",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        history.push("/login");
      }).catch(() => {
        toast({
          position: "top",
          title: "Votre recommendation n'a pas pu être enregistrée",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    }
  }

  window.addEventListener("resize", applyResponsive);
  return (
    <>
      <Box mt={isMobile ? "30%" : "10%"} mb={isMobile ? "30%" : "10%"}>
        <Flex justify="center">
          <Box
            w={isMobile ? "90%" : "40%"}
            borderRadius={5}
            boxShadow="md"
            mb="5%"
            paddingBottom={isMobile && 5}
            bg="white"
          >
            <Box bgColor="yalink.green" h="10%">
              <Flex justify="center" align="center" h="100%">
                <Text fontSize="22px" fontWeight="bold" color="white">
                  Formulaire de recommendation
                </Text>
              </Flex>
            </Box>
            <Box p={isMobile ? "2" : "5"}>
              <Box bgColor="yalink.grey" borderRadius="5" p="3" mb="5%">
                <Grid templateColumns="0.1fr 1fr" gap="10" alignItems="center">
                  <GridItem ml="50%">
                    <Bulb size="25px" />
                  </GridItem>
                  <GridItem>
                    <Text fontSize="15px">
                      Nous n'utilisons pas ces emails à des fins commerciales.
                      <br />
                      Nous conservons ces informations uniquement pour
                      <br /> garantir l’authenticité de ces recommandations.
                    </Text>
                  </GridItem>
                </Grid>
              </Box>
              <Box mb="3%">
                <Text fontSize="17px" fontWeight="semi-bold">
                  Renseignez vos informations ci-dessous et commencez à rédiger
                  votre recommendation.
                </Text>
              </Box>
              <Grid
                templateRows={
                  isMobile
                    ? "60px 60px 60px 60px 1fr 1fr"
                    : "60px 60px 60px 60px 3fr 2fr"
                }
              >
                <GridItem mb="5%">
                  <Grid
                    templateColumns={isMobile ? "0.5fr 1fr" : "0.3fr 1fr"}
                    alignItems="center"
                  >
                    <GridItem>
                      <Text fontSize="16px">Prénom :</Text>
                    </GridItem>
                    <GridItem>
                      <Input
                        size="md"
                        value={reference.firstName}
                        isInvalid={reference.firstName === ""}
                        onChange={(e) => {
                          let tempRef = reference;
                          tempRef.firstName = e.target.value;
                          setReference({ ...tempRef });
                        }}
                      ></Input>
                    </GridItem>
                  </Grid>
                </GridItem>
                <GridItem mb="5%">
                  <Grid
                    templateColumns={isMobile ? "0.5fr 1fr" : "0.3fr 1fr"}
                    alignItems="center"
                  >
                    <GridItem>
                      <Text fontSize="16px">Nom :</Text>
                    </GridItem>
                    <GridItem>
                      <Input
                        size="md"
                        value={reference.lastName}
                        isInvalid={reference.lastName === ""}
                        onChange={(e) => {
                          let tempRef = reference;
                          tempRef.lastName = e.target.value;
                          setReference({ ...tempRef });
                        }}
                      ></Input>
                    </GridItem>
                  </Grid>
                </GridItem>
                <GridItem mb="5%">
                  <Grid
                    templateColumns={isMobile ? "0.5fr 1fr" : "0.3fr 1fr"}
                    alignItems="center"
                  >
                    <GridItem>
                      <Text fontSize="16px">Adresse e-mail :</Text>
                    </GridItem>
                    <GridItem>
                      <Input
                        value={reference.email}
                        isInvalid={!isEmailValid(reference.email)}
                        onChange={(e) => {
                          let tempRef = reference;
                          tempRef.email = e.target.value;
                          setReference({ ...tempRef });
                        }}
                      ></Input>
                    </GridItem>
                  </Grid>
                </GridItem>
                <GridItem mb="5%">
                  <Grid
                    templateColumns={isMobile ? "0.5fr 1fr" : "0.3fr 1fr"}
                    alignItems="center"
                  >
                    <GridItem>
                      <Text fontSize="16px">Entreprise :</Text>
                    </GridItem>
                    <GridItem>
                      <Input
                        value={reference.company}
                        isInvalid={reference.company === ""}
                        onChange={(e) => {
                          let tempRef = reference;
                          tempRef.company = e.target.value;
                          setReference({ ...tempRef });
                        }}
                      ></Input>
                    </GridItem>
                  </Grid>
                </GridItem>
                <GridItem mb="5%">
                  <Textarea
                    h="100px"
                    resize="none"
                    placeholder="Votre commentaire public"
                    value={reference.description}
                    isInvalid={reference.description === ""}
                    onChange={(e) => {
                      let tempRef = reference;
                      tempRef.description = e.target.value;
                      setReference({ ...tempRef });
                    }}
                  
                  ></Textarea>
                </GridItem>
                <GridItem mb="5%">
                  <Flex justify="center">
                    <Flex
                      justify="space-between"
                      gap={10}
                      mt={isMobile && "10%"}
                    >
                      <Box>
                        <Button
                          _hover={{ bgColor: "yalink.green" }}
                          bgColor="yalink.green"
                          color="white"
                          fontSize={isMobile ? "14px" : "18px"}
                          disabled={
                            reference.firstName === "" | 
                            reference.lastName === "" | 
                            !isEmailValid(reference.email) | 
                            reference.description === "" |
                            reference.company === ""
                          }
                          onClick={() => {
                            submitReference();
                          }}
                        >
                          Publier la recommendation
                        </Button>
                      </Box>
                    </Flex>
                  </Flex>
                </GridItem>
              </Grid>
            </Box>
          </Box>
        </Flex>
      </Box>
    </>
  );
}

export default ExternalReco;
