import rocketImg from '../../images/rocketlaunch.svg'
import heroImg from '../../images/hero.svg'


export const homeObjOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: false,
    headline: 'Décrochez les missions qui vous passionnent',
    description:
      'Rejoignez la communauté Yalink, élargissez votre réseau. Augmentez votre revenu. Participez au "futur of work".',
    buttonLabel: 'En savoir plus',
    imgStart: '',
    img: rocketImg,
    alt: 'Rocket launch',
    start: ''
  };
  
  export const homeObjTwo = {
    primary: true,
    lightBg: true,
    lightTopLine: true,
    lightText: false,
    lightTextDesc: true,
    topLine: "Nous défendrons toujours vos intérêts ",
    headline:
      "C'est vous le patron",
    description:
      "Prenez votre carrière en main en accédant à des missions en Nouvelle-Aquitaine, chez le client ou en télétravail, en temps plein ou partagé. Échangez directement avec les clients et choisissez ce qui vous convient le mieux.",
    buttonLabel: 'Rejoindre la communauté 🚀',
    imgStart: 'start',
    img: heroImg,
    alt: 'Community',
    start: 'true'
  };
  