import React, { useState } from "react";

import {
  Box,
  Text,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import Mission from "../../../services/mission.service";
import { useParams } from "react-router-dom";

export default function RejectActivityReportModal({ isOpen, onClose }) {
  const history = useHistory();
  const toast = useToast();
  const [isRefusing, setIsRefusing] = useState(false);

  // Control the Textarea for the reason of the refusal
  let [rejectDescription, setRejectDescription] = React.useState("");

  let handleInputChange = (e) => {
    setRejectDescription(e.target.value);
  };

  // api post for the Textarea value
  const { missionId, year, month } = useParams();

  async function handleRefusal(missionId, year, month, rejectDescription) {
    if (rejectDescription === "") {
      toast({
        title: "Description requise",
        description:
          "Veuillez renseigner la raison du refus avant de compléter l'opération.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    } else {
      setIsRefusing(true);
      await Mission.requestChanges(missionId, year, month, rejectDescription)
        .then(() => {
          onClose();
          onRefusClientConfirmedOpen();
          setIsRefusing(false);
        })
        .catch({});
    }
  }
  //This is the second Modal for refusal confirmation
  const {
    isOpen: isRefusClientConfirmed,
    onOpen: onRefusClientConfirmedOpen,
    onClose: onRefusClientConfirmedClose,
  } = useDisclosure();

  const RefusClientConfirmedModal = ({ isOpen, onClose }) => {
    return (
      <>
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          isCentered
          closeOnOverlayClick={false}
          size="lg"
        >
          <ModalOverlay backdropFilter="blur(10px)" />
          <ModalContent>
            <ModalBody>
              <Flex justify="center" align="center" direction="column">
                <Box mb="3%" alignSelf="start">
                  <Text fontSize="18px" color="gray.700">
                    Vous venez de refuser le CRA de votre freelance.<br></br>{" "}
                    Une notification de refus lui sera envoyé dès maintenant
                    ainsi que les raisons de celui-ci.
                  </Text>
                </Box>
              </Flex>
            </ModalBody>

            <ModalFooter pt="0">
              <Flex w="100%">
                <Box>
                  <Button
                    bgColor="gray.100"
                    color="gray.700"
                    mr={3}
                    _hover={{ bgColor: "gray.200" }}
                    onClick={() => {
                      history.push("/freelance/missions/ongoing/" + missionId);
                    }}
                    size="xs"
                    borderRadius="0"
                    variant="outline"
                  >
                    Revenir à la vue détaillée
                  </Button>
                </Box>
              </Flex>{" "}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };

  return (
    <Box>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        closeOnOverlayClick={false}
        size="lg"
      >
        <ModalOverlay backdropFilter="blur(10px)" />
        <ModalContent>
          <ModalBody>
            <Flex justify="center" align="center" direction="column">
              <Box mb="3%" alignSelf="start">
                <Text fontSize="18px">
                  Vous vous appretez à refuser le CRA de votre freelance.
                </Text>
              </Box>
              <Box mb="3%" alignSelf="start">
                <Text fontSize="15px" color="gray.500">
                  Raison du refus :
                </Text>
              </Box>
              <Box mb="3%" alignSelf="start" w="80%">
                <Textarea
                  value={rejectDescription}
                  onChange={handleInputChange}
                  size="lg"
                  h="20vh"
                />
              </Box>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex w="100%">
              <Box mr="2vw">
                <Button
                  bgColor="gray.100"
                  color="gray.700"
                  mr={3}
                  _hover={{ bgColor: "gray.200" }}
                  onClick={() => {
                    onClose();
                  }}
                  borderRadius="0"
                  variant="outline"
                  disabled={isRefusing}
                >
                  Annuler
                </Button>
              </Box>
              <Box>
                <Button
                  bgColor="red.500"
                  mr={3}
                  onClick={() => {
                    handleRefusal(missionId, year, month, rejectDescription);
                  }}
                  borderRadius="0"
                  _hover={{ bgColor: "red.600" }}
                  isLoading={isRefusing}
                  disabled={isRefusing}
                >
                  <Text color="white">Refuser le CRA</Text>
                </Button>
              </Box>
            </Flex>{" "}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <RefusClientConfirmedModal
        isOpen={isRefusClientConfirmed}
        onClose={onRefusClientConfirmedClose}
      />
    </Box>
  );
}
