import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Flex,
  GridItem,
  Textarea,
  Input,
  useToast,
} from "@chakra-ui/react";
import { isEmailValid } from "../../libs/regexLib";
import References from "../../services/clientref.service";
import { querystring } from "../../libs/queryStringLib";

import { Bulb } from "@styled-icons/ionicons-outline/Bulb";
function RecoModal() {
  const [freelanceId] = useState(
    querystring("id", window.location.href)
      ? querystring("id", window.location.href)
      : null
  );
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const defaultRequest = {
    firstName: "",
    lastName: "",
    email: "",
    message:
      "Bonjour,\n\n J’ai créé mon profil sur Yalink, l’agence de\n freelancing spécialisé sur les métiers de\n l’industrie et de la construction et qui\n permet aux entreprises de trouver des\n experts freelances en moins de 48h.\n\n Je vous invite à me laisser une\n recommandation qui contribuera à\n renforcer mon profil auprès de potentiels\n clients.\n\n Merci d’avance !",
  };
  const [referenceRequest, setReferenceRequest] = useState(defaultRequest);

  // isMobile responsive useState and useEffect

  const [isMobile, setIsMobile] = useState(false);

  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    applyResponsive();
  }, []);

  async function submitReferenceRequest() {
    await References.requestRef(referenceRequest, freelanceId).then(() => {
      toast({
        position: "top",
        title: "Votre demande de recommendation a été envoyée !",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
      onClose();
      setReferenceRequest(defaultRequest);
    });
  }

  window.addEventListener("resize", applyResponsive);
  return (
    <>
      {/* In this modal we are going to store the input value in the useState */}
      <Button onClick={onOpen}>Demander une recommendation</Button>
      <Modal isOpen={isOpen} onClose={onClose} size={isMobile ? "xs" : "3xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="yalink.green" bgColor="yalink.green">
            <Flex justify="center">
              <Text fontSize="22px" fontWeight="bold" color="white">
                Demander une recommendation
              </Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton color="white" mt="1%" />
          <ModalBody p={10}>
            <Box>
              <Box bgColor="yalink.grey" borderRadius="5" p="3" mb="5%">
                <Grid templateColumns="0.1fr 1fr" gap="10" alignItems="center">
                  <GridItem ml="50%">
                    <Bulb size="25px" />
                  </GridItem>
                  <GridItem>
                    <Text fontSize="15px">
                      Nous n'utilisons pas ces emails à des fins commerciales.
                      <br />
                      Nous conservons ces informations uniquement pour
                      <br /> garantir l’authenticité de ces recommandations.
                    </Text>
                  </GridItem>
                </Grid>
              </Box>
              <Box mb="3%">
                <Text fontSize="17px" fontWeight="semi-bold">
                  Renseignez les informations ci-dessous pour demander à un de
                  vos anciens manager une recommendation pour renforcer votre
                  profil.
                </Text>
              </Box>
            </Box>

            <Grid templateRows="60px 60px 60px 3fr">
              <GridItem mb="5%">
                <Grid
                  templateColumns={isMobile ? "0.5fr 1fr" : "0.3fr 1fr"}
                  alignItems="center"
                >
                  <GridItem>
                    <Text fontSize="16px">Prénom :</Text>
                  </GridItem>
                  <GridItem>
                    <Input
                      size="md"
                      value={referenceRequest.firstName}
                      isInvalid={referenceRequest.firstName === ""}
                      onChange={(e) => {
                        let tempRef = referenceRequest;
                        tempRef.firstName = e.target.value;
                        setReferenceRequest({ ...tempRef });
                      }}
                    ></Input>
                  </GridItem>
                </Grid>
              </GridItem>
              <GridItem mb="5%">
                <Grid
                  templateColumns={isMobile ? "0.5fr 1fr" : "0.3fr 1fr"}
                  alignItems="center"
                >
                  <GridItem>
                    <Text fontSize="16px">Nom :</Text>
                  </GridItem>
                  <GridItem>
                    <Input
                      size="md"
                      value={referenceRequest.lastName}
                      isInvalid={referenceRequest.lastName === ""}
                      onChange={(e) => {
                        let tempRef = referenceRequest;
                        tempRef.lastName = e.target.value;
                        setReferenceRequest({ ...tempRef });
                      }}
                    ></Input>
                  </GridItem>
                </Grid>
              </GridItem>
              <GridItem mb="5%">
                <Grid
                  templateColumns={isMobile ? "0.5fr 1fr" : "0.3fr 1fr"}
                  alignItems="center"
                >
                  <GridItem>
                    <Text fontSize="16px">Adresse e-mail :</Text>
                  </GridItem>
                  <GridItem>
                    <Input
                      size="md"
                      isInvalid={!isEmailValid(referenceRequest.email)}
                      value={referenceRequest.email}
                      onChange={(e) => {
                        let tempRef = referenceRequest;
                        tempRef.email = e.target.value;
                        setReferenceRequest({ ...tempRef });
                      }}
                    ></Input>
                  </GridItem>
                </Grid>
              </GridItem>
              <GridItem mb="5%">
                <Grid templateColumns={isMobile ? "0.5fr 1fr" : "0.3fr 1fr"}>
                  <GridItem>
                    <Text fontSize="16px">Message:</Text>
                  </GridItem>
                  <GridItem>
                    <Textarea
                      h="330px"
                      value={referenceRequest.message}
                      resize="none"
                      isInvalid={referenceRequest.message === ""}
                      onChange={(e) => {
                        let tempRef = referenceRequest;
                        tempRef.message = e.target.value;
                        setReferenceRequest({ ...tempRef });
                      }}
                    ></Textarea>
                  </GridItem>
                </Grid>
              </GridItem>
            </Grid>
            <Flex justify="center" w="100%">
              <Flex
                justify="space-between"
                w={isMobile ? null : "50%"}
                gap="20px"
                mt={isMobile && "10%"}
              >
                <Box>
                  <Button
                    _hover={{ bgColor: "#003B36" }}
                    bgColor="yalink.green"
                    color="white"
                    fontSize={isMobile ? "14px" : "18px"}
                    disabled={
                      (referenceRequest.firstName === "") |
                      (referenceRequest.lastName === "") |
                      !isEmailValid(referenceRequest.email) |
                      (referenceRequest.message === "")
                    }
                    onClick={() => {
                      submitReferenceRequest();
                    }}
                  >
                    Envoyer la demande
                  </Button>
                </Box>
                <Box>
                  <Button
                    onClick={onClose}
                    fontSize={isMobile ? "14px" : "18px"}
                  >
                    Annuler
                  </Button>
                </Box>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default RecoModal;
