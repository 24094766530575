import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FreelancePreferencesForm, LoadingComponent } from "../../components";
import {
  Center,
  Box,
  Avatar,
  Heading,
  useToast,
  Grid,
  GridItem,
  Text,
  Button,
  SkeletonCircle,
  SkeletonText,
  SimpleGrid,
  Flex,
  Stack,
  HStack,
  Image,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Link,
  ModalFooter,
  Divider,
  Badge,
} from "@chakra-ui/react";
import { MdBuild } from "react-icons/md";
import { MdArrowRight } from "react-icons/md";
import User from "../../services/user.service";
import Newsletter from "../../services/newsletter.service";
import Blog from "../../services/blog.service";
import linkedinImg from "../../images/public_profile_linkedin.png";
import { useAppContext } from "../../libs/contextLib";
import ModalAvailability from "../../components/Modals/ModalAvailability";
import { querystring } from "../../libs/queryStringLib";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Cooptation from "../../images/Cooptation.svg";
import Partenaires from "../../images/Partenaires.svg";
import BlogImage from "../../images/Blog.svg";
import Abby from "../../images/Abby.svg";
import Industry from "../../images/Mission.png";
import RondJaune from "../../images/yellow-circle.png";
import { Stars } from "@styled-icons/bootstrap/Stars";
import { Dollar } from "@styled-icons/boxicons-regular/Dollar";
import { House } from "@styled-icons/material/House";
import { News } from "@styled-icons/fluentui-system-regular/News";
import { Book } from "@styled-icons/bootstrap/Book";
function FreelanceHome() {
  // Disclosure for modal indicating that boarding pass needs to be filled
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAvailabilityOpen,
    onOpen: onAvailabilityOpen,
    onClose: onAvailabilityClose,
  } = useDisclosure();
  const [alertBoardingPass, setalertBoardingPass] = useState(false);
  // Disclosure for modal indicating that linkedin profile must be public for
  // the import to work
  const [alertLinkedin, setalertLinkedin] = useState(false);
  const [linkedinError, setlinkedinError] = useState(false);

  const { userHasAuthenticated } = useAppContext();
  const history = useHistory();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [freelance, setFreelance] = useState(null);
  const [hasFreelancePreferences, setHasFreelancePreferences] = useState(false);
  const [freelanceDetails, setFreelanceDetails] = useState({
    picture: "",
    headline: "",
  });
  const [freelancePreferences, setFreelancePreferences] = useState({
    availability: "",
    tjm: "",
    city: "",
    workplace: "",
    area: "",
    length: "",
    daysperweek: "",
  });
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const [newsletters, setNewsletters] = useState(null);
  const [blogs, setBlogs] = useState(null);
  // Mobile responsive grid
  const [isMobile, setIsMobile] = useState(false);

  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    applyResponsive();
  }, []);

  window.addEventListener("resize", applyResponsive);

  useEffect(() => {
    async function getNewsletters() {
      await Newsletter.getNewsletters()
        .then((response) => {
          setNewsletters(response.data.newsletters);
        })
        .catch((error) => {});
    }
    async function getBlogs() {
      await Blog.getBlogs()
        .then((response) => {
          setBlogs(response.data.blogs);
        })
        .catch((error) => {});
    }
    async function completeProfile() {
      await User.populateProfileFromLinkedin()
        .then(() => {
          User.getFreelance()
            .then((response) => {
              setFreelanceDetails({
                picture: response.data.details.profile_picture,
                headline: response.data.details.headline,
              });
              setIsProfileComplete(true);
            })
            .catch(() => {});
        })
        .catch((error) => {
          setlinkedinError(true);
        });
    }

    async function onLoad() {
      const emailVerificationToken = querystring("token", window.location.href);
      // Verify email if present in URL
      if (emailVerificationToken) {
        await User.verifyEmail({
          token: emailVerificationToken,
        })
          .then(() => {
            toast({
              position: "top",
              title: "Ton email est maintenant verifié",
              status: "success",
              duration: 12000,
              isClosable: true,
            });
          })
          .catch(() => {});
      }
      await User.getFreelance()
        .then((response) => {
          setFreelance({
            fname: response.data.first_name,
            lname: response.data.last_name,
            profile_complete: response.data.profile_complete,
          });
          if (response.data.preferences) {
            setFreelancePreferences({
              availability: response.data.preferences.availability,
              tjm: response.data.preferences.tarification,
              city: response.data.preferences.city,
              workplace: response.data.preferences.remote_friendly,
              area: response.data.preferences.mobility,
              length: response.data.preferences.duration,
              daysperweek: response.data.preferences.fulltime,
            });
            if (
              // Check if at least two fields are not null
              response.data.preferences.availability &&
              response.data.preferences.tarification
            ) {
              setHasFreelancePreferences(true);
            }
          }
          setIsLoading(false);
          // This means linkedin data could be properly fetched
          if (response.data.profile_complete) {
            setFreelanceDetails({
              picture: response.data.details.profile_picture,
              headline: response.data.details.headline,
            });
            setIsProfileComplete(true);
          } else {
            completeProfile();
          }
        })
        .catch((error) => {
          // The user logs in without having finalized the freelance account.
          // Redirect to the register process
          if (error.response.status === 404) {
            history.push("/freelance/about/you");
          } else {
            userHasAuthenticated(false);
          }
        });
    }
    if (isLoading) {
      onLoad();
    } else {
      if (!alertBoardingPass && !isOpen && !hasFreelancePreferences) {
        // In case the preferences have not been filled and it's the load of the page, notify
        // user to fill in preferences
        setalertBoardingPass(true);
        onOpen();
      } else if (
        !alertLinkedin &&
        !isOpen &&
        linkedinError &&
        hasFreelancePreferences
      ) {
        // In case we got an error fetching the public profile,
        // Give information to the user about how to make his profile public
        // We are waiting for the user to be able to enter preferences before alerting him about linkedin
        setalertLinkedin(true);
        onOpen();
      }
    }
    if (!newsletters) {
      getNewsletters();
    }
    if (!blogs) {
      getBlogs();
    }
  }, [
    toast,
    isLoading,
    history,
    isProfileComplete,
    userHasAuthenticated,
    onOpen,
    isOpen,
    hasFreelancePreferences,
    alertBoardingPass,
    alertLinkedin,
    linkedinError,
    newsletters,
    blogs,
  ]);

  function handleModifyProfile() {
    history.push("/freelance/profile");
  }

  function onRefresh() {
    window.location.reload();
  }

  function renderBoardingPass() {
    return (
      <Box
        padding="5"
        borderWidth={1}
        borderRadius={5}
        boxShadow="md"
        bg="white"
      >
        <Grid gridTemplateColumns="1fr">
          <GridItem>
            <Heading mb="20px" size="md" color="gray.600">
              Boarding pass
            </Heading>
          </GridItem>
          <GridItem>
            <FreelancePreferencesForm
              freelancePreferences={freelancePreferences}
              setFreelancePref={setFreelancePreferences}
              hasFreelancePref={hasFreelancePreferences}
              setHasFreelancePref={setHasFreelancePreferences}
              openAvail={onAvailabilityOpen}
            />
          </GridItem>
        </Grid>
      </Box>
    );
  }

  function renderBoardingPassMobile() {
    return (
      <Box
        padding="5"
        borderWidth={1}
        borderRadius={5}
        boxShadow="md"
        bg="white"
        height="1000px"
        w="null"
        mb="5%"
      >
        <Heading mb="20px" size="md" color="gray.600">
          Boarding pass
        </Heading>
        <FreelancePreferencesForm
          freelancePreferences={freelancePreferences}
          setFreelancePref={setFreelancePreferences}
          hasFreelancePref={hasFreelancePreferences}
          setHasFreelancePref={setHasFreelancePreferences}
          openAvail={onAvailabilityOpen}
        />
      </Box>
    );
  }

  function renderFreelanceSummary() {
    return (
      <>
        <Box
          boxSize="100%"
          maxWidth="100%"
          height={isMobile ? "370px" : "367px"}
        >
          <Box mb="20px" width="100%" align={isMobile ? "center" : null}>
            <Avatar
              name={freelance.fname + " " + freelance.lname}
              src={freelanceDetails.picture}
              size="2xl"
              mb="10px"
              showBorder="true"
            />
            <Box width="100">
              <Box
                fontWeight="semibold"
                letterSpacing="wide"
                fontSize="xl"
                textTransform="uppercase"
                align={isMobile ? "center" : null}
                pb={isMobile ? "13px" : null}
                mt={isMobile ? "10px" : null}
                mb="5%"
              >
                {freelance.fname + " " + freelance.lname}
              </Box>
              <Box
                mt="1"
                fontWeight="semibold"
                as="h4"
                lineHeight="tight"
                isTruncated
                mb={isMobile ? "13px" : "2%"}
                mr={isMobile && "10px"}
              >
                <Stars size="20px" color="#F8C708" />{" "}
                {freelanceDetails.headline}
              </Box>
              <HStack width="100%" pb={isMobile ? "13px" : null}>
                <Box mb="2%">
                  <Dollar size="25px" />{" "}
                  {freelancePreferences.tjm !== ""
                    ? freelancePreferences.tjm
                    : "-"}{" "}
                  <Box as="span" color="gray.600" fontSize="sm">
                    €/jour (tarif indicatif)
                  </Box>
                </Box>
              </HStack>
              <Box align={isMobile ? "left" : null}>
                <House size="25px" />
                Disponible sur{" "}
                {freelancePreferences.city !== ""
                  ? freelancePreferences.city
                  : "-"}{" "}
              </Box>
            </Box>
          </Box>
          <HStack pl={isMobile ? "110px" : null}>
            <Button
              mt="5px"
              mb={isMobile && "10px"}
              leftIcon={<MdBuild />}
              color="white"
              bg="yalink.green"
              _hover={{ bg: "yalink.yellow" }}
              onClick={handleModifyProfile}
              disabled={!hasFreelancePreferences}
            >
              Modifier
            </Button>
          </HStack>
        </Box>
      </>
    );
  }

  function renderWhatIsNewTodayMobile() {
    return (
      <>
        <Box
          padding="5"
          pb="0"
          borderWidth={1}
          borderRadius={5}
          boxShadow="md"
          bg="white"
          mt={isMobile ? "10%" : "5%"}
          mb={isMobile ? "5%" : null}
          pl={10}
        >
          <Heading mb="20px" fontSize="28px" color="gray.600" align="left">
            <News size="30px" /> L'actu Yalink
          </Heading>
          <Carousel
            autoPlay={true}
            autoFocus={true}
            interval={7500}
            showStatus={false}
            infiniteLoop
            showArrows={false}
            selectedItem={0}
            swipeable={true}
            emulateTouch={true}
          >
            <div>
              <Box>
                <Text
                  align="left"
                  fontSize="21px"
                  fontWeight="semibold"
                  mb="1%"
                >
                  {" "}
                  Voici les news de la semaine chez Yalink !
                </Text>
                <Text
                  color="#003B36"
                  fontWeight="bold"
                  align="left"
                  fontSize="19px"
                >
                  Toute l'actualité sur nos événements se trouve ici :
                </Text>
              </Box>
            </div>
            {newsletters && newsletters.length !== 0 ? (
              newsletters.map((newsletter) => (
                <>
                  <div id={newsletter.title}>
                    <Heading
                      mb="2%"
                      size="md"
                      color="#003B36"
                      align={isMobile ? "center" : "left"}
                    >
                      {newsletter.title}
                    </Heading>
                    <Text
                      align={isMobile ? "center" : "left"}
                      mt={isMobile ? "20px" : "10px"}
                      mb={isMobile ? "5%" : "2%"}
                      fontWeight="semibold"
                    >
                      {newsletter.content.split("\n").map((line) => (
                        <Text
                          align={isMobile ? "center" : null}
                          // mt={isMobile ? "20px" : "10px"}
                        >
                          {line}
                        </Text>
                      ))}
                    </Text>
                  </div>
                </>
              ))
            ) : (
              <>
                <Text
                  align={isMobile ? "center" : null}
                  mt={isMobile ? "20px" : "10px"}
                >
                  Tu cherches des partenaires ?
                </Text>
                <Text
                  mt={isMobile ? "20px" : "10px"}
                  ml={isMobile ? "12px" : null}
                  align={isMobile ? "left" : null}
                >
                  Nous avons maintenant un partenariat avec CONDILLAC (cabinet
                  comptable) et SHINE (banque en ligne) pour notre communauté{" "}
                  <Stars size="40px" color="#F8C708" />
                </Text>
                <Text
                  mt={isMobile ? "20px" : "10px"}
                  ml={isMobile ? "12px" : null}
                  align={isMobile ? "left" : null}
                >
                  Retrouve nous à la cité numérique de Bordeaux, on sera ravi de
                  te rencontrer pour de vrai!
                </Text>
              </>
            )}
          </Carousel>
        </Box>
      </>
    );
  }

  return !isLoading && freelance ? (
    <>
      {/* <Image alt="Banner" src={bannerShuttleImg} /> */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>🎉 Nous-y sommes presque!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {alertBoardingPass ? (
              <Text mb="40px">
                Complète ton Boarding Pass afin de pouvoir accéder à ton profil
              </Text>
            ) : null}
            {alertLinkedin ? (
              <Box>
                <Text>
                  Afin de pouvoir récupérer les informations de ton profil
                  Linkedin, il t'est possible de le rendre public le temps de
                  l'import! Rends toi sur{" "}
                  <Link
                    href="https://www.linkedin.com/public-profile/settings?trk=d_flagship3_profile_self_view_public_profile"
                    isExternal
                    color="teal.500"
                  >
                    {" "}
                    cette adresse{" "}
                  </Link>
                  et configure ton profil comme ci-dessous.{" "}
                </Text>
                <Image alt="public_profile_linkedin" src={linkedinImg} />
                <Text>
                  Une fois ce changement fait, rafraîchis l'application!
                </Text>
                <ModalFooter>
                  <Button
                    color="white"
                    bg="yalink.green"
                    _hover={{ bg: "yalink.yellow" }}
                    mr={3}
                    onClick={onRefresh}
                  >
                    Rafraîchir
                  </Button>
                </ModalFooter>
              </Box>
            ) : null}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        blockScrollOnMount={false}
        isOpen={isAvailabilityOpen}
        onClose={onAvailabilityClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalAvailability
            onClose={onAvailabilityClose}
            freelancePreferences={freelancePreferences}
            setFreelancePreferences={setFreelancePreferences}
          />
        </ModalContent>
      </Modal>

      <Center>
        <Box
          w={isMobile ? "100%" : "80%"}
          maxWidth={isMobile ? "100%" : "80%"}
          mt="100px"
          padding="5"
        >
          <SimpleGrid columns={isMobile ? 1 : 2} gap={isMobile ? 7 : 3} mb="2%">
            <Box>
              <Flex
                w="100%"
                padding="5"
                borderWidth={1}
                borderRadius={5}
                boxShadow="md"
                bg="white"
              >
                {!isProfileComplete ? (
                  <>
                    <Center>
                      <Stack width="100%">
                        <SkeletonCircle size="12" mb="20px" />
                        <SkeletonText
                          mt="4"
                          noOfLines={8}
                          spacing="4"
                          width="800%"
                        />
                      </Stack>
                    </Center>
                  </>
                ) : (
                  <>{renderFreelanceSummary()}</>
                )}
              </Flex>
            </Box>
            <Box
              padding="5"
              w="100%"
              bgColor="yalink.green"
              color="white"
              borderWidth={1}
              borderRadius={5}
              boxShadow="md"
              h="410px"
              mb="5%"
            >
              <Grid
                templateColumns="repeat(2, 1fr)"
                templateRows="repeat(2, 0.3fr)"
              >
                <GridItem colStart={2} rowStart={1} align="right">
                  <Image src={RondJaune}></Image>
                </GridItem>
                <GridItem rowStart={2} rowSpan={1} colSpan={1}>
                  <Box>
                    <Text
                      fontWeight="bold"
                      fontSize="18px"
                      mb={isMobile ? "10%" : "15%"}
                    >
                      Trouvez votre prochaine mission
                    </Text>
                    <Text fontSize="15px" mb={isMobile ? "10%" : "10%"}>
                      Découvrez et trouvez votre prochaine mission proposée par
                      l’équipe Yalink
                    </Text>
                    <br></br>
                    <Button
                      bg="white"
                      w={isMobile ? "105%" : null}
                      color="yalink.green"
                      rightIcon={<MdArrowRight />}
                      fontWeight="semi-bold"
                      _hover={{ bg: "yalink.yellow" }}
                      mb="5%"
                      fontSize={isMobile ? "15px" : null}
                      onClick={() => {
                        history.push("/freelance/missions");
                      }}
                    >
                      Trouvez une mission
                    </Button>
                  </Box>
                </GridItem>
                <GridItem rowStart={2} colStart={2}>
                  <Flex justify="center" mt="10%">
                    <Box>
                      <Image src={Industry}></Image>
                    </Box>
                  </Flex>
                </GridItem>
              </Grid>
            </Box>
          </SimpleGrid>
          {isMobile ? (
            renderBoardingPassMobile()
          ) : (
            <Grid
              templateRows="1fr"
              templateColumns="repeat(5, 1fr)"
              gap={7}
              mb="5%"
            >
              <GridItem colSpan={5} rowSpan={1}>
                {renderBoardingPass()}
              </GridItem>
            </Grid>
          )}
          {renderWhatIsNewTodayMobile()}
          <Box
            padding={5}
            pr={isMobile ? null : "20px"}
            pl={isMobile ? null : "20px"}
            borderWidth={1}
            borderRadius={5}
            boxShadow="md"
            bg="white"
            mt={isMobile ? "10%" : "5%"}
            mb={isMobile ? "20%" : "5%"}
          >
            <Heading
              mb="20px"
              fontSize="28px"
              color="gray.600"
              align="left"
              pl={5}
            >
              <Book size="25px" /> Découvrir les guides Yalink
            </Heading>

            <Grid
              templateColumns={isMobile ? "null" : "repeat(3, 1fr)"}
              gap={6}
            >
              {blogs &&
                blogs.map((blog) => (
                  // It is recommended to put a link on a new tab (whith the isExternal prop) when it is external to the App !
                  <Link href={blog.link} isExternal>
                    <Box
                      borderWidth="1px"
                      borderRadius="lg"
                      overflow="hidden"
                      mb="20px"
                    >
                      <GridItem w="100%">
                        <Image src={blog.picture} alt="image blog" />

                        <Box p="6">
                          <Box display="flex" alignItems="baseline">
                            <Badge
                              borderRadius="full"
                              px="2"
                              colorScheme="teal"
                            >
                              {blog.category}
                            </Badge>
                          </Box>

                          <Box
                            mt="5"
                            fontWeight="semibold"
                            as="h4"
                            lineHeight="tight"
                            isTruncated
                          >
                            {blog.title}
                          </Box>
                        </Box>
                      </GridItem>
                    </Box>
                  </Link>
                ))}
            </Grid>
            <Center>
              <Link
                href="https://www.yalink.fr/category/blog/guides/"
                isExternal
                style={{ textDecoration: "none" }}
              >
                <Button
                  bg="yalink.green"
                  color="white"
                  rightIcon={<MdArrowRight />}
                  fontWeight="semi-bold"
                  _hover={{ bg: "yalink.yellow" }}
                  mt="10px"
                  mb="20px"
                >
                  Voir plus d'articles
                </Button>
              </Link>
            </Center>
          </Box>

          <Box
            padding={5}
            pr={isMobile ? null : "113px"}
            pl={isMobile ? null : "113px"}
            borderWidth={1}
            borderRadius={5}
            boxShadow="md"
            bg="white"
            mt={isMobile ? "10%" : "5%"}
            mb={isMobile ? "20%" : "5%"}
          >
            <br></br>
            <Box className="cooptation">
              <SimpleGrid
                columns={isMobile ? null : 2}
                rows={isMobile ? 2 : null}
              >
                <Box>
                  <Text
                    color="#003B36"
                    fontWeight="bold"
                    fontSize="24px"
                    mb="30px"
                  >
                    Cooptation
                  </Text>
                  <Text
                    color="rgba(0, 59, 54, 0.56)"
                    fontWeight="bold"
                    fontSize="18px"
                  >
                    Ton réseau est composé de freelances dans l’industrie / BTP
                    ?
                  </Text>
                  <Text
                    color="#003B36"
                    fontWeight="bold"
                    fontSize="18px"
                    mb="55px"
                  >
                    Coopte un ami et repars avec une commission (€) sur sa
                    première mission.
                  </Text>
                  <Box>
                    <Link
                      href="https://www.yalink.fr/cooptation/"
                      isExternal
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        bg="yalink.green"
                        color="white"
                        rightIcon={<MdArrowRight />}
                        fontWeight="semi-bold"
                        _hover={{ bg: "yalink.yellow" }}
                        mb="5%"
                      >
                        Je coopte un ami freelance
                      </Button>
                    </Link>
                  </Box>
                </Box>
                <Box ml={isMobile ? null : "50%"} mt={isMobile ? "5%" : null}>
                  <Image
                    src={Cooptation}
                    h={isMobile ? "140px" : "200px"}
                  ></Image>
                </Box>
              </SimpleGrid>
            </Box>
            <br></br>
            <Divider />
            <br></br>
            <Box className="offres">
              <SimpleGrid
                columns={isMobile ? null : 2}
                rows={isMobile ? 2 : null}
              >
                <Box>
                  <Text
                    color="#003B36"
                    fontWeight="bold"
                    fontSize="24px"
                    mb="30px"
                    mt="5%"
                  >
                    Des offres et tarifs avantageux
                  </Text>
                  <Text
                    color="rgba(0, 59, 54, 0.56)"
                    fontWeight="bold"
                    fontSize="18px"
                    mb="55px"
                  >
                    En appartenant à la première agence freelance de France,
                    vous bénéficiez de nombreux avantages (banque, assurance...)
                    pour vous faciliter votre quotidien de freelance.
                  </Text>
                  <Link
                    href="https://www.yalink.fr/nos-partenaires/"
                    isExternal
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      bg="yalink.green"
                      color="white"
                      rightIcon={<MdArrowRight />}
                      fontWeight="semi-bold"
                      _hover={{ bg: "yalink.yellow" }}
                      mb="5%"
                    >
                      Voir nos partenaires
                    </Button>
                  </Link>
                </Box>
                <Box ml={isMobile ? null : "50%"} mt={isMobile ? "5%" : null}>
                  <Image
                    src={Partenaires}
                    h={isMobile ? "140px" : "200px"}
                  ></Image>
                </Box>
              </SimpleGrid>
            </Box>
            <br></br>
            <Divider />
            <br></br>
            <Box className="blog">
              <SimpleGrid
                columns={isMobile ? null : 2}
                rows={isMobile ? 2 : null}
              >
                <Box>
                  <Text
                    color="#003B36"
                    fontWeight="bold"
                    fontSize="24px"
                    mb="30px"
                    mt="5%"
                  >
                    Blog
                  </Text>
                  <Text
                    color="rgba(0, 59, 54, 0.56)"
                    fontWeight="bold"
                    fontSize="18px"
                    mb="55px"
                  >
                    Consulte nos nouveaux articles et renseigne toi sur nos
                    meilleures astuces pour développer ton nombre de missions en
                    freelance.
                  </Text>
                  <Link
                    href="https://www.yalink.fr/blog/"
                    isExternal
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      bg="yalink.green"
                      color="white"
                      rightIcon={<MdArrowRight />}
                      fontWeight="semi-bold"
                      _hover={{ bg: "yalink.yellow" }}
                      mb="5%"
                    >
                      Consulter notre blog
                    </Button>
                  </Link>
                </Box>
                <Box ml={isMobile ? null : "50%"} mt={isMobile ? "5%" : null}>
                  <Image
                    src={BlogImage}
                    h={isMobile ? "140px" : "200px"}
                  ></Image>
                </Box>
              </SimpleGrid>
            </Box>
            <br></br>
            <Divider />
            <br></br>
            <Box className="abby">
              <SimpleGrid
                columns={isMobile ? null : 2}
                rows={isMobile ? 2 : null}
              >
                <Box>
                  <Text
                    color="#003B36"
                    fontWeight="bold"
                    fontSize="24px"
                    mb="30px"
                    mt="5%"
                  >
                    Démarrer son auto-entreprise avec Abby
                  </Text>
                  <Text
                    color="rgba(0, 59, 54, 0.56)"
                    fontWeight="bold"
                    fontSize="18px"
                    mb="55px"
                  >
                    Une seule plateforme gratuite qui vous permet la création et
                    la gestion de A à Z de votre auto-entreprise.
                  </Text>
                  <Link
                    href="https://yalink.app-abby.com/auth/login"
                    isExternal
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      bg="yalink.green"
                      color="white"
                      rightIcon={<MdArrowRight />}
                      fontWeight="semi-bold"
                      _hover={{ bg: "yalink.yellow" }}
                      mb="5%"
                    >
                      Démarrer avec ABBY
                    </Button>
                  </Link>
                </Box>
                <Box ml={isMobile ? null : "50%"} mt={isMobile ? "5%" : null}>
                  <Image src={Abby} h={isMobile ? "140px" : "200px"}></Image>
                </Box>
              </SimpleGrid>
            </Box>
          </Box>
        </Box>
      </Center>
    </>
  ) : (
    <LoadingComponent />
  );
}

export default FreelanceHome;
