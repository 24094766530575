import React, { useState } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useAppContext } from "../libs/contextLib.js";

import { querystring } from "../libs/queryStringLib";

export default function AuthenticatedRoute({ children, ...rest }) {
  const { pathname, search } = useLocation();
  const { isAuthenticated } = useAppContext();
  const [token] = useState(
    querystring("token", window.location.href)
      ? querystring("token", window.location.href)
      : null
  );
  return (
    <Route {...rest}>
      {isAuthenticated || token ? (
        children
      ) : (
        <Redirect to={`/login?redirect=${pathname}${search}`} />
      )}
    </Route>
  );
}
