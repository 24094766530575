import React from "react";
import { Box, Divider, Flex } from "@chakra-ui/react";
import { NewClientReference } from "../../components";

function ClientReferenceCard(props) {
  return (
    <Box>
      <Flex>
        <Box mr="20px">
          <Box fontWeight="semibold">
            {props.reference.referenceName === "first_contact"
              ? "Premier contact"
              : props.reference.referenceName === "client"
              ? "Retour client"
              : props.reference.referenceName}
          </Box>
          <Box>
            📈 Evaluation:{" "}
            {props.reference.grade === "excellent"
              ? "Excellent"
              : props.reference.grade === "good"
              ? "🟢 Bon"
              : props.reference.grade === "average"
              ? "Moyen"
              : props.reference.grade === "bad"
              ? "🔴 Pas bon"
              : "N/A"}
          </Box>
          {props.reference.description ? (
            <Box>🔎 Description: {props.reference.description}</Box>
          ) : null}
        </Box>
        <NewClientReference
          freelance={props.freelance}
          reference={props.reference}
          setReferences={props.setReferences}
        />
      </Flex>
      <Divider mt="10px" mb="10px" width="100%" />
    </Box>
  );
}

export default ClientReferenceCard;
