//Internal functions for defining Report Status badge Colors
export function getColorScheme(value) {
  switch (value) {
    case "Validé":
      return "green";
    case "Edition en cours":
      return "gray";
    case "Modifs client demandées":
      return "red";
    case "En attente client":
      return "orange";
    case "Pas d'activité":
      return "green";
    case "À faire":
      return "gray";
    default:
      return "gray";
  }
}
export function getColor(value) {
  switch (value) {
    case "Validé":
      return "green.700";
    case "Edition en cours":
      return "gray.700";
    case "Modifs client demandées":
      return "red.700";
    case "En attente client":
      return "orange.700";
    case "À faire":
      return "gray.700";
    case "Pas d'activité":
      return "green.700";
    default:
      return "gray.700";
  }
}
