 import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Grid,
  GridItem,
  Flex,
  Switch,
  Button,
  useToast,
} from "@chakra-ui/react";
import NotificationService from "../../services/notification.service";

export const ChangeNotifications: React.FC = () => {
  const toast = useToast();
  interface INotificationPreferences {
    receive_new_opportunities?: boolean;
    receive_boarding_pass_updates?: boolean;
    receive_document_updates?: boolean;
    receive_newsletter_updates?: boolean;
  }
  const [isLoading, setIsLoading] = useState(true);
  const [isSavingNotificationPreferences, setIsSavingNotificationPreferences] = useState(false);
  const [notificationPreferences, setNotificationPreferences] = useState<INotificationPreferences | undefined>(undefined);
  const [isMobile, setIsMobile] = useState(false);

  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    async function onLoad() {
      await NotificationService.getNotificationPreferences()
      .then((response) => {
        setNotificationPreferences(response.data.notification_preferences);
      })
      .catch((error) => {});
    }
    applyResponsive();
    if(isLoading) {
      onLoad();
      setIsLoading(false);
    }
  }, [isLoading, setNotificationPreferences]);

  async function handleNotificationPreferencesUpdate() {
    setIsSavingNotificationPreferences(true);
    await NotificationService.updateNotificationPreferences(notificationPreferences)
    .then(() => {
      toast({
        position: "top",
        title: "Préférences mises à jour",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      setIsSavingNotificationPreferences(false);
    })
    .catch(() => {});
  }

  return !isLoading ? (
    <>
      {isMobile ? (
        <Box mt="10%">
          <Flex justify="center">
            <Box
              w="90%"
              padding="5"
              borderWidth={1}
              borderRadius={5}
              boxShadow="md"
              mb="80px"
              mt="80px"
              bg="white"
            >
              <Text fontWeight="bold" fontSize="20px">
                Notifications
              </Text>
              <Box p={3}>
                <Box mb="3%">
                  <Flex justify="space-between" mb="15%">
                    <Box>
                      <Grid templateRows="0.4fr 1fr" gap="3px" w="90%">
                        <GridItem>
                          <Text fontWeight="semibold" fontSize="16px">
                            Nouvelles missions
                          </Text>
                        </GridItem>
                        <GridItem>
                          <Text fontSize="13px">
                            Recevoir dans ma boite à notifications les nouvelles
                            missions qui correspondent à mon profil
                          </Text>
                        </GridItem>
                      </Grid>
                    </Box>
                    <Box mt="10%">
                      <Switch isChecked={notificationPreferences?.receive_new_opportunities} onChange={() => {
                        let tempNotificationPreferences: INotificationPreferences | undefined = notificationPreferences;
                        tempNotificationPreferences!.receive_new_opportunities = !tempNotificationPreferences!.receive_new_opportunities;
                        setNotificationPreferences({...tempNotificationPreferences});
                      }} />
                    </Box>
                  </Flex>
                </Box>
                <Box mb="3%">
                  <Flex justify="space-between" mb="15%">
                    <Box>
                      <Grid templateRows="0.4fr 1fr" gap="3px" w="95%">
                        <GridItem>
                          <Text fontWeight="semibold" fontSize="16px">
                            Mise à jour de votre Boarding Pass
                          </Text>
                        </GridItem>
                        <GridItem>
                          <Text fontSize="13px">
                            Me tenir au courant quand je dois mettre à jour mon
                            Boarding Pass pour mettre à jour ma disponibilité
                          </Text>
                        </GridItem>
                      </Grid>
                    </Box>
                    <Box mt="10%">
                    <Switch isChecked={notificationPreferences?.receive_boarding_pass_updates} onChange={() => {
                        let tempNotificationPreferences: INotificationPreferences | undefined = notificationPreferences;
                        tempNotificationPreferences!.receive_boarding_pass_updates = !tempNotificationPreferences!.receive_boarding_pass_updates;
                        setNotificationPreferences({...tempNotificationPreferences});
                      }} />
                    </Box>
                  </Flex>
                </Box>
                <Box mb="3%">
                  <Flex justify="space-between" mb="15%">
                    <Box>
                      <Grid templateRows="0.4fr 1fr" gap="3px" w="90%">
                        <GridItem>
                          <Text fontWeight="semibold" fontSize="16px">
                            Mise à jour de vos documents
                          </Text>
                        </GridItem>
                        <GridItem>
                          <Text fontSize="13px">
                            Me tenir au courant quand je dois mettre à jour mes
                            documents pour renforcer mon profil
                          </Text>
                        </GridItem>
                      </Grid>
                    </Box>
                    <Box mt="10%">
                      <Switch 
                          isChecked={notificationPreferences?.receive_document_updates}
                          onChange={() => {
                            let tempNotificationPreferences: INotificationPreferences | undefined = notificationPreferences;
                            tempNotificationPreferences!.receive_document_updates = !tempNotificationPreferences!.receive_document_updates;
                            setNotificationPreferences({...tempNotificationPreferences});
                          }} />
                    </Box>
                  </Flex>
                </Box>
                <Box>
                  <Flex justify="space-between" mb="15%">
                    <Box>
                      <Grid templateRows="0.4fr 1fr" gap="3px" w="90%">
                        <GridItem>
                          <Text fontWeight="semibold" fontSize="16px">
                            Nouveautés sur le site
                          </Text>
                        </GridItem>
                        <GridItem>
                          <Text fontSize="13px">
                            Me tenir au courant sur les nouvelles
                            fonctionnalités disponibles sur le site de Yalink
                          </Text>
                        </GridItem>
                      </Grid>
                    </Box>
                    <Box mt="2%">
                      <Switch 
                          isChecked={notificationPreferences?.receive_newsletter_updates} 
                          onChange={() => {
                            let tempNotificationPreferences: INotificationPreferences | undefined = notificationPreferences;
                            tempNotificationPreferences!.receive_newsletter_updates = !tempNotificationPreferences!.receive_newsletter_updates;
                            setNotificationPreferences({...tempNotificationPreferences});
                          }}/>
                    </Box>
                  </Flex>
                </Box>
                <Box mt="5%">
                  <Button
                    w="100%"
                    borderRadius="0"
                    bgColor="#003B36"
                    color="white"
                    onClick={handleNotificationPreferencesUpdate}
                    isLoading={isSavingNotificationPreferences}
                    isDisabled={isSavingNotificationPreferences}
                  >
                    Enregistrer vos préférences
                  </Button>
                </Box>
              </Box>
            </Box>
          </Flex>
        </Box>
      ) : (
        <Box mt="10%">
          <Flex justify="center">
            <Box
              w="70%"
              padding="5"
              borderWidth={1}
              borderRadius={5}
              boxShadow="md"
              mb="20px"
              bg="white"
            >
              <Text fontWeight="bold" fontSize="20px">
                Notifications
              </Text>
              <Box p={5}>
                <Box mb="3%">
                  <Flex justify="space-between">
                    <Box>
                      <Grid templateRows="repeat(2, 1fr)" gap="3px">
                        <GridItem>
                          <Text fontWeight="semibold" fontSize="16px">
                            Nouvelles missions
                          </Text>
                        </GridItem>
                        <GridItem>
                          <Text>
                            Recevoir dans ma boite à notifications les nouvelles
                            missions qui correspondent à mon profil
                          </Text>
                        </GridItem>
                      </Grid>
                    </Box>
                    <Box mt="2%">
                      <Switch isChecked={notificationPreferences?.receive_new_opportunities} onChange={() => {
                        let tempNotificationPreferences: INotificationPreferences | undefined = notificationPreferences;
                        tempNotificationPreferences!.receive_new_opportunities = !tempNotificationPreferences!.receive_new_opportunities;
                        setNotificationPreferences({...tempNotificationPreferences});
                      }} />
                    </Box>
                  </Flex>
                </Box>
                <Box mb="3%">
                  <Flex justify="space-between">
                    <Box>
                      <Grid templateRows="repeat(2, 1fr)" gap="3px">
                        <GridItem>
                          <Text fontWeight="semibold" fontSize="16px">
                            Mise à jour de votre Boarding Pass
                          </Text>
                        </GridItem>
                        <GridItem>
                          <Text>
                            Me tenir au courant quand je dois mettre à jour mon
                            Boarding Pass pour mettre à jour ma disponibilité
                          </Text>
                        </GridItem>
                      </Grid>
                    </Box>
                    <Box mt="2%">
                      <Switch isChecked={notificationPreferences?.receive_boarding_pass_updates} onChange={() => {
                        let tempNotificationPreferences: INotificationPreferences | undefined = notificationPreferences;
                        tempNotificationPreferences!.receive_boarding_pass_updates = !tempNotificationPreferences!.receive_boarding_pass_updates;
                        setNotificationPreferences({...tempNotificationPreferences});
                      }} />
                    </Box>
                  </Flex>
                </Box>
                <Box mb="3%">
                  <Flex justify="space-between">
                    <Box>
                      <Grid templateRows="repeat(2, 1fr)" gap="3px">
                        <GridItem>
                          <Text fontWeight="semibold" fontSize="16px">
                            Mise à jour de vos documents
                          </Text>
                        </GridItem>
                        <GridItem>
                          <Text>
                            Me tenir au courant quand je dois mettre à jour mes
                            documents pour renforcer mon profil
                          </Text>
                        </GridItem>
                      </Grid>
                    </Box>
                    <Box mt="2%">
                      <Switch 
                        isChecked={notificationPreferences?.receive_document_updates}
                        onChange={() => {
                          let tempNotificationPreferences: INotificationPreferences | undefined = notificationPreferences;
                          tempNotificationPreferences!.receive_document_updates = !tempNotificationPreferences!.receive_document_updates;
                          setNotificationPreferences({...tempNotificationPreferences});
                        }} />
                    </Box>
                  </Flex>
                </Box>
                <Box>
                  <Flex justify="space-between">
                    <Box>
                      <Grid templateRows="repeat(2, 1fr)" gap="3px">
                        <GridItem>
                          <Text fontWeight="semibold" fontSize="16px">
                            Nouveautés sur le site
                          </Text>
                        </GridItem>
                        <GridItem>
                          <Text>
                            Me tenir au courant sur les nouvelles
                            fonctionnalités disponibles sur le site de Yalink
                          </Text>
                        </GridItem>
                      </Grid>
                    </Box>
                    <Box mt="2%">
                      <Switch 
                        isChecked={notificationPreferences?.receive_newsletter_updates} 
                        onChange={() => {
                          let tempNotificationPreferences: INotificationPreferences | undefined = notificationPreferences;
                          tempNotificationPreferences!.receive_newsletter_updates = !tempNotificationPreferences!.receive_newsletter_updates;
                          setNotificationPreferences({...tempNotificationPreferences});
                        }}/>
                    </Box>
                  </Flex>
                </Box>
                <Box mt="5%">
                  <Button
                    w="100%"
                    borderRadius="0"
                    bgColor="#003B36"
                    color="white"
                    onClick={handleNotificationPreferencesUpdate}
                    isLoading={isSavingNotificationPreferences}
                    isDisabled={isSavingNotificationPreferences}

                  >
                    Enregistrer vos préférences
                  </Button>
                </Box>
              </Box>
            </Box>
          </Flex>
        </Box>
      )}
    </>
  ) : (<></>);
};

export default ChangeNotifications;
