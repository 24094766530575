import React from "react";

import {
  Box,
  GridItem,
  Text,
  Grid,
  Input,
  InputRightElement,
  InputGroup,
  NumberInput,
  NumberInputField,
  FormControl,
  FormErrorMessage,
  CircularProgress,
  CircularProgressLabel,
} from "@chakra-ui/react";
import { Field, Form } from "formik";

export default function AmountProgress({
  mission,
  isMobile,
  getTotalAmount,
  values,
  isClient,
}) {
  return (
    <Box
      h={isMobile ? null : "25%"}
      bg="yalink.green"
      color="black"
      borderRadius={7}
      p={7}
      mb="5%"
    >
      <Grid templateColumns="repeat(2,1fr)" h="100%" gap="5%">
        <GridItem>
          <Grid templateRows="repeat(3,1fr)" h="100%">
            <GridItem>
              <Text color="white">Montant d'avancement à valider (en €)</Text>
            </GridItem>
            <GridItem>
              <Text color="white">Montant déjà facturé (en €)</Text>
            </GridItem>
            <GridItem>
              <Text color="white">Montant d'avancement total (en €)</Text>
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem>
          <Grid templateRows="repeat(3,1fr)" h="100%">
            <GridItem>
              <Form>
                <Field name="avancement">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.avancement && form.touched.avancement
                      }
                    >
                      <Input
                        {...field}
                        w={isMobile ? null : "30%"}
                        bgColor="white"
                        id="avancement"
                        placeholder="0"
                        type="number"
                        isReadOnly={isClient && true}
                      />
                      <FormErrorMessage>
                        {form.errors.avancement}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Form>
            </GridItem>
            <GridItem>
              <InputGroup w={isMobile ? null : "25%"}>
                <Input
                  bgColor="gray.400"
                  borderColor="gray.400"
                  value={mission.completed_amount}
                  isReadOnly
                />
                <InputRightElement children="€" />
              </InputGroup>
            </GridItem>
            <GridItem>
              <Grid
                templateColumns={isMobile ? null : "1fr 0.2fr 1fr 1fr"}
                gap="5%"
              >
                <GridItem>
                  <InputGroup>
                    <NumberInput
                      isReadOnly
                      value={getTotalAmount(values.avancement)}
                      max={mission.total_amount_billed}
                      keepWithinRange
                    >
                      <NumberInputField
                        bgColor="gray.400"
                        borderColor="gray.400"
                      />
                    </NumberInput>
                    <InputRightElement children="€" />
                  </InputGroup>
                </GridItem>
                <GridItem>
                  <Text color="white" align="center" fontSize="1.5rem">
                    /
                  </Text>
                </GridItem>
                <GridItem>
                  <InputGroup>
                    <Input
                      bgColor="gray.400"
                      borderColor="gray.400"
                      isReadOnly
                      value={mission.total_amount_billed}
                    />
                    <InputRightElement children="€" />
                  </InputGroup>
                </GridItem>
                <GridItem>
                  <CircularProgress
                    value={Math.round(
                      Number(
                        Number(getTotalAmount(values.avancement)) /
                          Number(mission.total_amount_billed)
                      ) * 100
                    )}
                    size="45px"
                    thickness="16px"
                    color="yalink.yellow"
                  >
                    <CircularProgressLabel color="white" fontWeight="bold">
                      {Math.round(
                        Number(
                          Number(getTotalAmount(values.avancement)) /
                            Number(mission.total_amount_billed)
                        ) * 100
                      ) + "%"}
                    </CircularProgressLabel>
                  </CircularProgress>
                </GridItem>
              </Grid>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Box>
  );
}
