import ActivityReportForfait from "../../components/ActivityReport/ActivityReportForfait";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Missions from "../../services/mission.service";
import FreelanceService from "../../services/freelance.service";
import { useParams } from "react-router-dom";
import ActivityReportRegie from "../../components/ActivityReport/ActivityReportRegie";
import { LoadingComponent } from "../../components";
import { useAppContext } from "../../libs/contextLib";
import Auth from "../../services/auth.service";
import { useToast } from "@chakra-ui/react";

function ActivityReport() {
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [mission, setMission] = useState(null);
  const [legalData, setLegalData] = useState(null);
  const [activityReport, setActivityReport] = useState(null);
  const { missionId, year, month } = useParams();
  const toast = useToast();
  const history = useHistory();
  async function onLoad() {
    if (Auth.loginFromQueryStringToken()) {
      userHasAuthenticated(true);
    }

    await Missions.getMission(missionId)
      .then((response) => {
        setMission({ ...response.data.mission });
        Missions.getActivityReport(missionId, year, month)
          .then((response) => {
            setActivityReport({ ...response.data.activity_report });
          })
          .catch((error) => {
            if (error.response.status === 404) {
              // Create the activity report for the current month
              Missions.createActivityReport(missionId, year, month).then(
                (response) => {
                  setActivityReport({ ...response.data.activity_report });
                }
              );
            }
          });
        FreelanceService.getLegalData(response.data.mission.freelance_id)
          .then((response) => {
            if (response.data.legalData) {
              setLegalData({ ...response.data.legalData });
            }
          })
          .catch((error) => {
            if (error.response.status === 404) {
              // Redirect the user and display a message
              history.push("/freelance/enterprise");
              toast({
                title: "Pas de données légales",
                description: "Veuillez remplir vos données entreprise ",
                position: "top",
                status: "error",
                duration: 9000,
                isClosable: true,
              });
            }
          });
      })
      .catch((error) => {});
  }
  if (isLoading) {
    onLoad();
    setIsLoading(false);
  }

  return (
    [isLoading, missionId],
    mission &&
    mission.service_type === "forfait" &&
    activityReport &&
    legalData ? (
      <ActivityReportForfait
        mission={mission}
        activityReport={activityReport}
        legalData={legalData}
      />
    ) : mission &&
      legalData &&
      mission.service_type === "regie" &&
      activityReport ? (
      <ActivityReportRegie
        mission={mission}
        activityReport={activityReport}
        legalData={legalData}
      />
    ) : (
      <LoadingComponent />
    )
  );
}

export default ActivityReport;
