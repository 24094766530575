import React from "react";

import {
  Box,
  Text,
  FormControl,
  FormErrorMessage,
  Flex,
  Textarea,
} from "@chakra-ui/react";
import { Field, Form } from "formik";

export default function ProgressDescription({ isClient }) {
  return (
    <Box
      bg="white"
      color="black"
      borderRadius={7}
      boxShadow="md"
      padding={7}
      pt={4}
      mb="5%"
    >
      <Box mb="1%">
        <Text fontSize="1.5rem" fontWeight="semibold">
          Justificatifs
        </Text>
      </Box>
      <Box mb="3%">
        <Flex justify="space-between" w="70%">
          <Box>
            <Text fontSize="1rem">Descriptif de l’avancement du mois.</Text>
          </Box>
        </Flex>
      </Box>
      <Box>
        <Form>
          <Field name="progressDescription">
            {({ field, form }) => (
              <FormControl
                isInvalid={
                  form.errors.progressDescription &&
                  form.touched.progressDescription
                }
              >
                <Textarea {...field} size="sm" id="progressDescription" isReadOnly={isClient && true}/>
                <FormErrorMessage>
                  {form.errors.progressDescription}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Form>
      </Box>
    </Box>
  );
}
