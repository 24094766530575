import React, { useState, useEffect } from "react";
import {
  Text,
  Badge,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Avatar,
  Button,
} from "@chakra-ui/react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
// import data
import authHeader from "../../../../libs/authHeaderLib";
import config from "../../../../config";
// import utils
import { getColorScheme, getColor } from "./utils";
import { Link } from "react-router-dom";

import axios from "axios";

export default function ActivityReportList() {
  const [isData, setIsData] = useState([]);

  //Here we define columns for the grid
  const defaultColumns = [
    {
      name: "id",
      header: "Id",
      defaultVisible: false,
      type: "number",
      maxWidth: 40,
    },
    {
      name: "avatar",
      header: "",
      defaultWidth: 50,
      render: ({ value }) => (
        <Flex justify="center">
          <Avatar src={value} size="xs" />
        </Flex>
      ),
    },
    { name: "freelanceName", header: "Freelance", defaultWidth: 150 },
    { name: "clientName", header: "Client", defaultWidth: 100 },
    {
      name: "name",
      header: "Mission",
      defaultWidth: 200,
      render: ({ value }) => (
        <Badge textTransform="none" p="1" colorScheme="gray" fontWeight="light">
          <Text>{value}</Text>
        </Badge>
      ),
    },
    {
      name: "month",
      header: "Mois",
      defaultWidth: 90,
      render: ({ value }) => (
        <Text>{value.charAt(0).toUpperCase() + value.slice(1)}</Text>
      ),
    },
    { name: "year", header: "Année", defaultWidth: 60 },
    {
      name: "reportStatus",
      header: "Statut",
      defaultWidth: 110,
      render: ({ value }) => (
        <Badge
          textTransform="none"
          colorScheme={getColorScheme(value)}
          borderRadius={15}
          fontWeight="light"
          pr={3}
          pl={3}
          pt={1}
          pb={1}
        >
          <Text color={getColor(value)}>{value}</Text>
        </Badge>
      ),
    },
    {
      name: "vatExcludedActivityTotal",
      header: "HT Freelance",
      defaultWidth: 80,
      render: ({ value }) => <Text>{value.toLocaleString()} € </Text>,
    },
    {
      name: "vatExcludedMarginTotal",
      header: "HT Commission",
      defaultWidth: 80,
      render: ({ value }) => <Text>{value.toLocaleString()} € </Text>,
    },
    {
      name: "vatExcludedTotal",
      header: "HT Total",
      defaultWidth: 80,
      render: ({ value }) => <Text>{value.toLocaleString()} € </Text>,
    },
    {
      name: "progressStatus",
      header: "% avancement",
      defaultWidth: 80,
      render: ({ value }) => (
        <Flex justify="center">
          <CircularProgress value={value} color="yalink.green" size="50px">
            <CircularProgressLabel color="black">
              {value + "%"}
            </CircularProgressLabel>
          </CircularProgress>
        </Flex>
      ),
    },
    {
      name: "urlToActivityReport",
      header: "CRA",
      defaultWidth: 130,
      render: ({ value }) => (
        <Flex justify="center">
          <Link
            to={[
              "/freelance/missions/ongoing",
              value.missionId,
              "activity-report",
              value.year,
              value.monthURL,
            ].join("/")}
          >
            <Button size="xs">Consulter le CRA</Button>
          </Link>{" "}
        </Flex>
      ),
    },
    {
      name: "urlToActivityReport",
      header: "CRA Client",
      defaultWidth: 130,
      render: ({ value }) => (
        <Flex justify="center">
          <Link
            to={[
              [
                "/freelance/missions/ongoing",
                value.missionId,
                "activity-report",
                value.year,
                value.monthURL,
              ].join("/"),
              "token=" + value.clientAuthToken,
            ].join("?")}
          >
            <Button size="xs">Consulter</Button>
          </Link>{" "}
        </Flex>
      ),
    },
  ];
  //We define the filters
  const defaultFilterValue = [
    {
      name: "freelanceName",
      operator: "startsWith",
      type: "string",
      value: "",
    },
    { name: "clientName", operator: "startsWith", type: "string", value: "" },
    { name: "name", operator: "startsWith", type: "string", value: "" },
    { name: "month", operator: "startsWith", type: "string", value: "" },
    { name: "year", operator: "startsWith", type: "string", value: "" },
    { name: "reportStatus", operator: "startsWith", type: "string", value: "" },
    {
      name: "vatExcludedActivityTotal",
      operator: "gte",
      type: "number",
      value: "",
    },
    {
      name: "vatExcludedMarginTotal",
      operator: "gte",
      type: "number",
      value: "",
    },
    {
      name: "vatExcludedTotal",
      operator: "gte",
      type: "number",
      value: "",
    },
    {
      name: "progressStatus",
      operator: "gte",
      type: "number",
      value: "",
    },
  ];

  // const gridStyle = { minHeight: 700 };
  const gridStyle = { minHeight: "100%" };
  //use state for data and columns
  const [columns] = useState(defaultColumns);

  // Now we import our data from the API
  /*  For the ReactDataGrid component, we need to return the data from a promise, that is why I didn't import 
   my API call from Mission.service */
  // Documentation : https://reactdatagrid.io/docs/#using-async-or-remote-data
  async function loadData() {
    let returnData = [];
    await axios
      .get(config.API_URL + "/mission/activity-report", {
        headers: authHeader(),
      })
      .then((response) => {
        returnData = response.data.data;
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
    return returnData;
  }

  useEffect(() => {
    let dataSource = loadData();
    dataSource.then((res) => {
      setIsData(res);
    });
  }, []);

  return (

        <ReactDataGrid
          idProperty="id"
          style={gridStyle}
          defaultFilterValue={defaultFilterValue}
          rowHeight={60}
          dataSource={isData}
          columns={columns}
        />


  );
}
