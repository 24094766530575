import React from "react";
import { useState } from "react";
import {
  Box,
  Center,
  Tab,
  TabPanel,
  Tabs,
  TabList,
  TabPanels,
  Text,
  Tag,
} from "@chakra-ui/react";
import FreelanceMissionAvailable from "../../components/FreelanceMissions/FreelanceMissionAvailable";
import FreelanceMissionsOngoingPast from "../../components/FreelanceMissions/FreelanceMissionsOngoingPast";

function FreelanceMissions() {
  const [totalItems, setTotalItems] = useState(0);
  const [totalOngoingItems, setTotalOngoingItems] = useState(0);
  const [totalClosedItems, setTotalClosedItems] = useState(0);
  return (
    <Box mb="50px">
      <Tabs>
        <Center mt="1%" ml="20px" mr="20px" padding="5">
          <TabList>
            <Tab outline="none">
              <Text>{"Missions disponibles"}</Text>
              <Tag ml="5px" colorScheme="cyan">
                {totalItems}
              </Tag>
            </Tab>
            <Tab>
              <Text>En cours</Text>
              <Tag ml="5px" colorScheme="green">
                {totalOngoingItems}
              </Tag>
            </Tab>
            <Tab>
              <Text>Terminées</Text>
              <Tag ml="5px" colorScheme="orange">
                {totalClosedItems}
              </Tag>
            </Tab>
          </TabList>
        </Center>
        <TabPanels>
          <TabPanel>
            <FreelanceMissionAvailable
              setTotalItems={setTotalItems}
              totalItems={totalItems}
            />
          </TabPanel>
          <TabPanel>
            <FreelanceMissionsOngoingPast
              status="ongoing"
              totalItems={totalOngoingItems}
              setTotalItems={setTotalOngoingItems}
            />
          </TabPanel>
          <TabPanel>
            <FreelanceMissionsOngoingPast
              status="closed"
              totalItems={totalClosedItems}
              setTotalItems={setTotalClosedItems}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default FreelanceMissions;
