import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import Auth from "../services/auth.service";
import { querystring } from "../libs/queryStringLib";

export default function UnauthenticatedRoute({ children, ...rest }) {
  const { isAuthenticated } = useAppContext();
  const redirect = querystring("redirect", window.location.href);
  let homePath = "";
  if (Auth.isFirstTimeLogin()) {
    homePath = "/freelance/about/you";
  } else if (Auth.isAdmin()) {
    homePath = "/admin";
  } else {
    homePath = "/freelance/home";
  }

  return (
    <Route {...rest}>
      {!isAuthenticated ? (
        children
      ) : (
        <Redirect
          to={redirect === "" || redirect === null ? homePath : redirect}
        />
      )}
    </Route>
  );
}
