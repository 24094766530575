import React from 'react'

import {
  Box,
  Text,
  Link,
  Flex,
  Badge
} from "@chakra-ui/react";
import { ArrowLeft } from "@styled-icons/bootstrap/ArrowLeft";
import { useHistory } from "react-router-dom";

export interface MissionProps {
  name: string
  id: Number
}

export interface ActivityReportProps {
  status: string
}

export interface HeaderProps {
  isMobile: boolean
  mission: MissionProps
  activityReport: ActivityReportProps
}

export default function Header({ isMobile, mission, activityReport }: HeaderProps) {
  const history = useHistory();

  return (
    <Box bg="yalink.green" h={isMobile ? "170px" : "10%"} w="100%" mb="5%">
      <Text color="white" padding="5" ml="3%">
        <ArrowLeft size="20px" />
        <Link
          ml="10px"
          onClick={() => {
            history.push("/freelance/missions/ongoing/" + mission.id);
          }}
          _hover={{ color: "yalink.yellow" }}
        >
          Retour
        </Link>
        <Flex alignItems="baseline">
          <Text mt="10px" fontSize="30px" fontWeight="bold">
            Vue détaillée mission : {mission.name}
          </Text>
          {activityReport.status === "approved" || activityReport.status === "nothing_declared" ? (
            <Box ml="2vh">
              <Badge colorScheme='green' fontSize='1em'>Rapport d'Activité validé</Badge>
            </Box>) : null}
        </Flex>
      </Text>
    </Box>
  )
}
