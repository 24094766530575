import React, { useState, useEffect } from "react";
import {
  Button,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  Box,
  Checkbox,
  Text,
  Spacer,
  HStack,
} from "@chakra-ui/react";
import ActivitySectorsService from "../../services/activitysectors.service";
import activitysectorsService from "../../services/activitysectors.service";
import { Stars } from "@styled-icons/bootstrap/Stars";

function ModalSectors(props) {
  const [availableActivitySectors, setActivitySectors] = useState(null);
  const [activitySectorCleanedUp, setActivitySectorCleanedUp] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    function performSectorCategoryUpdate(sectors) {
      let tempDetails = props.freelanceDetails;
      sectors.forEach((sector) => {
        if (sector.checked) {
          addActivitySector(sector.id);
          let sectorText = sector.sub_sector;
          if (sector.sub_sector === null) {
            sectorText = sector.sector;
          }
          tempDetails.sectors.push({ id: sectorText, text: sectorText });
        }
      });
      props.setFreelanceDetails({ ...tempDetails });
    }

    async function getActivitySectors() {
      await ActivitySectorsService.getAllActivitySectors()
        .then((response) => {
          setActivitySectors(response.data.activity_sectors);
          let tempActivitySectors = response.data.activity_sectors;
          // Set the sectors previously selected in the UI for convenience
          for (const sectorType of ["construction", "energy", "industry"]) {
            tempActivitySectors[sectorType].forEach((sector) => {
              props.freelanceDetails["sectors"].forEach(
                (previouslySelectedSector) => {
                  if (
                    (previouslySelectedSector.text === sector.sector &&
                      !sector.sub_sector) ||
                    previouslySelectedSector.text === sector.sub_sector
                  ) {
                    sector.checked = true;
                  }
                }
              );
            });
          }
          setActivitySectors({ ...tempActivitySectors });
        })
        .catch(() => {});
    }
    if (!availableActivitySectors) {
      getActivitySectors();
    }
    if (activitySectorCleanedUp) {
      for (const sectorType of ["construction", "energy", "industry"]) {
        performSectorCategoryUpdate(availableActivitySectors[sectorType]);
      }
      setProcessing(false);
      setActivitySectorCleanedUp(false);
      props.setSectorsOpen(false);
    }
  }, [
    availableActivitySectors,
    setActivitySectors,
    activitySectorCleanedUp,
    setActivitySectorCleanedUp,
    props,
  ]);

  function checkItem(category, checkedSector, checkValue) {
    let tempSectors = availableActivitySectors;
    let subsectorStillChecked = { value: false, sector: null };
    tempSectors[category].forEach((sector) => {
      // If we reach three sectors and we try to check another one, we want to block action

      if (
        // Check all sub sectors if sector is checked
        checkedSector.sub_sector === null &&
        sector.sector === checkedSector.sector
      ) {
        sector.checked = checkValue;
      }
      if (
        // Check only sub sector
        sector.sector === checkedSector.sector &&
        sector.sub_sector === checkedSector.sub_sector
      ) {
        sector.checked = checkValue;
      }
      if (
        // Check sector if at least one sub sector is checked
        checkedSector !== null &&
        sector.sector === checkedSector.sector &&
        sector.sub_sector === null
      ) {
        sector.checked = checkValue;
      }
      if (
        checkedSector !== null &&
        sector.sector === checkedSector.sector &&
        sector.sub_sector !== null &&
        sector.checked === true
      ) {
        subsectorStillChecked = { value: true, sector: checkedSector.sector };
      }
    });
    if (subsectorStillChecked.value) {
      tempSectors[category].forEach((sector) => {
        if (
          // If there is still a sub sector checked, we also make sure the sector
          // is kept checked!
          sector.sector === subsectorStillChecked.sector &&
          sector.sub_sector === null
        ) {
          sector.checked = true;
        }
      });
    }
    setActivitySectors({ ...tempSectors });
  }

  function renderSectors(categoryText, category) {
    return (
      <Box w="50%">
        <Text fontWeight="bold" mb="9px">
          {categoryText}
        </Text>
        {availableActivitySectors[category].map((sector) => {
          if (!sector.sub_sector) {
            return (
              <Stack pl={1} mt={1} spacing={1}>
                <Checkbox
                  id={sector.sector + " Principal-Sector"}
                  isChecked={sector.checked}
                  onChange={(e) =>
                    checkItem(category, sector, e.target.checked)
                  }
                >
                  {sector.sector}
                </Checkbox>
              </Stack>
            );
          } else {
            return (
              <Stack pl={6} mt={1} spacing={1}>
                <Checkbox
                  id={sector.sub_sector}
                  isChecked={sector.checked}
                  onChange={(e) =>
                    checkItem(category, sector, e.target.checked)
                  }
                >
                  {sector.sub_sector}
                </Checkbox>
              </Stack>
            );
          }
        })}
      </Box>
    );
  }

  async function clearPreviousActivitySectors() {
    await activitysectorsService
      .cleanUpActivitySectors()
      .then((response) => {
        setActivitySectorCleanedUp(true);
      })
      .catch(() => {});
  }
  async function addActivitySector(id) {
    await activitysectorsService
      .addActivitySectorToFreelance({ id: id })
      .then((response) => {})
      .catch(() => {});
  }

  async function handleSectorsUpdate() {
    let wasAlreadyCleared = false;
    let tempSectors = availableActivitySectors;
    for (const sectorType of ["construction", "energy", "industry"]) {
      if (
        tempSectors[sectorType].some((e) => {
          return e.checked;
        })
      ) {
        setProcessing(true);
        let tempDetails = props.freelanceDetails;
        if (!wasAlreadyCleared) {
          clearPreviousActivitySectors();
          tempDetails.sectors = [];
          props.setFreelanceDetails({ ...tempDetails });
          wasAlreadyCleared = true;
        }
      }
    }
  }

  return (
    availableActivitySectors && (
      <div>
        <ModalHeader
          w={props.isMobile ? "100%" : null}
          ml={props.isMobile ? "5%" : null}
          pl={0}
          fontSize={props.isMobile ? "17px" : null}
        >
          <Stars size="40px" color="#F8C708" /> Complète tes secteurs d'activité
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody mb="auto">
          <Box
            bg="gray.100"
            w="100%"
            p={4}
            color="black"
            borderRadius="7px"
            mr="5px"
          >
            Renseigne les secteurs dans lesquels tu souhaites qu’on te propose
            des missions <br />
            (Tu ne peux sélectionner que 3 catégories de secteurs !)
          </Box>
          <Stack
            direction={props.isMobile ? "column" : "row"}
            spacing={4}
            mt="20px"
            mb={props.isMobile ? "10%" : null}
          >
            {renderSectors(
              "Construction",
              "construction"
              // availableActivitySectors.construction
            )}
            {renderSectors("Energie et procédés", "energy")}
            {renderSectors("Industrie", "industry")}
          </Stack>
          <HStack>
            <Spacer />
            <Button
              ms="285"
              mb="5"
              onClick={() => handleSectorsUpdate()}
              disabled={processing}
              isLoading={processing}
            >
              Enregistrer
            </Button>
            <Spacer />
          </HStack>
        </ModalBody>
      </div>
    )
  );
}

export default ModalSectors;
