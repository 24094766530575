import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import {
  Heading,
  Grid,
  GridItem,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Select,
  InputGroup,
  InputRightElement,
  useToast,
  Link,
  Box,
  Text,
} from "@chakra-ui/react";
import User from "../../services/user.service";
import { useHistory } from "react-router";

export const preferencesDict = {
  availability: {
    avail: {
      code: "avail",
      text: "🟢 Disponible",
    },
    not_avail: {
      code: "not_avail",
      text: "🔴 Occupé",
    },
  },
  workplace: {
    on_site: {
      code: "on_site",
      text: "Sur site uniquement",
    },
    remote: {
      code: "remote",
      text: "Télé-travail uniquement",
    },
    no_pref: {
      code: "no_pref",
      text: "Indifférent",
    },
  },
  area: {
    city: {
      code: "city",
      text: "Ville",
    },
    department: {
      code: "department",
      text: "Département",
    },
    region: {
      code: "region",
      text: "Région",
    },
    country: {
      code: "country",
      text: "France",
    },
  },
  length: {
    lt3m: {
      code: "lt3m",
      text: "Courte (-3 mois)",
    },
    gt3m: {
      code: "gt3m",
      text: "Longue (+3 mois)",
    },
    indifferent: {
      code: "indifferent",
      text: "Indifférent",
    },
  },
  daysperweek: {
    part: {
      code: "part",
      text: "Temps partiel",
    },
    daysPerWeek1: {
      code: "daysPerWeek1",
      text: "1 jour",
    },
    daysPerWeek2: {
      code: "daysPerWeek2",
      text: "2 jours",
    },
    daysPerWeek3: {
      code: "daysPerWeek3",
      text: "3 jours",
    },
    daysPerWeek4: {
      code: "daysPerWeek4",
      text: "4 jours",
    },
    full: {
      code: "full",
      text: "Temps plein",
    },
    indifferent: {
      code: "indifferent",
      text: "Indifférent",
    },
  },
};

function FreelancePreferencesForm(props) {
  const openAvail = props.openAvail;
  const toast = useToast();
  const history = useHistory();

  // Mobile Responsive Grid
  const [gridRows, setGridRows] = useState("repeat(2, 1fr)");
  const [gridColumns, setGridColumns] = useState("repeat(4, 1fr)");
  const [isMobile, setIsMobile] = useState(false);

  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
      setGridRows("0.8fr 1fr 0.6fr");
      setGridColumns("1fr");
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    applyResponsive();
  }, []);

  window.addEventListener("resize", applyResponsive);

  async function handleSubmit(values, actions) {
    const preferences = {
      city: values.city,
      tarification: values.tjm,
      workplace: values.workplace,
      jobDuration: values.length,
      mobility: values.area,
      daysPerWeek: values.daysperweek,
    };
    await User.updateFreelancePreferences(preferences)
      .then(() => {
        toast({
          position: "top",
          title: "Boarding pass mis à jour",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch((error) => {});
    let tempFreelancePreferences = props.freelancePreferences;
    tempFreelancePreferences.tjm = values.tjm;
    tempFreelancePreferences.city = values.city;
    tempFreelancePreferences.workplace = values.workplace;
    tempFreelancePreferences.area = values.area;
    tempFreelancePreferences.length = values.length;
    tempFreelancePreferences.daysperweek = values.daysperweek;
    props.setFreelancePref({ ...tempFreelancePreferences });

    if (!props.hasFreelancePref) {
      props.setHasFreelancePref(true);
      actions.setSubmitting(false);
      history.push("/freelance/profile");
    } else {
      props.setHasFreelancePref(true);
      actions.setSubmitting(false);
    }
  }
  function validateText(value) {
    let error;
    if (!value) {
      error = "*Il va falloir remplir ce champ 😱";
    }
    return error;
  }

  function validatePostalCode(value) {
    let error;
    if (!/^[0-9]{5}$/i.test(value)) {
      error = "*Il va falloir remplir ce champ par 5 chiffres 😱";
    }
    return error;
  }

  function validateTJM(value) {
    let error;
    if (!/^((2000)|(1[0-9]{3})|([2-9][0-9]{2}))$/i.test(value)) {
      error =
        "*Il va falloir remplir ce champ par un nombre entre 200 et 2 000 😱";
    }
    return error;
  }

  function getAvailabilityText() {
    if (
      props.freelancePreferences.availability ===
      preferencesDict.availability.avail.code
    ) {
      return preferencesDict.availability.avail.text;
    }
    if (
      props.freelancePreferences.availability ===
      preferencesDict.availability.not_avail.code
    ) {
      return preferencesDict.availability.not_avail.text;
    }
  }
  return (
    <Formik
      initialValues={{
        tjm: props.freelancePreferences.tjm,
        city: props.freelancePreferences.city,
        workplace: props.freelancePreferences.workplace,
        area: props.freelancePreferences.area,
        length: props.freelancePreferences.length,
        daysperweek: props.freelancePreferences.daysperweek,
      }}
      onSubmit={(values, actions) => {
        handleSubmit(values, actions);
      }}
    >
      {(props) => (
        <Form>
          <Grid
            templateRows={gridRows}
            templateColumns={gridColumns}
            gap={4}
            mb="20px"
          >
            <GridItem rowSpan={isMobile ? 1 : 2} colSpan={1}>
              <Heading size="sm" mt="30px">
                Disponibilité
              </Heading>
              <FormLabel htmlFor="availability">Je suis</FormLabel>
              <Box
                borderWidth={1}
                borderRadius={5}
                boxShadow="sm"
                bg="white"
                padding={2}
              >
                <Text>{getAvailabilityText()}</Text>
              </Box>
              <Link
                ms="285"
                ml="1"
                mt="2"
                width="200px"
                color="teal"
                onClick={() => {
                  openAvail();
                }}
              >
                Modifier
              </Link>
              <Heading size="sm" mt="47px">
                Tarif indicatif
              </Heading>
              <Field name="tjm" validate={validateTJM}>
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.tjm && form.touched.tjm}
                    isRequired
                  >
                    <FormLabel htmlFor="tjm">TJM</FormLabel>
                    <InputGroup>
                      <Input
                        {...field}
                        id="tjm"
                        placeholder="Entre ton montant"
                      />
                      <InputRightElement
                        pointerEvents="none"
                        color="gray.300"
                        fontSize="1.2em"
                        children="€"
                      />
                    </InputGroup>
                    <FormErrorMessage>{form.errors.tjm}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </GridItem>
            <GridItem colSpan={3}>
              <Heading size="sm" mt="30px">
                Localisation et déplacement
              </Heading>
              <SimpleGrid
                columns={isMobile ? 1 : 3}
                spacing={isMobile ? 3 : 10}
              >
                <Field name="city" validate={validatePostalCode}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.city && form.touched.city}
                      isRequired
                    >
                      <FormLabel htmlFor="city">Code postal</FormLabel>
                      <Input {...field} id="city" placeholder="33600" />
                      <FormErrorMessage>{form.errors.city}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="workplace" validate={validateText}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.workplace && form.touched.workplace
                      }
                      isRequired
                    >
                      <FormLabel htmlFor="workplace">Lieu de travail</FormLabel>
                      <Select
                        {...field}
                        placeholder="Selectionner"
                        id="workplace"
                      >
                        <option value={preferencesDict.workplace.on_site.code}>
                          {preferencesDict.workplace.on_site.text}
                        </option>
                        <option value={preferencesDict.workplace.remote.code}>
                          {preferencesDict.workplace.remote.text}
                        </option>
                        <option value={preferencesDict.workplace.no_pref.code}>
                          {preferencesDict.workplace.no_pref.text}
                        </option>
                      </Select>
                      <FormErrorMessage>
                        {form.errors.workplace}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="area" validate={validateText}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.area && form.touched.area}
                      isRequired
                    >
                      <FormLabel htmlFor="area">Mobilité</FormLabel>
                      <Select {...field} placeholder="Selectionner" id="area">
                        <option value={preferencesDict.area.city.code}>
                          {preferencesDict.area.city.text}
                        </option>
                        <option value={preferencesDict.area.department.code}>
                          {preferencesDict.area.department.text}
                        </option>
                        <option value={preferencesDict.area.region.code}>
                          {preferencesDict.area.region.text}
                        </option>
                        <option value={preferencesDict.area.country.code}>
                          {preferencesDict.area.country.text}
                        </option>
                      </Select>
                      <FormErrorMessage>{form.errors.area}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </SimpleGrid>
            </GridItem>
            <GridItem colSpan={3} mt="30px">
              <Heading size="sm" mt="3">
                Préférences de missions
              </Heading>
              <SimpleGrid
                columns={isMobile ? 1 : 3}
                spacing={isMobile ? 3 : 10}
              >
                <Field name="length" validate={validateText}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.length && form.touched.length}
                      isRequired
                    >
                      <FormLabel htmlFor="length">Durée</FormLabel>
                      <Select {...field} placeholder="Selectionner" id="length">
                        <option value={preferencesDict.length.lt3m.code}>
                          {preferencesDict.length.lt3m.text}
                        </option>
                        <option value={preferencesDict.length.gt3m.code}>
                          {preferencesDict.length.gt3m.text}
                        </option>
                        <option value={preferencesDict.length.indifferent.code}>
                          {preferencesDict.length.indifferent.text}
                        </option>
                      </Select>
                      <FormErrorMessage>{form.errors.length}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="daysperweek" validate={validateText}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.daysperweek && form.touched.daysperweek
                      }
                      isRequired
                    >
                      <FormLabel htmlFor="daysperweek">Cadence</FormLabel>
                      <Select
                        {...field}
                        placeholder="Selectionner"
                        id="daysperweek"
                      >
                        <option
                          value={preferencesDict.daysperweek.daysPerWeek1.code}
                        >
                          {preferencesDict.daysperweek.daysPerWeek1.text}
                        </option>
                        <option
                          value={preferencesDict.daysperweek.daysPerWeek2.code}
                        >
                          {preferencesDict.daysperweek.daysPerWeek2.text}
                        </option>
                        <option
                          value={preferencesDict.daysperweek.daysPerWeek3.code}
                        >
                          {preferencesDict.daysperweek.daysPerWeek3.text}
                        </option>
                        <option
                          value={preferencesDict.daysperweek.daysPerWeek4.code}
                        >
                          {preferencesDict.daysperweek.daysPerWeek4.text}
                        </option>
                        <option value={preferencesDict.daysperweek.full.code}>
                          {preferencesDict.daysperweek.full.text}
                        </option>
                        <option
                          value={preferencesDict.daysperweek.indifferent.code}
                        >
                          {preferencesDict.daysperweek.indifferent.text}
                        </option>
                      </Select>
                      <FormErrorMessage>
                        {form.errors.daysperweek}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Button
                  mt="30px"
                  color="white"
                  bg="yalink.green"
                  _hover={{ bg: "yalink.yellow" }}
                  _checked={{
                    bg: "yalink.yellow",
                    color: "white",
                    borderColor: "yalink.yellow",
                  }}
                  isLoading={props.isSubmitting}
                  type="submit"
                  disabled={!props.isValid}
                >
                  Mettre à jour
                </Button>
              </SimpleGrid>
            </GridItem>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default FreelancePreferencesForm;
