import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Box, Stack, Center, Divider, Text } from "@chakra-ui/react";
import jobtitlesService from "../../services/jobtitles.service";
import CheckboxComponent from "../Modals/CheckboxComponent";

const categoriesPerActivityArea = {
  industry: ["studies", "production", "project"],
  construction: ["studies", "works", "project"],
};

const industrialJobs = [
  {
    prettyName: "Études & Développement",
    category: "studies",
    activityArea: "industry",
  },
  {
    prettyName: "Production",
    category: "production",
    activityArea: "industry",
  },
  { prettyName: "Projets", category: "project", activityArea: "industry" },
];
const constructionJobs = [
  { prettyName: "Etudes", category: "studies", activityArea: "construction" },
  { prettyName: "Travaux", category: "works", activityArea: "construction" },
  { prettyName: "Projets", category: "project", activityArea: "construction" },
];

function Job(props) {
  const history = useHistory();
  const [currentSelectedJobs, setCurrentSelectedJobs] = useState(null);
  const [openCheckboxComponent, setOpenCheckboxComponent] = useState(null);
  const [availableJobTitles, setAvailableJobTitles] = useState(null);
  // Mobile responsive grid

  const [jobsCleaned, setJobsCleaned] = useState(false);
  const [processing, setProcessing] = useState(false);

  const buttonColors = {
    industry: {
      bg: "gray.100",
      color: "black",
    },
    construction: {
      bg: "gray.100",
      color: "black",
    },
  };

  const focusedIndustryColors = { bg: "yalink.yellow", color: "black" };
  const focusedConstructionColors = { bg: "yalink.green", color: "white" };

  useEffect(() => {
    async function getJobTitles() {
      await jobtitlesService
        .getAllJobTitles()
        .then((response) => {
          setAvailableJobTitles(response.data.job_titles);
        })
        .catch(() => {});
    }
    if (!availableJobTitles) {
      getJobTitles();
    }
    if (jobsCleaned) {
      for (const activityArea of ["industry", "construction"]) {
        for (const category of categoriesPerActivityArea[activityArea]) {
          availableJobTitles[activityArea][category].forEach((chkItem) => {
            if (chkItem.isChecked) {
              addJobTitle(chkItem.id);
            }
          });
        }
      }
      setProcessing(false);
      setJobsCleaned(false);
      history.push("/freelance/about/preferences");
    }
  }, [availableJobTitles, setAvailableJobTitles, jobsCleaned, history, props]);

  function handleOnChange(event, option, selectedJobs) {
    let tempCurrentSelectedJobs = selectedJobs;
    tempCurrentSelectedJobs.forEach((chkItem) => {
      if (chkItem === option) {
        chkItem.isChecked = event.target.checked;
      }
    });
    let numberOfJobsChecked = tempCurrentSelectedJobs.filter(
      (chkItem) => chkItem.isChecked && chkItem.isChecked === true
    );
    if (numberOfJobsChecked && numberOfJobsChecked.length >= 3) {
      tempCurrentSelectedJobs.forEach((chkItem) => {
        if (!chkItem.isChecked || chkItem.isChecked === false) {
          chkItem.isDisabled = true;
        }
      });
    } else {
      tempCurrentSelectedJobs.forEach((chkItem) => {
        chkItem.isDisabled = false;
      });
    }
    setCurrentSelectedJobs([...tempCurrentSelectedJobs]);
  }

  const handleJobClick = (e, job) => {
    setOpenCheckboxComponent(true);
    setCurrentSelectedJobs(availableJobTitles[job.activityArea][job.category]);
  };

  async function addJobTitle(id) {
    await jobtitlesService
      .addJobTitleToFreelance({ id: id })
      .then((response) => {})
      .catch(() => {});
  }

  async function clearPreviousJobTitles() {
    await jobtitlesService
      .cleanUpJobTitles()
      .then((response) => {
        setJobsCleaned(true);
      })
      .catch(() => {});
  }

  const handleCheckClick = () => {
    let tempCurrentSelectedJobs = currentSelectedJobs;
    if (
      tempCurrentSelectedJobs &&
      tempCurrentSelectedJobs.some((e) => {
        return e.isChecked;
      })
    ) {
      setProcessing(true);
      clearPreviousJobTitles();
    }
  };

  return (
    availableJobTitles && (
      <>
        <Stack direction="column" spacing={4}>
          <Box>
            <Text fontSize="20px" fontWeight="semibold">
              🧑🏻‍🔧 Métiers de l'industrie
            </Text>
            <Stack direction="row" spacing={4}>
              {industrialJobs.map((job) => (
                <Button
                  onClick={(e) => handleJobClick(e, job)}
                  key={job.prettyName}
                  size="md"
                  height="120px"
                  width="160px"
                  color={buttonColors["industry"].color}
                  bg={buttonColors["industry"].bg}
                  _hover={focusedIndustryColors}
                  _focus={focusedIndustryColors}
                  style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                  }}
                >
                  {job.prettyName}
                </Button>
              ))}
            </Stack>
            <Text fontSize="20px" fontWeight="semibold" mt="20px">
              👨🏼‍🚒 Métiers de la construction
            </Text>
            <Stack direction="row" spacing={4}>
              {constructionJobs.map((job) => (
                <Button
                  onClick={(e) => handleJobClick(e, job)}
                  key={job.prettyName}
                  size="md"
                  height="120px"
                  width="160px"
                  color={buttonColors["construction"].color}
                  bg={buttonColors["industry"].bg}
                  _hover={focusedConstructionColors}
                  _focus={focusedConstructionColors}
                  variant="outline"
                  style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                  }}
                >
                  {job.prettyName}
                </Button>
              ))}
            </Stack>
          </Box>
          {openCheckboxComponent ? (
            <>
              {props.isMobile ? null : (
                <Center height="flex">
                  <Divider orientation="vertical" />
                </Center>
              )}
              <Center>
                <Box>
                  <div className="App container">
                    <div>
                      <div className="row">
                        <div className="col-xl-12">
                          <Text>🕵🏻‍♀️ Choisis ton métier :</Text>
                          <div className="form-row">
                            <div className="form-group col-xl-12">
                              <CheckboxComponent
                                JOBS={currentSelectedJobs}
                                onChange={handleOnChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </Center>
            </>
          ) : null}
        </Stack>
        <Stack
          direction={["column", "row"]}
          fontWeight="semibold"
          lineHeight="tight"
          mt={props.isMobile ? "20px" : "40px"}
          mb={props.isMobile ? "70px" : "40px"}
          mr="20px"
          ml="20px"
          gap={props.isMobile ? 10 : 20}
          justify="center"
        >
          <Button
            ml={props.isMobile ? "70px" : null}
            ms={props.isMobile ? null : "285"}
            mt="30px"
            color="white"
            bg="yalink.green"
            _hover={{ bg: "gray.200" }}
            _checked={{
              bg: "gray.200",
              color: "white",
              borderColor: "gray.200",
            }}
            onClick={() => handleCheckClick()}
            disabled={processing}
            isLoading={processing}
            type="submit"
          >
            Suivant
          </Button>
        </Stack>
      </>
    )
  );
}

export default Job;
