import { useEffect, useState } from "react";
import Table from "./Molecules/table";
import { Badge, Center, Box } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import Pagination from "../Utils/Pagination";
import PerPageSelector from "../Utils/PerPageSelector";
import { querystring } from "../../libs/queryStringLib";
import MissionService from "../../services/mission.service";

export default function FreelanceMissionsOngoingPast({
  status,
  totalItems,
  setTotalItems,
}) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(
    querystring("page", window.location.href)
      ? querystring("page", window.location.href)
      : 1
  );
  const [perPage, setPerPage] = useState(
    querystring("per_page", window.location.href)
      ? querystring("per_page", window.location.href)
      : 10
  );
  const [missions, setMissions] = useState(null);

  useEffect(() => {
    async function onLoad() {
      await MissionService.getMissions(page, perPage, status)
        .then((response) => {
          setMissions(response.data.missions);
          setTotalItems(response.data.total);
        })
        .catch((error) => {});
    }
    if (isLoading) {
      onLoad();
      setIsLoading(false);
    }
  }, [page, perPage, status, isLoading, setMissions, setTotalItems]);

  function handleSelectedPage(selectedPage) {
    setPage(selectedPage);
    setIsLoading(true);
  }

  const [isMobile, setIsMobile] = useState(false);
  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    applyResponsive();
  }, []);

  window.addEventListener("resize", applyResponsive);

  return (
    !isLoading && (
      <>
        <Center>
          <Table.Container
            p={4}
            mr="20px"
            ml="20px"
            mt="-18px"
            bg="white"
            width="90%"
            mb="20px"
          >
            <Table.Table data-testid="table">
              <Table.Thead bg="gray.200" data-testid="thead">
                <Table.Tr>
                  <Table.Th data-testid="th">Client</Table.Th>
                  <Table.Th data-testid="th" display={isMobile && "none"}>
                    Titre de la mission
                  </Table.Th>
                  <Table.Th data-testid="th" display={isMobile && "none"}>
                    Date de début
                  </Table.Th>
                  <Table.Th data-testid="th" display={isMobile && "none"}>
                    Date de fin
                  </Table.Th>
                  <Table.Th data-testid="th" display={isMobile && "none"}>
                    Type
                  </Table.Th>
                  <Table.Th data-testid="th" display={isMobile && "none"}>
                    Montant HT
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody data-testid="tbody">
                {missions &&
                  missions.map((mission) => (
                    <Table.Tr
                      data-testid="tr"
                      onClick={() => {
                        history.push(
                          "/freelance/missions/ongoing/" + mission.id
                        );
                      }}
                      key={mission.id}
                      _hover={{ bg: "yalink.yellow", cursor: "pointer" }}
                    >
                      <Table.Td data-testid="td" display={isMobile && "none"}>
                        <Badge variant="solid" bg="#003B36">
                          {mission.client_organization_name}
                        </Badge>
                      </Table.Td>
                      <Table.Td data-testid="td">{mission.name}</Table.Td>
                      <Table.Td data-testid="td" display={isMobile && "none"}>
                        {mission.start_date}
                      </Table.Td>
                      <Table.Td data-testid="td" display={isMobile && "none"}>
                        {mission.end_date}
                      </Table.Td>
                      <Table.Td data-testid="td" display={isMobile && "none"}>
                        <Badge variant="solid" bg="yalink.yellow" color="black">
                          {mission.service_type === "regie"
                            ? "Régie"
                            : "Forfait"}
                        </Badge>
                      </Table.Td>
                      <Table.Td data-testid="td" display={isMobile && "none"}>
                        {mission.service_type === "regie"
                          ? mission.total_working_days +
                            " jours à " +
                            mission.freelance_fees_per_day +
                            " €"
                          : mission.total_amount_billed}
                      </Table.Td>
                    </Table.Tr>
                  ))}
              </Table.Tbody>
            </Table.Table>
          </Table.Container>
        </Center>
        <Center>
          <Box mb="10px" padding="5">
            <PerPageSelector
              perPage={perPage}
              setPerPage={setPerPage}
              setPage={setPage}
              setIsLoading={setIsLoading}
            />
          </Box>
        </Center>
        <Pagination
          totalItems={totalItems}
          perPage={perPage}
          handleSelectedPage={handleSelectedPage}
          page={page}
        />
      </>
    )
  );
}
