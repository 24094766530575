export const UserProfiles = {
  FREELANCE: "freelance",
  ADMIN: "admin",
  COMPANY: "company",
};

export function hasAuthHeader() {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user && user.auth_token) {
    return user;
  } else {
    return false;
  }
}

export function getUserProfile() {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    if (user.auth_token) {
      if (user.admin) {
        return UserProfiles.ADMIN;
      }
      if (user.external) {
        return UserProfiles.COMPANY;
      }
      return UserProfiles.FREELANCE;
    }
  }
}

export default function authHeader() {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user && user.auth_token) {
    return { Authorization: "Bearer " + user.auth_token };
  } else {
    return {};
  }
}

export function getEmailFromAuth() {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.email) {
    return user.email;
  }
  return null;
}
