import React from 'react'

import {
  Box,
  Badge,
  Text,
  Flex,
  Divider,
} from "@chakra-ui/react";

export interface LegalDataProps {
  vatPercentage: Number
}

export interface ActivityReportProps {
  vat_and_margin_excluded_total_amount: Number
  vat_included_total: Number
  vat_included_freelance_total: Number
  vat_excluded_yalink_activity_margin: Number
  vat_included_margin_total: Number
}

export interface AmountsProps {
  activityReport: ActivityReportProps
  legalData: LegalDataProps
  isClient: boolean
}

export default function Amounts({ activityReport, legalData, isClient }: AmountsProps) {
  return (
    <>
      <Box mb="5%">
        <Divider bgColor="black" colorScheme="blackAlpha" h="0.5%" />
      </Box>
      <Box mb="10%">
        <Flex justify="space-between">
          <Box>
            <Text color="#4B4A4A" fontSize="25px">
              Total HT
            </Text>
          </Box>
          <Box>
            <Badge colorScheme="green" fontSize="1.5rem">
              {activityReport.vat_and_margin_excluded_total_amount +
                " €"}
            </Badge>
          </Box>
        </Flex>
      </Box>
      <Box mb="5%" p={2}>
        <Flex justify="space-between">
          <Box>
            <Text color="#4B4A4A" fontSize="15px">
              TVA
            </Text>
          </Box>
          <Box>
            <Text color="yalink.green" fontWeight="semibold" fontSize="15px">
              {legalData.vatPercentage + " %"}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box mb="5%" p={2}>
        <Flex justify="space-between">
          <Box>
            <Text color="#4B4A4A" fontSize="15px">
              Total Freelance TTC
            </Text>
          </Box>
          <Box>
            <Text color="yalink.green" fontWeight="semibold" fontSize="15px">
              {activityReport.vat_included_freelance_total + " €"}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box mb="5%" p={2}>
        <Flex justify="space-between">
          <Box>
            <Text color="#4B4A4A" fontSize="15px">
              Frais Yalink (HT)
            </Text>
          </Box>
          <Box>
            <Text color="yalink.green" fontWeight="semibold" fontSize="15px">
              {activityReport.vat_excluded_yalink_activity_margin + " €"}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box mb="5%" p={2}>
        <Flex justify="space-between">
          <Box>
            <Text color="#4B4A4A" fontSize="15px">
              Frais Yalink (TTC)
            </Text>
          </Box>
          <Box>
            <Text color="yalink.green" fontWeight="semibold" fontSize="15px">
              {activityReport.vat_included_margin_total + " €"}
            </Text>
          </Box>
        </Flex>
      </Box>
      {!isClient && (
        <>
          <Box mb="5%" p={2}>
            <Flex justify="space-between">
              <Box>
                <Text color="#4B4A4A" fontSize="15px">
                  A payer par le client
                </Text>
              </Box>
              <Box>
                <Text
                  color="yalink.green"
                  fontWeight="semibold"
                  fontSize="15px"
                >
                  {activityReport.vat_included_total + " €"}
                </Text>
              </Box>
            </Flex>
          </Box>
        </>
      )}
      <Box mb="5%" bgColor="#EDF7F2" p={2}>
        <Flex justify="space-between" align="center">
          <Box>
            <Text color="#4B4A4A" fontSize="20px">
              Vous {isClient ? "payerez" : "recevrez"} (TTC)
            </Text>
          </Box>
          <Box>
            <Text color="yalink.green" fontWeight="semibold" fontSize="17px">
              {isClient ? (activityReport.vat_included_total + " €") : (activityReport.vat_included_freelance_total + " €")}
            </Text>
          </Box>
        </Flex>
      </Box>
    </>
  )
}
