import React from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import UserService from "../../services/user.service";
import Auth from "../../services/auth.service";

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Box,
  Divider,
  Center,
  Heading,
  useToast,
} from "@chakra-ui/react";

function ChangePassword() {
  const history = useHistory();
  const toast = useToast();

  async function handleSubmit(values, actions) {
    await UserService.changePassword({
      oldPassword: values.oldPassword,
      password: values.password,
    })
      .then(() => {
        toast({
          position: "top",
          title: "Ton mot de passe a été mis à jour",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        if (Auth.isAdmin()) {
          history.push("/admin");
        } else {
          history.push("/freelance/home");
        }
      })
      .catch(() => {
        toast({
          position: "top",
          title:
            "Nous n'avons pas pu mettre à jour ton mot de passe. Vérifie les informations soumises et réessaie !",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
    actions.setSubmitting(false);
  }

  function validatePassword(value) {
    let error = "";
    const passwordRegex = /(?=.*[0-9])/;
    if (!value) {
      error = "*Il va falloir remplir ce champ 😱";
    } else if (value.length < 8) {
      error = "*Doit comprendre au moins 8 charactères";
    } else if (!passwordRegex.test(value)) {
      error = "*Doit comprendre au moins un chiffre";
    }
    return error;
  }

  function validateConfirmPassword(password, value) {
    let error;
    if (!value) {
      error = "*Il va falloir remplir ce champ 😱";
    } else {
      if (value !== password) {
        error = "*Les mots de passe ne correspondent pas";
      }
    }
    return error;
  }

  return (
    <Center>
      <Box
        w="60%"
        mt="100px"
        mb="20px"
        padding="5"
        maxW="3xl"
        borderWidth={1}
        borderRadius={8}
        boxShadow="md"
      >
        <Heading as="h1" size="lg" mb="25px">
          Change ton mot de passe
        </Heading>
        <Formik
          initialValues={{ oldPassword: "", password: "", confirmpassword: "" }}
          onSubmit={(values, actions) => {
            handleSubmit(values, actions);
          }}
        >
          {(props) => (
            <Form>
              <Field name="oldPassword">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.oldPassword && form.touched.oldPassword
                    }
                    isRequired
                  >
                    <FormLabel htmlFor="oldPassword">
                      Mot de passe précédent
                    </FormLabel>
                    <Input
                      {...field}
                      id="oldPassword"
                      type="password"
                      maxW="sm"
                    />
                    <FormErrorMessage>
                      {form.errors.oldPassword}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Divider padding="3" mb="20px" />
              <Field name="password" validate={validatePassword}>
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.password && form.touched.password}
                    isRequired
                  >
                    <FormLabel htmlFor="password">
                      Nouveau mot de passe
                    </FormLabel>
                    <Input {...field} id="password" type="password" maxW="sm" />
                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field
                name="confirmpassword"
                validate={(value) =>
                  validateConfirmPassword(props.values.password, value)
                }
              >
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.confirmpassword &&
                      form.touched.confirmpassword
                    }
                    isRequired
                  >
                    <FormLabel htmlFor="confirmpassword">
                      Confirmation du mot de passe
                    </FormLabel>
                    <Input
                      {...field}
                      id="confirmpassword"
                      type="password"
                      maxW="sm"
                    />
                    <FormErrorMessage>
                      {form.errors.confirmpassword}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Button
                mt={4}
                colorScheme="teal"
                isLoading={props.isSubmitting}
                type="submit"
                disabled={
                  !props.isValid ||
                  (Object.keys(props.touched).length === 0 &&
                    props.touched.constructor === Object)
                }
              >
                Changer
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Center>
  );
}

export default ChangePassword;
