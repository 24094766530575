import React, { useState, useEffect } from "react";
import { Box, Center, Heading } from "@chakra-ui/react";

import { querystring } from "../../libs/queryStringLib";
import Offers from "../../services/offers.service";
import Auth from "../../services/auth.service";
import { JobOfferCard } from "../../components";
import { useAppContext } from "../../libs/contextLib";
import { Stars } from "@styled-icons/bootstrap/Stars";

function OfferView() {
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [offerId] = useState(
    querystring("id", window.location.href)
      ? querystring("id", window.location.href)
      : ""
  );
  const [offer, setOffer] = useState(null);
  const [freelanceList, setFreelanceList] = useState(null);

  // When a company is seeing an offer, they are authenticated via a token present
  // in query parameters. They should not be able to see certain profile fields
  // const [authenticatedFromToken, setauthenticatedFromToken] = useState(false);

  useEffect(() => {
    async function onLoad() {
      Auth.loginFromQueryStringToken();

      await Offers.getOfferId(offerId)
        .then((response) => {
          setOffer(response.data.offer);
          if (response.data.offer.tentativeFreelanceId.length !== 0) {
            Offers.getFreelancesSummaryByOfferId(offerId).then((response) => {
              setFreelanceList(response.data.freelances);
            });
          }
        })
        .catch(() => {
          userHasAuthenticated(false);
        });

      setIsLoading(false);
      // await Offers.getAllOffers()
      //   .then((response) => {
      //     setOffers(response.data.offers);
      //   })
      //   .catch(() => {
      //     userHasAuthenticated(false);
      //   });
      // await Users.getAllFreelances()
      //   .then((response) => {
      //     setFreelanceList(response.data.freelances);
      //   })
      //   .catch(() => {
      //     userHasAuthenticated(false);
      //   });
      // setIsLoading(false);
      // setReloadOfferList(false);
    }
    if (isLoading) {
      onLoad();
    }
  }, [isLoading, offerId, freelanceList, userHasAuthenticated, offer]);
  return (
    !isLoading && (
      <Box>
        <Center mt="100px" ml="20px" mr="20px" mb="20px" padding="5">
          <Heading as="h1" size="lg" mb="25px">
            Visualisation Profils <Stars size="40px" color="#F8C708" />
          </Heading>
        </Center>
        <Center>
          <Box w="80%" mb="100px" padding="5">
            <JobOfferCard
              offer={offer}
              // offers={offers}
              // setOffers={setOffers}
              freelanceList={freelanceList}
              readOnly={true}
              // setSelectedAO={setSelectedAO}
              // setReloadOfferList={setReloadOfferList}
              // onOpen={onOpen}
            />
          </Box>
        </Center>
      </Box>
    )
  );
}

export default OfferView;
