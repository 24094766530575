import axios from "axios";
import authHeader from "../libs/authHeaderLib";
import config from "../config";

class SkillsService {
  async addSkillToFreelance(skill) {
    let path = "/skills/add";
    return axios.put(config.API_URL + path, skill, {
      headers: authHeader(),
    });
  }

  async removeSkillFromFreelance(skill) {
    let path = "/skills/remove";
    return axios.put(config.API_URL + path, skill, {
      headers: authHeader(),
    });
  }

  async getAllSkills() {
    let path = "/skills/all";
    return axios.get(config.API_URL + path, {
      headers: authHeader(),
    });
  }
}

export default new SkillsService();
