import { useState, useEffect } from "react";

import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Text,
  SimpleGrid,
  Heading,
  Button,
} from "@chakra-ui/react";

function BankAccount({
  legalData,
  setLegalData,
  handleLegalDataUpdate,
  setIsUpdatingLegalData,
  isUpdatingLegalData,
}) {
  const [isMobile, setIsMobile] = useState(false);
  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    applyResponsive();
  }, []);

  window.addEventListener("resize", applyResponsive);

  return (
    <Box
      mt="2%"
      mb={isMobile ? null : "80px"}
      padding="5"
      borderWidth={1}
      borderRadius={8}
      boxShadow="md"
      bgColor="#fff"
    >
      <Heading mb="20px" size="md" color="gray.600">
        Ton compte en banque
      </Heading>
      <Text fontSize="xs" mb={5}>
        Pour pouvoir te payer chaque mois nous avons besoin de connaitre ton RIB
      </Text>
      <SimpleGrid columns={isMobile ? 1 : 2} spacing={10} mb={10}>
        <Box>
          <FormControl isRequired>
            <FormLabel>IBAN</FormLabel>
            <Input
              data-testid="First Name"
              value={legalData.iban}
              onChange={(e) => {
                let tempLegalData = legalData;
                tempLegalData.iban = e.target.value;
                setLegalData({ ...tempLegalData });
              }}
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl isRequired>
            <FormLabel>BIC</FormLabel>
            <Input
              data-testid="Last Name"
              value={legalData.bic}
              onChange={(e) => {
                let tempLegalData = legalData;
                tempLegalData.bic = e.target.value;
                setLegalData({ ...tempLegalData });
              }}
            />
          </FormControl>
        </Box>
      </SimpleGrid>
      <Button
        mt={4}
        width="100%"
        color="white"
        borderRadius="0"
        bg="yalink.green"
        _hover={{ bg: "yalink.yellow" }}
        isLoading={isUpdatingLegalData}
        type="submit"
        onClick={() => {
          handleLegalDataUpdate(setIsUpdatingLegalData);
        }}
        disabled={!(legalData.iban && legalData.bic) || isUpdatingLegalData}
      >
        Enregistrer
      </Button>
    </Box>
  );
}

export default BankAccount;
