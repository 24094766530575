import styled from 'styled-components';

export const NotFoundContainerStyle = styled.div`
  padding-top: 100px;
  text-align: center;
  padding-bottom: 200px;
  color: #4B4A4A;
`;

export const Img = styled.img`
  padding-right: 0;
  padding-bottom: 100px;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 500px;
`;