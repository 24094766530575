import React from "react";

import { Box, Badge, Text, Button, Flex, Divider } from "@chakra-ui/react";
import { monthNamesFr } from "../../../libs/monthsLib";
import { Briefcase } from "@styled-icons/boxicons-solid/Briefcase";

export default function ProgressSummaryRegie({
  values,
  getValues,
  month,
  year,
  days,
  halfDays,
  mission,
  activityReport,
  isSendingRequest,
}) {
  return (
    <Box
      h="100%"
      bg="white"
      color="black"
      borderRadius={7}
      padding={10}
      pt={5}
      boxShadow="md"
    >
      <Box mb="4%">
        <Text align="left" fontWeight="bold">
          {monthNamesFr[month - 1]} {year}
        </Text>
      </Box>
      <Box mb="2%">
        <Text color="grey">Jours travaillés</Text>
      </Box>
      <Box mb="2%">
        <Flex>
          <Box mr="4%">
            <Briefcase size="20px" />
          </Box>
          <Box>
            <Text>{days.length + 0.5 * halfDays.length} </Text>
          </Box>
        </Flex>
      </Box>
      <Box mb="2%">
        <Text color="grey">TJM</Text>
      </Box>
      <Box mb="2%">
        <Flex>
          <Box mr="4%">
            <Briefcase size="20px" />
          </Box>
          <Box>
            <Text>{mission.freelance_fees_per_day}</Text>
          </Box>
        </Flex>
      </Box>
      <Box mb="2%">
        <Text color="grey">Frais</Text>
      </Box>
      <Box mb="6%">
        <Flex>
          <Box mr="4%">
            <Briefcase size="20px" />
          </Box>
          <Box>
            <Text>{getValues(values.frais) + " € HT"}</Text>
          </Box>
        </Flex>
      </Box>
      <Box mb="5%">
        <Divider bgColor="black" colorScheme="blackAlpha" h="0.5%" />
      </Box>
      <Box mb="3%">
        <Badge colorScheme="green" fontSize="1.5rem">
          {getValues(values.frais) +
            mission.freelance_fees_per_day *
              (days.length + halfDays.length * 0.5) +
            " €"}
        </Badge>
      </Box>
      <Box mb="5%">
        <Text color="grey">Montant HT de votre facture</Text>
      </Box>
      <Box>
        <Button
          p={4}
          bgColor="yalink.green"
          color="white"
          w="100%"
          borderRadius={0}
          type="submit"
          disabled={
            activityReport.status === "approved" ||
            activityReport.status === "pending_approval" ||
            activityReport.status === "nothing_declared" ||
            isSendingRequest
          }
          isLoading={isSendingRequest}
        >
          Valider mon CRA
        </Button>
      </Box>
    </Box>
  );
}
