import axios from "axios";
import authHeader from "../libs/authHeaderLib";
import config from "../config";

class ApplicationService {
  async apply(dealId, application) {
    let path = "/application/" + dealId;
    return axios.post(config.API_URL + path, application, {
      headers: authHeader(),
    });
  }

  async refer(dealId, referral) {
    let path = "/application/" + dealId + "/referral";
    return axios.post(config.API_URL + path, referral, {
      headers: authHeader(),
    });
  }
}

export default new ApplicationService();
