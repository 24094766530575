import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { useHistory } from "react-router-dom";
import {
  Box,
  Grid,
  GridItem,
  Text,
  Stack,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Divider,
  Checkbox,
  Link,
  HStack,
  Tooltip,
  useToast,
  Center,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import User from "../../services/user.service";
import TutoLinkedin from "../../videos/LinkedIn-Import.gif";

function FreelanceLinkedIn() {
  const toast = useToast();
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(false);
  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    applyResponsive();
  }, []);

  window.addEventListener("resize", applyResponsive);

  function validateLinkedinUrl(value) {
    let error;
    if (!value) {
      error = "*Il va falloir remplir ce champ 😱";
    }
    if (!value.startsWith("https://www.linkedin.com/in/")) {
      error = "L'URL Linkedin doit commencer par https://www.linkedin.com/in/";
    }
    return error;
  }
  function validateAgreement(value) {
    let error;
    if (!value || value !== true) {
      error = "*Il est indispensable d'accepter afin d'utiliser Yalink";
    }
    return error;
  }

  async function handleSubmit(values, actions) {
    const details = { linkedinUrl: values.linkedinUrl };
    await User.updateFreelanceDetails(details)
      .then(() => {
        toast({
          position: "top",
          title: "Lien Linkedin mis à jour !",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch(() => {});
    actions.setSubmitting(false);
    history.push("/freelance/home");
  }

  return (
    <Grid
      templateColumns={isMobile ? "1fr" : "1fr 1fr"}
      h="flex"
      mb={isMobile ? "70px" : null}
    >
      <GridItem h="100%" bg="white">
        <Box ml="10%" mr="10%" mt="50px">
          <Text fontSize="20px" fontWeight="bold" mb="8%">
            Génère ton profil en partant de ton LinkedIn
          </Text>
          <Formik
            initialValues={{
              linkedinUrl: "",
            }}
            onSubmit={(values, actions) => {
              handleSubmit(values, actions);
            }}
          >
            {(props) => (
              <Form>
                <Field name="linkedinUrl" validate={validateLinkedinUrl}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.linkedinUrl && form.touched.linkedinUrl
                      }
                    >
                      <FormLabel htmlFor="linkedinUrl">
                        <HStack>
                          <Text>Lien vers ton profil linkedin</Text>
                          <Tooltip
                            label="En créant ton profil à partir de Linkedin, nous pouvons dire bye bye au dossier de compétences !"
                            fontSize="md"
                          >
                            <InfoIcon />
                          </Tooltip>
                        </HStack>
                      </FormLabel>
                      <Input
                        {...field}
                        id="linkedinUrl"
                        placeholder="https://linkedin.com/in/jack.mongoose"
                      />
                      <FormErrorMessage>
                        {form.errors.linkedinUrl}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Divider padding="3" mb="10%" maxW="90%" mt="8%" />
                <Field name="agree" validate={validateAgreement}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.agree && form.touched.agree}
                      isRequired
                    >
                      <FormLabel htmlFor="agree">
                        En cochant la case ci-dessous, j'accepte d'importer les
                        données de mon profil public LinkedIn afin de créer mon
                        compte. Notre politique de confidentialité peut aussi
                        être consultée{" "}
                        <Link
                          href="https://www.yalink.fr/politique-de-confidentialite/"
                          isExternal
                          color="teal.500"
                        >
                          {" "}
                          par ici.{" "}
                        </Link>
                        Une question? N'hésitez pas à contacter
                        freelance@yalink.fr
                      </FormLabel>
                      <Checkbox {...field} id="agree">
                        J'accepte
                      </Checkbox>
                      <FormErrorMessage>{form.errors.agree}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Stack
                  direction={["column", "row"]}
                  fontWeight="semibold"
                  lineHeight="tight"
                  mt={isMobile ? "60px" : "10%"}
                  mb="10%"
                  mr="20px"
                  ml="20px"
                  gap={isMobile ? 10 : 30}
                  justify="center"
                >
                  <Button
                    variant="outline"
                    color="yalink.green"
                    _hover={{ bg: "gray.200" }}
                    _checked={{
                      bg: "gray.200",
                      color: "white",
                      borderColor: "gray.200",
                    }}
                    type="submit"
                    onClick={() => {
                      history.push("/freelance/about/preferences");
                    }}
                  >
                    Précédent
                  </Button>

                  <Button
                    color="white"
                    bg="yalink.green"
                    _hover={{ bg: "gray.200" }}
                    _checked={{
                      bg: "gray.200",
                      color: "white",
                      borderColor: "gray.200",
                    }}
                    type="submit"
                    disabled={
                      !(props.isValid && props.dirty) || props.isSubmitting
                    }
                  >
                    Continuer
                  </Button>
                  <Button
                    variant="outline"
                    color="yalink.green"
                    _hover={{ bg: "gray.200" }}
                    _checked={{
                      bg: "gray.200",
                      color: "white",
                      borderColor: "gray.200",
                    }}
                    type="submit"
                    onClick={() => {
                      history.push("/freelance/home");
                    }}
                  >
                    Passer cette étape
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </GridItem>
      <GridItem h="100%" bg="#f8fcfc">
        <Box align="center" mt="15%">
          <Text fontSize="35px" fontWeight="bold" color="yalink.green">
            Gagne du temps
          </Text>
          <Text color="yalink.green" fontSize="25px">
            en important ton profil depuis LinkedIn
          </Text>
        </Box>
        <Center mt="4%">
          <img width="60%" src={TutoLinkedin} alt="Tutoriel Linkedin" />
        </Center>
      </GridItem>
    </Grid>
  );
}

export default FreelanceLinkedIn;
