import React from "react";

import {
  Box,
  Text,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

export default function ValidationModal({ isOpen, onClose }) {
  const history = useHistory();

  return (
    <Box>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay backdropFilter="blur(10px)" />
        <ModalContent>
          <ModalBody>
            <Flex justify="center" align="center" direction="column">
              <Box mb="3%" alignSelf="start">
                <Text fontWeight="semibold" fontSize="22px" align="left">
                  L’état d’avancement est validé
                </Text>
              </Box>
              <Box mb="3%">
                <Text fontSize="18px">
                  Vous serez avertis quand le client l’aura validé et quand le
                  paiement aura aboutit.
                </Text>
              </Box>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              bgColor="yalink.green"
              mr={3}
              onClick={() => {
                history.push("/freelance/missions/");
              }}
              borderRadius="0"
              _hover={{ bgColor: "yalink.green" }}
            >
              <Text color="white">Retour à vos missions !</Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
