import rocketImg from "../../images/rocketlaunch.svg";
import moon from "../../images/to_the_moon.svg";

export const homeObjOne = {
  img: rocketImg,
  alt: "Rocket launch",
  start: "",
};

export const successfulSignupObj = {
  img: moon,
  alt: "Astronaut on the moon",
  start: true,
};
