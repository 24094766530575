import axios from "axios";
import authHeader from "../libs/authHeaderLib";
import config from "../config";

class NotificationService {
  async getNotificationPreferences() {
    let path = "/notification/preferences";

    return axios.get(config.API_URL + path, {
      headers: authHeader(),
    });
  }

  async updateNotificationPreferences(preferences) {
    let path = "/notification/preferences";

    return axios.put(config.API_URL + path, preferences, {
      headers: authHeader(),
    });
  }

  async getNotifications() {
    let path = "/notification";
    return axios.get(config.API_URL + path, {
      headers: authHeader(),
    });
  }
}

export default new NotificationService();
