import React, { useState } from "react";

import {
  Stack,
  Box,
  Textarea,
  Input,
  DrawerBody,
  FormLabel,
  InputGroup,
  DrawerFooter,
  Button,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  HStack,
  Text,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { AddIcon, EditIcon } from "@chakra-ui/icons";
import User from "../../services/user.service";

function NewFreelanceExperience(props) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const firstField = React.useRef();
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [location, setLocation] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [description, setDescription] = useState("");
  const [previousId, setPreviousId] = useState(null);

  const text = {
    formation: {
      titlePlaceholder: "École de mécanique",
      typeText: "Type de formation",
      typePlaceholder: "Diplôme de Genie Meca",
    },
    experience: {
      titlePlaceholder: "Yalink Corp.",
      typeText: "Type d'expérience",
      typePlaceholder: "Ingénieur Projet",
    },
  };

  function isFormValid() {
    if (title === "") {
      return false;
    }
    if (type === "") {
      return false;
    }
    if (from === "") {
      return false;
    } else {
      if (+from <= 1950 || +from >= 2022) {
        return false;
      }
    }
    if (to !== "") {
      if (+from > +to) {
        return false;
      }
    }
    if (description === "") {
      return false;
    }
    return true;
  }

  let elemName = "";
  if (props.type === "formation") {
    elemName = "education";
  } else {
    elemName = "experiences";
  }

  function handleUpdate() {
    var result = props.freelanceDetails[elemName].filter((obj) => {
      if (elemName === "education") {
        return (
          obj.degree_name === props.keyName &&
          obj.description === props.keyDescription
        );
      }
      if (elemName === "experiences") {
        return (
          obj.title === props.keyName &&
          obj.description === props.keyDescription
        );
      }
      return null;
    });
    if (elemName === "education") {
      setTitle(result[0].school);
      setType(result[0].degree_name);
      setLocation(result[0].location);
      setFrom(result[0].starts_at.year);
      setTo(result[0].ends_at ? result[0].ends_at.year : null);
      setDescription(result[0].description);
      setPreviousId(result[0].school + "-" + result[0].degree_name);
    }
    if (elemName === "experiences") {
      setTitle(result[0].company);
      setType(result[0].title);
      setLocation(result[0].location);
      setFrom(result[0].starts_at !== null ? result[0].starts_at.year : null);
      setTo(result[0].ends_at ? result[0].ends_at.year : null);
      setDescription(result[0].description);
      setPreviousId(result[0].company + "-" + result[0].title);
    }
    return true;
  }

  async function submitForm(verb) {
    const details = {
      freelanceId: props.freelanceId,
      [elemName]: {
        [verb]: {
          title: title,
          type: type,
          location: location,
          from: from,
          to: to ? to : "présent",
          description: description,
          previousId: previousId ? previousId : null,
        },
      },
    };
    await User.updateFreelanceDetails(details)
      .then((response) => {
        toast({
          position: "top",
          title: props.type + " mise à jour !",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        let tempDetails = props.freelanceDetails;
        tempDetails[elemName] = response.data.details[elemName];
        props.setFreelanceDetails({ ...tempDetails });
      })
      .catch(() => {});
  }

  function onUpdate() {
    handleUpdate();
    onOpen();
  }
  return (
    <>
      {props.keyName ? (
        <IconButton size="sm" icon={<EditIcon />} onClick={onUpdate} />
      ) : (
        <IconButton icon={<AddIcon />} onClick={onOpen} />
      )}

      <Drawer
        isOpen={isOpen}
        placement="right"
        initialFocusRef={firstField}
        onClose={onClose}
        size="lg"
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px">
              {(props.keyName ? "Mise à jour " : "Nouvelle ") + props.type}
            </DrawerHeader>

            <DrawerBody>
              <Stack spacing="24px">
                <Box>
                  <FormLabel htmlFor="title">Titre</FormLabel>
                  <Input
                    ref={firstField}
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder={
                      props.type === "formation"
                        ? text.formation.titlePlaceholder
                        : text.experience.titlePlaceholder
                    }
                  />
                </Box>

                <Box>
                  <FormLabel htmlFor="type" isRequired>
                    {props.type === "formation"
                      ? text.formation.typeText
                      : text.experience.typeText}
                  </FormLabel>
                  <Input
                    id="type"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    placeholder={
                      props.type === "formation"
                        ? text.formation.typePlaceholder
                        : text.experience.typePlaceholder
                    }
                  />
                </Box>
                <Box>
                  <FormLabel htmlFor="location">Lieu</FormLabel>
                  <Input
                    id="location"
                    placeholder="Paris"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </Box>
                <Box>
                  <FormLabel htmlFor="length">Durée</FormLabel>
                  <InputGroup>
                    <HStack>
                      <Input
                        id="from"
                        placeholder="2020"
                        value={from}
                        onChange={(e) => setFrom(e.target.value)}
                      />
                      <Text>à</Text>
                      <Input
                        id="to"
                        placeholder="2021"
                        value={to}
                        onChange={(e) => setTo(e.target.value)}
                      />
                    </HStack>
                  </InputGroup>
                </Box>
                <Box>
                  <FormLabel htmlFor="desc">Description</FormLabel>
                  <Textarea
                    id="desc"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Box>
              </Stack>
            </DrawerBody>

            <DrawerFooter borderTopWidth="1px">
              <Button variant="outline" mr={3} onClick={onClose}>
                Annuler
              </Button>
              {props.keyName ? (
                <>
                  <Button
                    colorScheme="red"
                    mr={3}
                    onClick={(e) => submitForm("remove")}
                  >
                    Supprimer
                  </Button>
                  <Button
                    colorScheme="teal"
                    isDisabled={!isFormValid()}
                    onClick={(e) => submitForm("update")}
                  >
                    Sauver
                  </Button>
                </>
              ) : (
                <Button
                  colorScheme="teal"
                  isDisabled={!isFormValid()}
                  onClick={(e) => submitForm("add")}
                >
                  Ajouter
                </Button>
              )}
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
}

export default NewFreelanceExperience;
