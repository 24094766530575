import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import {
  Grid,
  GridItem,
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  useToast,
  Text,
  Center,
  Select,
} from "@chakra-ui/react";
import User from "../../services/user.service";
import { querystring } from "../../libs/queryStringLib";

function FreelanceRegister() {
  const history = useHistory();
  const toast = useToast();
  const [isMobile, setIsMobile] = useState(false);
  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    async function onLoad() {
      let token = querystring("token", window.location.href);
      if (token && token !== "") {
        await User.verifyEmail({
          token: querystring("token", window.location.href),
        })
          .then(() => {
            toast({
              position: "top",
              title: "Ton email est maintenant verifié",
              description: "Complète ton profil en remplissant ce formulaire",
              status: "success",
              duration: 12000,
              isClosable: true,
            });
          })
          .catch(() => {});
      }
    }
    onLoad();
    applyResponsive();
  }, [toast]);

  window.addEventListener("resize", applyResponsive);

  async function handleSubmit(values, actions) {
    await User.createFreelance({
      email: getEmailFromUser(),
      firstName: values.firstname,
      lastName: values.lastname,
      phone: values.phone,
      acquisitionChannel: values.acquisitionChannel,
    })
      .then((response) => {
        if (response.status === 200) {
          toast({
            position: "top",
            title: "Votre profil à été créé avec succès !",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      })
      .catch(() => {
        toast({
          position: "top",
          title: "Nous n'avons pas pu finaliser la création de ton profil",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
    actions.setSubmitting(false);
    history.push("/freelance/about/job");
  }
  function validateName(value) {
    let error;
    if (!value) {
      error = "*Il va falloir remplir ce champ 😱";
    }
    return error;
  }
  function getEmailFromUser() {
    const user = JSON.parse(localStorage.getItem("user"));
    if ("email" in user) {
      return user.email;
    }
    return null;
  }
  function getFirstNameFromUser() {
    const user = JSON.parse(localStorage.getItem("user"));
    if ("first_name" in user) {
      return user.first_name;
    }
    return null;
  }
  function getLastNameFromUser() {
    const user = JSON.parse(localStorage.getItem("user"));
    if ("last_name" in user) {
      return user.last_name;
    }
    return null;
  }

  return (
    <>
      <Grid
        templateColumns={isMobile ? "1fr" : "1fr 1fr"}
        h="flex"
        mb={isMobile ? "70px" : null}
      >
        <GridItem h="100%" bg="white">
          <Box ml="10%" mr="10%" mt="50px">
            <Heading as="h1" size="lg" mb="25px">
              Faisons connaissance
            </Heading>
            <Formik
              initialValues={{
                firstname: getFirstNameFromUser() ? getFirstNameFromUser() : "",
                lastname: getLastNameFromUser() ? getLastNameFromUser() : "",
                phone: "",
                acquisition_channel: "",
              }}
              onSubmit={(values, actions) => {
                handleSubmit(values, actions);
              }}
            >
              {(props) => (
                <Form>
                  <Field name="firstname" validate={validateName}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.firstname && form.touched.firstname
                        }
                        isRequired
                      >
                        <FormLabel htmlFor="firstname">Prénom</FormLabel>
                        <Input
                          {...field}
                          id="firstname"
                          placeholder="Jack"
                          mb="25px"
                        />
                        <FormErrorMessage>
                          {form.errors.firstname}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="lastname" validate={validateName}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.lastname && form.touched.lastname
                        }
                        isRequired
                      >
                        <FormLabel htmlFor="lastname">Nom</FormLabel>
                        <Input
                          {...field}
                          id="lastname"
                          placeholder="Mongoose"
                          mb="25px"
                        />
                        <FormErrorMessage>
                          {form.errors.lastname}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="phone" validate={validateName}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.phone && form.touched.phone}
                        isRequired
                      >
                        <FormLabel htmlFor="phone">Téléphone</FormLabel>
                        <Input
                          {...field}
                          id="phone"
                          placeholder="06 79 21 12 09"
                        />
                        <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Box mt="2vh">
                    <Field
                      validate={validateName}
                      name="acquisitionChannel"
                      as="select"
                    >
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.phone && form.touched.phone}
                          isRequired
                        >
                          <FormLabel htmlFor="phone">
                            D'où venez-vous ?
                          </FormLabel>
                          <Select
                            placeholder="Sélectionnez une option"
                            {...field}
                          >
                            <option value="Linkedin">Linkedin</option>
                            <option value="Bouche à oreille">
                              Bouche à oreille
                            </option>
                            <option value="E-mail">E-mail</option>
                            <option value="Google">Google</option>
                            <option value="Cooptation">Cooptation</option>
                            <option value="Presse">Presse</option>
                            <option value="Partenaires">Partenaires</option>
                            <option value="Facebook">Facebook</option>
                            <option value="Youtube">Youtube</option>
                            <option value="Instagram">Instagram</option>
                          </Select>
                          <FormErrorMessage>
                            {form.errors.phone}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>

                  <Box>
                    <Button
                      mt="5vh"
                      mb="10%"
                      color="white"
                      bg="yalink.green"
                      _hover={{ bg: "gray.200" }}
                      _checked={{
                        bg: "gray.200",
                        color: "white",
                        borderColor: "gray.200",
                      }}
                      isLoading={props.isSubmitting}
                      type="submit"
                      disabled={!(props.isValid && props.dirty)}
                    >
                      Continuer
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </GridItem>
        <GridItem h="100%" bg="#f8fcfc">
          <Box align="center" mt="10%">
            <Text fontSize="35px" fontWeight="bold" color="yalink.green">
              Bienvenue
            </Text>
            <Text color="yalink.green" fontSize="25px">
              dans une nouvelle façon de travailler
            </Text>
          </Box>
          <Center mt="4%">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/68EGFZD7JgU?controls=1&autoplay=1&mute=1"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Center>
        </GridItem>
      </Grid>
    </>
  );
}

export default FreelanceRegister;
