import { useState, useEffect, useMemo } from "react";

import {
  Box,
  Text,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Button,
  Heading,
  FormErrorMessage,
} from "@chakra-ui/react";
import countryList from "react-select-country-list";
import Select from "react-select";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";

function LegalRepresentation({
  legalData,
  setLegalData,
  handleLegalDataUpdate,
  setIsUpdatingLegalData,
  isUpdatingLegalData,
}) {
  const [address, setAddress] = useState(legalData.legalRepresentativeAddress);
  const options = useMemo(() => countryList().getData(), []);
  const [isMobile, setIsMobile] = useState(false);
  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  window.addEventListener("resize", applyResponsive);

  const disabledButton =
    legalData.firstName === "" ||
    legalData.lastName === "" ||
    legalData.birthDate === "" ||
    legalData.citizenship === "" ||
    legalData.birthCity === "" ||
    legalData.birthCountry === "";

  useEffect(() => {
    applyResponsive();
  }, []);

  return (
    <Box
      mb="3%"
      padding="5"
      mt={isMobile && 10}
      borderWidth={1}
      borderRadius={8}
      boxShadow="md"
      bgColor="#fff"
    >
      <Heading mb="20px" size="md" color="gray.600">
        Représentant Légal
      </Heading>
      <Text mb={2} fontSize="12px">
        Dans le cadre de ton activité sur Yalink, et pour pouvoir te payer, nos
        partenaires bancaires ont besoin de connaître ta date de naissance ainsi
        que ta nationalité. Le représentant légal est la personne habilitée à
        émettre des factures et à signer le mandat de facturation pour le compte
        de l’entreprise.
      </Text>
      <SimpleGrid columns={2} spacing={isMobile ? 5 : 10} mb={10}>
        <Box>
          <FormControl isRequired isInvalid={legalData.firstName === ""}>
            <FormLabel>Prénom</FormLabel>
            <Input
              data-testid="First Name"
              value={legalData.firstName}
              onChange={(e) => {
                let tempLegalData = legalData;
                tempLegalData.firstName = e.target.value;
                setLegalData({ ...tempLegalData });
              }}
            />
            <FormErrorMessage>Ce champ est obligatoire !</FormErrorMessage>
          </FormControl>
        </Box>
        <Box>
          <FormControl isRequired isInvalid={legalData.lastName === ""}>
            <FormLabel>Nom</FormLabel>
            <Input
              data-testid="Last Name"
              value={legalData.lastName}
              onChange={(e) => {
                let tempLegalData = legalData;
                tempLegalData.lastName = e.target.value;
                setLegalData({ ...tempLegalData });
              }}
            />
            <FormErrorMessage>Ce champ est obligatoire !</FormErrorMessage>
          </FormControl>
        </Box>
      </SimpleGrid>
      <SimpleGrid columns={2} spacing={isMobile ? 5 : 10} mb={10}>
        <Box>
          <FormControl isRequired isInvalid={legalData.birthDate === ""}>
            <FormLabel>Date de naissance</FormLabel>
            <Input
              data-testid="Birth Date"
              type="date"
              value={legalData.birthDate}
              onChange={(e) => {
                let tempLegalData = legalData;
                tempLegalData.birthDate = e.target.value;
                setLegalData({ ...tempLegalData });
              }}
            />
            <FormErrorMessage>Ce champ est obligatoire !</FormErrorMessage>
          </FormControl>
        </Box>
        <Box>
          <FormControl isRequired isInvalid={legalData.citizenship === ""}>
            <FormLabel>Nationalité</FormLabel>
            <Select
              placeholder="Sélectionnez votre nationalité"
              options={options}
              value={legalData.citizenship}
              onChange={(value) => {
                let tempLegalData = legalData;
                tempLegalData.citizenship = value;
                setLegalData({ ...tempLegalData });
              }}
            />
          </FormControl>
          <FormErrorMessage>Ce champ est obligatoire !</FormErrorMessage>
        </Box>
      </SimpleGrid>
      <SimpleGrid columns={2} spacing={isMobile ? 5 : 10} mb={10}>
        <Box>
          <FormControl isRequired isInvalid={legalData.birthCity === ""}>
            <FormLabel>Ville de naissance</FormLabel>
            <Input
              data-testid="Birth City"
              value={legalData.birthCity}
              onChange={(e) => {
                let tempLegalData = legalData;
                tempLegalData.birthCity = e.target.value;
                setLegalData({ ...tempLegalData });
              }}
            />
            <FormErrorMessage>Ce champ est obligatoire !</FormErrorMessage>
          </FormControl>
        </Box>
        <Box>
          <FormControl
            isRequired
            mb={isMobile && 10}
            isInvalid={legalData.birthCountry === ""}
          >
            <FormLabel>Pays de naissance</FormLabel>
            <Select
              placeholder="Sélectionnez votre pays de naissance"
              options={options}
              value={legalData.birthCountry}
              onChange={(value) => {
                let tempLegalData = legalData;
                tempLegalData.birthCountry = value;
                setLegalData({ ...tempLegalData });
              }}
            />
            <FormErrorMessage>Ce champ est obligatoire !</FormErrorMessage>
          </FormControl>
        </Box>
      </SimpleGrid>
      <FormControl
        isRequired
        mb={isMobile && 10}
        isInvalid={legalData.legalRepresentativeAddress === ""}
      >
        <FormLabel>Adresse</FormLabel>
        <PlacesAutocomplete
          value={address}
          onChange={(address) => {
            setAddress(address);
          }}
          onSelect={(address) => {
            setAddress(address);
            geocodeByAddress(address)
              .then((results) => {
                let tempLegalData = legalData;
                tempLegalData.legalRepresentativeAddress = address;
                tempLegalData.legalRepresentativeAdressDetails = results[0];
                setLegalData({ ...tempLegalData });
              })
              .catch(() => {});
          }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <Input
                id="adresse"
                data-testid="address"
                placeholder={"Entrez votre adresse"}
                {...getInputProps({
                  placeholder: "Entrez votre adresse",
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        <FormErrorMessage>Ce champ est obligatoire !</FormErrorMessage>
      </FormControl>

      <Button
        color="white"
        bg="yalink.green"
        mt="4%"
        borderRadius="0"
        width="100%"
        _hover={{ bg: "yalink.yellow" }}
        _checked={{
          bg: "yalink.yellow",
          color: "white",
          borderColor: "yalink.yellow",
        }}
        type="submit"
        mb="5"
        onClick={() => handleLegalDataUpdate(setIsUpdatingLegalData)}
        isLoading={isUpdatingLegalData}
        disabled={disabledButton || isUpdatingLegalData}
      >
        Enregistrer
      </Button>
    </Box>
  );
}

export default LegalRepresentation;
