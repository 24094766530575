import React from "react";
import { NavLogo, NavIcon, Img } from "../../globalStyles";
import {
  // FaFacebook,
  // FaInstagram,
  // FaYoutube,
  // FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import {
  FooterContainer,
  FooterSubscription,
  FooterSubText,
  // FooterSubHeading,
  // Form,
  // FormInput,
  // FooterLinksContainer,
  // FooterLinksWrapper,
  // FooterLinkItems,
  // FooterLinkTitle,
  // FooterLink,
  SocialMedia,
  SocialMediaWrap,
  // SocialLogo,
  // SocialIcon,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from "./Footer.elements";

import img from "../../images/Fond-Blanc-x1.png";

function Footer() {
  return (
    <FooterContainer>
      {/* <FooterSubHeading>
          Join our exclusive membership to receive the latest news and trends
        </FooterSubHeading> */}

      {/* <FooterSubscription>
        <FooterSubText>Recevez notre newsletter</FooterSubText>
        <Form>
          <FormInput
            name="email"
            type="email"
            placeholder="bonjour@yalink.fr"
          />
          <Button fontBig>Subscribe</Button>
        </Form>
      </FooterSubscription> */}

      {/* <FooterLinksContainer>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkTitle>Entreprise</FooterLinkTitle>
            <FooterLink
              to={{ pathname: "https://www.yalink.fr/entreprises" }}
              target="_blank"
            >
              Offre
            </FooterLink>
            <FooterLink
              to={{ pathname: "https://www.yalink.fr/expertises" }}
              target="_blank"
            >
              Expertises
            </FooterLink>
          </FooterLinkItems>
          <FooterLinkItems>
            <FooterLinkTitle>Yalink</FooterLinkTitle>
            <FooterLink
              to={{ pathname: "https://www.yalink.fr/a_propos" }}
              target="_blank"
            >
              À propos
            </FooterLink>
            <FooterLink
              to={{ pathname: "https://www.yalink.fr/politique-de-confidentialite/" }}
              target="_blank"
            >
              Politique de confidentialité
            </FooterLink>
            <FooterLink
              to={{ pathname: "https://www.yalink.fr/cguv/" }}
              target="_blank"
            >
              Conditions générales
            </FooterLink>
          </FooterLinkItems>
        </FooterLinksWrapper>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkTitle>Freelances</FooterLinkTitle>
            <FooterLink
              to={{ pathname: "https://www.yalink.fr/freelances" }}
              target="_blank"
            >
              La communauté
            </FooterLink>
            <FooterLink
              to={{ pathname: "https://calendly.com/antoine-yalink/15min" }}
              target="_blank"
            >
              Rejoindre le mouvement
            </FooterLink>
          </FooterLinkItems>
          <FooterLinkItems>
            <FooterLinkTitle>Nous contacter</FooterLinkTitle>
            <FooterLink
              to={{ pathname: "https://calendly.com/antoine-yalink/15min" }}
              target="_blank"
            >
              bonjour@yalink.fr
            </FooterLink>
            <FooterLink
              to={{ pathname: "https://calendly.com/antoine-yalink/15min" }}
              target="_blank"
            >
              Prendre rendez-vous
            </FooterLink>
          </FooterLinkItems>
        </FooterLinksWrapper>
      </FooterLinksContainer> */}
      <FooterSubscription>
        <FooterSubText>Proudly made in Bordeaux with 💛</FooterSubText>
      </FooterSubscription>
      <WebsiteRights>YALINK © 2021-2022 | Tous droits réservés</WebsiteRights>
      <SocialMedia>
        <SocialMediaWrap>
          <NavLogo href="https://www.yalink.fr" target="_blank">
            <NavIcon>
              <Img src={img} alt="Yalink logo" />
            </NavIcon>
          </NavLogo>
          {/* <WebsiteRights>YALINK © 2020</WebsiteRights> */}
          <SocialIcons>
            {/* <SocialIconLink href="/" target="_blank" aria-label="Facebook">
              <FaFacebook />
            </SocialIconLink>
            <SocialIconLink href="/" target="_blank" aria-label="Instagram">
              <FaInstagram />
            </SocialIconLink>
            <SocialIconLink
              href={"/"}
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Youtube"
            >
              <FaYoutube />
            </SocialIconLink>
            <SocialIconLink href="/" target="_blank" aria-label="Twitter">
              <FaTwitter />
            </SocialIconLink> */}
            <SocialIconLink
              href="https://www.linkedin.com/company/yalinkommunity/"
              target="_blank"
              aria-label="LinkedIn"
            >
              <FaLinkedin />
            </SocialIconLink>
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  );
}

export default Footer;
