import {
  Box,
  Flex,
  Heading,
  Text,
  Grid,
  GridItem,
  CircularProgress,
  CircularProgressLabel,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { setProgressValue } from "../../libs/freelanceProgressbarLib";
import { Cross } from "@styled-icons/entypo/Cross";
import { Check } from "@styled-icons/bootstrap/Check";
import { EyeSlashFill } from "@styled-icons/bootstrap/EyeSlashFill";

function CheckListFreelance({ freelanceDetails, newSummary }) {
  // isMobile responsive useState and useEffect
  const [isMobile, setIsMobile] = useState(false);
  const [isFinalValue, setFinalValue] = useState(0);

  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    applyResponsive();
    setFinalValue(setProgressValue(freelanceDetails, newSummary));
  }, [freelanceDetails, newSummary]);

  window.addEventListener("resize", applyResponsive);
  return (
    <>
      <Box
        bgColor="yalink.green"
        pt={2}
        borderRadius={5}
        boxShadow="md"
        mt={isMobile && "20px"}
        w={isMobile ? "80" : "100%"}
      >
        <Box>
          <Grid templateColumns="0.8fr 0.3fr">
            <GridItem>
              <Box>
                <Flex>
                  <Heading mb="20px" size="md" color="#fff" mr="5px" ml="18px">
                    Complète ton profil
                  </Heading>
                </Flex>
              </Box>
              <Box>
                <Flex>
                  <Box>
                    <Text color="#fff" ml="20px" mt="-15px" mb="10px">
                      Privé pour vous
                    </Text>
                  </Box>
                  <Box mt="-15px" ml="10px">
                    <EyeSlashFill size="19px" color="#fff" />
                  </Box>
                </Flex>
              </Box>
            </GridItem>
            <GridItem>
              <Box ml={isMobile ? "17%" : "20%"}>
                <CircularProgress
                  value={isFinalValue}
                  color="yalink.yellow"
                  size="60px"
                >
                  <CircularProgressLabel color="#fff">
                    {isFinalValue + "%"}
                  </CircularProgressLabel>
                </CircularProgress>
              </Box>
            </GridItem>
          </Grid>
        </Box>

        <Box
          w={isMobile ? "80" : "100%"}
          mb="20px"
          mt={isMobile && "20px"}
          padding="5"
          borderWidth={1}
          borderLeftRadius="0"
          borderBottomRadius="5"
          // borderRadius={5}
          boxShadow="md"
          bg="white"
        >
          <Box>
            <Flex justify="space-between">
              <Box>
                <Grid templateRows="repeat(7, 1fr)" gap="3px">
                  {/* <GridItem>
                            <Flex>
                              <Text>Photo de profil</Text>
                            </Flex>
                          </GridItem> */}
                  <GridItem mt="3px">
                    <Text>Compétences</Text>
                  </GridItem>
                  {/* <GridItem mt="5px">
                            <Flex>
                              <Text>Recommandations</Text>
                            </Flex>
                          </GridItem> */}
                  <GridItem mt="5px">
                    <Text>Ma description</Text>
                  </GridItem>
                  <GridItem mt="5px">
                    <Text>Expériences</Text>
                  </GridItem>
                  <GridItem mt="5px">
                    <Text>Formation</Text>
                  </GridItem>
                  <GridItem mt="5px">
                    <Text>Langues</Text>
                  </GridItem>
                  <GridItem mt="5px">
                    <Text>Secteurs</Text>
                  </GridItem>
                  <GridItem mt="5px">
                    <Text>Métiers</Text>
                  </GridItem>
                </Grid>
              </Box>
              <Box mr="5%">
                <Grid templateRows="repeat(8, 0.8fr)" gap="6px">
                  {/* <Box>
                            <GridItem colSpan={1}>
                              <Check size="25px" color="#F8C708" />
                            </GridItem>
                          </Box> */}
                  <Box>
                    <GridItem>
                      {freelanceDetails.skills.length !== 0 ? (
                        <Check size="25px" color="#F8C708" />
                      ) : (
                        <Box data-testid="Cross">
                          <Cross size="25px" color="#000" />
                        </Box>
                      )}
                    </GridItem>
                  </Box>
                  {/* <Box>
                            <GridItem>
                              {isRecommendationChecked ? (
                                <Check size="25px" color="#F8C708" />
                              ) : (
                                <Cross size="25px" color="#000" />
                              )}
                            </GridItem>
                          </Box> */}
                  <Box>
                    <GridItem>
                      {newSummary !== "" ? (
                        <Check size="25px" color="#F8C708" />
                      ) : (
                        <Box data-testid="Cross">
                          <Cross size="25px" color="#000" />
                        </Box>
                      )}
                    </GridItem>
                  </Box>
                  <Box>
                    <GridItem>
                      {freelanceDetails.experiences &&
                      freelanceDetails.experiences.length !== 0 ? (
                        <Check size="25px" color="#F8C708" />
                      ) : (
                        <Box data-testid="Cross">
                          <Cross size="25px" color="#000" />
                        </Box>
                      )}
                    </GridItem>
                  </Box>
                  <Box>
                    <GridItem>
                      {freelanceDetails.education &&
                      freelanceDetails.education.length !== 0 ? (
                        <Check size="25px" color="#F8C708" />
                      ) : (
                        <Box data-testid="Cross">
                          <Cross size="25px" color="#000" />
                        </Box>
                      )}
                    </GridItem>
                  </Box>
                  <Box>
                    <GridItem>
                      {freelanceDetails.languages.length !== 0 ? (
                        <Check size="25px" color="#F8C708" />
                      ) : (
                        <Box data-testid="Cross">
                          <Cross size="25px" color="#000" />
                        </Box>
                      )}
                    </GridItem>
                  </Box>
                  <Box>
                    <GridItem>
                      {freelanceDetails.sectors.length !== 0 ? (
                        <Check size="25px" color="#F8C708" />
                      ) : (
                        <Box data-testid="Cross">
                          <Cross size="25px" color="#000" />
                        </Box>
                      )}
                    </GridItem>
                  </Box>
                  <Box>
                    <GridItem>
                      {freelanceDetails.jobTitles.length !== 0 ? (
                        <Check size="25px" color="#F8C708" />
                      ) : (
                        <Box data-testid="Cross">
                          <Cross size="25px" color="#000" />
                        </Box>
                      )}
                    </GridItem>
                  </Box>
                  <Box>
                    <GridItem></GridItem>
                  </Box>
                </Grid>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default CheckListFreelance;
