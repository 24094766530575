import {
  Box,
  GridItem,
  Text,
  Grid,
  Button,
  Flex,
  RadioGroup,
  Stack,
  Radio,
  useToast,
  Circle,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  AspectRatio,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DayPicker } from "react-day-picker";
import fr from "date-fns/locale/fr";
import "react-day-picker/dist/style.css";
import ContactInfo from "../Utils/ContactInfo";
import ExtraCosts from "./Molecules/ExtraCosts";
import InvoiceBreakdown from "../ActivityReportReview/Molecules/InvoiceBreakdown";
import ProgressSummaryRegie from "./Molecules/ProgressSummaryRegie";
import { handleSubmit } from "./Libs/submitActivityReportLib";
import { getDateList } from "../../libs/dateListLib";
import CompletedMissionModal from "./Molecules/CompletedMissionModal";
import { getUserProfile, UserProfiles } from "../../libs/authHeaderLib";
import Header from "../Utils/Header";

export default function ActivityReportRegie({
  mission,
  activityReport,
  legalData,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSendingRequest, setIsSendingRequest] = useState(false);

  const {
    isOpen: isTutorialOpen,
    onOpen: onTutorialOpen,
    onClose: onTutorialClose,
  } = useDisclosure();
  let isClient = true;
  const userProfile = getUserProfile();
  if (userProfile === UserProfiles.FREELANCE) {
    isClient = false;
  }
  const toast = useToast();
  const { year, month } = useParams();

  // Date picker state & footer
  const [days, setDays] = useState(
    getDateList(activityReport.full_days_worked)
  );
  const [halfDays, setHalfDays] = useState(
    getDateList(activityReport.half_days_worked)
  );
  // const [allDaysState, setAllDaysState] = useState([]);

  useEffect(() => {
    let tempHalfDays = halfDays;
    console.log(tempHalfDays);
  }, [days, halfDays]);

  const [dayMode, setDayMode] = useState("full");

  const css = `
  .rdp-button[aria-disabled='true'] {
    opacity: 0.7;
  }
  
  .full-day-selected:not([disabled]) { 
    font-weight: bold; 
    border: 2px solid #003B36;
    background-color: #003B36;
    color: white
  }
  .full-day-selected:hover:not([disabled]) { 
    border-color: blue;
    color: blue;
    color: white
  }
  .full-day-disabled{
    font-weight: bold; 
    border: 2px solid #cfdbd5;
    background: #cfdbd5
  }
  .half-day-selected:not([disabled]) { 
    font-weight: bold; 
    border: 2px solid #cfdbd5;
    border-radius: 50%;
    background: #cfdbd5
  }
  .half-day-selected:hover:not([disabled]) { 
    border-color: lightblue;
    color: lightblue;
  }
  .half-day-disabled {
    font-weight: bold; 
    border: 2px solid #003B36;
    color: white;
    background-color: #003B36;
  }
  .my-today { 
    font-weight: bold;
    font-size: 140%; 
    color: red;
  }
`;

  const Footer = () => {
    return (days && days.length > 0) || (halfDays && halfDays.length > 0) ? (
      <Flex mt="5%">
        <Box mr="1%">
          <Text fontSize="19px">
            {isClient ? "Le freelance à" : "Vous avez"} sélectionné
          </Text>
        </Box>
        <Box mr="1%">
          <Text fontWeight="semibold" fontSize="19px">
            {days.length} jour(s)
          </Text>
        </Box>
        <Box mr="1%">
          <Text fontSize="19px">et</Text>
        </Box>
        <Box mr="1%">
          <Text fontWeight="semibold" fontSize="19px">
            {halfDays.length} demi-journée(s).
          </Text>
        </Box>
      </Flex>
    ) : (
      <Text fontSize="19px">Sélectionnez des jours.</Text>
    );
  };

  // Responsive state and useEffect
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(false);

  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    applyResponsive();
  }, []);

  window.addEventListener("resize", applyResponsive);

  // Internal components
  const ModalTutorial = () => {
    return (
      <Modal isOpen={isTutorialOpen} onClose={onTutorialClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Tutoriel pour l'état d'avancement</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AspectRatio ratio={4 / 3}>
              <iframe
                title="tutorial"
                src="https://www.loom.com/embed/7935b3011d604cc8bc8a8f3ddd23557e?hideEmbedTopBar=true&t=1"
                allowFullScreen
                webkitallowfullscreen
                mozallowfullscreen
              />
            </AspectRatio>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onTutorialClose}>
              Fermer le tutoriel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };
  const Fonctionnement = () => {
    return (
      <Box
        h={isClient ? "80%" : "100%"}
        bg="white"
        color="black"
        borderRadius={7}
        boxShadow="md"
      >
        <Box
          h={isClient ? "20%" : "10%"}
          bg="yalink.green"
          borderTopRadius={7}
          padding={2}
          p={5}
          mb="2%"
        >
          <Text color="white" fontWeight="semi-bold">
            Fonctionnement
          </Text>
        </Box>
        <Grid
          templateRows={isClient ? "repeat(3,1fr)" : "repeat(6,1fr)"}
          h="85%"
          ml="5%"
          p={3}
        >
          <GridItem>
            <Text>
              1.{" "}
              {isClient
                ? "Deux possibilité de sélection"
                : "Sélectionnez un type de journée"}{" "}
              :
            </Text>
            <Text fontWeight="bold" ml="5%">
              journée entière <br />
              demie-journée
            </Text>
          </GridItem>
          <GridItem>
            <Text>
              2.{" "}
              {isClient
                ? "Le freelance clique sur les jours correspondants à ses journées de travail"
                : "Cliquez sur les jours correspondant à ce type de journée"}
            </Text>
          </GridItem>
          <GridItem>
            <Text>
              3.{" "}
              {isClient
                ? "Le freelance rajoute des frais si nécéssaire"
                : "Cliquez à nouveau sur un jour pour le supprimer"}
            </Text>
          </GridItem>
          {!isClient && (
            <>
              <GridItem>
                <Text>4. Ajoutez des frais si nécéssaire.</Text>
              </GridItem>
              <GridItem>
                <Text>
                  5. Cliquer sur le bouton "Valider l'état d'avancement".
                </Text>
              </GridItem>
              <GridItem align="center">
                <Button borderRadius={0} onClick={onTutorialOpen}>
                  Voir le tutoriel
                </Button>
                <ModalTutorial />
              </GridItem>
            </>
          )}
        </Grid>
      </Box>
    );
  };

  const DatePickerComp = () => {
    return (
      <Box
        bg="white"
        color="black"
        borderRadius={7}
        p={7}
        boxShadow="md"
        mb={isMobile ? null : "5%"}
      >
        <Grid templateColumns={isMobile ? null : "repeat(2,1fr)"} gap={5}>
          <GridItem>
            <Box disabled>
              <RadioGroup
                value={dayMode}
                onChange={() => {
                  if (dayMode === "full") {
                    setDayMode("half");
                  } else {
                    setDayMode("full");
                  }
                }}
              >
                <Stack spacing={10} direction="row">
                  <Radio value="full">Journée entière</Radio>
                  <Radio value="half">1/2 journée</Radio>
                </Stack>
              </RadioGroup>
              <style>{css}</style>
              <DayPicker
                month={new Date(Number(year), Number(month) - 1)}
                dayPickerProps={{ style: { width: 300 } }}
                mode="multiple"
                disableNavigation={true}
                disabled={dayMode === "full" ? halfDays : days}
                // disabled={() => {
                //   if (!isClient) {
                //     return dayMode === "full" ? halfDays : days;
                //   } else {
                //     let concatDays = days.concat(halfDays);
                //     return concatDays;
                //   }
                // }}
                selected={dayMode === "full" ? days : halfDays}
                // selected={() => {
                //   if (!isClient) {
                //     return dayMode === "full" ? days : halfDays;
                //   } else {
                //     return false;
                //   }
                // }}
                onSelect={dayMode === "full" ? setDays : setHalfDays}
                locale={fr}
                weekStartsOn={1}
                modifiers={
                  dayMode === "full" ? { halfDays: halfDays } : { days: days }
                }
                modifiersClassNames={
                  dayMode === "full"
                    ? {
                        selected: "full-day-selected",
                        disabled: "full-day-disabled",
                      }
                    : {
                        selected: "half-day-selected",
                        disabled: "half-day-disabled",
                      }
                }
              />
              <Footer />
            </Box>
          </GridItem>
          <GridItem>
            <Box mb={isMobile ? "5%" : "3%"}>
              <Text fontSize="20px">Légende :</Text>
            </Box>{" "}
            <Flex h={isMobile ? "40%" : "15%"} align="center">
              <Box mr="5%">
                <Circle size="40px" bgColor="#003B36" />
              </Box>
              <Box>
                <Text fontSize="18px">Journée entière</Text>
              </Box>
            </Flex>{" "}
            <Flex h={isMobile ? "40%" : "15%"} align="center">
              <Box mr="5%">
                <Circle size="40px" bgColor="#cfdbd5" />
              </Box>
              <Box>
                <Text fontSize="18px">Demi-journée</Text>
              </Box>
            </Flex>
          </GridItem>
        </Grid>
      </Box>
    );
  };

  //Formik functions for Fees

  function getValues(values) {
    let amount = 0;
    if (values && values.length !== 0) {
      values.forEach((f) => {
        amount += f.amount;
      });
    }
    return parseInt(amount);
  }
  return (
    <Box bgColor="yalink.bg">
      <Header
        isMobile={isMobile}
        mission={mission}
        activityReport={activityReport}
      />
      <Formik
        initialValues={{
          frais: activityReport.extra_costs,
        }}
        onSubmit={(values, actions) => {
          values.days = days.map((day) => {
            return (
              day.getFullYear() +
              "-" +
              (day.getMonth() + 1) +
              "-" +
              day.getDate()
            );
          });
          values.halfDays = halfDays.map((halfDay) => {
            return (
              halfDay.getFullYear() +
              "-" +
              (halfDay.getMonth() + 1) +
              "-" +
              halfDay.getDate()
            );
          });
          if (days.length === 0 && halfDays.length === 0) {
            toast({
              position: "top",
              title: "Aucun jour n'a été selectionné !",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          } else if (
            days.length + halfDays.length * 0.5 + mission.completed_days ===
            mission.total_working_days
          ) {
            onOpen();
          } else if (
            days.length + halfDays.length * 0.5 + mission.completed_days >
            mission.total_working_days
          ) {
            toast({
              position: "top",
              title:
                "Le nombre de jours selectionnés dépasse le nombre de jours total de la mission",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          } else {
            setIsSendingRequest(true);
            handleSubmit(mission, year, month, values, actions, history, toast);
            setIsSendingRequest(false);
          }
        }}
      >
        {({ values, actions }) => (
          <Form>
            {!isMobile ? (
              <Grid
                templateColumns={isMobile ? null : "2fr 5fr"}
                mr="4%"
                ml="4%"
                mb={isMobile ? "30%" : "10%"}
                gap={isMobile ? "1%" : "4%"}
              >
                <GridItem h="100%">
                  <Grid
                    templateRows={isClient ? " 1fr" : "1fr 1fr 1fr"}
                    h="100%"
                    gap="2%"
                  >
                    {!isClient && (
                      <GridItem>
                        <Fonctionnement />
                      </GridItem>
                    )}
                    <GridItem>
                      {!isClient ? (
                        <ProgressSummaryRegie
                          values={values}
                          getValues={getValues}
                          month={month}
                          year={year}
                          days={days}
                          halfDays={halfDays}
                          mission={mission}
                          activityReport={activityReport}
                          isSendingRequest={isSendingRequest}
                        />
                      ) : (
                        <InvoiceBreakdown
                          activityReport={activityReport}
                          legalData={legalData}
                          mission={mission}
                          isClient={isClient}
                        />
                      )}
                    </GridItem>
                    <GridItem>
                      <ContactInfo mission={mission} isClient={isClient} />
                    </GridItem>
                  </Grid>
                </GridItem>
                <GridItem h="100%">
                  <DatePickerComp />
                  <ExtraCosts
                    isMobile={isMobile}
                    values={values}
                    isClient={isClient}
                  />
                </GridItem>
              </Grid>
            ) : (
              <Flex mr="5%" ml="5%" direction="column" mb="20%">
                <Box mb="10%">
                  <Fonctionnement />
                </Box>
                <Box mb="10%">
                  <DatePickerComp />
                </Box>
                <ExtraCosts isMobile={isMobile} values={values} />
                {!isClient ? (
                  <ProgressSummaryRegie
                    values={values}
                    getValues={getValues}
                    month={month}
                    year={year}
                    days={days}
                    halfDays={halfDays}
                    mission={mission}
                    activityReport={activityReport}
                    isSendingRequest={isSendingRequest}

                  />
                ) : (
                  <InvoiceBreakdown
                    activityReport={activityReport}
                    legalData={legalData}
                    mission={mission}
                    isClient={isClient}
                  />
                )}
                <Box>
                  <ContactInfo mission={mission} isClient={isClient} />
                </Box>
              </Flex>
            )}
            <CompletedMissionModal
              isOpen={isOpen}
              onClose={onClose}
              mission={mission}
              year={year}
              month={month}
              values={values}
              actions={actions}
              toast={toast}
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
}
