import axios from "axios";
import authHeader from "../libs/authHeaderLib";
import config from "../config";

class FreelanceService {
  async updateLegalData(legalData, freelanceId) {
    let path = "/freelance/legal-data";
    if (freelanceId) {
      path += "?id=" + freelanceId;
    }
    return axios.put(config.API_URL + path, legalData, {
      headers: authHeader(),
    });
  }

  async getLegalData(freelanceId) {
    let path = "/freelance/legal-data";
    if (freelanceId) {
      path += "?id=" + freelanceId;
    }
    return axios.get(config.API_URL + path, {
      headers: authHeader(),
    });
  }

  async getGoogleMapsApiKey() {
    let path = "/freelance/legal-data/maps/api";
    return axios.get(config.API_URL + path, {
      headers: authHeader(),
    });
  }

  async uploadProfilePicture(file) {
    if (!file) return null;

    const photoFormData = new FormData();

    photoFormData.append("picture", file);

    return axios.post(
      config.API_URL + "/freelance/file/profile-picture",
      photoFormData,
      { headers: authHeader() }
    );
  }

  async uploadPortfolio(file) {
    if (!file) return null;

    const formData = new FormData();

    formData.append("file", file);

    return axios.post(config.API_URL + "/freelance/file/portfolio", formData, {
      headers: authHeader(),
    });
  }

  async deletePortfolio(file_key) {
    return axios.delete(config.API_URL + "/freelance/file/portfolio", {
      headers: authHeader(),
      // we use data because body isn't allowed in delete method
      data: {
        name: file_key,
      },
    });
  }

  async uploadFile(file, filename, freelanceId) {
    if (!file) return null;

    let path = "/freelance/file";
    if (freelanceId) {
      path += "?id=" + freelanceId;
    }

    const formData = new FormData();

    formData.append("file", file);
    formData.append("filename", filename);

    return axios.post(config.API_URL + path, formData, {
      headers: authHeader(),
    });
  }

  async getFreelanceList(searchTerm) {
    let path = "/freelance?search_term=" + searchTerm;
    return axios.get(config.API_URL + path, {
      headers: authHeader(),
    });
  }
}

export default new FreelanceService();
