const dev = {
  API_URL: "http://localhost:" + process.env.REACT_APP_API_PORT,
  CLIENT_URL: "http://localhost:" + process.env.REACT_APP_CLIENT_PORT,
};

const staging = {
  API_URL: "https://callisto-core.herokuapp.com",
  CLIENT_URL: "https://staging.app.yalink.fr",
};

const prod = {
  API_URL: "https://callisto-core-prd.herokuapp.com",
  CLIENT_URL: "https://app.yalink.fr",
};

const config = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  // Default to dev if not set
  ...(process.env.REACT_APP_STAGE === "prod"
    ? prod
    : process.env.REACT_APP_STAGE === "staging"
    ? staging
    : dev),
};

export default config;
