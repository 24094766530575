import React from "react";
import { useState } from "react";

import {
  Box,
  Text,
  Button,
  Flex,
  useDisclosure,
  useToast,
  Input,
  Checkbox,
} from "@chakra-ui/react";
import { monthNamesFr } from "../../../libs/monthsLib";
import { useParams } from "react-router-dom";
import { PersonMoney } from "@styled-icons/fluentui-system-regular/PersonMoney";
import { handleActivityReportUpdate } from "../Libs/activityReportUpdateLib";
import ValidationModal from "./ValidationModal";
import RejectActivityReportModal from "./RejectActivityReportModal";
import ValidationModalClient from "./ValidationModalClient.jsx";
import Mission from "../../../services/mission.service";
import Amounts from "./Amounts";
import BreakdownRegie from "./BreakdownRegie";
import BreakdownForfait from "./BreakdownForfait";

export default function InvoiceBreakdown({
  activityReport,
  legalData,
  mission,
  isClient,
}) {
  const [orderNumber, setOrderNumber] = useState(null);
  const [noOrderNumber, setNoOrderNumber] = useState(false);
  const isActivityReportClientUpdatable =
    activityReport.status !== "approved" ? true : false;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { missionId, year, month } = useParams();
  const toast = useToast();
  const {
    isOpen: isRefusClient,
    onOpen: onRefusClientOpen,
    onClose: onRefusClientClose,
  } = useDisclosure();
  const {
    isOpen: isValidationClient,
    onOpen: onValidationClientOpen,
    onClose: onValidationClientClose,
  } = useDisclosure();
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  //API Post for validating the CRA
  async function handleValidation(missionId, year, month, orderNumber) {
    setIsSendingRequest(true);
    await Mission.confirmActivityReport(missionId, year, month, orderNumber)
      .then(() => {
        onValidationClientOpen();
        setIsSendingRequest(false);
      })
      .catch((error) => {
        if (error.response.status === 422) {
          toast({
            position: "top",
            title: "Le compte rendu d'activité n'est pas soumis à validation",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      });
  }

  return (
    <Box
      bg="white"
      color="black"
      borderRadius={7}
      padding={10}
      pt={5}
      boxShadow="md"
    >
      <Box mb="4%">
        <Text align="left" fontWeight="bold">
          {monthNamesFr[month - 1]} {year}
        </Text>
      </Box>
      {mission.service_type === "forfait" ? (
        <BreakdownForfait activityReport={activityReport} />
      ) : (
        <BreakdownRegie activityReport={activityReport} mission={mission} />
      )}
      <Box id="Frais">
        <Box mb="2%">
          <Text color="grey">Frais</Text>
        </Box>
        <Box mb="6%">
          <Flex>
            <Box mr="4%">
              <PersonMoney size="20px" />
            </Box>
            <Box>
              <Text>{activityReport.total_extra_cost_amount + " € HT"}</Text>
            </Box>
          </Flex>
        </Box>
      </Box>
      <Amounts
        activityReport={activityReport}
        legalData={legalData}
        isClient={isClient}
      />
      {!isClient ? (
        <>
          <Box>
            <Button
              p={4}
              bgColor="yalink.green"
              color="white"
              w="100%"
              onClick={() => {
                handleActivityReportUpdate(
                  missionId,
                  year,
                  month,
                  activityReport
                );
                onOpen();
              }}
              disabled={isSendingRequest}
              isLoading={isSendingRequest}
              borderRadius={0}
            >
              Envoyer l'état d'avancement
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Input
            mt="4vh"
            placeholder="Bon de commande"
            value={orderNumber}
            isInvalid={!orderNumber}
            onChange={(e) => {
              setOrderNumber(e.target.value);
            }}
          />
          <Checkbox
            mt="1vh"
            checked={noOrderNumber}
            onChange={() => {
              setNoOrderNumber(!noOrderNumber);
            }}
          >
            Je n'ai pas de bon de commande
          </Checkbox>
          <Flex w="100%" mt="1vh" justifyContent="space-between">
            <Box mr="2vw">
              <Button
                bgColor="red.500"
                _hover={{ bgColor: "red.600" }}
                borderRadius={0}
                color="white"
                disabled={!isActivityReportClientUpdatable || isSendingRequest}
                onClick={() => {
                  onRefusClientOpen();
                }}
              >
                Refuser le CRA
              </Button>
            </Box>
            <Box>
              <Button
                bgColor="yalink.green"
                _hover={{ bgColor: "yalink.green", transform: "scale(1.050)" }}
                borderRadius={0}
                color="white"
                disabled={
                  !isActivityReportClientUpdatable ||
                  (!noOrderNumber && !orderNumber) ||
                  isSendingRequest
                }
                onClick={() => {
                  handleValidation(missionId, year, month, orderNumber);
                }}
                isLoading={isSendingRequest}
              >
                Valider le CRA
              </Button>
            </Box>
          </Flex>
        </>
      )}
      <ValidationModal isOpen={isOpen} onClose={onClose} />
      <RejectActivityReportModal
        isOpen={isRefusClient}
        onClose={onRefusClientClose}
      />
      <ValidationModalClient
        isOpen={isValidationClient}
        onClose={onValidationClientClose}
      />
    </Box>
  );
}
