import axios from "axios";
import authHeader from "../libs/authHeaderLib";
import config from "../config";

class BlogService {
  async getBlogs() {
    let path = "/blog/";
    return axios.get(config.API_URL + path, {
      headers: authHeader(),
    });
  }
}

export default new BlogService();
