// Takes a list of date strings ('2020-02-02') in input and returns a list of Date()
export function getDateList(dateStringList) {
  if (dateStringList && dateStringList !== 0) {
    return dateStringList.map((day) => {
      return new Date(
        day.split("-")[0],
        Number(day.split("-")[1]) - 1,
        day.split("-")[2]
      );
    });
  }
  return [];
}
