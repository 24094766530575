import React from "react";

import {
  Box,
  GridItem,
  Text,
  Grid,
  InputGroup,
  Input,
  InputRightElement,
  Button,
} from "@chakra-ui/react";
import { Field, Form, FieldArray } from "formik";

// Internal components
const DescriptionComponent = ({
  field,
  ...props // { name, value, onChange, onBlur }
}) => (
  <div>
    <Input type="text" {...field} {...props}></Input>
  </div>
);
const PriceComponent = ({
  field,
  ...props // { name, value, onChange, onBlur }
}) => (
  <div>
    <InputGroup>
      <Input type="number" {...field} {...props}></Input>
      <InputRightElement children="€" />
    </InputGroup>
  </div>
);

export default function ExtraCosts({ isMobile, values, isClient }) {
  return (
    <Box
      bg="white"
      color="black"
      borderRadius={7}
      boxShadow="md"
      padding={7}
      pt={4}
      mb="5%"
    >
      <Box mb="2%">
        <Text fontSize="1.5rem" fontWeight="semibold">
          {isClient ? "Liste" : "Ajouter"} des frais
        </Text>
      </Box>

      <Form>
        <FieldArray name="frais">
          {({ insert, remove, push }) => (
            <div>
              {values.frais.length > 0 && (
                <>
                  <Grid templateColumns="2fr 1fr" mb="2%">
                    <GridItem>
                      <Text>
                        Descriptif {!isClient && "(en précisant la date)"}
                      </Text>
                    </GridItem>
                    <GridItem>
                      <Text>Prix</Text>
                    </GridItem>
                  </Grid>
                </>
              )}

              {values.frais.length > 0 &&
                values.frais.map((friend, index) => (
                  <>
                    <div className="row" key={index}></div>
                    <Box key={index} mb="3%">
                      <Grid
                        templateColumns={
                          isMobile ? "1fr 0.5fr 0.1fr" : "2fr 0.5fr 0.5fr"
                        }
                        gap="3%"
                      >
                        <GridItem>
                          <Box>
                            <Field
                              name={`frais.${index}.description`}
                              component={DescriptionComponent}
                              type="text"
                              isReadOnly={isClient && true}
                            />
                          </Box>
                        </GridItem>
                        <GridItem>
                          <Box>
                            <Field
                              name={`frais.${index}.amount`}
                              component={PriceComponent}
                              type="number"
                              isReadOnly={isClient && true}
                            />
                          </Box>
                        </GridItem>
                        {!isClient && (
                          <>
                            <GridItem>
                              <Button
                                type="button"
                                onClick={() => remove(index)}
                              >
                                X
                              </Button>
                            </GridItem>
                          </>
                        )}
                      </Grid>
                    </Box>
                  </>
                ))}
              {!isClient && (
                <>
                  <Grid
                    templateColumns="1fr"
                    gap="3%"
                    w={isMobile ? null : "30%"}
                  >
                    <GridItem>
                      <Button
                        type="button"
                        borderRadius={0}
                        onClick={() =>
                          push({
                            description: "",
                            amount: "",
                          })
                        }
                      >
                        Ajouter un frais
                      </Button>
                    </GridItem>
                  </Grid>
                </>
              )}
            </div>
          )}
        </FieldArray>
      </Form>
    </Box>
  );
}
