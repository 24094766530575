import React from "react";
import { SuccessfulSignupSection } from "../../components";
import { successfulSignupObj } from "./Data";

export default function SuccessfulSignup() {
  return (
    <>
      <SuccessfulSignupSection {...successfulSignupObj} />
    </>
  );
}
