import Missions from "../../../services/mission.service";

export async function handleActivityReportUpdate(
  missionId,
  year,
  month,
  activityReport
) {
  await Missions.updateActivityReport(missionId, year, month, {
    avancement: activityReport.amount_billed,
    progressDescription: activityReport.progress_proof_description,
    days: activityReport.full_days_worked,
    halfDays: activityReport.half_days_worked,
    frais: activityReport.extra_costs,
    freelanceInternalReference: activityReport.freelance_internal_reference,
    freelanceApprovalRequested: true,
  }).then(() => {});
}
