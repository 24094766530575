import { Box, Flex, Heading, Text, GridItem, Grid } from "@chakra-ui/react";
import { Cross } from "@styled-icons/entypo/Cross";
import { Check } from "@styled-icons/bootstrap/Check";

function LegalDocumentsChecklist({ legalData, showDocuments }) {
  function CheckListItem({ isChecked }) {
    return (
      <GridItem>
        {isChecked ? (
          <Check size="25px" color="#F8C708" />
        ) : (
          <Cross size="25px" color="#000" />
        )}
      </GridItem>
    );
  }

  return (
    <Box
      padding="7"
      pl="0"
      pr="0"
      pt="0"
      borderWidth={1}
      borderRadius={8}
      boxShadow="md"
      h="100%"
      bgColor="#fff"
    >
      <Box
        bgColor="yalink.green"
        pt={2}
        pl="3"
        borderRadius={5}
        borderBottomLeftRadius="0"
        borderBottomRightRadius="0"
        boxShadow="md"
        w="100%"
      >
        <Flex>
          <Heading mb="20px" size="md" color="#fff" ml="5px">
            Checklist des documents légaux
          </Heading>
        </Flex>
      </Box>

      <Box>
        <Flex justify="space-between">
          <Box ml="5%" mt="5%">
            <Grid templateRows="repeat(7, 1fr)" gap="3px">
              <GridItem mt="3px">
                <Text>Carte d'identité</Text>
              </GridItem>
              <GridItem mt="5px">
                <Text>RIB</Text>
              </GridItem>
              <GridItem mt="5px">
                <Text>RC Pro</Text>
              </GridItem>
              {showDocuments.vigilance ? (
                <GridItem mt="5px">
                  <Flex>
                    <Text>Attestation de vigilance</Text>
                  </Flex>
                </GridItem>
              ) : null}
              {showDocuments.sirene ? (
                <GridItem mt="5px">
                  <Text>Avis de situation SIRENE</Text>
                </GridItem>
              ) : null}
              {showDocuments.kbis ? (
                <GridItem mt="5px">
                  <Text>Kbis</Text>
                </GridItem>
              ) : null}
              {showDocuments.legalForms ? (
                <GridItem mt="5px">
                  <Text>Statuts</Text>
                </GridItem>
              ) : null}
              <GridItem mt="5px">
                <Text>Déclaration Mangopay</Text>
              </GridItem>
            </Grid>
          </Box>
          <Box mr="10%" mt="5%">
            <Grid templateRows="repeat(8, 0.8fr)" gap="6px">
              <Box>
                <CheckListItem
                  isChecked={legalData && legalData.identityDocument}
                />
              </Box>
              <Box>
                <CheckListItem
                  isChecked={
                    legalData && legalData.iban && legalData.iban !== ""
                  }
                />
              </Box>
              <Box>
                <CheckListItem
                  isChecked={legalData && legalData.rcProDocument}
                />
              </Box>
              {showDocuments.vigilance ? (
                <Box>
                  <CheckListItem
                    isChecked={
                      legalData && legalData.vigilanceCertificateDocument
                    }
                  />
                </Box>
              ) : null}
              {showDocuments.sirene ? (
                <Box>
                  <CheckListItem
                    isChecked={
                      legalData && legalData.sireneStatusCertificateDocument
                    }
                  />
                </Box>
              ) : null}
              {showDocuments.kbis ? (
                <Box>
                  <CheckListItem
                    isChecked={legalData && legalData.kbisDocument}
                  />
                </Box>
              ) : null}
              {showDocuments.legalForms ? (
                <Box>
                  <CheckListItem
                    isChecked={legalData && legalData.legalFormsDocument}
                  />
                </Box>
              ) : null}
              <Box>
                <CheckListItem
                  isChecked={legalData && legalData.mangopayDeclarationDocument}
                />
              </Box>
            </Grid>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}

export default LegalDocumentsChecklist;
