import axios from "axios";
import authHeader from "../libs/authHeaderLib";
import config from "../config";

class DealService {
  async getDeals(page, perPage, requestUnpublishedOffers) {
    let path = "/deal";
    path += "?page=" + page;
    path += "&per_page=" + perPage;
    if (requestUnpublishedOffers) {
      path += "&unpublished=" + requestUnpublishedOffers;
    }

    return axios.get(config.API_URL + path, {
      headers: authHeader(),
    });
  }
  async getDeal(dealId) {
    let path = "/deal/" + dealId;
    return axios.get(config.API_URL + path, {
      headers: authHeader(),
    });
  }
}

export default new DealService();
