import { useState } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Input,
  Grid,
  GridItem,
  useToast,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { isEmailValid } from "../../libs/regexLib";
import MissionService from "../../services/mission.service";

function OnboardingFreelance({ deal }) {
  const toast = useToast();
  const [searchCriteria, setSearchCriteria] = useState({
    email: "",
    organizationId: "",
  });
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(false);
  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  window.addEventListener("resize", applyResponsive);

  async function handleSubmit() {
    await MissionService.createMission(deal.id, searchCriteria).then(
      (response) => {
        if (response.status === 200) {
          toast({
            position: "top",
            title: "La mission a déjà été créée",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
        if (response.status === 201) {
          toast({
            position: "top",
            title: "La mission vient d'être créée !",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
        history.push(
          "/freelance/missions/" +
            deal.id +
            "/onboarding/details?missionId=" +
            response.data.mission_id
        );
      }
    );
  }

  return (
    <Box mt="5%">
      <Flex justify="center">
        <Box
          w="70%"
          padding={isMobile ? "5" : "10"}
          borderWidth={1}
          borderRadius={5}
          boxShadow="md"
          mb="80px"
          mt={isMobile ? "100px" : "80px"}
          bg="white"
        >
          <Box mb="3%">
            <Text fontSize="22" fontWeight="bold" data-testid="onboarding">
              Onboarding du freelance
            </Text>
          </Box>
          <Grid
            templateRows={isMobile ? "1fr 0.5fr" : "1fr 1fr"}
            gap={isMobile ? "10%" : "20%"}
          >
            <GridItem>
              <Grid
                templateColumns={isMobile ? null : "0.5fr 0.1fr 0.5fr"}
                templateRows={isMobile && "0.5fr 0.8fr 0.8fr"}
              >
                <GridItem>
                  <Grid templateRows="50px 1fr">
                    <GridItem>
                      <Box>
                        <Text fontSize="15">
                          Sélection de l'entreprise (via l'email opérationnel)
                        </Text>
                      </Box>
                    </GridItem>
                    <GridItem>
                      <Input
                        value={searchCriteria.email}
                        onChange={(e) => {
                          let tempSearchCriteria = searchCriteria;
                          tempSearchCriteria.email = e.target.value;
                          setSearchCriteria({ ...tempSearchCriteria });
                        }}
                        isInvalid={
                          searchCriteria.email !== "" &&
                          !isEmailValid(searchCriteria.email)
                        }
                      ></Input>
                    </GridItem>
                  </Grid>
                </GridItem>
                <GridItem>
                  <Flex
                    justify="center"
                    alignItems="self-end"
                    h={isMobile ? "70%" : "100%"}
                  >
                    <Text>Ou</Text>
                  </Flex>
                </GridItem>
                <GridItem>
                  <Grid templateRows="50px 1fr">
                    <GridItem>
                      <Box>
                        <Text fontSize="15">
                          ID Pipedrive de l'entreprise du client
                        </Text>
                      </Box>
                    </GridItem>
                    <GridItem>
                      <Input
                        value={searchCriteria.organizationId}
                        onChange={(e) => {
                          let tempSearchCriteria = searchCriteria;
                          tempSearchCriteria.organizationId = e.target.value;
                          setSearchCriteria({ ...tempSearchCriteria });
                        }}
                      ></Input>
                    </GridItem>
                  </Grid>
                </GridItem>
              </Grid>
            </GridItem>
            <GridItem>
              <Button
                bgColor="yalink.green"
                w={isMobile ? "100%" : "40%"}
                color="white"
                borderRadius={0}
                _hover={{ bg: "yalink.yellow" }}
                onClick={handleSubmit}
                isDisabled={
                  searchCriteria.email === "" &&
                  searchCriteria.organizationId === ""
                }
              >
                Charger les informations
              </Button>
            </GridItem>
          </Grid>
        </Box>
      </Flex>
    </Box>
  );
}

export default OnboardingFreelance;
