import React, { Component } from "react";
import "./TagInput.css";
import { WithContext as ReactTags } from "react-tag-input";
import SkillsService from "../../services/skills.service";

class TagInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: null,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
  }

  async handleDelete(i) {
    await SkillsService.removeSkillFromFreelance(this.props.existingSkills[i])
      .then((response) => {
        let tempDetails = this.props.freelanceDetails;
        tempDetails.skills = this.props.existingSkills.filter(
          (tag, index) => index !== i
        );
        this.props.setFreelanceDetails({ ...tempDetails });
      })
      .catch(() => {});
  }

  async handleAddition(skill) {
    await SkillsService.addSkillToFreelance(skill)
      .then((response) => {
        let tempDetails = this.props.freelanceDetails;
        tempDetails.skills = [
          ...this.props.existingSkills,
          { id: response.data.skill, text: response.data.skill },
        ];
        this.props.setFreelanceDetails({ ...tempDetails });
      })
      .catch(() => {});
  }

  async getSuggestions() {
    await SkillsService.getAllSkills()
      .then((response) => {
        this.setState({ suggestions: response.data.skills });
      })
      .catch(() => {});
  }

  render() {
    const { suggestions } = this.state;
    if (!suggestions) {
      this.getSuggestions();
    }
    return suggestions ? (
      <div id="skillsInput">
        <ReactTags
          inputFieldPosition="top"
          tags={this.props.existingSkills}
          suggestions={suggestions}
          handleDelete={this.handleDelete}
          handleAddition={this.handleAddition}
          let
          placeholder="ex: Solidworks, Suivi travaux"
        />
      </div>
    ) : null;
  }
}

export default TagInput;
