import { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  Grid,
  GridItem,
  Avatar,
  Text,
  Collapse,
  Link,
} from "@chakra-ui/react";
import RecoModal from "./RecoModal";
import { getUserProfile } from "../../libs/authHeaderLib";
import { UserProfiles } from "../../libs/authHeaderLib";

function Recommendations({ recommendations }) {
  // isMobile responsive useState and useEffect

  const [isMobile, setIsMobile] = useState(false);
  const [collapse, setCollapse] = useState(true);
  const userProfile = getUserProfile();

  const handleToggle = () => setCollapse(!collapse);
  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    applyResponsive();
  }, []);

  window.addEventListener("resize", applyResponsive);

  return (
    <Box
      w={isMobile ? "80" : "95%"}
      padding="5"
      mt="20px"
      mb="20px"
      borderWidth={1}
      borderRadius={5}
      boxShadow="md"
      bg="white"
    >
      <Box>
        <Heading mb={isMobile ? "40px" : "20px"} size="md" color="gray.600">
          Recommandations
        </Heading>
      </Box>
      <Box>
        <Collapse startingHeight={isMobile ? 250 : 260} in={collapse}>
          <Box>
            <Grid
            // templateRows="repeat(4, 1fr)"
            // gap={isMobile ? "40px" : "20px"}
            >
              {recommendations.map((reco) => (
                <GridItem w={isMobile ? "95%" : "90%"} mt="3%" key={reco.title}>
                  <Flex align="center" mb={isMobile ? "5%" : "3%"}>
                    <Box>
                      <Avatar
                        name={reco.title}
                        size="md"
                        mr="5"
                        mb={isMobile ? "70px" : "35px"}
                        showBorder="true"
                      />
                    </Box>

                    <Box>
                      <Heading
                        mb="5px"
                        size="4xs"
                        color="yalink.green"
                        data-testid="reco"
                      >
                        {reco.title}
                      </Heading>
                      <Text mb="5px" fontSize="13px">
                        {reco.company}
                      </Text>
                      <Text align="justify" fontSize="15px">
                        {reco.description}
                      </Text>
                    </Box>
                  </Flex>
                </GridItem>
              ))}
            </Grid>
          </Box>
        </Collapse>

        <Box ml="9%" mt={collapse ? "3%" : "4%"}>
          <Flex justify="space-between" flexDirection={isMobile && "column"}>
            {recommendations.length > 2 ? (
              <Box mb={isMobile && "10%"}>
                <Link
                  onClick={handleToggle}
                  color="yalink.green"
                  fontWeight="semibold"
                >
                  {collapse ? "Voir moins" : "Voir plus"}
                </Link>
              </Box>
            ) : (
              <Box></Box>
            )}

            <Box>
              {userProfile === UserProfiles.COMPANY ? null : (
                <Box>
                  <RecoModal />
                </Box>
              )}
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
}

export default Recommendations;
