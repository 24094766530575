import axios from "axios";
import authHeader from "../libs/authHeaderLib";
import config from "../config";

class MissionService {
  async createMission(dealId, searchCriteria) {
    let path = "/mission/?deal_id=" + dealId;
    return axios.post(config.API_URL + path, searchCriteria, {
      headers: authHeader(),
    });
  }

  async updateMission(missionId, data) {
    let path = "/mission/" + missionId;
    return axios.put(config.API_URL + path, data, {
      headers: authHeader(),
    });
  }

  async getMissions(page, perPage, status) {
    let path = "/mission/";
    path += "?page=" + page;
    path += "&per_page=" + perPage;
    if (status) {
      path += "&status=" + status;
    }

    return axios.get(config.API_URL + path, {
      headers: authHeader(),
    });
  }

  async getMission(missionId) {
    let path = "/mission/" + missionId;
    return axios.get(config.API_URL + path, {
      headers: authHeader(),
    });
  }

  async getAllActivityReports() {
    let path = ["mission", "activity-report"].join("/");
    return axios.get([config.API_URL, path].join("/"), {
      headers: authHeader(),
    });
  }

  async getActivityReport(missionId, year, month) {
    let path = ["mission", missionId, "activity-report", year, month].join("/");
    return axios.get([config.API_URL, path].join("/"), {
      headers: authHeader(),
    });
  }

  async requestChanges(missionId, year, month, rejectDescription) {
    let path = [
      "mission",
      missionId,
      "activity-report",
      year,
      month,
      "request-changes",
    ].join("/");
    return axios.post(
      [config.API_URL, path].join("/"),
      { requested_modification_description: rejectDescription },
      {
        headers: authHeader(),
      }
    );
  }

  async confirmActivityReport(missionId, year, month, orderNumber) {
    let path = [
      "mission",
      missionId,
      "activity-report",
      year,
      month,
      "confirm",
    ].join("/");
    return axios.post(
      [config.API_URL, path].join("/"),
      { orderNumber: orderNumber },
      {
        headers: authHeader(),
      }
    );
  }

  async alertActivityReport(missionId, year, month) {
    let path = [
      "mission",
      missionId,
      "activity-report",
      year,
      month,
      "alert",
    ].join("/");
    return axios.get([config.API_URL, path].join("/"), {
      headers: authHeader(),
    });
  }

  async createActivityReport(missionId, year, month) {
    let path = ["mission", missionId, "activity-report", year, month].join("/");
    return axios.post(
      [config.API_URL, path].join("/"),
      {},
      {
        headers: authHeader(),
      }
    );
  }

  async updateActivityReport(missionId, year, month, data) {
    let path = ["mission", missionId, "activity-report", year, month].join("/");

    return axios.put([config.API_URL, path].join("/"), data, {
      headers: authHeader(),
    });
  }

  async approveReportWithNoActivity(missionId, year, month) {
    let path = [
      "mission",
      missionId,
      "activity-report",
      year,
      month,
      "approve",
      "no-activity",
    ].join("/");

    return axios.put(
      [config.API_URL, path].join("/"),
      {},
      {
        headers: authHeader(),
      }
    );
  }
}

export default new MissionService();
