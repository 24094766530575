import { useState } from "react";
import { useParams } from "react-router-dom";
import { LoadingComponent } from "../../components";
import FreelanceMissionDetailedAvailable from "../../components/FreelanceMissions/FreelanceMissionDetailedAvailable";
import Deals from "../../services/deal.service";

function FreelanceOfferView() {
  const [isLoading, setIsLoading] = useState(true);
  const [mission, setMission] = useState(null);
  const { offerId } = useParams();

  async function onLoad() {
    await Deals.getDeal(offerId)
      .then((response) => {
        setMission({
          id: offerId,
          sector: response.data.deal.sector,
          job: response.data.deal.jobTitle,
          name: response.data.deal.name,
          published: response.data.deal.published,
          serviceType: response.data.deal.serviceType,
          location: response.data.deal.postalCode,
          beginDate: response.data.deal.startDate,
          sizing: response.data.deal.sizing,
          skill: null,
          timing: response.data.deal.workRythm,
          homeoffice: response.data.deal.workplace,
          description: response.data.deal.description,
        });
      })
      .catch((error) => {});
  }
  if (isLoading) {
    onLoad();
    setIsLoading(false);
  }

  return (
    [isLoading, offerId],
    mission ? (
      <FreelanceMissionDetailedAvailable mission={mission} />
    ) : (
      <LoadingComponent />
    )
  );
}

export default FreelanceOfferView;
