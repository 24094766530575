import { Formik, Form, Field } from "formik";
import {
  Heading,
  Grid,
  GridItem,
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Select,
  InputGroup,
  InputRightElement,
  useToast,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import User from "../../services/user.service";
import { useHistory } from "react-router";
import { useState } from "react";

export const preferencesDict = {
  availability: {
    avail: {
      code: "avail",
      text: "🟢 Disponible",
    },
    not_avail: {
      code: "not_avail",
      text: "🔴 Occupé",
    },
  },
  workplace: {
    on_site: {
      code: "on_site",
      text: "Sur site uniquement",
    },
    remote: {
      code: "remote",
      text: "Télé-travail uniquement",
    },
    no_pref: {
      code: "no_pref",
      text: "Indifférent",
    },
  },
  area: {
    city: {
      code: "city",
      text: "Ville",
    },
    department: {
      code: "department",
      text: "Département",
    },
    region: {
      code: "region",
      text: "Région",
    },
    country: {
      code: "country",
      text: "France",
    },
  },
  length: {
    lt3m: {
      code: "lt3m",
      text: "Courte (-3 mois)",
    },
    gt3m: {
      code: "gt3m",
      text: "Longue (+3 mois)",
    },
    indifferent: {
      code: "indifferent",
      text: "Indifférent",
    },
  },
  daysperweek: {
    part: {
      code: "part",
      text: "Temps partiel",
    },
    daysPerWeek1: {
      code: "daysPerWeek1",
      text: "1 jour",
    },
    daysPerWeek2: {
      code: "daysPerWeek2",
      text: "2 jours",
    },
    daysPerWeek3: {
      code: "daysPerWeek3",
      text: "3 jours",
    },
    daysPerWeek4: {
      code: "daysPerWeek4",
      text: "4 jours",
    },
    full: {
      code: "full",
      text: "Temps plein",
    },
    indifferent: {
      code: "indifferent",
      text: "Indifférent",
    },
  },
};

function FreelancePreferencesFormOnboarding(props) {
  const toast = useToast();
  const history = useHistory();

  async function handleSubmit(values, actions) {
    const preferences = {
      city: values.city,
      tarification: values.tjm,
      workplace: values.workplace,
      jobDuration: values.length,
      mobility: values.area,
      daysPerWeek: values.daysperweek,
    };
    await User.updateFreelancePreferences(preferences)
      .then(() => {
        toast({
          position: "top",
          title: "Boarding pass mis à jour",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch((error) => {});
    let tempFreelancePreferences = props.freelancePreferences;
    tempFreelancePreferences.tjm = values.tjm;
    tempFreelancePreferences.city = values.city;
    tempFreelancePreferences.workplace = values.workplace;
    tempFreelancePreferences.area = values.area;
    tempFreelancePreferences.length = values.length;
    tempFreelancePreferences.daysperweek = values.daysperweek;
    props.setFreelancePref({ ...tempFreelancePreferences });

    props.setHasFreelancePref(true);
    actions.setSubmitting(false);
  }
  function validateText(value) {
    let error;
    if (!value) {
      error = "*Il va falloir remplir ce champ 😱";
    }
    return error;
  }

  function validatePostalCode(value) {
    let error;
    if (!/^[0-9]{5}$/i.test(value)) {
      error = "*Il va falloir remplir ce champ par 5 chiffres 😱";
    }
    return error;
  }

  function validateTJM(value) {
    let error;
    if (!/^((2000)|(1[0-9]{3})|([2-9][0-9]{2}))$/i.test(value)) {
      error =
        "*Il va falloir remplir ce champ par un nombre entre 200 et 2 000 😱";
    }
    return error;
  }

  const [available, setAvailable] = useState(
    props.freelancePreferences.availability
  );
  const [availableDate, setAvailableDate] = useState(null);
  const today = new Date(Date.now());
  const dateInOneMonth = new Date(today.setMonth(today.getMonth() + 1));
  const dateInThreeMonth = new Date(today.setMonth(today.getMonth() + 2));
  const dateInSixMonth = new Date(today.setMonth(today.getMonth() + 3));

  function isAvailable() {
    if (available === "not_avail") {
      return false;
    } else {
      return true;
    }
  }

  function updateAvailability() {
    const preferences = {
      availability: available,
      availabilityDate: availableDate,
    };
    User.updateFreelancePreferences(preferences)
      .then(() => {
        toast({
          position: "top",
          title: "Disponibilité mise à jour",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch((error) => {});
  }

  return (
    <Formik
      initialValues={{
        tjm: props.freelancePreferences.tjm,
        city: props.freelancePreferences.city,
        workplace: props.freelancePreferences.workplace,
        area: props.freelancePreferences.area,
        length: props.freelancePreferences.length,
        daysperweek: props.freelancePreferences.daysperweek,
      }}
      onSubmit={(values, actions) => {
        handleSubmit(values, actions);
        updateAvailability();
        history.push("/freelance/about/linkedin");
      }}
    >
      {(props) => (
        <Form>
          <Grid>
            <GridItem>
              <Heading size="sm" mt="47px">
                Tarif indicatif
              </Heading>
              <Field name="tjm" validate={validateTJM}>
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.tjm && form.touched.tjm}
                    isRequired
                  >
                    <FormLabel htmlFor="tjm">TJM</FormLabel>
                    <InputGroup>
                      <Input
                        {...field}
                        id="tjm"
                        placeholder="Entre ton montant"
                      />
                      <InputRightElement
                        pointerEvents="none"
                        color="gray.300"
                        fontSize="1.2em"
                        children="€"
                      />
                    </InputGroup>
                    <FormErrorMessage>{form.errors.tjm}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </GridItem>
            <GridItem>
              <Heading size="sm" mt="30px">
                Localisation et déplacement
              </Heading>

              <Field name="city" validate={validatePostalCode}>
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.city && form.touched.city}
                    isRequired
                  >
                    <FormLabel htmlFor="city">Code postal</FormLabel>
                    <Input {...field} id="city" placeholder="33600" />
                    <FormErrorMessage>{form.errors.city}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="workplace" validate={validateText}>
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.workplace && form.touched.workplace}
                    isRequired
                  >
                    <FormLabel htmlFor="workplace">Lieu de travail</FormLabel>
                    <Select
                      {...field}
                      placeholder="Selectionner"
                      id="workplace"
                    >
                      <option value={preferencesDict.workplace.on_site.code}>
                        {preferencesDict.workplace.on_site.text}
                      </option>
                      <option value={preferencesDict.workplace.remote.code}>
                        {preferencesDict.workplace.remote.text}
                      </option>
                      <option value={preferencesDict.workplace.no_pref.code}>
                        {preferencesDict.workplace.no_pref.text}
                      </option>
                    </Select>
                    <FormErrorMessage>{form.errors.workplace}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="area" validate={validateText}>
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.area && form.touched.area}
                    isRequired
                  >
                    <FormLabel htmlFor="area">Mobilité</FormLabel>
                    <Select {...field} placeholder="Selectionner" id="area">
                      <option value={preferencesDict.area.city.code}>
                        {preferencesDict.area.city.text}
                      </option>
                      <option value={preferencesDict.area.department.code}>
                        {preferencesDict.area.department.text}
                      </option>
                      <option value={preferencesDict.area.region.code}>
                        {preferencesDict.area.region.text}
                      </option>
                      <option value={preferencesDict.area.country.code}>
                        {preferencesDict.area.country.text}
                      </option>
                    </Select>
                    <FormErrorMessage>{form.errors.area}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </GridItem>
            <GridItem mt="30px">
              <Heading size="sm" mt="3">
                Préférences de missions
              </Heading>
              <Field name="length" validate={validateText}>
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.length && form.touched.length}
                    isRequired
                  >
                    <FormLabel htmlFor="length">Durée</FormLabel>
                    <Select {...field} placeholder="Selectionner" id="length">
                      <option value={preferencesDict.length.lt3m.code}>
                        {preferencesDict.length.lt3m.text}
                      </option>
                      <option value={preferencesDict.length.gt3m.code}>
                        {preferencesDict.length.gt3m.text}
                      </option>
                      <option value={preferencesDict.length.indifferent.code}>
                        {preferencesDict.length.indifferent.text}
                      </option>
                    </Select>
                    <FormErrorMessage>{form.errors.length}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="daysperweek" validate={validateText}>
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.daysperweek && form.touched.daysperweek
                    }
                    isRequired
                  >
                    <FormLabel htmlFor="daysperweek">Cadence</FormLabel>
                    <Select
                      {...field}
                      placeholder="Selectionner"
                      id="daysperweek"
                    >
                      <option
                        value={preferencesDict.daysperweek.daysPerWeek1.code}
                      >
                        {preferencesDict.daysperweek.daysPerWeek1.text}
                      </option>
                      <option
                        value={preferencesDict.daysperweek.daysPerWeek2.code}
                      >
                        {preferencesDict.daysperweek.daysPerWeek2.text}
                      </option>
                      <option
                        value={preferencesDict.daysperweek.daysPerWeek3.code}
                      >
                        {preferencesDict.daysperweek.daysPerWeek3.text}
                      </option>
                      <option
                        value={preferencesDict.daysperweek.daysPerWeek4.code}
                      >
                        {preferencesDict.daysperweek.daysPerWeek4.text}
                      </option>
                      <option value={preferencesDict.daysperweek.full.code}>
                        {preferencesDict.daysperweek.full.text}
                      </option>
                      <option
                        value={preferencesDict.daysperweek.indifferent.code}
                      >
                        {preferencesDict.daysperweek.indifferent.text}
                      </option>
                    </Select>
                    <FormErrorMessage>
                      {form.errors.daysperweek}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Heading size="sm" mt="30px">
                Disponibilité
              </Heading>
              Etes-vous disponible pour recevoir des propositions ?
              <RadioGroup onChange={setAvailable} value={available}>
                <Stack spacing={5} direction="row">
                  <Radio value="avail" colorScheme="green">
                    Oui
                  </Radio>
                  <Radio value="not_avail" colorScheme="red">
                    Non
                  </Radio>
                </Stack>
              </RadioGroup>
              Merci d'indiquer quand vous serez à nouveau disponible (choisis
              une durée ou une date)
              <RadioGroup onChange={setAvailableDate} value={availableDate}>
                <Stack direction="row">
                  <Radio
                    value={dateInOneMonth.toISOString().slice(0, 10)}
                    isDisabled={isAvailable()}
                  >
                    Dans 1 mois
                  </Radio>
                  <Radio
                    value={dateInThreeMonth.toISOString().slice(0, 10)}
                    isDisabled={isAvailable()}
                  >
                    Dans 3 mois
                  </Radio>
                  <Radio
                    value={dateInSixMonth.toISOString().slice(0, 10)}
                    isDisabled={isAvailable()}
                  >
                    Dans 6 mois
                  </Radio>
                </Stack>
                <Stack spacing={4}>
                  <InputGroup>
                    <Input
                      type="date"
                      isDisabled={isAvailable()}
                      value={availableDate}
                      onChange={(e) => {
                        setAvailableDate(e.target.value);
                      }}
                    />
                  </InputGroup>
                </Stack>
              </RadioGroup>
              <Stack
                direction={["column", "row"]}
                fontWeight="semibold"
                lineHeight="tight"
                mt="20px"
                mb="15px"
                mr="20px"
                ml="20px"
                gap={20}
                justify="center"
              >
                <Button
                  variant="outline"
                  color="yalink.green"
                  _hover={{ bg: "gray.200" }}
                  _checked={{
                    bg: "gray.200",
                    color: "white",
                    borderColor: "gray.200",
                  }}
                  type="submit"
                  onClick={() => {
                    history.push("/freelance/about/job");
                  }}
                >
                  Précédent
                </Button>
                <Button
                  mt="30px"
                  color="white"
                  bg="yalink.green"
                  _hover={{ bg: "gray.200" }}
                  _checked={{
                    bg: "gray.200",
                    color: "white",
                    borderColor: "gray.200",
                  }}
                  isLoading={props.isSubmitting}
                  type="submit"
                  disabled={!props.isValid}
                >
                  Suivant
                </Button>
              </Stack>
            </GridItem>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default FreelancePreferencesFormOnboarding;
