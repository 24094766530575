import React, { useState, useEffect } from "react";
import {
  Box,
  Center,
  Heading,
  Flex,
  Divider,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
} from "@chakra-ui/react";
import {
  FreelanceSummary,
  NewJobOffer,
  JobOfferCard,
  LoadingComponent,
} from "../../components";
import Offers from "../../services/offers.service";
import Users from "../../services/user.service";
import { useAppContext } from "../../libs/contextLib";
import FilterInput from "../../components/Utils/FilterInput";
import PerPageSelector from "../../components/Utils/PerPageSelector";
import Pagination from "../../components/Utils/Pagination";
import { matchingFreelanceList } from "../../libs/regexLib";
import { querystring } from "../../libs/queryStringLib";

function FreelanceHome() {
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [offers, setOffers] = useState(null);
  const [filter, setFilter] = useState("");
  const [freelanceFilter, setFreelanceFilter] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedAO, setSelectedAO] = useState("");
  const [freelanceList, setFreelanceList] = useState(null);
  const [reloadOfferList, setReloadOfferList] = useState(false);
  const [reloadFreelanceList, setReloadFreelanceList] = useState(false);
  const [statusFilter, setStatusFilter] = useState("all");
  const [searching, setSearching] = useState(false);
  const [isBrowsing, setIsBrowsing] = useState(true);

  // Offer pagination
  const [page, setPage] = useState(
    querystring("page", window.location.href)
      ? querystring("page", window.location.href)
      : 1
  );
  const [perPage, setPerPage] = useState(
    querystring("per_page", window.location.href)
      ? querystring("per_page", window.location.href)
      : 10
  );
  const [totalItems, setTotalItems] = useState(0);

  // Freelance pagination
  const [freelancePage, setFreelancePage] = useState(1);
  const [freelancePerPage, setFreelancePerPage] = useState(10);
  const [totalFreelance, setTotalFreelance] = useState(0);

  useEffect(() => {
    async function search() {
      if (isBrowsing) {
        setFreelancePage(1);
      }
      await Users.searchFreelance(
        freelanceFilter,
        isBrowsing ? 1 : freelancePage,
        freelancePerPage
      )
        .then((response) => {
          setFreelanceList(response.data.freelances);
          setTotalFreelance(response.data.total);
          setIsBrowsing(false);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setFreelanceList([]);
          } else {
            userHasAuthenticated(false);
          }
        });
      setSearching(false);
    }
    async function loadOffers() {
      await Offers.getAllOffers(page, perPage)
        .then((response) => {
          setOffers(response.data.offers);
          setTotalItems(response.data.total);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setOffers([]);
          } else {
            userHasAuthenticated(false);
          }
        });
      setReloadOfferList(false);
      setIsLoading(false);
    }
    async function loadFreelances() {
      await Users.getAllFreelances(freelancePage, freelancePerPage)
        .then((response) => {
          setFreelanceList(response.data.freelances);
          setTotalFreelance(response.data.total);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setFreelanceList([]);
          } else {
            userHasAuthenticated(false);
          }
        });
      setReloadFreelanceList(false);
    }

    if (isLoading) {
      loadOffers();
      loadFreelances();
    } else if (reloadOfferList) {
      loadOffers();
    } else if (reloadFreelanceList) {
      loadFreelances();
    }

    // Handle search
    if (searching) {
      if (freelanceFilter) {
        search();
      } else {
        loadFreelances();
      }
      setSearching(false);
    }
  }, [
    isLoading,
    userHasAuthenticated,
    reloadOfferList,
    page,
    perPage,
    freelancePage,
    freelancePerPage,
    reloadFreelanceList,
    freelanceFilter,
    isBrowsing,
    searching,
  ]);

  function handleSelectedPage(selectedPage) {
    setPage(selectedPage);
    setReloadOfferList(true);
  }

  function handleSelectedFreelancePage(selectedFreelancePage) {
    setFreelancePage(selectedFreelancePage);
    setReloadFreelanceList(true);
  }

  function PaginationInstance() {
    return (
      <Pagination
        totalItems={totalItems}
        perPage={perPage}
        handleSelectedPage={handleSelectedPage}
        page={page}
      />
    );
  }

  function FreelancePaginationInstance() {
    return (
      <Pagination
        totalItems={totalFreelance}
        perPage={freelancePerPage}
        handleSelectedPage={handleSelectedFreelancePage}
        page={freelancePage}
      />
    );
  }

  function setReloadAll(value) {
    setReloadFreelanceList(value);
    setReloadOfferList(value);
  }

  function FreelanceList() {
    let notYetSelectedFreelance = null;

    notYetSelectedFreelance = freelanceList.filter((freelance) => {
      return !freelance.offersId.includes(selectedAO.id);
    });

    // Filtering of freelance based on filter input
    notYetSelectedFreelance = matchingFreelanceList(
      notYetSelectedFreelance,
      freelanceFilter
    );

    return notYetSelectedFreelance.map((freelance) => (
      <Box key={freelance.id}>
        <FreelanceSummary
          freelance={freelance}
          selectedOfferId={selectedAO.id}
          setReloadOfferList={setReloadAll}
        />
        <Divider mb="20px" />
      </Box>
    ));
  }

  function OfferList() {
    var sortedOfferList = [];

    sortedOfferList = offers.filter((offer) => {
      if (statusFilter === "all" || statusFilter === "") {
        return true;
      } else {
        return offer.status === statusFilter;
      }
    });

    sortedOfferList = sortedOfferList.filter((offer) => {
      return (
        offer.offerName.toLowerCase().includes(filter.toLowerCase()) ||
        (offer.company &&
          offer.company.toLowerCase().includes(filter.toLowerCase()))
      );
    });

    sortedOfferList = sortedOfferList.sort((a, b) =>
      parseInt(a.id) < parseInt(b.id) ? 1 : -1
    );

    return sortedOfferList && sortedOfferList.length ? (
      sortedOfferList.map((offer) => (
        <JobOfferCard
          offer={offer}
          offers={offers}
          setOffers={setOffers}
          freelanceList={freelanceList}
          selectedAO={selectedAO}
          setSelectedAO={setSelectedAO}
          setReloadOfferList={setReloadOfferList}
          onOpen={onOpen}
          key={offer.id}
        />
      ))
    ) : (
      <Center mt="100px" ml="20px" mr="20px" mb="20px" padding="5">
        <Heading as="h1" size="lg" mb="25px">
          Ouuups, nous n'avons trouvé aucun appel d'offre...
        </Heading>
      </Center>
    );
  }
  return !isLoading ? (
    <>
      <Modal onClose={onClose} size="xl" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Center mb="20px">{selectedAO.name} ⚡️ Freelances</Center>
            <Flex>
              <FilterInput
                filter={freelanceFilter}
                setFilter={setFreelanceFilter}
                placeholder="Nom, Prénom, Email, Titre"
                searching={searching}
                setSearching={setSearching}
              />
              <PerPageSelector
                perPage={freelancePerPage}
                setPerPage={setFreelancePerPage}
                setPage={setFreelancePage}
                setIsLoading={setReloadFreelanceList}
              />
            </Flex>
            <FreelancePaginationInstance />
          </ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody>
            <FreelanceList />
            <FreelancePaginationInstance />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box>
        <Center mt="100px" ml="20px" mr="20px" mb="20px" padding="5">
          <Heading as="h1" size="lg" mb="25px">
            Appels d'offre en cours 🖖
          </Heading>
        </Center>
        <Center>
          <NewJobOffer offers={offers} setOffers={setOffers} />
        </Center>
        <Center>
          <Box w="80%" mb="20px" padding="5">
            <Flex>
              <FilterInput
                filter={filter}
                setFilter={setFilter}
                searching={searching}
                setSearching={setSearching}
              />
              <Select
                ml="20px"
                width="300px"
                variant="outline"
                bg="white"
                placeholder="Statut de l'offre"
                value={statusFilter}
                onChange={(e) => {
                  setStatusFilter(e.target.value);
                }}
              >
                <option value="open">Ouvert</option>
                <option value="ongoing">En cours</option>
              </Select>
              <PerPageSelector
                perPage={perPage}
                setPerPage={setPerPage}
                setPage={setPage}
                setIsLoading={setReloadOfferList}
              />
            </Flex>
            <Box mb="20px">
              <PaginationInstance />
            </Box>

            <OfferList />
            <PaginationInstance />
          </Box>
        </Center>
      </Box>
    </>
  ) : (
    <LoadingComponent />
  );
}

export default FreelanceHome;
