import React from 'react';
import { Button } from '../../globalStyles';
import { BiRocket } from 'react-icons/bi';
import { FaRocket } from 'react-icons/fa';
import { ImRocket } from 'react-icons/im';
import { IconContext } from 'react-icons/lib';
import {
  PricingSection,
  PricingWrapper,
  PricingHeading,
  PricingSubHeading,
  PricingContainer,
  PricingCard,
  PricingCardInfo,
  PricingCardIcon,
  PricingCardPlan,
  PricingCardCost,
  PricingCardLength,
  PricingCardFeatures,
  PricingCardFeature
} from './Pricing.elements';

function Pricing() {
  return (
    <IconContext.Provider value={{ color: '#F8C708', size: 64 }}>
      <PricingSection>
        <PricingWrapper>
          <PricingHeading>Une offre transparente</PricingHeading>
          <PricingSubHeading>Pour savoir où va votre argent</PricingSubHeading>
          <PricingContainer>
            <PricingCard to='/company/signup'>
              <PricingCardInfo>
                <PricingCardIcon>
                  <BiRocket />
                </PricingCardIcon>
                <PricingCardPlan>Dans tous les cas</PricingCardPlan>
                <PricingCardCost>Gratuit</PricingCardCost>
                <PricingCardLength>Inclus dans l'offre Yalink</PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>Indépendants vérifiés et qualifiés</PricingCardFeature>
                  <PricingCardFeature>Matching sous 48 heures</PricingCardFeature>
                  <PricingCardFeature>Contact direct avec l'indépendant</PricingCardFeature>
                  <PricingCardFeature>Contractualisation en ligne</PricingCardFeature>
                  <PricingCardFeature>Support personnalisé</PricingCardFeature>
                </PricingCardFeatures>
              </PricingCardInfo>
            </PricingCard>
            <PricingCard to='/company/signup'>
              <PricingCardInfo>
                <PricingCardIcon>
                  <FaRocket />
                </PricingCardIcon>
                <PricingCardPlan>Pré - Paiement</PricingCardPlan>
                <PricingCardCost>10%</PricingCardCost>
                <PricingCardLength>Ajoutés au TJM Indépendant</PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>L'offre la moins chère du marché</PricingCardFeature>
                  <PricingCardFeature>Argent bloqué en début du mois sur compte sequestre</PricingCardFeature>
                  <PricingCardFeature>Paiement du solde à la fin du mois</PricingCardFeature>
                </PricingCardFeatures>
                <Button>Démarrer maintenant</Button>
              </PricingCardInfo>
            </PricingCard>
            <PricingCard to='/company/signup'>
              <PricingCardInfo>
                <PricingCardIcon>
                  <ImRocket />
                </PricingCardIcon>
                <PricingCardPlan>Classique</PricingCardPlan>
                <PricingCardCost>15%</PricingCardCost>
                <PricingCardLength>Ajoutés au TJM Indépendant</PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>Une offre idéale pour l'indépendant comme pour vous</PricingCardFeature>
                  <PricingCardFeature>Paiement à 30 jours réception de facture</PricingCardFeature>
                </PricingCardFeatures>
                <Button>Démarrer maintenant</Button>
              </PricingCardInfo>
            </PricingCard>
          </PricingContainer>
        </PricingWrapper>
      </PricingSection>
    </IconContext.Provider>
  );
}
export default Pricing;
