import { useState, useEffect } from "react";

import {
  Box,
  Text,
  SimpleGrid,
  Button,
  Heading,
  Link,
  useToast,
} from "@chakra-ui/react";
import FreelanceService from "../../services/freelance.service";
import { querystring } from "../../libs/queryStringLib";

function LegalDocuments({ showDocuments, setLegalData, legalData }) {
  const [freelanceId] = useState(
    querystring("id", window.location.href)
      ? querystring("id", window.location.href)
      : null
  );
  const toast = useToast();
  const [isMobile, setIsMobile] = useState(false);
  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    applyResponsive();
  }, []);

  window.addEventListener("resize", applyResponsive);

  let hiddenIdentityDocumentInput = null;
  let hiddenRcProInput = null;
  let hiddenVigilanceCertificateInput = null;
  let hiddenSireneStatusCertificateInput = null;
  let hiddenKbisInput = null;
  let hiddenLegalFormsInput = null;
  let hiddenMangopayDeclarationInput = null;

  async function handleFileSelect(file, fileType) {
    await FreelanceService.uploadFile(file, fileType, freelanceId)
      .then((response) => {
        toast({
          position: "top",
          title: "Votre document est enregistré !",
          status: "success",
          duration: 1000,
          isClosable: true,
        });

        let tempLegalData = legalData;
        tempLegalData[fileType] = response.data.document_key;
        setLegalData({ ...tempLegalData });
      })
      .catch((error) => {
        if (error.response.status === 413) {
          toast({
            position: "top",
            title: "Le fichier ne doit pas depasser 10 MB",
            status: "warning",
            duration: 9000,
            isClosable: true,
          });
        }
      });
  }

  return (
    <Box
      mb={isMobile ? "10%" : "7%"}
      padding="7"
      borderWidth={1}
      borderRadius={8}
      boxShadow="md"
      bgColor="#fff"
    >
      <Heading mb="20px" size="md" color="gray.600">
        Documents légaux
      </Heading>
      <Text fontSize="xs" mb={5}>
        Les documents légaux sont nécessaires pour commencer une mission chez
        Yalink et doivent être à jour.
      </Text>
      <Text fontSize="xs" mb={5}>
        Pour pouvoir uploader les documents, il est nécessaire de remplir les
        parties "Votre entreprise" et "Représentant légal" au préalable
      </Text>
      <Box
        mt="5%"
        mb="4%"
        padding="5"
        borderWidth={1}
        borderRadius={8}
        boxShadow="md"
        maxW="100%"
      >
        <SimpleGrid columns={2} spacing={10}>
          <Box>
            <Text fontSize="18px" fontWeight="bold">
              Pièce d’identité (recto / verso)
            </Text>
            <Text fontSize="12px" color="red">
              Attention à bien mettre le recto et le verso sur le même document
            </Text>
          </Box>
          <Box>
            <input
              hidden
              type="file"
              ref={(inputElement) =>
                (hiddenIdentityDocumentInput = inputElement)
              }
              onChange={(e) =>
                handleFileSelect(e.target.files[0], "identityDocument")
              }
            />
            <Button
              width="100%"
              color="white"
              borderRadius="0"
              bg="yalink.green"
              _hover={{ bg: "yalink.yellow" }}
              mt="3%"
              ml={isMobile ? "40%" : "60%"}
              w={isMobile ? "60%" : "40%"}
              onClick={() => {
                hiddenIdentityDocumentInput.click();
              }}
              disabled={
                !legalData.paymentProviderUserExists ||
                legalData.paymentProviderUserExists === false
              }
            >
              {legalData && legalData.identityDocument ? "Modifier" : "Ajouter"}
            </Button>
          </Box>
        </SimpleGrid>
      </Box>
      <Box
        mt="5%"
        mb="1%"
        padding="5"
        borderWidth={1}
        borderRadius={8}
        boxShadow="md"
        maxW="100%"
      >
        <SimpleGrid columns={2}>
          <Box>
            <Text fontSize="18px" fontWeight="bold">
              RC PRO
            </Text>
            <Text fontSize="12px" color="red">
              En cours de validité
            </Text>
          </Box>
          <Box>
            <input
              hidden
              type="file"
              ref={(element) => (hiddenRcProInput = element)}
              onChange={(e) =>
                handleFileSelect(e.target.files[0], "rcProDocument")
              }
            />
            <Button
              width="100%"
              color="white"
              borderRadius="0"
              bg="yalink.green"
              _hover={{ bg: "yalink.yellow" }}
              mt="3%"
              ml={isMobile ? "40%" : "60%"}
              w={isMobile ? "60%" : "40%"}
              onClick={() => {
                hiddenRcProInput.click();
              }}
              disabled={
                !legalData.paymentProviderUserExists ||
                legalData.paymentProviderUserExists === false
              }
            >
              {legalData && legalData.rcProDocument ? "Modifier" : "Ajouter"}
            </Button>
          </Box>
        </SimpleGrid>
      </Box>
      {showDocuments.vigilance ? (
        <Box
          mt="5%"
          mb="1%"
          padding="5"
          borderWidth={1}
          borderRadius={8}
          boxShadow="md"
          maxW="100%"
        >
          <SimpleGrid columns={2}>
            <Box>
              <Text fontSize="18px" fontWeight="bold" mr="-20px">
                Attestation de vigilance
              </Text>
              <Link
                fontSize="12px"
                isExternal
                href="https://www.urssaf.fr/portail/home/employeur/declarer-et-payer/obtenir-une-attestation/attestation-de-vigilance.html"
              >
                Obtenir son attestation
              </Link>
            </Box>
            <Box>
              <input
                hidden
                type="file"
                ref={(element) => (hiddenVigilanceCertificateInput = element)}
                onChange={(e) =>
                  handleFileSelect(
                    e.target.files[0],
                    "vigilanceCertificateDocument"
                  )
                }
              />
              <Button
                width="100%"
                color="white"
                borderRadius="0"
                bg="yalink.green"
                _hover={{ bg: "yalink.yellow" }}
                mt="3%"
                ml={isMobile ? "40%" : "60%"}
                w={isMobile ? "60%" : "40%"}
                onClick={() => {
                  hiddenVigilanceCertificateInput.click();
                }}
                disabled={
                  !legalData.paymentProviderUserExists ||
                  legalData.paymentProviderUserExists === false
                }
              >
                {legalData && legalData.vigilanceCertificateDocument
                  ? "Modifier"
                  : "Ajouter"}
              </Button>
            </Box>
          </SimpleGrid>
        </Box>
      ) : null}
      {showDocuments.sirene ? (
        <Box
          mt="5%"
          mb="1%"
          padding="5"
          borderWidth={1}
          borderRadius={8}
          boxShadow="md"
          maxW="100%"
        >
          <SimpleGrid columns={2}>
            <Box>
              <Text fontSize="18px" fontWeight="bold" mr="-20px">
                Avis de situation SIRENE
              </Text>
              <Link
                fontSize="12px"
                isExternal
                href="https://avis-situation-sirene.insee.fr/"
              >
                Obtenir son avis de situation
              </Link>
            </Box>
            <Box>
              <input
                hidden
                type="file"
                ref={(element) =>
                  (hiddenSireneStatusCertificateInput = element)
                }
                onChange={(e) =>
                  handleFileSelect(
                    e.target.files[0],
                    "sireneStatusCertificateDocument"
                  )
                }
              />
              <Button
                width="100%"
                color="white"
                borderRadius="0"
                bg="yalink.green"
                _hover={{ bg: "yalink.yellow" }}
                mt="3%"
                ml={isMobile ? "40%" : "60%"}
                w={isMobile ? "60%" : "40%"}
                onClick={() => {
                  hiddenSireneStatusCertificateInput.click();
                }}
                disabled={
                  !legalData.paymentProviderUserExists ||
                  legalData.paymentProviderUserExists === false
                }
              >
                {legalData && legalData.sireneStatusCertificateDocument
                  ? "Modifier"
                  : "Ajouter"}
              </Button>
            </Box>
          </SimpleGrid>
        </Box>
      ) : null}
      {showDocuments.kbis ? (
        <Box
          mt="5%"
          mb="1%"
          padding="5"
          borderWidth={1}
          borderRadius={8}
          boxShadow="md"
          maxW="100%"
        >
          <SimpleGrid columns={2}>
            <Box>
              <Text fontSize="18px" fontWeight="bold">
                Kbis
              </Text>
              <Text fontSize="12px" color="red">
                Kbis datant de moins de 3 mois obligation pour la première
                mission. A renouveler tous les ans.
              </Text>
            </Box>
            <Box mt="15px">
              <input
                hidden
                type="file"
                ref={(element) => (hiddenKbisInput = element)}
                onChange={(e) =>
                  handleFileSelect(e.target.files[0], "kbisDocument")
                }
              />
              <Button
                width="100%"
                color="white"
                borderRadius="0"
                bg="yalink.green"
                _hover={{ bg: "yalink.yellow" }}
                mt="3%"
                ml={isMobile ? "40%" : "60%"}
                w={isMobile ? "60%" : "40%"}
                onClick={() => {
                  hiddenKbisInput.click();
                }}
                disabled={
                  !legalData.paymentProviderUserExists ||
                  legalData.paymentProviderUserExists === false
                }
              >
                {legalData && legalData.kbisDocument ? "Modifier" : "Ajouter"}
              </Button>
            </Box>
          </SimpleGrid>
        </Box>
      ) : null}
      {showDocuments.legalForms ? (
        <Box
          mt="5%"
          mb="1%"
          padding="5"
          borderWidth={1}
          borderRadius={8}
          boxShadow="md"
          maxW="100%"
        >
          <SimpleGrid columns={2}>
            <Box>
              <Text fontSize="18px" fontWeight="bold">
                Statuts
              </Text>
              <Text fontSize="12px" color="red">
                Les statuts doivent être à jour, signés, avec la mention
                "certifié conforme" sur la première page et paraphés sur toutes
                les pages
              </Text>
            </Box>
            <Box mt="20px">
              <input
                hidden
                type="file"
                ref={(element) => (hiddenLegalFormsInput = element)}
                onChange={(e) =>
                  handleFileSelect(e.target.files[0], "legalFormsDocument")
                }
              />
              <Button
                width="100%"
                color="white"
                borderRadius="0"
                bg="yalink.green"
                _hover={{ bg: "yalink.yellow" }}
                mt="3%"
                ml={isMobile ? "40%" : "60%"}
                w={isMobile ? "60%" : "40%"}
                onClick={() => {
                  hiddenLegalFormsInput.click();
                }}
                disabled={
                  !legalData.paymentProviderUserExists ||
                  legalData.paymentProviderUserExists === false
                }
              >
                {legalData && legalData.legalFormsDocument
                  ? "Modifier"
                  : "Ajouter"}
              </Button>
            </Box>
          </SimpleGrid>
        </Box>
      ) : null}
      <Box
        mt="5%"
        mb="1%"
        padding="5"
        borderWidth={1}
        borderRadius={8}
        boxShadow="md"
        maxW="100%"
      >
        <SimpleGrid columns={2}>
          <Box>
            <Text fontSize="18px" fontWeight="bold">
              Déclaration Mangopay
            </Text>
            <Link
              fontSize="12px"
              isExternal
              href="https://www.mangopay.com/terms/shareholder-declaration/Shareholder_Declaration-FR.pdf"
            >
              Obtenir la déclaration
            </Link>
          </Box>
          <Box mt="20px">
            <input
              hidden
              type="file"
              ref={(element) => (hiddenMangopayDeclarationInput = element)}
              onChange={(e) =>
                handleFileSelect(
                  e.target.files[0],
                  "mangopayDeclarationDocument"
                )
              }
            />
            <Button
              width="100%"
              color="white"
              bg="yalink.green"
              borderRadius="0"
              _hover={{ bg: "yalink.yellow" }}
              mt="3%"
              ml={isMobile ? "40%" : "60%"}
              w={isMobile ? "60%" : "40%"}
              onClick={() => {
                hiddenMangopayDeclarationInput.click();
              }}
              disabled={
                !legalData.paymentProviderUserExists ||
                legalData.paymentProviderUserExists === false
              }
            >
              {legalData && legalData.mangopayDeclarationDocument
                ? "Modifier"
                : "Ajouter"}
            </Button>
          </Box>
        </SimpleGrid>
      </Box>
    </Box>
  );
}

export default LegalDocuments;
