import {
  Box,
  GridItem,
  Text,
  Grid,
  Button,
  Input,
  Flex,
  Switch,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import InvoiceBreakdown from "./Molecules/InvoiceBreakdown";
import Header from "../Utils/Header";

const BillInfo = ({
  isMobile,
  activityReport,
  setActivityReport,
  legalData,
  history,
}) => {
  return (
    <Box
      h={isMobile ? null : "70%"}
      bg="white"
      color="black"
      borderRadius={7}
      boxShadow="md"
      pl={5}
      pr={5}
      pt={10}
      pb={4}
    >
      <Grid
        h="90%"
        templateColumns={isMobile ? null : "repeat(2,1fr)"}
        gap={isMobile ? null : "20"}
      >
        <GridItem>
          <Grid templateRows="repeat(6,1fr)" h="100%">
            <GridItem>
              <Flex direction="column">
                <Box>
                  <Text fontSize="18px" fontWeight="bold" color="#4E4C4C">
                    Numéro de facture / Votre référence interne
                  </Text>
                </Box>
                <Box>
                  <Input
                    borderColor="black"
                    placeholder="Ex : F2022-01-25"
                    value={activityReport.freelance_internal_reference}
                    onChange={(e) => {
                      let tempActivityReport = activityReport;
                      tempActivityReport.freelance_internal_reference =
                        e.target.value;
                      setActivityReport({ ...tempActivityReport });
                    }}
                  ></Input>
                </Box>
              </Flex>
            </GridItem>
            <GridItem>
              <Box mb="2%">
                <Text fontSize="20px" color="#4E4C4C">
                  Taux de TVA
                </Text>
              </Box>
              <Grid
                templateColumns="0.5fr 0.5fr 0.5fr"
                w="40%"
                alignItems="center"
              >
                <GridItem>
                  <Text fontSize="18px">0 %</Text>
                </GridItem>
                <GridItem>
                  <Switch
                    colorScheme="green"
                    isChecked={legalData.vatPercentage === 20}
                  ></Switch>
                </GridItem>
                <GridItem fontSize="18px">20 %</GridItem>
              </Grid>
            </GridItem>
            <GridItem>
              <Flex direction="column">
                <Box>
                  <Text fontSize="18px" color="#4E4C4C">
                    SIRET
                  </Text>
                </Box>
                <Box>
                  <Input
                    variant="filled"
                    borderColor="black"
                    value={legalData.siretNumber}
                    isDisabled
                  ></Input>
                </Box>
              </Flex>
            </GridItem>
            <GridItem>
              <Flex direction="column">
                <Box>
                  <Text fontSize="18px" color="#4E4C4C">
                    Adresse de facturation
                  </Text>
                </Box>
                <Box>
                  <Input
                    variant="filled"
                    borderColor="black"
                    value={legalData.headquartersAdress}
                    isDisabled
                  ></Input>
                </Box>
              </Flex>
            </GridItem>
            <GridItem>
              <Flex direction="column">
                <Box>
                  <Text fontSize="18px" color="#4E4C4C">
                    Nom
                  </Text>
                </Box>
                <Box>
                  <Input
                    variant="filled"
                    borderColor="black"
                    value={legalData.lastName}
                    isDisabled
                  ></Input>
                </Box>
              </Flex>
            </GridItem>
            <GridItem>
              <Flex direction="column">
                <Box>
                  <Text fontSize="18px" color="#4E4C4C">
                    Prénom
                  </Text>
                </Box>
                <Box>
                  <Input
                    variant="filled"
                    borderColor="black"
                    value={legalData.firstName}
                    isDisabled
                  ></Input>
                </Box>
              </Flex>
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem>
          <Grid templateRows={isMobile ? null : "repeat(6,1fr)"} h="100%">
            <GridItem>
              <Flex direction="column">
                <Box>
                  <Text fontSize="18px" color="#4E4C4C">
                    Votre société
                  </Text>
                </Box>
                <Box>
                  <Input
                    variant="filled"
                    borderColor="black"
                    value={legalData.businessName}
                    isDisabled
                  ></Input>
                </Box>
              </Flex>
            </GridItem>
            <GridItem>
              <Flex direction="column">
                <Box>
                  <Text fontSize="18px" color="#4E4C4C">
                    TVA Intracommunautaire
                  </Text>
                </Box>
                <Box>
                  <Input
                    variant="filled"
                    borderColor="black"
                    value={legalData.vatIntracommunityNumber}
                    isDisabled
                  ></Input>
                </Box>
              </Flex>
            </GridItem>
            <GridItem>
              <Flex direction="column">
                <Box>
                  <Text fontSize="18px" color="#4E4C4C">
                    Forme Juridique
                  </Text>
                </Box>
                <Box>
                  <Input
                    variant="filled"
                    borderColor="black"
                    value={legalData.legalStructure}
                    isDisabled
                  ></Input>
                </Box>
              </Flex>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
      <Flex justify="flex-end" mt={isMobile ? "10%" : null}>
        <Button
          borderRadius={0}
          onClick={() => {
            history.push("/freelance/enterprise");
          }}
        >
          Modifier mon entreprise
        </Button>
      </Flex>
    </Box>
  );
};

export default function ActivityReportReviewComponent({
  mission,
  activityReport,
  setActivityReport,
  legalData,
}) {
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(false);

  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    applyResponsive();
  }, []);

  window.addEventListener("resize", applyResponsive);

  const Fonctionnement = () => {
    return (
      <Box h="100%" bg="white" color="black" borderRadius={7} boxShadow="md">
        <Box
          h="20%"
          bg="yalink.green"
          borderTopRadius={7}
          padding="2vh"
          pl={5}
          mb="2%"
        >
          <Text color="white" fontWeight="semi-bold">
            Fonctionnement
          </Text>
        </Box>
        <Grid templateRows="repeat(3,1fr)" h="85%" ml="5%">
          <GridItem>
            <Text>1. Vérifiez les informations de votre entreprise</Text>
          </GridItem>
          <GridItem>
            <Text>2. Vérifiez votre taux de TVA</Text>
          </GridItem>
          <GridItem>
            <Text>3. Indiquez une référence de facture</Text>
          </GridItem>
        </Grid>
      </Box>
    );
  };

  return (
    <>
      <Box bgColor="yalink.bg">
        <Header isMobile={isMobile} mission={mission} activityReport={activityReport}/>
        {!isMobile ? (
          <Grid
            templateColumns={isMobile ? null : "2fr 5fr"}
            mr="4%"
            ml="4%"
            mb={isMobile ? "40%" : "10%"}
            gap={isMobile ? "1%" : "4%"}
          >
            <GridItem h="100%">
              <Grid templateRows="0.9fr 2fr" h="100%" gap="2%">
                <GridItem>
                  <Fonctionnement />
                </GridItem>
                <GridItem>
                  <InvoiceBreakdown
                    activityReport={activityReport}
                    legalData={legalData}
                    mission={mission}
                  />
                </GridItem>
              </Grid>
            </GridItem>
            <GridItem>
              <BillInfo
                isMobile={isMobile}
                activityReport={activityReport}
                setActivityReport={setActivityReport}
                legalData={legalData}
                history={history}
              />
            </GridItem>
          </Grid>
        ) : (
          <Flex mr="5%" ml="5%" mb="10%" direction="column">
            <Box mb="10%">
              <Fonctionnement />
            </Box>
            <Box mb="10%">
              <BillInfo />
            </Box>
            <Box mb="10%">
              <InvoiceBreakdown
                activityReport={activityReport}
                legalData={legalData}
                mission={mission}
              />
            </Box>
          </Flex>
        )}
      </Box>
    </>
  );
}
