import React from "react";
import { SignupSection } from '../../components';
import { homeObjOne } from './Data';


export default function FreelanceSignup() {
  return (
    <>
      <SignupSection {...homeObjOne} />
    </>
  )
}