import React, { useState, useEffect } from "react";
import Auth from "../../services/auth.service";
import { Link as RouterLink } from "react-router-dom";

import {
  Box,
  Grid,
  Center,
  Heading,
  Text,
  Image,
  Button,
  Link,
} from "@chakra-ui/react";

function SuccessfulSignupSection({ img, alt, start }) {
  const [picture, setPicture] = useState(true);

  const hidePicture = () => {
    if (window.innerWidth <= 960) {
      setPicture(false);
    } else {
      setPicture(true);
    }
  };

  useEffect(() => {
    hidePicture();
  }, []);

  window.addEventListener("resize", hidePicture);

  // isMobile responsive useState and useEffect

  const [isMobile, setIsMobile] = useState(false);

  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    applyResponsive();
  }, []);

  window.addEventListener("resize", applyResponsive);

  function renderSignup() {
    return (
      <Center>
        <Box
          w={isMobile ? "87%" : "60%"}
          mt="100px"
          mb={isMobile ? "80px" : "10px"}
          padding="5"
          maxW="3xl"
          borderWidth={1}
          borderRadius={5}
          bg="white"
          boxShadow="md"
        >
          <Heading as="h1" size="lg" mb="25px">
            Bienvenue dans l'aventure 🌱
          </Heading>
          <Text mb="10px">
            Nous venons de t'envoyer un email de vérification à l'adresse
            ci-dessous
          </Text>
          <Text mb="10px" fontWeight="bold">
            {Auth.getCurrentUser().email}
          </Text>
          <Text mb="20px">
            Clique sur le lien contenu dans cet email afin de finaliser ton
            inscription (courage, dans 30 secondes c'est fini !)
          </Text>
          <Box mt="10%">
            <Link
              color="teal.500"
              as={RouterLink}
              to="/freelance/about/you"
              style={{ textDecoration: "none" }}
            >
              <Button
                color="white"
                bgColor="yalink.green"
                borderRadius={0}
                w="60%"
                _hover={{ bg: "yalink.yellow" }}
              >
                Suivant
              </Button>
            </Link>
          </Box>
        </Box>
      </Center>
    );
  }

  return (
    <>
      {picture ? (
        <Grid templateColumns="repeat(2, 1fr)" gap={6} mt="50px" mb="100px">
          <Center>
            <Box boxSize="500px" mt="100px">
              <Image src={img} alt={alt} />
            </Box>
          </Center>
          {renderSignup()}
        </Grid>
      ) : (
        <>{renderSignup()}</>
      )}
    </>
  );
}

export default SuccessfulSignupSection;
