import axios from "axios";
import authHeader from "../libs/authHeaderLib";
import config from "../config";

class UserService {
  async getLoginStatus() {
    return axios.get(config.API_URL + "/auth/status", {
      headers: authHeader(),
    });
  }

  async changePassword({ oldPassword, password }) {
    return axios.put(
      config.API_URL + "/user/password",
      {
        oldPassword: oldPassword,
        password: password,
      },
      { headers: authHeader() }
    );
  }

  async deleteAccount({ email }) {
    return axios.post(
      config.API_URL + "/user",
      {
        email: email,
      },
      { headers: authHeader() }
    );
  }

  async forgetPassword({ email }) {
    return axios.post(config.API_URL + "/user/forget/password", {
      email: email,
    });
  }

  async resetPassword({ email, password, token }) {
    return axios.post(config.API_URL + "/user/reset/password", {
      email: email,
      password: password,
      token: token,
    });
  }

  async createVerificationCode({ email }) {
    return axios.post(
      config.API_URL + "/user/verification/email",
      {
        email: email,
      },
      { headers: authHeader() }
    );
  }

  async verifyEmail({ token }) {
    return axios.post(config.API_URL + "/user/confirm/email", {
      token: token,
    });
  }

  async createFreelance({ email, firstName, lastName, phone, linkedinUrl, acquisitionChannel }) {
    return axios.post(
      config.API_URL + "/user/freelance/initiate",
      {
        email: email,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        linkedinUrl: linkedinUrl,
        acquisitionChannel: acquisitionChannel,

      },
      { headers: authHeader() }
    );
  }

  async populateProfileFromLinkedin(freelanceId) {
    return axios.post(
      config.API_URL + "/user/freelance/linkedin/populate",
      { freelanceId: freelanceId },
      { headers: authHeader() }
    );
  }

  async getFreelance(id, isExternal) {
    let path = isExternal ? "/user/freelance/obfuscated" : "/user/freelance";
    if (id) {
      path += "?id=" + id;
    }
    return axios.get(config.API_URL + path, {
      headers: authHeader(),
    });
  }

  async getAllFreelances(page, perPage) {
    let path = "/user/freelance/all";
    if (page) {
      path += "?page=" + page;
      if (perPage) {
        path += "&per_page=" + perPage;
      }
    }
    return axios.get(config.API_URL + path, {
      headers: authHeader(),
    });
  }

  async searchFreelance(searchTerm, page, perPage) {
    let path = "/user/freelance/search?search_term=" + searchTerm;
    if (page) {
      path += "&page=" + page;
      if (perPage) {
        path += "&per_page=" + perPage;
      }
    }
    return axios.get(config.API_URL + path, {
      headers: authHeader(),
    });
  }

  async updateFreelancePreferences(preferences) {
    return axios.put(
      config.API_URL + "/user/freelance/preferences",
      preferences,
      { headers: authHeader() }
    );
  }

  async updateFreelanceDetails(details) {
    return axios.put(config.API_URL + "/user/freelance/details", details, {
      headers: authHeader(),
    });
  }

  async uploadProfilePicture(file) {
    if (!file) return null;

    const photoFormData = new FormData();

    photoFormData.append("picture", file);

    return axios.post(
      config.API_URL + "/freelance/file/profile-picture",
      photoFormData,
      { headers: authHeader() }
    );
  }
}

export default new UserService();
