import React, { useState, useEffect } from "react";
import CheckboxComponent from "./CheckboxComponent";
import {
  Button,
  Box,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  Center,
  Divider,
} from "@chakra-ui/react";
import jobtitlesService from "../../services/jobtitles.service";
import { Stars } from "@styled-icons/bootstrap/Stars";

const categoriesPerActivityArea = {
  industry: ["studies", "production", "project"],
  construction: ["studies", "works", "project"],
};

const industrialJobs = [
  {
    prettyName: "Études & Développement",
    category: "studies",
    activityArea: "industry",
  },
  {
    prettyName: "Production",
    category: "production",
    activityArea: "industry",
  },
  { prettyName: "Projets", category: "project", activityArea: "industry" },
];
const constructionJobs = [
  { prettyName: "Etudes", category: "studies", activityArea: "construction" },
  { prettyName: "Travaux", category: "works", activityArea: "construction" },
  { prettyName: "Projets", category: "project", activityArea: "construction" },
];

function ModalJobs(props) {
  const [currentSelectedJobs, setCurrentSelectedJobs] = useState(null);
  const [openCheckboxComponent, setOpenCheckboxComponent] = useState(null);
  const [availableJobTitles, setAvailableJobTitles] = useState(null);
  // Mobile responsive grid
  const [isMobile, setIsMobile] = useState(false);
  const [jobsCleaned, setJobsCleaned] = useState(false);
  const [processing, setProcessing] = useState(false);

  const buttonColors = {
    industry: {
      bg: "gray.100",
      color: "black",
    },
    construction: {
      bg: "gray.100",
      color: "black",
    },
  };

  const focusedIndustryColors = { bg: "yalink.yellow", color: "black" };
  const focusedConstructionColors = { bg: "yalink.green", color: "white" };

  const applyResponsive = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    async function getJobTitles() {
      await jobtitlesService
        .getAllJobTitles()
        .then((response) => {
          setAvailableJobTitles(response.data.job_titles);
        })
        .catch(() => {});
    }
    applyResponsive();
    if (!availableJobTitles) {
      getJobTitles();
    }
    if (jobsCleaned) {
      let tempDetails = props.freelanceDetails;
      for (const activityArea of ["industry", "construction"]) {
        for (const category of categoriesPerActivityArea[activityArea]) {
          availableJobTitles[activityArea][category].forEach((chkItem) => {
            if (chkItem.isChecked) {
              addJobTitle(chkItem.id);
              tempDetails.jobTitles.push({
                id: chkItem.name,
                text: chkItem.name,
              });
            }
          });
        }
      }
      props.setFreelanceDetails({ ...tempDetails });
      setProcessing(false);
      setJobsCleaned(false);
      props.setJobsOpen(false);
    }
  }, [availableJobTitles, setAvailableJobTitles, jobsCleaned, props]);

  window.addEventListener("resize", applyResponsive);

  function handleOnChange(event, option, selectedJobs) {
    let tempCurrentSelectedJobs = selectedJobs;
    tempCurrentSelectedJobs.forEach((chkItem) => {
      if (chkItem === option) {
        chkItem.isChecked = event.target.checked;
      }
    });
    let numberOfJobsChecked = tempCurrentSelectedJobs.filter(
      (chkItem) => chkItem.isChecked && chkItem.isChecked === true
    );
    if (numberOfJobsChecked && numberOfJobsChecked.length >= 3) {
      tempCurrentSelectedJobs.forEach((chkItem) => {
        if (!chkItem.isChecked || chkItem.isChecked === false) {
          chkItem.isDisabled = true;
        }
      });
    } else {
      tempCurrentSelectedJobs.forEach((chkItem) => {
        chkItem.isDisabled = false;
      });
    }
    setCurrentSelectedJobs([...tempCurrentSelectedJobs]);
  }

  const handleJobClick = (e, job) => {
    setOpenCheckboxComponent(true);
    // Should change the button color and bg to the hover colors
    // But for some reason it doesnt work for bg prop...
    // e.preventDefault();
    // e.target.style.bg = focusedButtonColors[job.activityArea].bg;
    // e.target.style.color = focusedButtonColors[job.activityArea].color;
    setCurrentSelectedJobs(availableJobTitles[job.activityArea][job.category]);
  };

  async function addJobTitle(id) {
    await jobtitlesService
      .addJobTitleToFreelance({ id: id })
      .then((response) => {})
      .catch(() => {});
  }

  async function clearPreviousJobTitles() {
    await jobtitlesService
      .cleanUpJobTitles()
      .then((response) => {
        setJobsCleaned(true);
      })
      .catch(() => {});
  }

  const handleCheckClick = () => {
    let tempCurrentSelectedJobs = currentSelectedJobs;
    let tempDetails = props.freelanceDetails;
    if (
      tempCurrentSelectedJobs &&
      tempCurrentSelectedJobs.some((e) => {
        return e.isChecked;
      })
    ) {
      setProcessing(true);
      clearPreviousJobTitles();
      tempDetails.jobTitles = [];
      props.setFreelanceDetails({ ...tempDetails });
    }
  };

  return (
    availableJobTitles && (
      <div>
        <Box w={isMobile ? "40%" : "100%"} ml={isMobile ? "240px" : null}>
          <ModalHeader>
            <Stars size="40px" color="#F8C708" /> Complète tes métiers
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb="auto">
            <Stack
              direction={isMobile ? "column" : "row"}
              spacing={4}
              mb={isMobile ? "10%" : null}
            >
              <Box>
                <Box
                  bg="gray.100"
                  w="100%"
                  p={4}
                  color="black"
                  borderRadius="7px"
                  mr="5px"
                >
                  Renseigne les métiers dans lesquels tu souhaites qu’on te
                  propose des missions
                </Box>
                <ModalHeader>🧑🏻‍🔧 Métiers de l'industrie</ModalHeader>
                <Stack direction="row" spacing={4}>
                  {industrialJobs.map((job) => (
                    <Button
                      onClick={(e) => handleJobClick(e, job)}
                      key={job.prettyName}
                      size="md"
                      height="120px"
                      width="160px"
                      color={buttonColors["industry"].color}
                      bg={buttonColors["industry"].bg}
                      _hover={focusedIndustryColors}
                      _focus={focusedIndustryColors}
                      style={{
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                      }}
                    >
                      {job.prettyName}
                    </Button>
                  ))}
                </Stack>
                <ModalHeader>👨🏼‍🚒 Métiers de la construction</ModalHeader>
                <Stack direction="row" spacing={4}>
                  {constructionJobs.map((job) => (
                    <Button
                      onClick={(e) => handleJobClick(e, job)}
                      key={job.prettyName}
                      size="md"
                      height="120px"
                      width="160px"
                      color={buttonColors["construction"].color}
                      bg={buttonColors["industry"].bg}
                      _hover={focusedConstructionColors}
                      _focus={focusedConstructionColors}
                      variant="outline"
                      style={{
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                      }}
                    >
                      {job.prettyName}
                    </Button>
                  ))}
                </Stack>
              </Box>
              {openCheckboxComponent ? (
                <>
                  {isMobile ? null : (
                    <Center height="515px">
                      <Divider orientation="vertical" />
                    </Center>
                  )}
                  <Center>
                    <Box>
                      <div className="App container">
                        <div>
                          <div className="row">
                            <div className="col-xl-12">
                              <ModalHeader>🕵🏻‍♀️ Choisis ton métier :</ModalHeader>
                              <div className="form-row">
                                <div className="form-group col-xl-12">
                                  <CheckboxComponent
                                    JOBS={currentSelectedJobs}
                                    onChange={handleOnChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Center>
                </>
              ) : null}
            </Stack>
            <Button
              ml={isMobile ? "70px" : null}
              ms={isMobile ? null : "285"}
              mb="5"
              mt="8"
              onClick={() => handleCheckClick()}
              disabled={processing}
              isLoading={processing}
            >
              Enregistrer
            </Button>
          </ModalBody>
        </Box>
      </div>
    )
  );
}

export default ModalJobs;
